import { Tooltip } from "@mui/material";
import React, { useContext } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
const FavMark = ({ id, fav, userListItem }) => {
  const { setPageLoading } = useContext(DataContext);
  const handleFavoriteMark = () => {
    setPageLoading(true);
    apiService(`user/fav/mark?id=${id}&type=TANFUND`, {}, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data?.responseStatus === "Success") {
          userListItem();
        }
      })
      .catch((err) => {});
  };
  const handleFavoriteUnMark = () => {
    setPageLoading(true);
    apiService(`user/fav/unmark?id=${id}&type=TANFUND`, {}, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data?.responseStatus === "Success") {
          userListItem();
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Tooltip title="Add to Favorite" placement="top">
        {fav ? (
          <StarIcon
            className="c-yellow c-pointer"
            onClick={() => handleFavoriteUnMark()}
          />
        ) : (
          <StarBorderIcon
            className="c-pointer"
            onClick={() => handleFavoriteMark()}
          />
        )}
      </Tooltip>
    </>
  );
};

export default FavMark;
