import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import './scst-page/scst.css'
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import { Badge } from '@mui/material';
import NotificationsIcon from "@mui/icons-material/Notifications";
const ScstadminNavbar = ({ role }) => {
    const { scstRemoveFiters } = useContext(DataContext);
    const handleClick = (event) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
        }
    };
    const thresholdCount = localStorage.getItem('thresholdCount')
    const notificationCount = localStorage.getItem('notificationCount')
    return (
        <nav>
            <div className='submenu_div'>
                <div className='container-fluid'>
                    <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/dashboard`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Dashboard</NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/startups`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Startups</NavLink>
                        </Typography>
                        {/* <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/report`} onClick={handleClick}>Report</NavLink>
                        </Typography> */}
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/report`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Report</NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/import-page`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Import</NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink
                                className="nav-link"
                                to={`/${role}/approval-pending`}
                                onClick={(e) => {
                                    handleClick(e);
                                    scstRemoveFiters();
                                }}
                            >
                                Approval Pending
                                <span className='pos-top1'>
                                    {/* <Badge badgeContent={notificationCount} color="primary" sx={{width:'10px'}}>
                                </Badge> */}
                                    {notificationCount > 0 && (
                                        <Badge color="primary" sx={{ width: '10px' }}>
                                            {/* <NotificationsIcon sx={{ color: "#253b80" }} /> */}
                                            <img src="/images/notification.gif" alt="" style={{ width: '28px' }} />
                                        </Badge>
                                    )}

                                </span>
                            </NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink
                                className="nav-link"
                                to={`/${role}/alert-pending`}
                                onClick={(e) => {
                                    handleClick(e);
                                    scstRemoveFiters();
                                }}
                            >
                                Crossed Threshold
                                <span className='pos-top2'>
                                    {/* <Badge badgeContent={thresholdCount} color="primary">
                                    </Badge> */}
                                    {thresholdCount > 0 && (
                                        <Badge color="primary" sx={{ width: '10px' }}>
                                            <img src="/images/alerts.gif" alt="" style={{ width: '23px', marginLeft: '-8px' }} />
                                        </Badge>
                                    )}

                                </span>
                            </NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/scstadmin/mentortab`} onClick={handleClick}>Mentorship Startup</NavLink>
                        </Typography>
                        {/* <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/previousmentor`} onClick={handleClick}>Previous Startup</NavLink>
                        </Typography> */}
                    </Box>
                </div>
            </div>
        </nav>
    )
}


export default ScstadminNavbar