import { TabContext, TabList } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormHelperText, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../api/notifySerivce';
import { MuiFileInput } from 'mui-file-input';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Viewimage from '../../../api/Viewimage';

const DocumentRoomStartup = () => {
    const {
        handleSubmit,
        // getValues,
        control,
        reset,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm({});
    const [addInfoValue, setAddInfoValue] = React.useState("1");
    const handleChangeInfo = (event, newValue) => {
        debugger
        setAddInfoValue(newValue);
    };


    const { setPageLoading, navigator,notificationBell,setNotificationBell,notificationGet,tableIndexValue } = useContext(DataContext);
    const [historyListValue, setHistoryListValue] = useState([]);
    const [countHistory, setCountHistory] = useState(0);
    const [statusValue, setStatusValue] = useState(0);
    const [pageHistory, setPageHistory] = useState(0);
    const [rowsPerPageHistory, setRowsPerPageHistory] = useState(10);
    const [historyNotify, setHistoryNotify] = useState(false);
    useDidMountEffect(() => {
        RoomList();
    }, [statusValue, pageHistory, rowsPerPageHistory]);
    const RoomList = () => {
        setHistoryListValue([]);
        setPageLoading(true);
        var req = {
            listSize: rowsPerPageHistory,
            pageNumber: pageHistory + 1,
            status: statusValue,
            startupId: localStorage.getItem('userId')
        };
        apiService("document/room/details/list", req, "post").then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                setCountHistory(res.data.count);
                // setHistoryNotify(res.data.additionalInfoRequested);
                if (res.data.documentRoomDetailsList) {
                    res.data.documentRoomDetailsList.forEach((element) => {

                        if (element.docUrl) {
                            var val = element.docUrl;
                            var data = val.split("?");
                            if (data) {
                                var formet = data[0].split(".");
                                element.formet = formet[4];
                            }
                        }
                    });
                    setHistoryListValue(res.data.documentRoomDetailsList);
                }
            }
        });
    };
    const [statusVal, setStatusVal] = useState();
    const handleStatus = (value, status) => {
        setStatusValue(value);
        notificationGet()
    };
    const handleHistoryChangePage = (event, newPage) => {
        setPageHistory(newPage);
    };
    const handleChangeHistoryRowsPerPage = (event) => {
        setRowsPerPageHistory(parseInt(event.target.value, 10));
        setPageHistory(0);
    };
    const [isFullTextVisible1, setIsFullTextVisible1] = useState({})
    const toggleText1 = (index) => {
        setIsFullTextVisible1(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const [isFullTextVisible2, setIsFullTextVisible2] = useState({})
    const toggleText2 = (index) => {
        setIsFullTextVisible2(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const truncateTexts = (text, charLimit) => {
        if (typeof text !== 'string') return '';
        return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
    };
    const [open, setOpen] = useState(false);
    const [saveId, setSaveId] = useState();
    const handleClickOpen = (id) => {
        setOpen(true);
        setSaveId(id)
        reset()
    };
    const handleClose = () => {
        reset()
        setAdditionalDocValue(null)
        setOpen(false);
    };
    const [additionalDocURL, setAdditionalDocURL] = useState(null);
    const [additionalDocValue, setAdditionalDocValue] = useState(null);
    let logoselecetdFile;
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        debugger;
        if (event) {
            if (event.target === undefined) {
                logoselecetdFile = event;
            } else {
                logoselecetdFile = event.target.files[0];
            }
            const maxFileSize = 10 * 1024 * 1024;
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    debugger
                    if (
                        img_crt_type === "jpeg" ||
                        img_crt_type === "jpg" ||
                        img_crt_type === "png" ||
                        img_crt_type === "pdf" ||
                        img_crt_type === "csv" ||
                        img_crt_type ===
                        "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        img_crt_type ===
                        "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                        img_crt_type === "x-zip-compressed"
                    ) {
                        setValue(filekey, "");
                        setValue(fileName, "");
                        setdata("");
                        setUrl("");
                        if (event.target === undefined) {
                            setdata(event);
                        } else {
                            setdata(event.target.files[0]);
                        }
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            setUrl(logourl1);
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            clearErrors(filekey);
                            clearErrors(fileName);
                            debugger;
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File size exceeds 10MB",
                        "Please check your file size"
                    );
                }
            }
        }
    };
    const onSubmit = (data) => {
        setPageLoading(true);
        var getform = data;
        getform.startupId = localStorage.getItem('userId');
        getform.status = 1;
        getform.id = saveId;
        debugger
        apiService("document/room/request/reponse/save", getform, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService("success", "Success", "Saved successfully !!");
                    RoomList();
                    notificationGet()
                    handleClose();

                }
            }
        });
    };
    let userId = localStorage.getItem('userId');
      const [openModal, setOpenModal] = useState(false)
          const [listId, setListId] = useState(0)
      const OpensureModal = (id) => {
        setOpenModal(true)
        setListId(id)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const submit = () => {
        setPageLoading(true);
        var req = {
            "startupId": userId,
            "status": 2,
            "id": listId
        }
        debugger
        apiService("document/room/request/reponse/save", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService("success", "Success", "Saved successfully !!");
                    RoomList();
                    notificationGet()
                    setOpenModal(false)
                }
            }
        });
    }
    const [open2, setOpen2] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpenDoc = (value, formet) => {
        debugger
        setViewImage(value);
        if (formet === "xlsx" || formet === "docx" || formet === "zip" || formet === "csv") {
            const link = document.createElement('a');
            link.href = value;
            // link.download = 'excelfile.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            setOpen2(true);
        }
    };
    const handleCloseDoc = () => {
        setOpen2(false);
    };
    return (
        <>
            <TabContext value={addInfoValue}>
                <Box
                    sx={{ borderBottom: 0, borderColor: "transparent" }}
                >
                    <TabList
                        onChange={handleChangeInfo}
                        aria-label="lab API tabs example"
                    >
                        <Tab
                            label={
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Pending
                                    {notificationBell === true ? (
                                        <span className="ml-2">
                                            <img
                                                src="/images/giphy.gif"
                                                alt="gif"
                                                className="gif-bell img-fluid"
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            }
                            value="1"
                            onClick={() => handleStatus(0)}
                        />
                        <Tab
                            label="Submitted"
                            value="2"
                            onClick={() => handleStatus(4)}
                        />

                    </TabList>
                </Box>
                <Divider />
                <div className="mt-3">
                    {historyListValue.length > 0 &&
                        historyListValue.length ? (
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell align="center">
                                            Subject
                                        </TableCell>
                                        <TableCell align="center">
                                            Body
                                        </TableCell>
                                        <TableCell align="center">
                                            Requested Time
                                        </TableCell>

                                        {statusValue === 4 && (
                                            <TableCell align="center">
                                                Submitted Time
                                            </TableCell>
                                        )}
                                        {statusValue === 4 && (
                                            <TableCell align="center">
                                                Status
                                            </TableCell>
                                        )}
                                        <TableCell align="center">
                                            {statusValue === 4
                                                ? `Attached Document`
                                                : `Upload Document`}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {historyListValue.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">
                                                {/* {index + 1} */}
                                                {tableIndexValue(rowsPerPageHistory, pageHistory, index)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {list.subject ? (
                                                    <>
                                                        {isFullTextVisible1[index]
                                                            ? list.subject
                                                            : truncateTexts(list.subject || '', 20)}
                                                        {list.subject && list.subject.length > 20 && (
                                                            <h6
                                                                onClick={() => toggleText1(index)}
                                                                style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                            >
                                                                {isFullTextVisible1[index] ? " Read Less" : " Read More"}
                                                            </h6>
                                                        )}
                                                    </>) : ((`-`))}
                                            </TableCell>
                                            <TableCell align="center">
                                                {list.body ? (
                                                    <>
                                                        {isFullTextVisible2[index]
                                                            ? list.body
                                                            : truncateTexts(list.body || '', 20)}
                                                        {list.body && list.body.length > 20 && (
                                                            <h6
                                                                onClick={() => toggleText2(index)}
                                                                style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                            >
                                                                {isFullTextVisible2[index] ? " Read Less" : " Read More"}
                                                            </h6>
                                                        )}
                                                    </>) : ((`-`))}
                                            </TableCell>
                                            <TableCell align="center">
                                                {list.requestedTime}
                                            </TableCell>
                                            {statusValue === 4 && (
                                                <TableCell align="center">
                                                    {list.submittedTime ?? '-'}
                                                </TableCell>
                                            )}
                                            {statusValue === 4 && (
                                                <TableCell align="center">
                                                    {list.status === 1 && ("Submitted")}
                                                    {list.status === 2 && ("Responded")}
                                                    {list.status === 3 && ("Request Closed")}
                                                </TableCell>
                                            )}
                                            {statusValue === 0 && (
                                                <TableCell align="center">
                                                    {/* <Button className='mr-2' style={{ background: '#aec1ff', color: 'black' }}>Mark as NA</Button> */}
                                                    {/* <i class="fas fa-pencil-alt" onClick={() =>handleClickOpen(list.id)} style={{ color: 'green', cursor: 'pointer', fontSize: '18px' }}></i> */}
                                                    <Tooltip title="Mark as NA" arrow>
                                                        <img src="/images/error-file.png" alt="" style={{ width: '35px',cursor:'pointer' }} onClick={() => OpensureModal(list.id)} />
                                                    </Tooltip>
                                                    <Tooltip title="Upload Document" arrow>
                                                        <DriveFolderUploadIcon onClick={() => handleClickOpen(list.id)} style={{ color: '#518EF8', cursor: 'pointer', fontSize: '35px' }} />
                                                    </Tooltip>

                                                </TableCell>
                                            )}
                                            {statusValue === 4 && (
                                                <TableCell align="center">
                                                    {list.docUrl ? (<>
                                                        <Tooltip title="View Document" arrow>
                                                            <img
                                                                src="/images/img/file (1).png"
                                                                alt="Document Icon"
                                                                style={{ cursor: 'pointer', width: '35px' }}
                                                                onClick={() => handleClickOpenDoc(list.docUrl,list.formet)}
                                                            />
                                                        </Tooltip></>) : (<>
                                                            {list.status === 2 && ("NA")}
                                                            {list.status === 3 && ("-")}
                                                        </>)}

                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <caption>
                                    <TablePagination
                                        component="div"
                                        count={countHistory}
                                        page={pageHistory}
                                        onPageChange={handleHistoryChangePage}
                                        rowsPerPage={rowsPerPageHistory}
                                        onRowsPerPageChange={
                                            handleChangeHistoryRowsPerPage
                                        }
                                        style={{ float: "left" }}
                                    />
                                </caption>
                            </Table>
                        </TableContainer>
                    ) : (
                        <div className="nodata_tag">
                            <img
                                src="/images/Tanfundlogo-black-1.png"
                                className="no_data_logo"
                                alt=""
                            />
                            <p>No Records Found</p>
                        </div>
                    )}

                </div>
            </TabContext>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="form-group mt-2">
                                    <Controller
                                        name="doc"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Please Upload Your Document",
                                        }}
                                        render={({ field: { value, onChange, ...field } }) => (
                                            <MuiFileInput
                                                {...field}
                                                onChange={(file) => {
                                                    if (file) {
                                                        handleFileUpload(
                                                            file,
                                                            "doc",
                                                            "docName",
                                                            setAdditionalDocValue,
                                                            setAdditionalDocURL
                                                        );
                                                    } else {
                                                        setAdditionalDocValue("");
                                                        setValue("doc", "");
                                                    }
                                                }}
                                                value={additionalDocValue}
                                                variant="outlined"
                                                fullWidth={true}
                                                label="Upload Document"
                                                placeholder="Choose File"
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.doc && errors.doc.message}
                                    </FormHelperText>
                                    <span className="font-sm">
                                        Maximum 10 mb allowed doc (png, jpg, jpeg, pdf, excel, doc, csv, zip){" "}
                                        {/* <span className="text-danger">*</span> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <Button className="mr-3" onClick={handleClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={() => submit()}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Viewimage
                viewImage={viewImage}
                open={open2}
                handleClose={handleCloseDoc}
            />
        </>
    )
}

export default DocumentRoomStartup