import React, { useContext, useState } from 'react'
import NewReportFilters from './NewReportFilters'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import DataContext from '../../../context/DataContext'
import apiService from '../../../api/apiService'

import notifyService from '../../../api/notifySerivce'

const NewScStReport = () => {
    const { setPageLoading } = useContext(DataContext)
    const [scsthub, setScsthub] = useState("All")
    const [yearNameSelected, setYearNameSelected] = useState('All');
    const [report, setReport] = useState('');
    const [yearName, setYearName] = useState('0');
    const [scsthub2, setScsthub2] = useState("All")
    const [yearNameSelected2, setYearNameSelected2] = useState('All');
    const [report2, setReport2] = useState('');
    const [yearName2, setYearName2] = useState('0');

    const [openExportModal, setOpenExportModal] = useState(false)
    const handleExportCloseModal =()=>{setOpenExportModal(false)}
    const [openExportModal2, setOpenExportModal2] = useState(false)
    const handleExportCloseModal2 =()=>{setOpenExportModal2(false)}

    const handleClear = () => {
        setScsthub("All");
        setYearNameSelected("All")
        setYearName('0')
       
    };
    const handleClear2 = () =>{
        setScsthub2("All");
        setYearNameSelected2("All")
        setYearName2('0')
    }
    const handleExport = ()=>{
       
        let startYear = '0';   

        if (yearName && yearName !== 'All') { 
            debugger
            startYear = yearName.split('-')[0].trim();
        }else{
            startYear = '0'; 
        }
        debugger
        var req = {
            hub: scsthub,
            startYear:startYear,
            report:report
        }
        // if(report === "Criteria Report"){
         
        // } else{
        //     var req = {
        //         hub: scsthub,
        //         startYear:startYear,
        //         report:report
        //     }
        // }
       
        debugger
        apiService('admin/scst/admin/report/mail', req, "post")
        .then((result) => {
            if(result){
                notifyService('success', 'Success', 'Export successfully !!')
                handleExportCloseModal()
                // handleClear()
            }
        }).catch((err) => {
            console.log(err);
            
        });
    }
    const handleExport2 = ()=>{
        // setPageLoading(true)
    
        let startYear = '0';   

        if (yearName2 && yearName2 !== 'All') { 
            startYear = yearName2.split('-')[0].trim();
        }else{
            startYear = '0'; 
        }
      
        var req = {
            hub: scsthub2,
            startYear:startYear,
            report:report2
        }
      
       
        debugger
        apiService('admin/scst/admin/checklist/report/mail', req, "post")
        .then((result) => {
            if(result){
                notifyService('success', 'Success', 'Export successfully !!')
                handleExportCloseModal2()
                // handleClear()
            }
        }).catch((err) => {
            console.log(err);
            
        });
    }
    return (
        <div className="reports-commons">
            <div className="">
                <NewReportFilters setOpenExportModal={setOpenExportModal} setOpenExportModal2={setOpenExportModal2} scsthub={scsthub}  setScsthub={setScsthub} yearNameSelected={yearNameSelected} setYearNameSelected={setYearNameSelected} yearName={yearName} setYearName={setYearName}  report={report} setReport={setReport} scsthub2={scsthub2}  setScsthub2={setScsthub2} yearNameSelected2={yearNameSelected2} setYearNameSelected2={setYearNameSelected2} yearName2={yearName2} setYearName2={setYearName2}  report2={report2} setReport2={setReport2} handleClear={handleClear} handleClear2={handleClear2} />
                
            </div>
            <Dialog
                open={openExportModal}
                // onClose={handleExportCloseModal} 
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention -man.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleExportCloseModal}
                    >
                        Back
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={()=>handleExport()}>
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openExportModal2}
                // onClose={handleExportCloseModal} 
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention -man.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleExportCloseModal2}
                    >
                        Back
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={()=>handleExport2()}>
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default NewScStReport
