import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from '@mui/material/Tooltip';
import DataContext from "../../../context/DataContext";
// import useDidMountEffect from '../../hooks/useDidMountEffect';
// import apiService from '../../api/apiService';
import { useParams } from "react-router-dom";
import Scsthubavpremarks from "./Scsthubavpremarks";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
// import Scsthubavpremarks from '../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';

const Scsthubavpevaluation = ({ listData, listApi, startupId, startupCheckList, showStartupValue }) => {
  // console.log(listApi());
  debugger
  const { navigator, Accordion, AccordionSummary, AccordionDetails, setPageLoading, } =
    useContext(DataContext);
  // const [viewQuestionData, setViewQuestionData] = useState([])
  // const [startupData, setStartupData] = useState({})

  const [juryData, setJuryData] = useState([]);
  const roles = localStorage.getItem("role");
  useDidMountEffect(() => {
    if (roles === "SCSTHUB") {
      listApi();
    }
  }, []);

  useDidMountEffect(() => {
    if (listData) {
      // setListData(listData.startupRounds[1])
      setJuryData(listData.jury);
    }
  }, [listData]);

  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const getstatusText = (value) => {
    if (value === 0) {
      return "N/A";
    } else if (value === 1) {
      return "Recommend to BDD First Connect";
    } else if (value === 2) {
      return "Recommend to Mentorship";
    } else {
      return "";
    }
  };
  const [openModal, setOpenModal] = useState(false);
  const [juryId, setJuryId] = useState();
  const [juryStatus, setJuryStatus] = useState();
  const [modalVal, setModalVal] = useState();
  const handleOpenModal = (id, status, val) => {
    setOpenModal(true);
    setJuryId(id)
    setJuryStatus(status)
    setModalVal(val)
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const partnerId = localStorage.getItem('userId')
  const jurBtn = () => {
    setPageLoading(true)
    var req = {
      startupId: startupId,
      juryId: juryId,
      partnerId: partnerId,
      juryStatus: juryStatus
    };
    debugger
    apiService("partner/scst/startuplevel/jury/status/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        setOpenModal(false);
        showStartupValue()
        debugger
        listApi();

      }
    });
  };
  return (
    <>
      {listData && (
        <div className="">
          <div className="table_header_tag d-flex justify-content-between">
            <div>
              Status : <b className="c-green">{getstatusText(listData.status)}</b>
            </div>
            {listData && juryData && (
              <div className="ml-auto">
                Overall Average Score : <b>{listData.avgScore}</b>
              </div>
            )}
          </div>
          {juryData ? (
            juryData.map((data, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id={`panel${index}d-header`}
                >
                  <div className="row w-100">
                    <div className="col-lg-4 col-md-6 col-12">
                      <b>{data.name}</b>
                      {(roles === "SCSTHUBAVP" || roles === 'SCSTADMIN' || roles === 'MENTORSHIP') && (
                        <>
                          {data.markAsPresent ? (
                            <span className="text-red-500 pre-font ml-2">(Absent)</span>
                          ) : (
                            <span className="text-green-500 pre-font ml-2">(Present)</span>
                          )}
                        </>
                      )}
                      {roles === "SCSTHUB" && (
                        <>
                          {((startupCheckList.status === 1) || (startupCheckList.status === 28)) && (data.status === 0) && (

                            <>
                              {data.markAsPresent === true &&
                                <>
                                  <span className="text-red-500 pre-font mx-2">(Absent)</span>
                                  <Tooltip title="Mark as Present">
                                  <EventAvailableIcon onClick={() => handleOpenModal(data.id, 1, 'Confirm to mark as present ?')}
                                     style={{ color: 'green', cursor: 'pointer' }}/>
                                     </Tooltip>
                                  {/* <button className="viewdetails-Approve mb-2" onClick={() => handleOpenModal(data.id, 1, 'Confirm to Present')}>Mark as Present</button> */}
                                </>
                              }
                              {data.markAsPresent === false &&
                                <>
                                  <span className="text-green-500 pre-font mx-2">(Present)</span>
                                  <Tooltip title="Mark as Absent">
                                  <EventBusyIcon onClick={() => handleOpenModal(data.id, 2, 'Confirm to mark as absent ?')}
                                     style={{ color: '#d11100', cursor: 'pointer' }}/>
                                     </Tooltip>
                                   
                                  {/* <button className="viewdetails-Schemes mb-2" onClick={() => handleOpenModal(data.id, 2, 'Confirm to Absent')}>Mark as Absent</button> */}
                                </>
                              }
                            </>
                          )}


                        </>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      Average Score : <b>{data.score}</b>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12" style={{fontSize:'14px'}}>
                      Status :
                      <b>
                        {data.status === 0 && <span> Pending</span>}
                        {data.status === 1 && (
                          <span className="c-green">
                            {" "}
                            Recommend to BDD First Connect
                          </span>
                        )}
                        {data.status === 2 && (
                          <span className="c-green">
                            {" "}
                            Recommend to Mentorship
                          </span>
                        )}
                        {data.status === 3 && (
                          <span className="c-red"> Rejected</span>
                        )}
                      </b>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="table_tag">
                    {data.startupQuestions ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="caption table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>No</TableCell>
                              <TableCell>Question</TableCell>
                              <TableCell align="center">Rating</TableCell>
                              {/* {list.questionType !== "yesno" && ( */}
                              <TableCell align="center">Description</TableCell>
                              {/* )} */}
                              <TableCell align="center">Remarks</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.startupQuestions.map((list, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{list.question}</TableCell>
                                <TableCell align="center">
                                  {list.questionType === "yesno"
                                    ? list.answer
                                    : list.score}
                                </TableCell>
                                {/* {list.questionType !== "yesno" && ( */}
                                {/* <TableCell align="center">
                                  {list.scoreDescription
                                    ? list.scoreDescription[list.score - 1]
                                        .description
                                    : "-"}
                                </TableCell> */}
                                {/* <TableCell align="center">
                                  {list.scoreDescription && list.score > 0 && list.score <= list.scoreDescription.length
                                    ? list.scoreDescription[list.score - 1].description
                                    : "-"}
                                </TableCell> */}
                                <TableCell align="center">
                                  {list.scoreDescription && list.scoreDescription.length > 0
                                    ? list.scoreDescription.map((desc, index) => (
                                      <div key={index}>
                                        {desc.rating === list.score ? desc.description : null}
                                      </div>
                                    ))
                                    : "-"}
                                </TableCell>

                                {/* )} */}
                                <TableCell align="center">
                                  <Scsthubavpremarks
                                    remarks={list.remarks}
                                    lblName="View"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="nodata_tag">
                        <img
                          src="/images/Tanfundlogo-black-1.png"
                          className="no_data_logo"
                          alt=""
                        />
                        <p>No Data Available</p>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <div className="nodata_tag">
              <img
                src="/images/Tanfundlogo-black-1.png"
                className="no_data_logo"
                alt=""
              />
              <p>No Data Available</p>
            </div>
          )}
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <center>
                <img src="/images/img/attention.gif" alt="" className="alert-image" />
              </center>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4 className="text-center p-3">{modalVal}</h4>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="d-flex justify-content-center mb-2">
              <Button
                className="remove-api-modal-cancel"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button className="remove-api-modal-confirm" onClick={jurBtn}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>

      )}
    </>
  );
};

export default Scsthubavpevaluation;
