import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import notifyService from '../../../api/notifySerivce';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import axios from 'axios';

const UpdateStatus = ({ startupId, userListItemTanseed, scstHublist, setCheckedList, setItemsChecked }) => {
    const { modalstyle, setPageLoading, fullScreen } = useContext(DataContext)
    const [formData, setFormData] = useState({})
    const { handleSubmit, reset, control, clearErrors, setValue, formState: { errors } } = useForm({ values: formData });
    const [openEmailModal, setopenEmailModal] = useState(false)
    // useDidMountEffect(() => {
    //     scstStatusFilterApi()
    // }, [])
    // const [startupStatusValue, setStartupStatusValue] = useState([])
    // const scstStatusFilterApi = () => {
    //     axios.get('/json/startupstatus.json', '').then((result) => {
    //         setStartupStatusValue(result.data.phaseName)
    //         debugger
    //         console.log(result.data.phaseName);

    //     }).catch((err) => {

    //     });
    // }
    const [startupStatusValue, setStartupStatusValue] = useState([]);
    const [phaseNames, setPhaseNames] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState('');
    const [stageNames, setStageNames] = useState([]);

    useEffect(() => {
        scstStatusFilterApi();
    }, []);

    const scstStatusFilterApi = () => {
        axios.get('/json/startupstatus.json')
            .then((result) => {
                const stages = result.data.flatMap(phase => phase.stageList);
                setStageNames(stages); // Set the flattened stage list
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const [btnShow, setBtnShow] = useState(false)
    useEffect(() => {
        if (startupId.length > 0) {
            setBtnShow(true)
        }
        else {
            setBtnShow(false)
        }
    }, [startupId])




    // const handlePhaseChange = (e) => {
    //     const selected = e.target.value;
    //     setSelectedPhase(selected);
    //     const selectedPhaseData = startupStatusValue.find(item => item.phaseName === selected);
    //     setStageNames(selectedPhaseData ? selectedPhaseData.stageList : []); 
    // };
    const handlePhaseChange = (e, fieldOnChange) => {
        const selected = e.target.value;
        setSelectedPhase(selected); // Update selected phase in local state
        const selectedPhaseData = startupStatusValue.find(item => item.phaseName === selected);
        setStageNames(selectedPhaseData ? selectedPhaseData.stageList : []); // Update stages

        fieldOnChange(selected); // Call field's onChange to update form state
    };
    const handleOpenModal = () => {
        setFormData({})
        reset()
        setSelectedPhase('')
        setopenEmailModal(true)
    }
    const handleMarkEditable = () => {
        var startupIdList;
        if (typeof startupId === 'string') {
            startupIdList = [startupId];
        } else {
            startupIdList = startupId;
        }
        const data = {
            startupIdList: startupIdList
        };

        apiService('partner/scst/enable/editform', data, 'post').then((result) => {
            debugger
            setPageLoading(false)
            if (result) {
                debugger
                if (result.data.responseStatus === "Success") {
                    notifyService('success', 'Success', result.data.responseStatus)
                    setItemsChecked(false);
                    setCheckedList([])

                }
            }
        }).catch((err) => {

        });

    }
    const handleOpenModalClose = () => {
        setFormData({})
        reset()
        setopenEmailModal(false)
    }
    const [statusValue, setStatusValue] = useState(0)
    const handleStatus = (value) => {
        setStatusValue(value)
        setValue('status', value)
        clearErrors('status')
    }
    const onSubmit = (data) => {
        delete data.phase
        var val = typeof startupId
        if (val === 'string') {
            data.startupId = [startupId]
        } else {
            data.startupId = startupId
        }
        console.log(data);
        setPageLoading(true)
        apiService('startup/scst/round/update', data, 'post').then((result) => {
            debugger
            setPageLoading(false)
            if (result) {
                debugger
                if (result.data.responseStatus === "Updated Successfully") {
                    notifyService('success', 'Success', result.data.responseStatus)
                    handleOpenModalClose()
                    userListItemTanseed()
                    scstHublist()

                }
            }
        }).catch((err) => {

        });
        // setEmailValues(emails)
        // exportExcelStartup('All',emails)
    };
    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };
    const roles = localStorage.getItem('role')

    return (
        <div>
            {roles === 'SCSTADMIN' && (
                <>
                    <Button
                        variant="contained"
                        onClick={handleOpenModal}
                        disabled={!btnShow}
                        className={btnShow ? "down-pdf-btn mb-3 mr-2": "down-pdf-btn-disabled mb-3 mr-2"}

                    >
                        Update Status
                    </Button>
                    <Button
                       variant="contained"
                        onClick={handleMarkEditable}
                        disabled={!btnShow}
                        className={btnShow ? "down-pdf-btn mb-3 ml-2": "down-pdf-btn-disabled mb-3 ml-2"}
                    >
                        Mark as Editable
                    </Button>
                </>
            )}

            <Dialog
                open={openEmailModal}
                fullScreen={fullScreen}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle >
                    <div className='d-flex align-item-center'> Update Status </div>
                </DialogTitle>
                <DialogContent>
                    <form
                        className="signin-form"
                        onSubmit={handleSubmit(onSubmit, onError)}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-12">

                                {/* <div className="form-group mt-2">
                                <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Phase *</InputLabel>
                                    <Controller
                                        name="phase"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Phase is required" }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                // labelId="demo-simple-select-label"
                                                // id="demo-simple-select"
                                                fullWidth
                                                // displayEmpty
                                                // label="Enter Phase"
                                                // inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={(e) => handlePhaseChange(e, field.onChange)} // Combined onChange
                                            >
                                                {phaseNames.map((phase, index) => (
                                                    <MenuItem key={index} value={phase}>{phase}</MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.phase && errors.phase.message}
                                    </FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="form-group mt-2">
                                
                                    {selectedPhase && (
                                        <>
                                        <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                                            <Controller
                                                name="status"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: "Status is required" }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field} 
                                                        fullWidth
                                                    >
                                                        {stageNames.map((stage) => (
                                                            <MenuItem key={stage.stageId} value={stage.stageId}>
                                                                {stage.stageName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.status && errors.status.message}
                                            </FormHelperText>
                                            </FormControl>
                                        </>
                                    )}
                                </div> */}
                                <div className="form-group mt-2">


                                    <>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                                            <Controller
                                                name="status"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: "Status is required" }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        fullWidth
                                                        label="Status"
                                                    >
                                                        {stageNames.map((stage) => (
                                                            <MenuItem key={stage.stageId} value={stage.stageId}>
                                                                {stage.stageName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.status && errors.status.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </>


                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center mt-4 ">
                            <Button
                                variant="contained"
                                onClick={handleOpenModalClose}
                                className="btn btn-cancel submit px-3"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="btn btn-primary submit px-3 ml-3"
                            >
                                <span>Update</span>
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default UpdateStatus