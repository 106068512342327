import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext'
import notifyService from '../../../../api/notifySerivce';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormHelperText, FormLabel, Grid } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { Link } from 'react-router-dom';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import apiService from '../../../../api/apiService';
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];

const CSDocumentScst = () => {
    const { handleBackapp, navigator, setPageLoading } = useContext(DataContext)
    const [formData, setFormData] = useState({})
    const [getId, setGetId] = useState(0)
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors }, } = useForm({ values: formData });

    const handleClickOpens = (url) => {
        if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    useDidMountEffect(() => {
        getApi();
    }, []);

    const getApi = async () => {
        setPageLoading(true);
        await apiService(`startup/invdoc/cs/get`, "", "get")
            .then((res) => {
                setPageLoading(false);
                if (res && res.data) {
                    if (res.data.editForm === false && res.data.superEdit === false) {
                        setReadyOnlyValue(true);
                    }
                    if (res.data.editForm === false && res.data.superEdit === true) {
                        setReadyOnlyValue(false);
                    }
                    if (res.data.editForm === true && res.data.superEdit === false) {
                        setReadyOnlyValue(false);
                    }
                    if (res.data.id) {
                        setGetId(res.data.id);

                    }
                    setFormData(res.data)
                }

            }).catch((err) => {
                console.log(err);

            });
    }

    const onSubmit = (data) => {
        setPageLoading(true);
        debugger;
        apiService("startup/invdoc/cs/save", data, "post").then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService("success", "Success", "Saved successfully !!");
                    handleBackapp()
                }
            }
        });
    };
    const saveData = (req) => {
        debugger
        setPageLoading(true);
        apiService("startup/invdoc/cs/save", req, "post").then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    getApi();
                }
            }
        });
    };
    const handleFileUpload = (event, filekey, fileName, fileURL, format, val) => {
        debugger
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event;
            } else {
                logoselecetdFile = event.target.files[0];
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type;
                var imagedatatype = imagetype.split("/");
                var img_crt_type = imagedatatype[1];

                if (
                    (format === "file" && img_crt_type === "pdf") ||
                    img_crt_type === "vnd.ms-excel" ||
                    img_crt_type ===
                    "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    img_crt_type ===
                    "vnd.openxmlformats-officedocument.wordprocessingml.document"
                ) {
                    setValue(filekey, "");
                    setValue(fileName, "");

                    var fileValue = logoselecetdFile;
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(",");
                        var ImageValue = spl[1];
                        var img_name = fileValue.name;
                        setValue(filekey, ImageValue);
                        setValue(fileName, img_name);
                        setValue(fileURL, logourl1);
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        if (val !== 'nosave') {
                            var req = {
                                [filekey]: ImageValue,
                                [fileName]: img_name,
                                id: getId,
                            };
                            console.log(req);

                            saveData(req);
                        }
                    };
                } else {
                    notifyService(
                        "danger",
                        "File Format Invalided",
                        "Please check your file format"
                    );
                }
            }
        }
    };

    // PAS 3 Document
    const handlepas3Doc = (e) => {
        handleFileUpload(
            e,
            "ccdStampingTran1Doc",
            "ccdStampingTran1DocName",
            "ccdStampingTran1DocURL",
            "file"
        );
    };

    // DebentureDoc
    const handleDebentureDoc = (e) => {
        handleFileUpload(
            e,
            "debentureCertificateTran1Doc",
            "debentureCertificateTran1DocName",
            "debentureCertificateTran1DocURL",
            "file"
        );
    }

    // Amended AOA and ROC 
    const handleAmendedDoc = (e) => {
        handleFileUpload(
            e,
            "amendedAOAAndROCCopyDoc",
            "amendedAOAAndROCCopyDocName",
            "amendedAOAAndROCCopyDocURL",
            "file"
        );
    }

    // MGT - 14 Copy of Filing 

    const handlemgt14filingDoc = (e) => {
        handleFileUpload(
            e,
            "mgt14FilingCopyDoc",
            "mgt14FilingCopyDocName",
            "mgt14FilingCopyDocURL",
            "file"
        );
    }

    // Angel Tax Exemption

    const handleAngelTaxDoc = (e) => {
        handleFileUpload(
            e,
            "angelTaxExemptionDoc",
            "angelTaxExemptionDocName",
            "angelTaxExemptionDocURL",
            "file"
        );
    }

    // UC Certificate
    const handleUCDoc = (e) => {
        handleFileUpload(
            e,
            "milestoneCompletionDoc",
            "milestoneCompletionDocName",
            "milestoneCompletionDocURL",
            "file"
        );
    }

    // PAS 3 for allotment

    const handlePas3allotDoc = (e) => {
        handleFileUpload(
            e,
            "ccdStampingTran2Doc",
            "ccdStampingTran2DocName",
            "ccdStampingTran2DocURL",
            "file"
        );
    }

    // PAS-4, PAS -5 form

    const handlepas45Doc = (e) => {
        handleFileUpload(
            e,
            "pas4AndPas5CsDoc",
            "pas4AndPas5CsDocName",
            "pas4AndPas5CsDocURL",
            "file"
        );
    }

    // MGT 14 Filed with ROC

    const handlem14filingDoc = (e) => {
        handleFileUpload(
            e,
            "mgt14FilingAndPaymentRecDoc",
            "mgt14FilingAndPaymentRecDocName",
            "mgt14FilingAndPaymentRecDocURL",
            "file"
        );
    }

    // Debenture Certificate 
    const handleDebentureCertificateDoc = (e) => {
        handleFileUpload(
            e,
            "debentureCertificateTran2Doc",
            "debentureCertificateTran2DocName",
            "debentureCertificateTran2DocURL",
            "file"
        );
    }

    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };

    return (
        <>
            <section className="container-fluid">
                <div className="form_div">
                    <div className="d-flex align-items-center">
                        <span className="back_span" onClick={handleBackapp}>
                            <img src="/images/Fig-back.png" className="img-fluid" alt="" />
                        </span>
                        <h6 className="ml-2 mb-0">Information from the Company:</h6>
                    </div>
                    <hr />
                    <form
                        className="signin-form"
                        onSubmit={handleSubmit(onSubmit, onError)}
                    >
                        <div className='my-4'>
                            <h6 style={{ color: "blue" }}>Post Investment Tranche 1/3 Documents:</h6>
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>

                            {/* PAS 3 for allotment */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    PAS 3 for allotment of CCDs with Payment Receipt (Stamping duty for CCD certificate) - Original
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="ccdStampingTran1Doc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handlepas3Doc}
                                                    value={getValues("ccdStampingTran1Doc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("ccdStampingTran1Doc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "ccdStampingTran1Doc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "ccdStampingTran1Doc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.ccdStampingTran1Doc &&
                                                errors.ccdStampingTran1Doc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("ccdStampingTran1DocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* Original Debenture Certificate */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Original Debenture Certificate
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="debentureCertificateTran1Doc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleDebentureDoc}
                                                    value={getValues("debentureCertificateTran1Doc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("debentureCertificateTran1Doc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "debentureCertificateTran1Doc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "debentureCertificateTran1Doc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.debentureCertificateTran1Doc &&
                                                errors.debentureCertificateTran1Doc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("debentureCertificateTran1DocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* Amended AOA and ROC  */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Copy of Amended AOA and ROC Filing with Company Seal & Sign
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="amendedAOAAndROCCopyDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleAmendedDoc}
                                                    value={getValues("amendedAOAAndROCCopyDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("amendedAOAAndROCCopyDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "amendedAOAAndROCCopyDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "amendedAOAAndROCCopyDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.amendedAOAAndROCCopyDoc &&
                                                errors.amendedAOAAndROCCopyDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("amendedAOAAndROCCopyDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/*  MGT - 14 Copy of Filing   */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Copy of Filing of MGT - 14 (e-AOA) Amendment
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="mgt14FilingCopyDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handlemgt14filingDoc}
                                                    value={getValues("mgt14FilingCopyDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("mgt14FilingCopyDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "mgt14FilingCopyDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "mgt14FilingCopyDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.mgt14FilingCopyDoc &&
                                                errors.mgt14FilingCopyDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("mgt14FilingCopyDocURL")
                                            )
                                        }>
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* Angel Tax Exemption  */}

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Angel Tax Exemption Certificate
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="angelTaxExemptionDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleAngelTaxDoc}
                                                    value={getValues("angelTaxExemptionDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("angelTaxExemptionDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "angelTaxExemptionDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "angelTaxExemptionDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.angelTaxExemptionDoc &&
                                                errors.angelTaxExemptionDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("angelTaxExemptionDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                        </Grid>

                        <div className='my-4'>
                            <h6 style={{ color: "blue" }}>Post Investment Tranche 2/3 Documents:</h6>
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>

                            {/* UC Certificate */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Milestone Completion Document / UC Certificate from Auditor
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="milestoneCompletionDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleUCDoc}
                                                    value={getValues("milestoneCompletionDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("milestoneCompletionDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "milestoneCompletionDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "milestoneCompletionDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.milestoneCompletionDoc &&
                                                errors.milestoneCompletionDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("milestoneCompletionDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* PAS-4, PAS -5 form */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    PAS-4, PAS -5 form for offering Shares to TANSIM with company seal
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="pas4AndPas5CsDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handlepas45Doc}
                                                    value={getValues("pas4AndPas5CsDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("pas4AndPas5CsDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "pas4AndPas5CsDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "pas4AndPas5CsDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.pas4AndPas5CsDoc &&
                                                errors.pas4AndPas5CsDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("pas4AndPas5CsDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* MGT 14 Filed with ROC  */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Copy of MGT 14 Filed with ROC with Payment Receipt
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="mgt14FilingAndPaymentRecDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handlem14filingDoc}
                                                    value={getValues("mgt14FilingAndPaymentRecDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("mgt14FilingAndPaymentRecDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "mgt14FilingAndPaymentRecDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "mgt14FilingAndPaymentRecDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.mgt14FilingAndPaymentRecDoc &&
                                                errors.mgt14FilingAndPaymentRecDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("mgt14FilingAndPaymentRecDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>



                        </Grid>
                        <div className='my-4'>
                            <h6 style={{ color: "blue" }}>Post Investment Tranche 3/3 Documents:</h6>
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>

                            {/*PAS 3 for allotment CCDs */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    PAS 3 for allotment of CCDs with Payment Receipt (Stamping duty for CCD certificate) - Original
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="ccdStampingTran2Doc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handlePas3allotDoc}
                                                    value={getValues("ccdStampingTran2Doc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("ccdStampingTran2Doc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "ccdStampingTran2Doc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "ccdStampingTran2Doc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.ccdStampingTran2Doc &&
                                                errors.ccdStampingTran2Doc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("ccdStampingTran2DocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/*Debenture Certificate  */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Original Debenture Certificate
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="debentureCertificateTran2Doc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleDebentureCertificateDoc}
                                                    value={getValues("debentureCertificateTran2Doc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("debentureCertificateTran2Doc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "debentureCertificateTran2Doc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "debentureCertificateTran2Doc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.debentureCertificateTran2Doc &&
                                                errors.debentureCertificateTran2Doc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("debentureCertificateTran2DocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button
                                variant="contained"
                                onClick={handleBackapp}
                                className="btn btn-cancel submit px-3"
                            >
                                Cancel
                            </Button>
                            {/* {readyOnlyValue === false ? ( */}
                            {/* <Button
                                type="submit"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3 ml-3"
                            >
                                <span>Save & Proceed Next</span>
                            </Button> */}
                            {/* ) : (
                                        <></>
                                      )} */}
                        </div>
                    </form>
                </div>

            </section>
        </>
    )
}

export default CSDocumentScst

