import React, { useContext, useState } from 'react';
import { Autocomplete, InputAdornment, } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
const Investorprofiledetails = () => {

    const { validatePhoneNumber, validateEmail, handleStartupDetails, handlepreference, handleeoi, setPageLoading, validateNumberonly, yearListApi, loading, setLoading, yearListValue, navigator, VisuallyHiddenInput, } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );

    useDidMountEffect(() => {
        (async () => await getApi())()
        // dropdownValueApi()
        // fundprogramlistApi()
        yearListApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState([])
    // const [fundingStatusesList, setFundingStatusesList] = useState([])
    // const [investmentTicketSize, setInvestmentTicketSize] = useState([])
    // const [instrumentTypeData, setInstrumentTypeData] = useState([])
    // const [revenuesList, setLevenuesList] = useState([])
    // const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState([])
    // const [businessModelsData, setBusinessModelsData] = useState([])
    // const [purposeList, setPurposeList] = useState([])
    // const [technologiesList, setTechnologiesList] = useState([])
    // const [sectorData, setSectorData] = useState([])
    // const [startupStagesList, setStartupStagesList] = useState([])
    // const [geoPreList, setGeoPreList] = useState([])
    // const [revenueStageList, setRevenueStageList] = useState([])
    // const [investmentData, setInvestmentData] = useState([])
    // const dropdownValueApi = async () => {
    //     await apiService(`admin/dropdownvalues/get?key=investor`, '', 'get').then((res) => {
    //         var val = res.data.dropDownValues
    //         // setPreferredInvestorTypeList(val.prefInvestorTypes)
    //         // setInvestmentTicketSize(val.desiredFundingAmount)
    //         // setFundingStatusesList(val.previousFundingStatus)
    //         // setLevenuesList(val.revenueStages)
    //         // setBusinessModelsData(val.businessModels)
    //         // setPurposeList(val.purposes)
    //         // setTechnologiesList(val.technologyStacks)
    //         // setSectorData(val.sectors)
    //         // setStartupStagesList(val.startupStages)
    //         // setGeoPreList(val.geoPreferences)
    //         // setRevenueStageList(val.revenueStages)
    //         // setInstrumentTypeData(val.prefFundingInstruments)
    //         // setInvestmentData(val.investCharacterstics)

    //     })
    // }
    const [StartupDetailStatus, setStartupDetailStatus] = useState(false)
    const [preferenceStatus, setpreferenceStatus] = useState(false)
    const [eoiStatus, setEoiStatus] = useState(false)
    const getApi = async () => {
        setPageLoading(true)
        const userId = Number(localStorage.getItem('userId'))
        await apiService(`userprofile/get?userId=${userId}`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                let result = res.data
                if (result.estdYear !== undefined) {
                    setFormData(result)
                    // setOrganizationTypeValue(result.organizationType);
                    // setInstrumentTypeValueValue(result.investmentInstrumentType);
                    // if (result.investmentCharacterstics) {
                    //     if (result.investmentCharacterstics.length === 1) {
                    //         if (result.investmentCharacterstics[0] === "") {
                    //             result.investmentCharacterstics = undefined
                    //         }
                    //     }
                    //     if (result.investmentCharacterstics !== undefined) {
                    //         setInvestmentCharactersticsValue(result.investmentCharacterstics)
                    //     }
                    // }
                    // if (result.geoPreferences) {
                    //     if (result.geoPreferences.length === 1) {
                    //         if (result.geoPreferences[0] === "") {
                    //             result.geoPreferences = undefined
                    //         }
                    //     }
                    //     if (result.geoPreferences !== undefined) {
                    //         setGeoPreferencesValue(result.geoPreferences)
                    //     }
                    // }
                    // setInvestTicketSizeValue(result.investTicketSize)
                    // setPreferToValue(result.preferTo)
                    // setStartupStagesValue(result.startupStages)
                    // setRevenueStageValue(result.revenueStage)
                    if (result.organizationLogoName === undefined) {
                        setLogoDisplayUrl('/images/user.png')
                    } else {
                        setLogoDisplayUrl(result.organizationLogoName)
                        setLogoDisplayValue(result.organizationLogoName)
                    }
                    // setFundingStatusesValue(result.fundingStatuses)
                    // if (result.preferredSectors) {
                    //     setPreferredSectors(result.preferredSectors)
                    // }

                    var con = result.openToCoInvest.toString()
                    setValue('openToCoInvest', con)
                    // setOpenToCoInvestValue(con)
                    // setGeoPreferencesValue(result.geoPreferences)
                    // setBusinessModelsValue(result.businessModels)
                } else {
                    setValue('openToCoInvest', result.openToCoInvest.toString())
                    // setOpenToCoInvestValue(result.openToCoInvest.toString())
                    setFormData(result)
                }
                // setAcceptPrivacyPolicyChecked(result.acceptPrivacyPolicy)
                // setAcceptTermsAndConditionsChecked(result.acceptTermsAndConditions)
                // setValue('acceptPrivacyPolicy', result.acceptPrivacyPolicy)
                // clearErrors('acceptPrivacyPolicy')
                // setValue('acceptTermsAndConditions', result.acceptTermsAndConditions)
                // clearErrors('acceptTermsAndConditions')
                if (result.noOfPortfolioStartups === 0) {
                    setValue('noOfPortfolioStartups', '')
                }
                if (result.estdYear !== undefined) {
                    setStartupDetailStatus(true)
                } if (result.fundingStatuses !== undefined) {
                    setpreferenceStatus(true)
                } if (result.acceptPrivacyPolicy !== false) {
                    setEoiStatus(true)
                }
            }
        })
    }
    // const [termsAndConditionsHTML, setTermsAndConditionsHTML] = useState('')
    // function termsAndConditionsMarkup() {
    //     return { __html: termsAndConditionsHTML };
    // }
    // const [privacyPolicyHTML, setPrivacyPolicyHTML] = useState('')
    // function privacyPolicyMarkup() {
    //     return { __html: privacyPolicyHTML };
    // }
    // const [instrumentTypeValue, setInstrumentTypeValueValue] = useState([]);
    // const handleSelectInstrument = (value) => {
    //     setInstrumentTypeValueValue(value);
    //     setValue('investmentInstrumentType', value)
    //     clearErrors('investmentInstrumentType');
    // }


    // const [investmentCharactersticsValue, setInvestmentCharactersticsValue] = useState([]);
    // const handleSelectInvestment = (value) => {
    //     setInvestmentCharactersticsValue(value);
    //     setValue('investmentCharacterstics', value)
    //     clearErrors('investmentCharacterstics');
    // };

    // const [investTicketSizeValue, setInvestTicketSizeValue] = useState([]);
    // const handleSelectTicketSize = (value) => {
    //     setInvestTicketSizeValue(value);
    //     setValue('investTicketSize', value)
    //     clearErrors('investTicketSize');
    // };

    // const preferToData = [
    //   "Co-Invest",
    //   "Lead the round",
    //   "Solo Invest"
    // ]
    // const [preferToValue, setPreferToValue] = useState('');
    // const handleSelectPrefer = (value) => {
    //   setPreferToValue(value);
    //   setValue('preferTo', value)
    //   clearErrors('preferTo');
    // };

    // const [businessModelsValue, setBusinessModelsValue] = useState([]);
    // const handleSelectBusiness = (value) => {
    //     setBusinessModelsValue(value);
    //     setValue('businessModels', value)
    //     clearErrors('businessModels');
    // }


    // const [startupStagesValue, setStartupStagesValue] = useState([]);
    // const handleSelectStageStartup = (value) => {
    //     setStartupStagesValue(value)
    //     setValue('startupStages', value)
    //     clearErrors('startupStages');
    // }

    // const [revenueStageValue, setRevenueStageValue] = useState([]);
    // const handleSelectRevenueStageValue = (value) => {
    //     setRevenueStageValue(value)
    //     setValue('revenueStage', value)
    //     clearErrors('revenueStage');
    // }

    // const [geoPreferencesValue, setGeoPreferencesValue] = useState([]);
    // const handleSelectGeoPreferencesValue = (value) => {
    //     // if (value[value.length - 1] === "allSelect") {
    //     //   setGeoPreferencesValue(geoPreList);
    //     //   return;
    //     // } if (value[value.length - 1] === "allRemove") {
    //     //   setGeoPreferencesValue([]);
    //     //   return;
    //     // } else {
    //     //   setGeoPreferencesValue(value);
    //     // }
    //     setGeoPreferencesValue(value);
    //     setValue('geoPreferences', value)
    //     clearErrors('geoPreferences');
    // }
    // const [fundingStatusesValue, setFundingStatusesValue] = useState([]);
    // const handleSelectFundingStatuses = (value) => {
    //     setFundingStatusesValue(value)
    //     setValue('fundingStatuses', value)
    //     clearErrors('fundingStatuses');
    // }

    // const [preferredSectors, setPreferredSectors] = useState([]);
    // const handleSelectSector = (value) => {
    //     setPreferredSectors([])
    //     if (value.length === 0) {
    //         return;
    //     }
    //     if (value[value.length - 1] === 'Select All') {


    //         var dataa = sectorData.filter((item) => item !== 'Remove All' && item !== 'Select All')

    //         setPreferredSectors(dataa);
    //         setValue('preferredSectors', dataa)
    //         clearErrors('preferredSectors');

    //     } else if (value[value.length - 1] === 'Remove All') {
    //         setPreferredSectors([]);
    //         setValue('preferredSectors', [])

    //     } else {
    //         setPreferredSectors(value);
    //         setValue('preferredSectors', value)
    //         clearErrors('preferredSectors');

    //     }
    // };

    // const [fundingList, setFundingList] = useState([]);
    // const fundprogramlistApi = async () => {
    //     await apiService(`partner/fundprograms`, '', 'get').then((res) => {
    //         if (res) {
    //             setFundingList(res.data.fundingPrograms)
    //         }
    //     })
    // }

    // const [organizationTypeValue, setOrganizationTypeValue] = useState('');
    // const handleSelectOrganizationType = (value) => {
    //     // setOrganizationTypeValue(value);
    //     setValue('organizationType', value)
    //     clearErrors('organizationType');
    //     const name = fundingList.filter(item => ((item.partner)).includes(value)
    //         || ((item.partner)).includes(value)
    //     )
    //     setValue('fundingProgramVersionConfigId', name[0].versionId)
    // }

    const [logoDisplayValue, setLogoDisplayValue] = useState(null)
    const [logoDisplayUrl, setLogoDisplayUrl] = useState('/images/user.png')
    let logoselecetdFile
    // const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
    //     setValue(filekey, "")
    //     setValue(fileName, "")
    //     setdata('')
    //     setUrl('')
    //     if (event.target === undefined) {
    //         logoselecetdFile = event
    //     } else {
    //         logoselecetdFile = event.target.files[0]
    //     }
    //     if (logoselecetdFile) {
    //         var reader = new FileReader();
    //         var imagetype = logoselecetdFile.type
    //         var imagedatatype = imagetype.split("/")
    //         var img_crt_type = imagedatatype[1]
    //         if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png") {
    //             if (event.target === undefined) {
    //                 setdata(event)
    //             } else {
    //                 setdata(event.target.files[0])
    //             }
    //             var fileValue = logoselecetdFile
    //             reader.readAsDataURL(logoselecetdFile);
    //             reader.onload = () => {
    //                 var logourl1 = reader.result;
    //                 var spl = logourl1.split(',')
    //                 var ImageValue = spl[1]
    //                 var img_name = fileValue.name
    //                 setUrl(logourl1)
    //                 setValue(filekey, ImageValue)
    //                 setValue(fileName, img_name)
    //                 clearErrors(filekey);
    //                 clearErrors(fileName);
    //                 setLogoInvalid(false)
    //             }
    //         }
    //     }
    // }

    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        debugger;
        if (event) {
          if (event.target === undefined) {
            logoselecetdFile = event;
          } else {
            logoselecetdFile = event.target.files[0];
          }
          const maxFileSize = 5 * 1024 * 1024;
          if (logoselecetdFile) {
            if (logoselecetdFile.size < maxFileSize) {
              var reader = new FileReader();
              var imagetype = logoselecetdFile.type;
              var imagedatatype = imagetype.split("/");
              var img_crt_type = imagedatatype[1];
              if (
                img_crt_type === "jpeg" ||
                img_crt_type === "jpg" ||
                img_crt_type === "png"
              ) {
                setValue(filekey, "");
                setValue(fileName, "");
                setdata("");
                setUrl("");
                if (event.target === undefined) {
                  setdata(event);
                } else {
                  setdata(event.target.files[0]);
                }
                var fileValue = logoselecetdFile;
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                  var logourl1 = reader.result;
                  var spl = logourl1.split(",");
                  var ImageValue = spl[1];
                  var img_name = fileValue.name;
                  setUrl(logourl1);
                  setValue(filekey, ImageValue);
                  setValue(fileName, img_name);
                  clearErrors(filekey);
                  clearErrors(fileName);
                  debugger;
                };
              } else {
                notifyService(
                  "danger",
                  "File Format Invalided",
                  "Please check your file format"
                );
              }
            } else {
              notifyService(
                "danger",
                "File size exceeds 5MB",
                "Please check your file size"
              );
            }
          }
        }
      };

    const handleSelectYearData = (value) => {
        setValue('estdYear', value)
        clearErrors('estdYear')
    }
    // const [acceptPrivacyPolicyChecked, setAcceptPrivacyPolicyChecked] = useState(null);
    // const handleChangeAcceptPrivacyPolicy = (event) => {
    //     setAcceptPrivacyPolicyChecked(event.target.checked);
    //     if (event.target.checked) {
    //         setValue('acceptPrivacyPolicy', event.target.checked)
    //         clearErrors('acceptPrivacyPolicy')
    //     } else {
    //         setValue('acceptPrivacyPolicy', null)
    //     }
    // };
    // const [acceptTermsAndConditionsChecked, setAcceptTermsAndConditionsChecked] = useState(null);
    // const handleChangeAcceptTermsAndConditions = (event) => {
    //     setAcceptTermsAndConditionsChecked(event.target.checked);
    //     if (event.target.checked) {
    //         setValue('acceptTermsAndConditions', event.target.checked)
    //         clearErrors('acceptTermsAndConditions')
    //     } else {
    //         setValue('acceptTermsAndConditions', null)
    //     }
    // };
    // const [openToCoInvestValue, setOpenToCoInvestValue] = useState(false)
    const [noOfPortfolioStartupsValue, setNoOfPortfolioStartupsValue] = useState(false)
    const [logoInvalid, setLogoInvalid] = useState(false);
    const onSubmit = (data) => {

        if (logoDisplayValue !== null) {

            var getform = data
            if (data.openToCoInvest === "true") {
                getform.openToCoInvest = true
            } else {
                getform.openToCoInvest = false
            }
            if (data.investmentCharacterstics === '') {
                getform.investmentCharacterstics = []
            }
            if (data.geoPreferences === '') {
                getform.geoPreferences = []
            }
            getform.id = Number(data.id)
            getform.noOfPortfolioStartups = Number(data.noOfPortfolioStartups)

            if (getform.noOfPortfolioStartups !== 0) {
                setLoading(true);
                setPageLoading(true)
                setNoOfPortfolioStartupsValue(false)
                apiService('userprofile/save', getform, 'post').then((res) => {
                    setPageLoading(false)
                    setLoading(false);
                    if (res) {
                        if (res.data.responseStatus === "Success") {

                            notifyService('success', 'Success', 'Saved successfully !!')
                            navigator('/investor/profile/preference')
                        }
                    }
                })
            } else {
                setNoOfPortfolioStartupsValue(true)
            }
            // if (typeof (data.geoPreferences) !== "object") {
            //   getform.geoPreferences = data.geoPreferences.split(',');
            // }


        } else {
            setLogoInvalid(true)
            setPageLoading(false)
            notifyService('danger', 'Error', 'Please upload Profile Logo')
        }
    };
    const handleCancel = () => {
        navigator('/investor/associated-startups/list')
    }
    // function getDate() {
    //     const today = new Date();
    //     const month = today.getMonth() + 1;
    //     const year = today.getFullYear();
    //     const date = today.getDate();
    //     return `${date}/${month}/${year}`;
    // }
    return (
        <section className='container-fluid'>
            <div className='form_div'>
                <div className='d-flex align-items-center'>
                    <span className='back_span' onClick={handleCancel}>
                        <i className="fas fa-arrow-alt-circle-left"></i>
                    </span>
                    <div className='ml-2'>
                        <h6 className='mb-0'>Investor Profile</h6>
                        <p className='header-p mb-0'>Provide basic details about your organization</p>
                    </div>
                    <div className='ml-auto'>
                        <div className='activeradio'>
                            {
                                (StartupDetailStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer mr-2' onClick={handleStartupDetails} />
                                        <div className='underlinecssgreen'></div>
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={true} />
                                        <div className='underlinecss'></div>
                                    </>
                                )
                            }
                            {
                                (preferenceStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer mx-2' onClick={handlepreference} />
                                        <div className='underlinecssgreen'></div>
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={false} />
                                        <div className='underlinecss'></div>
                                    </>
                                )
                            }
                            {
                                (eoiStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer ml-2' onClick={handleeoi} />
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={false} />
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <hr />
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="id" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="organizationLogoName" control={control} defaultValue=""
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="organizationLogo" control={control} defaultValue=""
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="fundingProgramVersionConfigId" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='col-lg-9 col-12'>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="name" control={control} defaultValue="" rules={{ required: 'Organization name is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Name of Organization *"
                                                    placeholder='Enter Organization Name'
                                                    autoFocus
                                                    fullWidth
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="estdYear" control={control} defaultValue="" rules={{ required: 'Year of Establishment is required' }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    options={yearListValue}
                                                    onChange={(e, selectedOptions) => handleSelectYearData(selectedOptions)}
                                                    renderInput={(params) => <TextField {...params} label="Year of Establishment *" />}
                                                />
                                            } />
                                        <FormHelperText className='text-danger'>{errors.estdYear && errors.estdYear.message}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="noOfPortfolioStartups" control={control} defaultValue="" rules={{ required: 'Number of Portfolio Startups is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Number of Portfolio Startups *"
                                                    placeholder='Enter Number of Portfolio Startups'
                                                    fullWidth
                                                    {...field}
                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.noOfPortfolioStartups && errors.noOfPortfolioStartups.message}</FormHelperText>
                                        <FormHelperText className='text-danger'>{noOfPortfolioStartupsValue === true && 'Portfolio can not be zero'}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="website" control={control} defaultValue="" rules={{ required: 'Website is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Website *"
                                                    placeholder='Enter Website'
                                                    fullWidth
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.website && errors.website.message}</FormHelperText>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3 col-12 text-center mb-3'>
                            <div className='logo_div'>
                                <img src={logoDisplayUrl} className='user_logo' alt='' />
                                <div className='camera_icon'>
                                    <Button onChange={(e) => handleFileUpload(e, "organizationLogo", "organizationLogoName", setLogoDisplayValue, setLogoDisplayUrl)} component="label" startIcon={<CameraAltIcon />}>
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <span>Organization Logo <span className='text-danger'>*</span></span>
                            </div>
                            <div>
                                <span className='fn-12'>Recommend Size (128px * 128px)</span>
                            </div>
                            <div>
                                <span className='fn-12'>Maximum 10 mb allowed doc (png, jpg, jpeg) <span className='text-danger'>*</span></span>
                            </div>
                            {
                                (logoInvalid === true) ? (<span className='text-danger'>Organization logo is required</span>) : (<></>)
                            }
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="organizationBrief" control={control} defaultValue="" rules={{ required: 'Brief about Organization is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            multiline
                                            // rows={3}
                                            label="Brief about Organization *"
                                            placeholder='Enter Brief about Organization'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.organizationBrief && errors.organizationBrief.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email is required', validate: validateEmail }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Email *"
                                            placeholder='Enter Email'
                                            fullWidth
                                            inputProps={
                                                { readOnly: true, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="phone" control={control} defaultValue="" rules={{ required: 'Contact is required', validate: validatePhoneNumber }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Contact *"
                                            placeholder='Enter Contact'
                                            fullWidth
                                            {...field}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +91
                                                    </InputAdornment>
                                                ), maxLength: 10
                                            }}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.phone && errors.phone.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="country" control={control} defaultValue="India" rules={{ required: 'Country is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Country *"
                                            placeholder='Enter Country'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.country && errors.country.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="state" control={control} defaultValue="Tamil Nadu" rules={{ required: 'State is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="State *"
                                            placeholder='Enter State'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.state && errors.state.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="city" control={control} defaultValue="" rules={{ required: 'City is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="City *"
                                            placeholder='Enter City'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.city && errors.city.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="portfolio" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Link to Portfolio"
                                            placeholder='Enter Link to Portfolio'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.portfolio && errors.portfolio.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="twitter" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Link to twitter"
                                            placeholder='Enter Link to twitter'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.twitter && errors.twitter.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="linkedIn" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Link to LinkedIn"
                                            placeholder='Enter Link to LinkedIn'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.linkedIn && errors.linkedIn.message}</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12 col-12'>
                            <div className='div_header'>
                                <h6>Point of Contact Details</h6>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="pocName" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Name *"
                                                    placeholder='Enter Name'
                                                    fullWidth
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.pocName && errors.pocName.message}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="pocDesignation" control={control} defaultValue="" rules={{ required: 'Designation is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Designation *"
                                                    placeholder='Enter Designation'
                                                    fullWidth
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.pocDesignation && errors.pocDesignation.message}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="pocEmail" control={control} defaultValue="" rules={{ required: 'Email is required', validate: validateEmail }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Email *"
                                                    placeholder='Enter Email'
                                                    fullWidth
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.pocEmail && errors.pocEmail.message}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="pocPhone" control={control} defaultValue="" rules={{ required: 'Phone Number is required', validate: validatePhoneNumber }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Phone Number *"
                                                    placeholder='Enter Phone Number'
                                                    fullWidth
                                                    {...field}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                +91
                                                            </InputAdornment>
                                                        ), maxLength: 10
                                                    }}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.pocPhone && errors.pocPhone.message}</FormHelperText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3">Cancel</Button>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            className="btn btn-primary submit px-3 ml-3"
                        >
                            <span>Save & Proceed Next</span>
                        </LoadingButton>

                    </div>
                </form>
            </div>
        </section>
    )
}

export default Investorprofiledetails