import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../context/DataContext";
import { useParams } from "react-router-dom";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Scsthubavpvalidation from "./Scsthubavpvalidation";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Scsthubavpevaluation from "./Scsthubavpevaluation";
import Applicationscst from "../../scst-page/Applicationscst";
import Fundingscstview from "../../scst-page/Fundingscstview";
import Founderscstview from "../../scst-page/Founderscstview";
import Moreinfosave from "../../investor-module/associated-startups-page/associated-details-page/pages/Moreinfosave";
import { FaCheckCircle } from "react-icons/fa";
import Viewimage from "../../../api/Viewimage";
import CommonViewPitchDeck from "../../common-page/CommonViewPitchDeck";
import IncubatorAccelerationScst from "../../scst-page/IncubatorAccelerationScst";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FaEye } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { AiFillSafetyCertificate } from "react-icons/ai";
import Zoomlist from "../../jury-module/zoom-page/Zoomlist";
import ScstBddVerificationLayout from "../../scstBdd-page/scst-verification-details/ScstBddVerificationLayout";
import notifyService from "../../../api/notifySerivce";
import BDDDeepDiveLayout from "../../scst-page/bdd-dee-dive/BDDDeepDiveLayout";
import ViewAllReports from "../../scst-page/view-reports/ViewAllReports";
import LDDLayout from "../../scst-page/ldd-stage/LDDLayout";
import FDDLayout from "../../scst-page/fdd-stage/FDDLayout";
import { Controller, useForm } from "react-hook-form";
import StartupApplicationStatus from "../../jury-module/ApplicationStatus-page/StartupApplicationStatus";
import CommentsModal from "../../scst-page/CommentsModal";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { TabContext, TabList } from "@mui/lab";
import Chronology from "../../jury-module/ApplicationStatus-page/Chronology";
import CpcsLayout from "../../scst-page/cp-cs-documents/CpcsLayout";
import CpDocuments from "../../scst-page/cp-cs-documents/CpDocuments";
import DocumentRoomAdmin from "../../scst-page/Document-Room-admin/DocumentRoomAdmin";
import { Padding } from "@mui/icons-material";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function a11yProps1(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const Scsthubavpdetails = ({ role, status }) => {
  ;
  const { setPageLoading, navigator, getSCSTStartupStatus, fullScreen, tableIndexValue,   badgeCount,
    notificationList,pendinglistApi } =
    useContext(DataContext);
  const { startupId } = useParams();
  const { id } = useParams();
  let idNumber = atob(startupId);
  let partnerOrInvestorId = atob(id);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    getValues,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ values: formData });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {

    setValueTab(newValue);
    setStatusValue(0)
    setAddInfoValue('1');
  };
  const [meetingIndex, setMeetingIndex] = useState(1);
  const [docmntRmIndex, setDocmntRmIndex] = useState(1);
  const [progressIndex, setProgressIndex] = useState(1);
  const [progressIndex1, setProgressIndex1] = useState(1);
  useDidMountEffect(() => { }, []);
  const theme = useTheme();
  useDidMountEffect(() => {
    listApi();

    viewStartupDetails(idNumber, partnerOrInvestorId);
    showStartupValue();
  }, []);

  const [listData, setListData] = useState([]);
  const listApi = () => {
    setPageLoading(true);
    var req = {
      partnerOrInvestorId: partnerOrInvestorId,
      startupId: idNumber,
    };
    apiService("partner/scst/admin/startup/get", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.startupRounds) {
            setListData(result.data.startupRounds);
          }
        }
      })
      .catch((err) => { });
  };
  const [cpListData, setCpListData] = useState([]);
  const [countHistory, setCountHistory] = useState(0);
  const [pageHistory, setPageHistory] = useState(0);
  const [rowsPerPageHistory, setRowsPerPageHistory] = useState(10);
  const handleHistoryChangePage = (event, newPage) => {
    setPageHistory(newPage);
  };
  const handleChangeHistoryRowsPerPage = (event) => {
    setRowsPerPageHistory(parseInt(event.target.value, 10));
    setPageHistory(0);
  };
  const [cpType, setCpType] = useState()
  const [statusValue, setStatusValue] = useState(0)
  useDidMountEffect(() => {
    CpApi(cpType)
  }, [pageHistory, rowsPerPageHistory, statusValue]);
  const [historyListValue, setHistoryListValue] = useState([])
  const CpApi = (value) => {
    setCpType(value)
    setCpListData([])
    setHistoryListValue([])
    setPageLoading(true);
    var req = {
      listSize: rowsPerPageHistory,
      pageNumber: pageHistory + 1,
      type: value,
      startupId: idNumber,
      // listSize: rowsPerPage,
      // pageNumber: page + 1,
      // startupId: id,
      // userId: localStorage.getItem('userId'),
      status: statusValue
    };
    apiService("cp/scstadmin/additionalinfo/history", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setCountHistory(result.data.count);
          if (result.data.additionalInfo) {
            setHistoryListValue(result.data.additionalInfo);
          }
        }
      })
      .catch((err) => { });
  }
  const [startupGetData, setStartupGetData] = useState({});
  const [startupValue, setStartupValue] = useState({});
  const [scstAdminAllRemarks, setScstAdminAllRemarks] = useState([]);
  const [countRemarks, setCountRemarks] = useState(0);
  const [pageRemarks, setPageRemarks] = useState(0);
  const [rowsPerPageRemarks, setRowsPerPageRemarks] = useState(10);
  const handleChangePageRemarks = (event, newPage) => {
    setPageRemarks(newPage);
  };
  const handleChangeRowsPerPageRemarks = (event) => {
    setRowsPerPageRemarks(parseInt(event.target.value, 10));
    setPageRemarks(0);
  };
  useDidMountEffect(() => {
    AdminRemark()
  }, [pageRemarks, rowsPerPageRemarks]);
  const AdminRemark = () => {
    setPageLoading(true);
    setScstAdminAllRemarks([])
    var req = {
      listSize: rowsPerPageRemarks,
      pageNumber: pageRemarks + 1,
      startupId: idNumber,

    };
    apiService("partner/scst/admin/mentorship/remarks/list", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setCountRemarks(result.data.count);
          if (result.data.adminRemarksList) {
            setScstAdminAllRemarks(result.data.adminRemarksList);
          }
        }
      })
      .catch((err) => { });
  }


  const viewStartupDetails = async (id, partnerOrInvestorId) => {
    setPageLoading(true);
    // setNavTabValue('1')
    setStartupGetData({});
    setStartupValue({});
    apiService(
      `partner/scstadmin/startups/get?id=${id}&partnerOrInvestorId=${partnerOrInvestorId}`,
      "",
      "get"
    ).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.applicationForm) {
            setStartupGetData(res.data.applicationForm);
          }
          setStartupValue(res.data);
          if (role === "scsthubavp" || role === "scstadmin") {
            var startupValue = res.data;
            if (startupValue.status == 32) {
              setDocmntRmIndex(10);
              setMeetingIndex(11);
              setProgressIndex(12);
              setProgressIndex1(13);
            }
            else if (startupValue.status == 31) {
              setDocmntRmIndex(9);
              setMeetingIndex(10);
              setProgressIndex(11);
              setProgressIndex1(12);
            }
            else if (startupValue.status == 30) {
              setDocmntRmIndex(5);
              setMeetingIndex(6);
              setProgressIndex(7);
              setProgressIndex1(8);
            } else if (startupValue.status == 29) {
              setDocmntRmIndex(5);
              setMeetingIndex(6);
              setProgressIndex(7);
              setProgressIndex1(8);
            }
            else if (startupValue.status >= 28) {
              setDocmntRmIndex(3);
              setMeetingIndex(4);
              setProgressIndex(5);
              setProgressIndex1(6);
            }
            
            else if (startupValue.status >= 26) {
              setDocmntRmIndex(10);
              setMeetingIndex(11);
              setProgressIndex(12);
              setProgressIndex1(13);
            }
            else if (startupValue.status >= 20) {
              setDocmntRmIndex(9);
              setMeetingIndex(10);
              setProgressIndex(11);
              setProgressIndex1(12);
            }
            // else if (startupValue.status >= 18) {
            //   setMeetingIndex(9);
            //   setProgressIndex(10);
            //   setProgressIndex1(11);
            // }
            else if (startupValue.status >= 17) {
              setDocmntRmIndex(7);
              setMeetingIndex(8);
              setProgressIndex(9);
              setProgressIndex1(10);
            } else if (startupValue.status >= 16) {
              setDocmntRmIndex(5);
              setMeetingIndex(6);
              setProgressIndex(7);
              setProgressIndex1(8);
            } else if (startupValue.status >= 11) {
              setDocmntRmIndex(5);
              setMeetingIndex(6);
              setProgressIndex(7);
              setProgressIndex1(8);
            } else if (startupValue.status >= 10) {
              setDocmntRmIndex(5);
              setMeetingIndex(6);
              setProgressIndex(7);
              setProgressIndex1(8);
            }
            else if (startupValue.status == 9) {
              setDocmntRmIndex(4);
              setMeetingIndex(5);
              setProgressIndex(6);
              setProgressIndex1(7);
            } else if (startupValue.status >= 8) {
              setDocmntRmIndex(5);
              setMeetingIndex(6);
              setProgressIndex(7);
              setProgressIndex1(8);
            } else if (startupValue.status >= 5) {
              setDocmntRmIndex(4);
              setMeetingIndex(5);
              setProgressIndex(6);
              setProgressIndex1(7);
            } else if (startupValue.status >= 3) {
              setDocmntRmIndex(3);
              setMeetingIndex(4);
              setProgressIndex(5);
              setProgressIndex1(6);
            } else if (startupValue.status >= 1) {
              setDocmntRmIndex(3);
              setMeetingIndex(4);
              setProgressIndex(5);
              setProgressIndex1(6);
            } else if (startupValue.status < 1) {
              setDocmntRmIndex(2);
              setMeetingIndex(3);
              setProgressIndex(4);
              setProgressIndex1(5);
            }
          }
        } else {
          setStartupGetData({});
        }
      }
    });
  };
  useEffect(() => {
    scstStatusFilterApi();
  }, []);
  const scstStatusFilterApi = () => {
    axios.get('/json/startupstatus.json')
      .then((result) => {
        const stages = result.data.flatMap(phase => phase.stageList);
        setStageNames(stages); // Set the flattened stage list
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [startupCheckList, setStartupCheckList] = useState([]);
  const [comments, setComments] = useState([]);
  const showStartupValue = async () => {
    apiService(
      `startup/clarifications/status/get?id=${idNumber}`,
      "",
      "get"
    ).then((res) => {
      if (res) {
        if (res.data) {
          ;
          setStartupCheckList(res.data);
          if (res.data && res.data.reportComments) {
            setComments(res.data.reportComments);
          }
        }
      }
    });
  };
  const isRegistered = startupGetData.dpiitRegistered;

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openReport, setOpeReport] = useState(false);
  const [viewReportImage, setViewReportImage] = useState("");
  const handleClickReportOpen = (value) => {
    setViewReportImage(value);
    setOpeReport(true);
  };
  const handleReportClose = () => {
    setOpeReport(false);
  };

  const updateStatuses = (data) => {

    data.startupId = [idNumber]
    console.log(data);
    setPageLoading(true)
    apiService('startup/scst/round/update', data, 'post').then((result) => {

      setPageLoading(false)
      if (result) {

        if (result.data.responseStatus === "Updated Successfully") {
          notifyService('success', 'Success', result.data.responseStatus)
          handleOpenModalClose()
          viewStartupDetails(idNumber, partnerOrInvestorId);
          showStartupValue();
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  };
  const handleBack = () => {
    if (role === "scsthubavp" || role === "scstadmin") {
      let data = window.location.pathname.split("/");
      ;
      if (data[2] === "approval-pending") {
        navigator(`/${role}/approval-pending/list`);
      }
      else if (data[2] === "alert-pending") {
        navigator(`/${role}/alert-pending/list`);
      }

      else if (data[2] === "currentmentor") {
        navigator(`/${role}/mentortab`);
      }
      else if (data[2] === "previousmentor") {
        navigator(`/${role}/mentortab`);
      } else {
        navigator(`/${role}/startups/list`);
      }
    } else {
      navigator(`/${role}/startups/list`);
    }
  };
  const [openView, setOpenView] = useState(false);
  const handleViewOpen = () => {
    setOpenView(true);
  };
  const handleViewClose = () => {
    setOpenView(false);
  };
  const [openReportAdmin, setOpeReportAdmin] = useState(false);
  const handleReportCloseAdmin = () => {
    setOpeReportAdmin(false);
  };
  const [recommendStatus, setRecommendStatus] = useState()
  const handleViewOpenAdmin = (status) => {
    setRecommendStatus(status)
    setOpeReportAdmin(true);
  };
  const onSubmit = (data) => {
    setPageLoading(true);
    var getform = data;
    getform.status = recommendStatus;
    getform.startupId = idNumber;
    ;
    apiService("partner/scst/status/save", getform, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          viewStartupDetails(idNumber, partnerOrInvestorId);
          showStartupValue();
          AdminRemark()
          MentorRemark()
          pendinglistApi()
          handleReportCloseAdmin();
          setOpeReportAdmin(false);
        }
      }
    });
  };
  const [openReports, setOpenReports] = useState(false);
  const handleMentorApprove = () => {
    setOpenReports(true);
  };
  const handleReportCloses = () => {
    setOpenReports(false);
  };
  const roles = localStorage.getItem("role");
  const [openModal, setOpenModal] = useState(false);
  const handleCommentsopen = () => {
    setOpenModal(true);
  };
  const onSubmitReport = (data) => {
    var req = {
      startupId: idNumber,
      status: 1,
      comments: data.comments,
    };
    ;
    setPageLoading(true);
    apiService("mentorship/scst/admin/status/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          setOpenReports(false);
          reset();
          listApi();
          showStartupValue();
          viewStartupDetails(idNumber, partnerOrInvestorId);
        }
      }
    });
  };
  // const handleClickDownload = () => {
  //   apiService(`startup/pitchdeckurl/get?startupid=${idNumber}`, "", "get")
  //     .then((result) => {
  //       if (result?.data?.pitchDeckName) {
  //         const link = document.createElement("a");
  //         link.href = result.data.pitchDeckName;
  //         link.target = "_blank";
  //         link.rel = "noopener noreferrer";
  //         link.setAttribute("download", "Pitchdeck");
  //         document.body.appendChild(link);
  //         link.click();
  //       } else {
  //         notifyService("info", "File Not Found", "PitchDeck file not found");
  //       }
  //     })
  //     .catch((err) => { });
  // };
  const handleClickDownload = (data) => {
    const pdfUrl = `data:application/pdf;base64,${data}`;
    // Create an anchor element for downloading
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Pitchdeck.pdf";
    document.body.appendChild(link);
    // Trigger download
    link.click();
    // Cleanup
    document.body.removeChild(link);
  };
  const [allowModal, setAllowModal] = useState(false);
  const handleAllowOpenModal = () => {
    setAllowModal(true);
  }
  const handleAllowCloseModal = () => {
    setAllowModal(false);
  };
  const handleMarkEditable = () => {
    const data = {
      startupIdList: [idNumber]
    };
    apiService('partner/scst/enable/editform', data, 'post').then((result) => {
      setPageLoading(false)
      if (result) {
        if (result.data.responseStatus === "Success") {
          notifyService('success', 'Success', result.data.responseStatus)
          viewStartupDetails(idNumber, partnerOrInvestorId)
          handleAllowCloseModal()
        }
      }
    }).catch((err) => {
      console.log(err);
    });

  }

  const [openEmailModal, setopenEmailModal] = useState(false)
  const [stageNames, setStageNames] = useState([]);
  const handleOpenModal = () => {
    setFormData({})
    reset()
    // setSelectedPhase('')
    setopenEmailModal(true)
  }
  const handleOpenModalClose = () => {
    setFormData({})
    reset()
    setopenEmailModal(false)
  }
  let roundDet;
  const handledownloadpdf = () => {
    setPageLoading(true);
    // if (roles === "SCSTHUB") {
    //   roundDet = "hub";
    // } else if (roles === "BDD") {
    //   roundDet = "bdd";
    // } else if (roles === "LDD") {
    //   roundDet = "ldd";
    // } else if (roles === "FDD") {
    //   roundDet = "fdd";
    // }
    var req = {
      id: idNumber,
      // round: roundDet,
    };

    apiService("partner/export/scst/startup/application", req, "post")
      .then((res) => {
        setPageLoading(false);
        if (res.data && res.data.responseStatus === "Success") {
          notifyService("success", "Success", "PDF sent to the email");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [isFullTextVisible, setIsFullTextVisible] = useState({})
  const toggleText = (index) => {

    setIsFullTextVisible(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));

  };
  const [isFullTextVisible2, setIsFullTextVisible2] = useState({})
  const toggleText2 = (index) => {
    setIsFullTextVisible2(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const [isFullTextVisible3, setIsFullTextVisible3] = useState({})
  const toggleText3 = (index) => {
    setIsFullTextVisible3(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const [isFullTextVisible4, setIsFullTextVisible4] = useState({})
  const toggleText4 = (index) => {
    setIsFullTextVisible4(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const truncateTexts = (text, charLimit) => {
    if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
    return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
  };

  const handleStatus = (value) => {
    setStatusValue(value)
  }
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  // useDidMountEffect(() => {
  //   // infoGetInfo()
  //   historyList()
  // }, [statusValue, page]);



  const [addInfoValue, setAddInfoValue] = React.useState('1');
  const handleChangeInfo = (event, newValue) => {
    setAddInfoValue(newValue);
    debugger
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [reasonopenModal, setReasonOpenModal] = useState(false)
  const [reasonAll, setReasonAll] = useState()
  const handlereasonModal = (value) => {

    setReasonAll(value)
    setReasonOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false);
    setReasonOpenModal(false)
  };
  const [openModal2, setOpenModal2] = useState(false);
  const [clarificationList, setClarificationList] = useState();
  const handleOpenModal2 = (val) => {
    setOpenModal2(true);
    setClarificationList(val)
  }
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };
  const [openModal3, setOpenModal3] = useState(false);
  const handleCloseModal3 = () => {
    setOpenModal3(false)
    setPage2(0)
    setRowsPerPage2(5)
  }
  const ViewCurrent = () => {
    setOpenModal3(true)
    setPage2(0)
    setRowsPerPage2(5)
  }
  const [count2, setCount2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(5);

  const [page2, setPage2] = useState(0);
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };
  useDidMountEffect(() => {
    commentList()
  }, [page2, rowsPerPage2,]);
  const [tableData, settableData] = useState([])
  const commentList = () => {
    settableData([])
    setPageLoading(true)
    var id = Number(idNumber)
    var req = {
      listSize: rowsPerPage2,
      pageNumber: page2 + 1,
      startupId: id,
    }
    apiService('partner/scst/startup/current/stage/list', req, 'post').then((res) => {
      setPageLoading(false)
      if (res && res.data) {
        setCount2(res.data.count)
        if (res.data.startupCurrentStageDTOList) {
          settableData(res.data.startupCurrentStageDTOList)
        }
      }
    })
  }
  const [remarks, setRemarks] = useState(0);
  const handleOpenModal4 = (val) => {
    setOpenModal4(true);
    setRemarks(0)
  }
  const [openModal4, setOpenModal4] = useState(false);

  const handleCloseModal4 = () => {
    setOpenModal4(false)
  }
  const [approveModal, setApproveModal] = useState(false);
  const [statusVal, setStatusVal] = useState()
  const [btnName, setBtnName] = useState()
  const handleApproveOpenModal = (val, btnName) => {
    setStatusVal(val)
    setBtnName(btnName)
    setApproveModal(true);
  }
  const handleApproveCloseModal = () => {
    setApproveModal(false);
  };
   
  const onStatusUpdate = () => {
    setPageLoading(true);
    var getform = {
      status: statusVal,
      startupId: idNumber,
    };
    ;
    apiService("partner/scst/status/save", getform, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          viewStartupDetails(idNumber, partnerOrInvestorId);
          showStartupValue();
          pendinglistApi()
          setApproveModal(false);
        }
      }
    });
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



  const handleChanges = (event, newValue) => {
    setRemarks(newValue);
  };
  const [scstMentorAllRemarks, setScstMentorAllRemarks] = useState([]);
  const [countMentor, setCountMentor] = useState(0);
  const [pageMentor, setPageMentor] = useState(0);
  const [rowsPerPageMentor, setRowsPerPageMentor] = useState(10);
  const handleChangePageMentor = (event, newPage) => {
    setPageMentor(newPage);
  };
  const handleChangeRowsPerPageMentor = (event) => {
    setRowsPerPageMentor(parseInt(event.target.value, 10));
    setPageMentor(0);
  };
  useDidMountEffect(() => {
    MentorRemark()
  }, [pageMentor, rowsPerPageMentor]);
  const MentorRemark = () => {
    setPageLoading(true);
    setScstMentorAllRemarks([])
    var req = {
      listSize: rowsPerPageMentor,
      pageNumber: pageMentor + 1,
      startupId: idNumber,

    };
    apiService("mentorship/scst/remarks/list", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setCountMentor(result.data.count);
          if (result.data.mentorRemarksList) {
            setScstMentorAllRemarks(result.data.mentorRemarksList);
          }
        }
      })
      .catch((err) => { });
  }

  return (
    <div className="box-show">
      <section className="bg-white">
        <div className="container-fluid">
          <div className="p-3">
            <div className="row">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Button onClick={handleBack}>
                          <ArrowBackIcon />
                        </Button>
                      </div>
                      <img
                        src={startupGetData.startupLogoName}
                        className="img-fluid user_logos"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="content">
                      <div className="d-flex align-items-start flex-wrap mb-1" style={{ gap: '5px' }}>
                        <h3 className="mr-3 mb-0" style={{ wordBreak: 'break-word' }}>{startupValue.startupName} ({startupValue.applicationNo})</h3>
                        <button
                          className={`dpi-button ${isRegistered ? "register-btn" : "not-register-btn"
                            }`}
                        >
                          {isRegistered && (
                            <AiFillSafetyCertificate className="icon" />
                          )}
                          {isRegistered
                            ? "DPIIT Recognised"
                            : "DPIIT Not Registered"}
                        </button>
                        <button
                          // className="viewdetails-surpitch"
                          className="downloaddetails-surpitch"
                          onClick={() => {
                            handleClickOpen(startupGetData.pitchDeck);
                          }}
                        >
                          View PitchDeck
                        </button>
                        <button
                          className="downloaddetails-surpitch"
                          onClick={() => {
                            handleClickDownload(startupGetData.pitchDeck);
                          }}
                        >
                          Download PitchDeck
                        </button>

                        {(roles === "SCSTADMIN" || roles === "SCSTHUBAVP") && (
                          <div >
                            <button
                              className="downloaddetails-surpitch"
                              onClick={() => handledownloadpdf()}
                            >
                              Export as PDF
                            </button>
                          </div>
                        )}
                      </div>
                      <span className="black-clr-sur">
                        <img src="/images/phone.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupPhone
                          ? startupValue.startupPhone
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        <img src="/images/email.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupEmail
                          ? startupValue.startupEmail
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        Member Since : {startupValue.date}
                      </span>
                      <div>
                        <div>
                          Current Status :{" "}
                          <span className="status_startup">
                            {getSCSTStartupStatus(startupCheckList.status)}
                          </span>{" "}
                          {(roles === 'SCSTADMIN' && status !== "status") && (
                            <>
                              <button
                                variant="contained"
                                onClick={handleOpenModal}
                                className="downloaddetails-surpitch"

                              >
                                Update Status
                              </button>
                              {startupValue.superEditEnabled == false && (
                                <button
                                  className="downloaddetails-surpitch"
                                  onClick={handleAllowOpenModal}
                                >
                                  <span> Allow Startup to Edit</span>
                                </button>)}
                            </>)}


                        </div>

                      </div>
                      {startupCheckList.status === 28 && (<>
                        <div className="mt-2">
                          Status :{" "}

                          <>
                            {startupCheckList.scstadminBddApproveButton !==
                              true ? (
                              <>
                                {startupCheckList.scstadminBddRejectedButton && (
                                  <span className="status_reject">
                                    SCST Admin Recommended to MentorShip
                                  </span>
                                )}
                                {startupCheckList.scstadminBddRecommendedButton && (
                                  <span className="status_approved">
                                    SCST Admin Approved for BDD
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                <span className="status_Pending">
                                  SCST Admin Approval Pending
                                </span>
                              </>
                            )}{" "}
                            {startupCheckList.hubadminBddApproveButton !==
                              true ? (
                              <>
                                {startupCheckList.hubadminBddRejectedButton && (
                                  <span className="status_reject">
                                    Hub Admin Recommended to MentorShip
                                  </span>
                                )}
                                {startupCheckList.hubadminBddRecommendedButton && (
                                  <span className="status_approved">
                                    Hub Admin Approved for BDD
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className="status_Pending">
                                Hub Admin Approval Pending
                              </span>
                            )}
                          </>
                        </div></>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="ml-auto text-right">
                  {tableData.length > 0 && (
                    <>
                      <Tooltip title="View Current Progress">
                        <img src="/images/assign.gif" alt="" style={{ cursor: 'pointer', width: '60px' }} onClick={() => ViewCurrent()} />
                      </Tooltip>
                    </>
                  )}




                  {/* {role === "scsthubavp" && (
                    <>
                      {startupCheckList.hubadminBddRecommendedButton ===
                        true && (
                          <span className="status_startup mr-2">
                            Recommended to BDD
                          </span>
                        )}
                      {startupCheckList.hubadminBddRejectedButton === true && (
                        <span className="status_startup mr-2">
                          Recommended to MentorShip
                        </span>
                      )}
                    </>
                  )} */}
                  {(status !== "status" && startupCheckList.status) >= 6 && (
                    <>
                      {startupCheckList.bddDeepDiveReportDoc ||
                        startupCheckList.icReportDoc ||
                        startupCheckList.pscReportDoc ||
                        startupCheckList.lddReportDoc ||
                        startupCheckList.fddReportDoc ||
                        startupCheckList.cpLddReportDoc ||
                        startupCheckList.cpFddReportDoc ||
                        startupCheckList.csLddReportDoc ||
                        startupCheckList.csFddReportDoc ? (
                        <ViewAllReports startupCheckList={startupCheckList} />
                      ) : (
                        <>
                          {[5, 6, 7, 8, 9, 10].includes(startupCheckList.status) && (
                            <>
                              {startupCheckList.bddFirstConnectReportDoc && (
                                <div className="d-flex align-items-center justify-content-between">

                                  <>
                                    <button
                                      className="downloaddetails-surpitch-bbdd my-1"
                                      onClick={() =>
                                        handleClickReportOpen(
                                          startupCheckList.bddFirstConnectReportDoc
                                        )
                                      }
                                    >
                                      BDD First Connect Report
                                    </button>
                                  </>
                                  <>
                                    {comments?.[0]?.comment && (
                                      <span
                                        className="c-pointer"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View BDD First Connect Comment"
                                        onClick={() => handleViewOpen()}
                                      >
                                        <img
                                          src="/images/comment.png"
                                          alt="Comment Icon"
                                          className="img-fluid"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            filter:
                                              "invert(33%) sepia(92%) saturate(1109%) hue-rotate(202deg) brightness(94%) contrast(89%)",
                                          }}
                                        />
                                      </span>
                                    )}
                                  </>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}


                    </>
                  )}
                  {((roles === "SCSTADMIN" || roles === "SCSTHUBAVP") && (scstAdminAllRemarks.length > 0 || scstMentorAllRemarks.length > 0)) && (
                    // <button className="downloaddetails-surpitch" onClick={() => handleOpenModal4()}>
                    //   MentorShip Remarks
                    // </button>
                    <Tooltip title="Admin & Mentorship Remarks">
                      <img src="/images/chat.gif" alt="" style={{ width: '50px', cursor: 'pointer' }} onClick={() => handleOpenModal4()} />
                    </Tooltip>

                  )}
                  {/* {roles === "SCSTADMIN" &&
                    startupValue.adminRemarks && (
                      <>
                        <img
                          src="/images/comment.png"
                          alt="Comment Icon"
                          className="img-fluid"
                          onClick={() => handleCommentsopen()}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: '5px',
                            filter:
                              "invert(33%) sepia(92%) saturate(1109%) hue-rotate(202deg) brightness(94%) contrast(89%)",
                          }}
                        />
                        <CommentsModal
                          remarks={startupValue}
                          style={{ color: "white" }}
                          openModal={openModal}
                          setOpenModal={setOpenModal}
                        />
                      </>
                    )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: '-6px' }}>
            <div className="offset-2 ">
              <div className="d-flex align-items-center flex-wrap">
                {(
                  (status !== "status" && (
                    startupCheckList.status === 6 ||
                    startupCheckList.status === 18 ||
                    startupCheckList.status === 10 ||
                    startupCheckList.status === 21 ||
                    startupCheckList.status === 22 ||
                    startupCheckList.status === 24
                  )) ||
                  // (roles === "SCSTADMIN" && (
                  //   startupCheckList.status === 14 ||
                  //   startupCheckList.status === 30 ||
                  //   startupCheckList.status === 12
                  // )) 
                  // ||
                  (role === "scstadmin" && startupCheckList.scstadminBddApproveButton) ||
                  (role === "scsthubavp" && startupCheckList.hubadminBddApproveButton)
                ) && (
                    <><span> Actions: {""}</span>
                      <img src="/images/img/gif right hand.gif" alt="hand-icon" className="hand-icon" />
                    </>)}
                {(status !== "status" && startupCheckList.status) === 6 && (
                  <>
                    <div>
                      <button
                        className="viewdetails-Approve"
                        onClick={() => handleApproveOpenModal(7, 'Approve for Deep Dive')}
                      >
                        Approve for Deep Dive
                      </button>
                      <span className="mx-2 ">or</span>
                      <button
                        className="viewdetails-Schemes"
                        // onClick={() => onStatusUpdate(9)}
                        onClick={() => handleViewOpenAdmin(9)}
                      >
                        Recommend to Mentorship
                      </button>
                    </div>
                  </>
                )}
                {(status !== "status" && startupCheckList.status) === 10 && (
                  <>
                    <div className="mb-2">
                      <button
                        className="viewdetails-Approve"
                        onClick={() => handleApproveOpenModal(11, 'Approve for IC')}
                      >
                        Approve for IC
                      </button>
                      <span className="mx-2 ">or</span>
                      <button
                        className="viewdetails-Schemes"
                        // onClick={() => onStatusUpdate(12)}
                        onClick={() => handleViewOpenAdmin(12)}
                      >
                        Recommend to Mentorship
                      </button>
                    </div>
                  </>
                )}
                {(status !== "status" && startupCheckList.status) === 18 && (
                  <>
                    <div className="mb-1">
                      <button
                        className="viewdetails-Approve"
                        onClick={() => handleApproveOpenModal(20, 'Approve for CP')}
                      >
                        Approve for CP
                      </button>
                      <span className="mx-2 ">or</span>
                      <button
                        className="viewdetails-Schemes"
                        // onClick={() => onStatusUpdate(19)}
                        onClick={() => handleViewOpenAdmin(19)}
                      >
                        Recommend to Mentorship
                      </button>
                    </div>
                  </>
                )}

                {role === "scstadmin" &&
                  startupCheckList.scstadminBddApproveButton && (
                    <>
                      <div className="mb-2">
                        <button
                          className="viewdetails-Approve"
                          onClick={() => handleApproveOpenModal(3, 'Recommend to BDD')}
                        >
                          Recommend to BDD
                        </button>
                        <span className="mx-2 ">or</span>
                        <button
                          className="viewdetails-Schemes"
                          onClick={() => handleViewOpenAdmin(4)}
                        >
                          Recommend to Mentorship
                        </button>
                      </div>
                    </>
                  )}
                {role === "scsthubavp" &&
                  startupCheckList.hubadminBddApproveButton && (
                    <>
                      <div>
                        <button
                          className="viewdetails-Approve"
                          onClick={() => handleApproveOpenModal(3, 'Recommend to BDD')}
                        >
                          Recommend to BDD
                        </button>
                        <span className="mx-2 ">or</span>
                        <button
                          className="viewdetails-Schemes"
                          onClick={() => handleViewOpenAdmin(4)}
                        >
                          Recommend to Mentorship
                        </button>
                      </div>
                    </>
                  )}
                {(status !== "status" && startupCheckList.status) === 21 && (
                  <>
                    <div>
                      <button
                        className="viewdetails-Approve"
                        onClick={() => handleApproveOpenModal(22, 'Approve for Fund Sanction')}
                      >
                        Approve for Fund Sanction
                      </button>
                      <span className="mx-2 ">or</span>
                      <button
                        className="viewdetails-Schemes"
                        // onClick={() => onStatusUpdate(23)}
                        onClick={() => handleViewOpenAdmin(23)}
                      >
                        Recommend to Mentorship
                      </button>
                    </div>
                  </>
                )}
                {status !== "status" && startupCheckList.status === 22 && (
                  <>
                    <div className="mt-2">
                      <button
                        className="viewdetails-Approve"
                        onClick={() => handleApproveOpenModal(24, 'Approved for Fund Disbursement')}
                      >
                        Approved for Fund Disbursement{" "}
                      </button>
                      {/* <span className="mx-2 ">or</span>
                      <button
                        className="viewdetails-Schemes"
                        onClick={() => handleViewOpenAdmin(25)}
                      >
                        FS Recommend to Mentorship
                      </button> */}
                    </div>
                  </>
                )}

                {/* {((roles === "SCSTADMIN" && startupCheckList.status === 14 || startupCheckList.status === 12) ||
                  startupCheckList.status === 30) && (

                    <button
                      className="viewdetails-Approve ml-3"
                      onClick={() => handleMentorApprove()}
                    >
                      Approve
                    </button>
                  )} */}
                {(status !== "status" && startupCheckList.status) === 24 && (
                  <>
                    <div className="">
                      <button
                        className="viewdetails-Approve mr-3 "
                        onClick={() => handleApproveOpenModal(26, 'Approved for CS Stage')}
                      >
                        Approved for CS Stage
                      </button>
                    </div>
                  </>
                )}


              </div>
            </div>
          </div>
          <hr />
          <div className="setHeg">
            <Grid container>
              <Grid item xs={2}>
                <Tabs
                  orientation="vertical"
                  // variant="scrollable"
                  value={valueTab}
                  onChange={handleChangeTab}
                  aria-label="Vertical tabs example"
                >
                  <Tab label="Profile" {...a11yProps(0)} className="side-tab" />
                  <Tab
                    label="Validation"
                    {...a11yProps(1)}
                    className="side-tab"
                  />
                  {startupCheckList.status >= 1 && (
                    <Tab
                      label="Evaluation"
                      {...a11yProps(2)}
                      className="side-tab"
                    />
                  )}
                  {(startupCheckList.status >= 5 &&
                    startupCheckList.status !== 28) && (
                      <Tab
                        label="BDD First Connect"
                        {...a11yProps(3)}
                        className="side-tab"
                      />
                    )}
                  {((startupCheckList.status >= 8) &&
                    (startupCheckList.status !== 9 && startupCheckList.status !== 28)) && (
                      <Tab
                        label="BDD deep dive"
                        {...a11yProps(4)}
                        className="side-tab"
                      />
                    )}
                  {(startupCheckList.status >= 17 &&
                    startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30) && (
                      <Tab label="LDD" {...a11yProps(5)} className="side-tab" />
                    )}
                  {(startupCheckList.status >= 17 &&
                    startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30) && (
                      <Tab label="FDD" {...a11yProps(6)} className="side-tab" />
                    )}
                  {(startupCheckList.status >= 20 &&
                    startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30) && (
                      <Tab label="LDD CP" {...a11yProps(7)} className="side-tab" onClick={() => CpApi('LDD')} />
                    )}

                  {(startupCheckList.status >= 20 &&
                    startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30) && (
                      <Tab label="FDD CP" {...a11yProps(8)} className="side-tab" onClick={() => CpApi('FDD')} />
                    )}
                  {(startupCheckList.status >= 26 &&
                    startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30 && startupCheckList.status !== 31) && (
                      <Tab label="CS Documents" {...a11yProps(9)} className="side-tab" />
                    )}
                  <Tab
                    label="Document Room"
                    {...a11yProps(docmntRmIndex)}
                    className="side-tab"
                  />

                  {/* {
                    role === 'scsthubavp' && ( */}
                  <Tab
                    label="Zoom"
                    {...a11yProps(meetingIndex)}
                    className="side-tab"
                  />
                  {role === "scstadmin" && (
                    <Tab
                      label="Staging & Aging"
                      {...a11yProps(progressIndex)}
                      className="side-tab"
                    />
                  )}
                  {role === "scstadmin" && (
                    <Tab
                      label="Chronology"
                      {...a11yProps(progressIndex1)}
                      className="side-tab"
                    />
                  )}

                </Tabs>
              </Grid>
              <Grid item xs={10}>
                <TabPanel value={valueTab} index={0}>
                  <div className="overall table_tag">
                    <Box>
                      <Tabs
                        className="verticalTab"
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                      >
                        <Tab
                          className="verticalLable"
                          label="Application Overview"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="verticalLable"
                          label="Funding & Financials"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="verticalLable"
                          label="Founder Info"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="verticalLable"
                          label="Incubation & Acceleration"
                          {...a11yProps(3)}
                        />
                      </Tabs>
                      <Box sx={{ p: 3 }}>
                        <TabPanel value={value} index={0}>
                          <Applicationscst
                            details={startupGetData}
                            detail={startupValue}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <Fundingscstview
                            details={startupGetData}
                            detail={startupValue}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <Founderscstview details={startupGetData} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <IncubatorAccelerationScst
                            details={startupGetData}
                            detail={startupValue}
                          />
                        </TabPanel>
                      </Box>
                    </Box>
                  </div>
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                  <Box sx={{ p: 3 }}>
                    <Scsthubavpvalidation listData={listData[0]} />
                  </Box>
                </TabPanel>
                {startupCheckList.status >= 1 && (
                  <TabPanel value={valueTab} index={2}>
                    <div className="table_tag">
                      <Box sx={{ p: 3 }}>
                        <Scsthubavpevaluation
                          listApi={listApi}
                          listData={listData[1]}
                          idNumber={idNumber}
                          startupCheckList={startupCheckList}
                          showStartupValue={showStartupValue}
                        />
                      </Box>
                    </div>
                  </TabPanel>
                )}
                {(startupCheckList.status >= 5 &&
                  startupCheckList.status !== 28) && (
                    <TabPanel value={valueTab} index={3}>
                      <ScstBddVerificationLayout
                        idNumber={idNumber}
                        path={""}
                        viewStartupDetails={viewStartupDetails}
                        startupValue={startupValue}
                      />
                    </TabPanel>
                  )}
                {((startupCheckList.status >= 8) &&
                  (startupCheckList.status !== 9 && startupCheckList.status !== 28)) && (
                    <TabPanel value={valueTab} index={4}>
                      <BDDDeepDiveLayout
                        idNumber={idNumber}
                        path={""}
                        viewStartupDetails={viewStartupDetails}
                        startupValue={startupValue}
                      />
                    </TabPanel>
                  )}
                {(startupCheckList.status >= 17 &&
                  startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30) && (
                    <TabPanel value={valueTab} index={5}>
                      <LDDLayout
                        idNumber={idNumber}
                        path={""}
                        viewStartupDetails={viewStartupDetails}
                        startupValue={startupValue}
                      />
                    </TabPanel>
                  )}
                {(startupCheckList.status >= 17 &&
                  startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30) && (
                    <TabPanel value={valueTab} index={6}>
                      <FDDLayout
                        idNumber={idNumber}
                        path={""}
                        viewStartupDetails={viewStartupDetails}
                        startupValue={startupValue}
                      />
                    </TabPanel>
                  )}
                {(startupCheckList.status >= 20 &&
                  startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30) && (
                    <TabPanel value={valueTab} index={7}>
                      <div className="">
                        <TabContext value={addInfoValue}>
                          <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                            <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                              <Tab className="verticalLable" label="Clarification Requested" value="1" onClick={() => handleStatus(0)} />

                              <Tab className="verticalLable"
                                label={
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Management Responded

                                  </div>
                                }
                                value="2" onClick={() => handleStatus(1)} />



                              <Tab className="verticalLable" label="Proposed Action" value="3" onClick={() => handleStatus(4)} />
                              <Tab className="verticalLable" label="Clarified - Closed" value="4" onClick={() => handleStatus(3)} />
                              <Tab className="verticalLable" label="CP Documents" value="5" />
                            </TabList>

                          </Box>
                          <Divider />
                          <div className="mt-3">
                            {addInfoValue !== "5" && (<>
                              {historyListValue.length > 0 &&
                                (historyListValue.length) ? (
                                <TableContainer component={Paper}>
                                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align='center'>No</TableCell>
                                        {/* <TableCell>Reason</TableCell> */}

                                        <TableCell align='center'>Area Diligence</TableCell>

                                        {/* {
                                                    (statusValue === 1 || statusValue === 4) && <TableCell align='center'>Propose Action</TableCell>
                                                } */}
                                        {
                                          statusValue === 2 && <TableCell align='center'>Time</TableCell>
                                        }
                                        {/* {
                                                    statusValue === 1 && <TableCell align='center'>Attached Document</TableCell>
                                                } */}
                                        {/* {
                                                    (statusValue === 2 || statusValue === 4) && <TableCell align='center'>View Document</TableCell>
                                                } */}
                                        {/* {
                                                    (statusValue === 1 || statusValue === 4) && <TableCell align='center'>Action</TableCell>
                                                } */}





                                        <TableCell align='center'>Observations</TableCell>
                                        <TableCell align='center'>Impact</TableCell>
                                        <TableCell align='center'>Risk</TableCell>
                                        <TableCell align='center'>Clarification Requested</TableCell>
                                        {
                                          (statusValue === 3 || statusValue === 4) &&
                                          <TableCell align='center'>Proposed Action</TableCell>
                                        }
                                        {
                                          (statusValue === 1 || statusValue === 4 || statusValue === 3) && <TableCell align='center'>Attached Document</TableCell>
                                        }
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {historyListValue.map((list, index) => (
                                        <TableRow key={index}>
                                          <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>


                                          <TableCell align="center">
                                            {list.diligenceArea ? (
                                              <>
                                                {isFullTextVisible[index]
                                                  ? list.diligenceArea
                                                  : truncateTexts(list.diligenceArea || '', 10)}

                                                {list.diligenceArea && list.diligenceArea.length > 10 && (
                                                  <h6
                                                    onClick={() => toggleText(index)}
                                                    style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                  >
                                                    {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                                  </h6>
                                                )}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {list.observations ? (
                                              <>
                                                {isFullTextVisible2[index] ? list.observations : truncateTexts(list.observations || '', 10)}
                                                {list.observations && list.observations.length > 10 && (<h6
                                                  onClick={() => toggleText2(index)}
                                                  style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                >
                                                  {isFullTextVisible2[index] ? " Read Less" : " Read More"}
                                                </h6>)}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {list.impact ? (
                                              <>
                                                {isFullTextVisible3[index] ? list.impact : truncateTexts(list.impact || '', 10)}
                                                {list.impact && list.impact.length > 10 && (<h6
                                                  onClick={() => toggleText3(index)}
                                                  style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                >
                                                  {isFullTextVisible3[index] ? " Read Less" : " Read More"}
                                                </h6>)}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {list.risk ? (
                                              <>
                                                {list.risk}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            <img src="/images/chat.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleOpenModal2(list.clarificationList)} className="mr-2" />
                                            {/* {list.reason ? (
                                                            <>
                                                                {list.reason && (
                                                                    <>
                                                                        {isFullTextVisible4[index] ? list.reason : truncateTexts(list.reason || '', 10)}
                                                                        {list.reason && list.reason.length > 10 && (<span
                                                                            onClick={() => toggleText4(index)}
                                                                            style={{ cursor: "pointer", color: "#7F9AD2", }}
                                                                        >
                                                                            {isFullTextVisible4[index] ? " Read Less" : " Read More"}
                                                                        </span>)}
                                                                    </>
                                                                )}
                                                            </>) : ((`-`))} */}
                                          </TableCell>

                                          {
                                            (statusValue === 3 || statusValue === 4) &&
                                            <TableCell align='center' className='c-pointer'>
                                              {list.proposeAction ? (
                                                <img src="/images/drafts.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handlereasonModal(list.proposeAction, list.id)} />
                                              ) : (`-`)}

                                            </TableCell>
                                          }
                                          {
                                            (statusValue === 2 || statusValue === 4 || statusValue === 3) && <TableCell align='center'>
                                              {/* <button className='viewdetails' onClick={() => { handleClickReportOpen(list.document) }}>View</button> */}
                                              <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickReportOpen(list.document)} />
                                            </TableCell>
                                          }
                                          {
                                            statusValue === 1 && <TableCell align='center'>
                                              {/* <button className='viewdetails' onClick={() => { handleClickReportOpen(list.document) }}>View</button> */}
                                              <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickReportOpen(list.document)} />
                                            </TableCell>
                                          }

                                          {/* {
                                                        (statusValue === 1 || statusValue === 4 || statusValue === 3) && (
                                                            <>
                                                                <TableCell align='center'>
                                                                    {
                                                                        (statusValue === 1 || statusValue === 4) && (
                                                                            <Resubmit id={list.id} idNumber={idNumber} historyList={historyList} name="cp" statusValue={statusValue} showStartupValue={showStartupValue} />
                                                                        )
                                                                    }
                                                                    {
                                                                        (statusValue === 1 || statusValue === 4 ) && (
                                                                            <button className='verified_btn' onClick={() => handelMarkVerifed(list.id)}>Mark as Clarified & Closed</button>
                                                                        )}
                                                                </TableCell>
                                                            </>
                                                        )
                                                    } */}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                    <caption>
                                      <TablePagination
                                        component="div"
                                        count={countHistory}
                                        page={pageHistory}
                                        onPageChange={handleHistoryChangePage}
                                        rowsPerPage={rowsPerPageHistory}
                                        onRowsPerPageChange={
                                          handleChangeHistoryRowsPerPage
                                        }
                                        style={{ float: 'left' }}
                                      />
                                    </caption>
                                  </Table>

                                </TableContainer>
                              ) : (
                                <>
                                  {addInfoValue !== "5" && (<div className="nodata_tag">
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <p>No Records Found</p>
                                  </div>)}
                                </>
                              )
                              }
                            </>)
                            }
                            <div className="p-3">
                              {addInfoValue === "5" ? (<TabPanel >
                                <CpDocuments idNumber={idNumber} />
                              </TabPanel>) : (<></>)}
                            </div>
                          </div>

                        </TabContext>
                      </div>
                    </TabPanel>
                  )}
                {startupCheckList.status >= 20 &&
                  startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30 && (
                    <TabPanel value={valueTab} index={8}>

                      <div className="">
                        <TabContext value={addInfoValue}>
                          <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                            <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                              <Tab className="verticalLable" label="Clarification Requested" value="1" onClick={() => handleStatus(0)} />

                              <Tab className="verticalLable"
                                label={
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Management Responded
                                    {/* {startupCheckList.additionalInfoLddCpRequested === true ? (
                                                    <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                ) : (<></>)} */}
                                  </div>
                                }
                                value="2" onClick={() => handleStatus(1)} />



                              <Tab className="verticalLable" label="Proposed Action" value="3" onClick={() => handleStatus(4)} />
                              <Tab className="verticalLable" label="Clarified - Closed" value="4" onClick={() => handleStatus(3)} />
                              <Tab className="verticalLable" label="CP Documents" value="5" />
                            </TabList>

                          </Box>
                          <Divider />
                          <div className="mt-3">
                            {addInfoValue !== "5" && (<>
                              {historyListValue.length > 0 &&
                                (historyListValue.length) ? (
                                <TableContainer component={Paper}>
                                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align='center'>No</TableCell>
                                        {/* <TableCell>Reason</TableCell> */}

                                        <TableCell align='center'>Area Diligence</TableCell>

                                        {/* {
                                                    (statusValue === 1 || statusValue === 4) && <TableCell align='center'>Propose Action</TableCell>
                                                } */}
                                        {
                                          statusValue === 2 && <TableCell align='center'>Time</TableCell>
                                        }
                                        {/* {
                                                    statusValue === 1 && <TableCell align='center'>Attached Document</TableCell>
                                                } */}
                                        {/* {
                                                    (statusValue === 2 || statusValue === 4) && <TableCell align='center'>View Document</TableCell>
                                                } */}
                                        {/* {
                                                    (statusValue === 1 || statusValue === 4) && <TableCell align='center'>Action</TableCell>
                                                } */}


                                        <TableCell align='center'>Observations</TableCell>
                                        <TableCell align='center'>Impact</TableCell>
                                        <TableCell align='center'>Risk</TableCell>
                                        <TableCell align='center'>Clarification Requested</TableCell>
                                        {
                                          (statusValue === 3 || statusValue === 4) &&
                                          <TableCell align='center'>Proposed Action	</TableCell>
                                        }
                                        {
                                          (statusValue === 1 || statusValue === 4 || statusValue === 3) && <TableCell align='center'>Attached Document</TableCell>
                                        }
                                        {/* <TableCell align='center'>Action</TableCell> */}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {historyListValue.map((list, index) => (
                                        <TableRow key={index}>
                                          <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>


                                          <TableCell align="center">
                                            {list.diligenceArea ? (
                                              <>
                                                {isFullTextVisible[index]
                                                  ? list.diligenceArea
                                                  : truncateTexts(list.diligenceArea || '', 10)}

                                                {list.diligenceArea && list.diligenceArea.length > 10 && (
                                                  <h6
                                                    onClick={() => toggleText(index)}
                                                    style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                  >
                                                    {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                                  </h6>
                                                )}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {list.observations ? (
                                              <>
                                                {isFullTextVisible2[index] ? list.observations : truncateTexts(list.observations || '', 10)}
                                                {list.observations && list.observations.length > 10 && (<h6
                                                  onClick={() => toggleText2(index)}
                                                  style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                >
                                                  {isFullTextVisible2[index] ? " Read Less" : " Read More"}
                                                </h6>)}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {list.impact ? (
                                              <>
                                                {isFullTextVisible3[index] ? list.impact : truncateTexts(list.impact || '', 10)}
                                                {list.impact && list.impact.length > 10 && (<h6
                                                  onClick={() => toggleText3(index)}
                                                  style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                >
                                                  {isFullTextVisible3[index] ? " Read Less" : " Read More"}
                                                </h6>)}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {list.risk ? (
                                              <>
                                                {list.risk}
                                              </>) : ((`-`))}
                                          </TableCell>
                                          <TableCell align='center'>
                                            <img src="/images/chat.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleOpenModal2(list.clarificationList)} className="mr-2" />
                                            {/* {list.reason ? (
                                                            <>
                                                                {list.reason && (
                                                                    <>
                                                                        {isFullTextVisible4[index] ? list.reason : truncateTexts(list.reason || '', 10)}
                                                                        {list.reason && list.reason.length > 10 && (<span
                                                                            onClick={() => toggleText4(index)}
                                                                            style={{ cursor: "pointer", color: "#7F9AD2", }}
                                                                        >
                                                                            {isFullTextVisible4[index] ? " Read Less" : " Read More"}
                                                                        </span>)}
                                                                    </>
                                                                )}
                                                            </>) : ((`-`))} */}
                                          </TableCell>


                                          {
                                            (statusValue === 3 || statusValue === 4) &&
                                            <TableCell align='center' className='c-pointer'>
                                              {list.proposeAction ? (
                                                <img src="/images/drafts.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handlereasonModal(list.proposeAction, list.id)} />
                                              ) : (`-`)}
                                            </TableCell>
                                          }
                                          {
                                            (statusValue === 2 || statusValue === 4 || statusValue === 3) && <TableCell align='center'>
                                              {/* <button className='viewdetails' onClick={() => { handleClickReportOpen(list.document) }}>View</button> */}
                                              <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickReportOpen(list.document)} />
                                            </TableCell>
                                          }
                                          {
                                            statusValue === 1 && <TableCell align='center'>
                                              {/* <button className='viewdetails' onClick={() => { handleClickReportOpen(list.document) }}>View</button> */}
                                              <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickReportOpen(list.document)} />
                                            </TableCell>
                                          }
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                    <caption>
                                      <TablePagination
                                        component="div"
                                        count={countHistory}
                                        page={pageHistory}
                                        onPageChange={handleHistoryChangePage}
                                        rowsPerPage={rowsPerPageHistory}
                                        onRowsPerPageChange={
                                          handleChangeHistoryRowsPerPage
                                        }
                                        style={{ float: 'left' }}
                                      />
                                    </caption>
                                  </Table>

                                </TableContainer>
                              ) : (
                                <>
                                  {addInfoValue !== "5" && (<div className="nodata_tag">
                                    <img
                                      src="/images/Tanfundlogo-black-1.png"
                                      className="no_data_logo"
                                      alt=""
                                    />
                                    <h4>No Records Found</h4>
                                  </div>)}</>
                              )
                              }
                            </>
                            )
                            }
                            <div className="p-3">
                              {addInfoValue === "5" ? (<TabPanel><CpDocuments idNumber={idNumber} /></TabPanel>) : (<></>)}
                            </div>
                          </div>


                        </TabContext>
                      </div>
                    </TabPanel>
                  )}
                {(startupCheckList.status >= 26 &&
                  startupCheckList.status !== 28 && startupCheckList.status !== 29 && startupCheckList.status !== 30 && startupCheckList.status !== 31) && (
                    <TabPanel value={valueTab} index={9}>
                      <CpcsLayout
                        idNumber={idNumber}
                        startupValue={startupValue}
                      />
                    </TabPanel>
                  )}
                <TabPanel value={valueTab} index={docmntRmIndex}>
                  <div className="table_tag">
                    <Box sx={{ p: 3 }}>
                      <DocumentRoomAdmin startupId={idNumber} />
                    </Box>
                  </div>
                </TabPanel>
                {/* {
                  role === 'scsthubavp' && ( */}
                <TabPanel value={valueTab} index={meetingIndex}>
                  <div className="table_tag">
                    <Box sx={{ p: 3 }}>
                      <Zoomlist startupId={idNumber} />
                    </Box>
                  </div>
                </TabPanel>
                {role === "scstadmin" && (
                  <TabPanel value={valueTab} index={progressIndex}>
                    <div className="table_tag">
                      <Box sx={{ p: 3 }}>
                        <StartupApplicationStatus startupIds={idNumber} />
                      </Box>
                    </div>
                  </TabPanel>
                )}
                {role === "scstadmin" && (
                  <TabPanel value={valueTab} index={progressIndex1}>
                    <div className="table_tag">
                      <Box sx={{ p: 3 }}>
                        <Chronology startupIds={idNumber} />
                      </Box>
                    </div>
                  </TabPanel>
                )}

              </Grid>
            </Grid>
          </div>
          <CommonViewPitchDeck
            viewImage={viewImage}
            open={open}
            handleClose={handleClose}
          />
          <Viewimage
            viewImage={viewReportImage}
            open={openReport}
            handleClose={handleReportClose}
          />
          <Dialog
            open={openView}
            maxWidth="sm"
            fullWidth={true}
            onClose={handleViewClose}
          >
            <DialogContent>
              <h1 className="views-scomm">View Comments</h1>
              <TableContainer component={Paper} className="table-container">
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="table-header">
                        S.No
                      </TableCell>
                      <TableCell align="center" className="table-header">
                        Phase
                      </TableCell>
                      <TableCell align="center" className="table-header">
                        Remarks
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {comments && comments.map((data, i) => (
                      <TableRow className="table-row">
                        <TableCell align="center" className="table-cell">
                          {i + 1}
                        </TableCell>
                        <TableCell align="center" className="table-cell">
                          {data.reportType}
                        </TableCell>
                        <TableCell align="center" >
                          {data.comment ? (
                            <>
                              {isFullTextVisible[i]
                                ? data.comment
                                : truncateTexts(data.comment || '', 15)}

                              {data.comment && data.comment.length > 15 && (
                                <h6
                                  onClick={() => toggleText(i)}
                                  style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                >
                                  {isFullTextVisible[i] ? " Read Less" : " Read More"}
                                </h6>
                              )}
                            </>) : ((`-`))}
                        </TableCell>


                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* {comments.map((report, index) => (
                <p key={index} className="mt-4">
                  {report.comment}
                </p>
              ))} */}
            </DialogContent>
          </Dialog>

          {/* Admin Report */}
          <Dialog
            maxWidth={"sm"}
            fullWidth={true}
            open={openReportAdmin}
            onClose={handleReportCloseAdmin}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Remarks</DialogTitle>
            <DialogContent>
              <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="form-group mt-2">
                      <Controller
                        name="comments"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please Enter Remarks" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            multiline
                            label="Remarks *"
                            placeholder="Enter Remarks"
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.comments && errors.comments.message}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
                <div className="text-center p-3">
                  <Button
                    className="mr-3"
                    onClick={handleReportCloseAdmin}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
          <Dialog
            maxWidth={"sm"}
            fullWidth={true}
            open={openReports}
            onClose={handleReportCloses}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle>
              <h4>Remarks</h4>
            </DialogTitle>
            <DialogContent>
              <form
                className="signin-form"
                onSubmit={handleSubmit(onSubmitReport)}
              >
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="form-group mt-2">
                      <Controller
                        name="comments"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Remarks is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            multiline
                            label="Enter Remarks *"
                            placeholder="Enter Remarks"
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.comments && errors.comments.message}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
                <div className="text-center p-3">
                  <Button
                    className="mr-3"
                    onClick={handleReportCloses}
                    variant="outlined"
                  >
                    Review
                  </Button>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openEmailModal}
            fullScreen={fullScreen}
            maxWidth={'sm'}
            fullWidth={true}
          >
            <DialogTitle >
              <div className='d-flex align-item-center'> Update Status </div>
            </DialogTitle>
            <DialogContent>
              <form
                className="signin-form"
                onSubmit={handleSubmit(updateStatuses)}
              >
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="form-group mt-2">

                      <>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                          <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Status is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                fullWidth
                                label="Status"
                              >
                                {stageNames.map((stage) => (
                                  <MenuItem key={stage.stageId} value={stage.stageId}>
                                    {stage.stageName}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.status && errors.status.message}
                          </FormHelperText>
                        </FormControl>
                      </>


                    </div>
                  </div>
                </div>
                <div className="form-group text-center mt-4 ">
                  <Button
                    variant="contained"
                    onClick={handleOpenModalClose}
                    className="btn btn-cancel submit px-3"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Update</span>
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
          <Dialog
            open={reasonopenModal}
            onClose={handleCloseModal}
            maxWidth={"xs"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">

              <h4 className="">Proposed Action:</h4>

            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {/* <ol>
                            {reasonAll && reasonAll.map((reason, index) => (
                                <li key={index}>{reason ? reason : ""}</li>
                            ))}
                        </ol> */}
                {reasonAll ? (<h6>{reasonAll}</h6>) : (`-`)}

              </DialogContentText>
            </DialogContent>

          </Dialog>
          <Dialog
            open={openModal2}
            onClose={handleCloseModal2}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4 className="text-center p-3" style={{ color: 'black' }}>Clarification Requested</h4>

                <ol>
                  {Array.isArray(clarificationList) && clarificationList.slice().reverse().map((reason, index) => (
                    <li style={{ color: 'black' }} key={index}>{reason || ""}</li>
                  ))}
                </ol>
              </DialogContentText>
            </DialogContent>

          </Dialog>

          <Dialog
            open={openModal3}
            onClose={handleCloseModal3}
            maxWidth={"md"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => setOpenModal3(false)}
                sx={{ position: "absolute", right: 8, top: 8, color: 'red' }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">

                <div >
                  {tableData.length ? (
                    <>
                      <h4>View Current Progress :</h4>
                      <TableContainer component={Paper} className="table-container">
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" className="table-header">
                                S.No
                              </TableCell>
                              <TableCell align="center" className="table-header">
                                Comments
                              </TableCell>
                              <TableCell align="center" className="table-header">
                                Stage
                              </TableCell>
                              <TableCell align="center" className="table-header">
                                Phase
                              </TableCell>
                              <TableCell align="center" className="table-header">
                                Last Updated
                              </TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData && tableData.map((data, i) => (
                              <TableRow className="table-row">
                                <TableCell align="center" className="table-cell">
                                  {
                                    tableIndexValue(rowsPerPage2, page2, i)
                                  }
                                </TableCell>
                                <TableCell align="center" sx={{ width: '300px' }}>
                                  {data.comment ? (
                                    <>
                                      {isFullTextVisible[i]
                                        ? data.comment
                                        : truncateTexts(data.comment || '', 15)}

                                      {data.comment && data.comment.length > 15 && (
                                        <h6
                                          onClick={() => toggleText(i)}
                                          style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                        >
                                          {isFullTextVisible[i] ? " Read Less" : " Read More"}
                                        </h6>
                                      )}
                                    </>) : ((`-`))}
                                </TableCell>

                                <TableCell align='center'>
                                  {data.stage ? (
                                    <>
                                      {isFullTextVisible2[i] ? data.stage : truncateTexts(data.stage || '', 8)}
                                      {data.stage && data.stage.length > 8 && (<h6
                                        onClick={() => toggleText2(i)}
                                        style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                      >
                                        {isFullTextVisible2[i] ? " Read Less" : " Read More"}
                                      </h6>)}
                                    </>) : ((`-`))}
                                </TableCell>
                                <TableCell align="center" className="table-cell">
                                  {data.phase}
                                </TableCell>
                                <TableCell align="center" className="table-cell">
                                  {data.dateTime ?? "-"}
                                </TableCell>


                              </TableRow>
                            ))}
                          </TableBody>
                          <caption>

                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={count2}
                                rowsPerPage={rowsPerPage2}
                                page={page2}
                                slotProps={{
                                  select: {
                                    inputProps: {
                                      'aria-label': 'rows per page',
                                    },
                                    native: true,
                                  },
                                }}
                                onPageChange={handleChangePage2}
                                onRowsPerPageChange={handleChangeRowsPerPage2}
                              />
                            </TableRow>
                          </caption>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (<>
                    {addInfoValue !== "5" && (<div className="nodata_tag">
                      <img
                        src="/images/Tanfundlogo-black-1.png"
                        className="no_data_logo"
                        alt=""
                      />
                      <h4>No Records Found</h4>
                    </div>)}
                  </>)}
                </div>
              </DialogContentText>
            </DialogContent>

          </Dialog>
          <Dialog
            open={openModal4}
            onClose={handleCloseModal4}
            maxWidth={"md"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => setOpenModal4(false)}
                sx={{ position: "absolute", right: 8, top: 8, color: 'red' }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">

                <div >
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={remarks} onChange={handleChanges} aria-label="basic tabs example">
                        <Tab label="Admin Remarks" {...a11yProps(0)} />
                        <Tab label="Mentorship Remarks" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={remarks} index={0}>
                      {scstAdminAllRemarks.length ? (
                        <TableContainer component={Paper} className="table-container">
                          <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                  S.No
                                </TableCell>
                                <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                  Phase
                                </TableCell>
                                <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                  Submitted Time
                                </TableCell>
                                <TableCell align="left" className="table-header" sx={{ width: '350px', fontWeight: 600 }}>
                                  Remarks
                                </TableCell>


                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {scstAdminAllRemarks && scstAdminAllRemarks.map((data, i) => (
                                <TableRow className="table-row" key={i}>
                                  <TableCell component="th" scope="row" align="center">
                                    {/* {i + 1} */}
                                    {
                                      tableIndexValue(rowsPerPage, page, i)
                                    }
                                  </TableCell>
                                  <TableCell align="center" className="table-cell">
                                    {data.phase ?? "-"}
                                  </TableCell>
                                  <TableCell align="center" className="table-cell">
                                    {data.submittedTime ?? "-"}
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: '300px' }}>
                                    {data.remarks ? (
                                      <>
                                        {isFullTextVisible[i]
                                          ? data.remarks
                                          : truncateTexts(data.remarks || '', 60)}

                                        {data.remarks && data.remarks.length > 60 && (
                                          <h6
                                            onClick={() => toggleText(i)}
                                            style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                          >
                                            {isFullTextVisible[i] ? " Read Less" : " Read More"}
                                          </h6>
                                        )}
                                      </>) : ((`-`))}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <caption>
                              <TablePagination
                                component="div"
                                count={countRemarks}
                                page={pageRemarks}
                                onPageChange={handleChangePageRemarks}
                                rowsPerPage={rowsPerPageRemarks}
                                onRowsPerPageChange={
                                  handleChangeRowsPerPageRemarks
                                }
                                style={{ float: 'left' }}
                              />
                            </caption>
                          </Table>
                        </TableContainer>
                      ) : (<>
                        <>
                          {addInfoValue !== "5" && (<div className="nodata_tag">
                            <img
                              src="/images/Tanfundlogo-black-1.png"
                              className="no_data_logo"
                              alt=""
                            />
                            <h4>No Records Found</h4>
                          </div>)}</>
                      </>)}
                    </CustomTabPanel>
                    <CustomTabPanel value={remarks} index={1}>
                      {scstMentorAllRemarks.length ? (
                        <TableContainer component={Paper} className="table-container">
                          <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                  S.No
                                </TableCell>
                                <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                  Phase
                                </TableCell>
                                <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                  Submitted Time
                                </TableCell>
                                <TableCell align="left" className="table-header" sx={{ width: '200px', fontWeight: 600 }}>
                                  Remarks
                                </TableCell>
                                <TableCell align="left" className="table-header" sx={{ fontWeight: 600 }}>
                                  Attachment
                                </TableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {scstMentorAllRemarks && scstMentorAllRemarks.map((data, i) => (
                                <TableRow className="table-row" key={i}>
                                  <TableCell component="th" scope="row" align="center">
                                    {
                                      tableIndexValue(rowsPerPageMentor, page, i)
                                    }
                                  </TableCell>
                                  <TableCell align="center" className="table-cell">
                                    {data.phase ?? "-"}
                                  </TableCell>
                                  <TableCell align="center" className="table-cell">
                                    {data.submittedTime ?? "-"}
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: '200px' }}>
                                    {data.remarks ? (
                                      <>
                                        {isFullTextVisible[i]
                                          ? data.remarks
                                          : truncateTexts(data.remarks || '', 60)}

                                        {data.remarks && data.remarks.length > 60 && (
                                          <h6
                                            onClick={() => toggleText(i)}
                                            style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                          >
                                            {isFullTextVisible[i] ? " Read Less" : " Read More"}
                                          </h6>
                                        )}
                                      </>) : ((`-`))}
                                  </TableCell>
                                  <TableCell className="table-cell">

                                    <div style={{ marginLeft: '30px' }}>
                                      {data.documentname ? (
                                        <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickReportOpen(data.documentname)} />
                                      ) : (
                                        <>
                                          {"-"}
                                        </>
                                      )}
                                    </div>

                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <caption>
                              <TablePagination
                                component="div"
                                count={countMentor}
                                page={pageMentor}
                                onPageChange={handleChangePageMentor}
                                rowsPerPage={rowsPerPageMentor}
                                onRowsPerPageChange={
                                  handleChangeRowsPerPageMentor
                                }
                                style={{ float: 'left' }}
                              />
                            </caption>
                          </Table>
                        </TableContainer>
                      ) : (<>
                        <>
                          {addInfoValue !== "5" && (<div className="nodata_tag">
                            <img
                              src="/images/Tanfundlogo-black-1.png"
                              className="no_data_logo"
                              alt=""
                            />
                            <h4>No Records Found</h4>
                          </div>)}</>
                      </>)}
                    </CustomTabPanel>
                  </Box>
                </div>
              </DialogContentText>
            </DialogContent>

          </Dialog>
          {/* Approve-Btn */}
          <Dialog
            open={approveModal}
            onClose={handleApproveCloseModal}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <center>
                <img src="/images/img/attention.gif" alt="" className="alert-image" />
              </center>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4 className="text-center p-3">Are you sure ?</h4>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="d-flex justify-content-center mb-2">
              <Button
                className="remove-api-modal-cancel"
                onClick={handleApproveCloseModal}
              >
                Cancel
              </Button>
              <Button className="remove-api-modal-confirm" onClick={onStatusUpdate}>
                {btnName}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Allow to Edit */}
          <Dialog
            open={allowModal}
            onClose={handleAllowCloseModal}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <center>
                <img src="/images/img/attention.gif" alt="" className="alert-image" />
              </center>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4 className="text-center p-3">Are you sure ?</h4>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="d-flex justify-content-center mb-2">
              <Button
                className="remove-api-modal-cancel"
                onClick={handleAllowCloseModal}
              >
                Close
              </Button>
              <Button className="remove-api-modal-confirm" onClick={handleMarkEditable}>
                Allow to Edit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </section>
    </div>
  );
};

export default Scsthubavpdetails;
