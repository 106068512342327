import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import DataContext from "../../../../context/DataContext";
import Viewimage from "../../../../api/Viewimage";
import Scsthubavpremarks from "../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks";
import ClarificationAll from "../../scstBdd-details/ClarificationAll";

const PriorDocuments = ({
  idNumber,
  path,
  startupValue,
  viewStartupDetails,
  showStartupValue,
  startupCheckList,
}) => {
  const { setPageLoading, getBDDStatus } = useContext(DataContext);
  const [formData, setFormData] = useState();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const { fields: priorDocumentsFields } = useFieldArray({
    control,
    name: "priorDocuments",
  });
  useDidMountEffect(() => {
    getApi();
  }, []);
  const getApi = () => {
    setPageLoading(true);
    var req = {
      startupId: idNumber,
      category: "Prior Documents",
      round: "firstconnect",
    };
    apiService(`partner/startup/clarification/list`, req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          result.data.priorDocuments.forEach((element) => {
            if (element.status === 3) {
              element.status = 2;
            }
            if (element.fileUrl) {
              var val = element.fileUrl;
              var data = val.split("?");
              if (data) {
                var formet = data[0].split(".");
                element.formet = formet[4];
              }
            }
          });
          setFormData(result.data);
        }
      })
      .catch((err) => {});
  };
  const handelVerifiedStatus = (value, i) => {
    console.log(value);
    setValue(`priorDocuments.${i}.status`, Number(value));
    var getData = getValues(`priorDocuments.${i}`);
    if (value === "1") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        status: 1,
        round: "firstconnect",
      };
      apiService(`partner/startups/clarification/status/save`, req, "post")
        .then((result) => {
          if (result?.data?.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => {});
    }
    setFormData(getValues());
  };
  const handleRequestValue = (value, i) => {
    setValue(`priorDocuments.${i}.clarification`, value);
    clearErrors(`priorDocuments.${i}.clarification`);
  };
  const onSubmit = (data) => {};
  const handleRequestSubmit = (i) => {
    var getData = getValues(`priorDocuments.${i}`);
    if (getData.clarification !== "") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        clarification: getData.clarification,
        status: 2,
        round: "firstconnect",
      };
      apiService(`partner/startup/clarification/save`, req, "post")
        .then((result) => {
          if (result?.data?.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => {});
    } else {
      setError(`priorDocuments.${i}.clarification`, {
        message: "Request Clarification is required",
      });
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value, formet) => {
    setViewImage(value);
    debugger
    if (formet === "xlsx") {
      const link = document.createElement("a");
      link.href = value;
      link.download = "excelfile.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="table_main_div">
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
          {priorDocumentsFields && priorDocumentsFields.length ? (
            <>
              {priorDocumentsFields.map((data, index) => (
                <div className="mb-3">
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="mb-0">
                        {index + 1}. {data.attribute}
                      </p>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      {data.value && (
                        <div className="ans-div">{data.value}</div>
                      )}
                      {data.fileUrl && (
                        <div className="my-2">
                          Attached Document :{" "}
                          <span
                            className="view-doc"
                            onClick={() => handleClickOpen(data.fileUrl,data.formet)}
                          >
                           {data.formet === "xlsx" ? "Download" : "View & Download"}
                          </span>
                        </div>
                      )}
                      {data.fileUrl && data.formet &&
                            <>
                              {data.fileUrl && data.formet === 'xlsx' ?
                                (<>
                                  <span>(File Format : Excel)</span>
                                </>) : (<>
                                  <span>(File Format : PDF)</span></>)}
                            </>
                          }
                      {!data.value && !data.fileUrl && <b>Not Provided</b>}
                    </Grid>
                    {path === "scstbdd" && startupCheckList.status < 6 ? (
                      <>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <div>
                            <FormControl>
                              <Controller
                                name={`priorDocuments.${index}.status`}
                                control={control}
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Innovation"
                                    name="radio-buttons-group"
                                    {...field}
                                    onChange={(e) =>
                                      handelVerifiedStatus(
                                        e.target.value,
                                        index
                                      )
                                    }
                                  >
                                    <span className="d-flex">
                                      {/* {data.value && (
                                        <FormControlLabel
                                          disabled={
                                            getValues(
                                              `priorDocuments.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false
                                          }
                                          value={1}
                                          control={<Radio />}
                                          label="Verified"
                                        />
                                      )} */}
                                      { getValues(`priorDocuments.${index}.status`) === 1 && (
                                        <b>Clarified </b>
                                      )}
                                      {/* <FormControlLabel disabled={(getValues(`priorDocuments.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" /> */}
                                      { getValues(`priorDocuments.${index}.status`) !== 1 && (
                                      <FormControlLabel
                                        disabled={
                                          getValues(
                                            `priorDocuments.${index}.status`
                                          ) === 2 && data.clarification
                                            ? true
                                            : false
                                        }
                                        value={2}
                                        control={<Radio />}
                                        label="More clarification"
                                      />
                                    )}
                                    </span>
                                  </RadioGroup>
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.priorDocuments?.[index]?.status &&
                                  errors.priorDocuments?.[index]?.status
                                    .message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          {getValues(`priorDocuments.${index}.status`) ===
                            2 && (
                            <div className="form-group">
                              <Controller
                                name={`priorDocuments.${index}.clarification`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Request Clarification is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Request Clarification"
                                    placeholder="Enter Message"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    onInput={(e) =>
                                      handleRequestValue(e.target.value, index)
                                    }
                                    inputProps={{
                                      readOnly:
                                        getValues(
                                          `priorDocuments.${index}.status`
                                        ) === 2 && data.clarification
                                          ? true
                                          : false,
                                    }}
                                    {...field}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Button
                                            disabled={
                                              getValues(
                                                `priorDocuments.${index}.status`
                                              ) === 2 && data.clarification
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              handleRequestSubmit(index)
                                            }
                                          >
                                            <SendIcon />
                                          </Button>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.priorDocuments?.[index]
                                  ?.clarification &&
                                  errors.priorDocuments?.[index]?.clarification
                                    .message}
                              </FormHelperText>
                            </div>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <p className="mb-1">
                            Status : <b>{getBDDStatus(data.status)}</b>
                          </p>
                          {data.clarification && (
                            <p className="mb-1">
                              Clarification :{" "}
                              {data.clarificationAll ? (
                                <ClarificationAll
                                  remarks={data.clarificationAll}
                                  lblName="Clarifications"
                                  btnName="View"
                                />
                              ) : (
                                <Scsthubavpremarks
                                  remarks={data.clarification}
                                  lblName="View"
                                />
                              )}{" "}
                            </p>
                          )}
                          {data.response && (
                            <p className="mb-1">
                              Startup Response :{" "}
                              <Scsthubavpremarks
                                remarks={data.response}
                                lblName="View"
                              />
                            </p>
                          )}
                          {data.documentName && (
                            <p className="mb-1">
                              Startup Attached Document :{" "}
                              <Button
                                onClick={() =>
                                  handleClickOpen(data.documentName,data.formet)
                                }
                                className="text-cap"
                              >
                                View
                              </Button>
                            </p>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider />
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="nodata_tag">
                <img
                  src="/images/Tanfundlogo-black-1.png"
                  className="no_data_logo"
                  alt=""
                />
                <h4>Prior documents details is empty</h4>
              </div>
            </>
          )}
        </form>
      </div>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </div>
  );
};

export default PriorDocuments;
