import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import Viewimage from "../../../../api/Viewimage";
// import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
// import PreliminaryTextPart1 from "./PreliminaryTextPart1";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
// const fileTypes = ["pdf","XLSX","zip","doc", "docx"];
const fileTypes = ["pdf"];
const fileTypesPDF = ["pdf"];
// const fileTypesPDF = ["pdf"];
// const fileTypesExcel = ["XLSX"];
// const fileTypesPDFandExcel = ["pdf", "XLSX"];
const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];
// const fileTypesExcel = ["pdf", "XLSX"];
const PreliminaryDocumentPart1 = () => {
  const [formData, setFormData] = useState({});
  const propertyList = [
    "Copyright/Trademark",
    "Domain Name",
    "Any Other IP",
    "Whether all software used by the Company is licensed",
  ];

  const nameList = [
    "Website Privacy Policy and consent request",
    "Website Terms of Use",
    "POSH Policy employees",
    "Maternity Benefit Policy"
  ];
  const actList = [
    "PF",
    "ESI",
    "Shops & Establishments Act",
    "Professional Tax",
    "Contract Labour Regulation Act",
    "Labour Welfare Fund",
    "Trade License",
    "MSME",
    "Start Up India",
    "Any other business-related licenses",
    "Any other applicable licenses"
  ];
  const defaultValues = {
    intellectualProperty: propertyList.map((property, index) => ({
      index, // Save the index explicitly
      property,
      details: "",
      status: "",
      ownedBy: "",
      fileDoc: "",
      fileDocName: ""
    })),
    startupLabourLaw: actList.map((act, index) => ({
      index,
      act,
      obtained: "",
      validity: "",
      status: "",

    })),
    startupPolicy: nameList.map((name, index) => ({
      index, // Save the index explicitly
      name,
      status: "",
    })),
  };

  const { handleBackapp, setPageLoading, validateNumberonly, navigator, validateDecimalNumberonly } =
    useContext(DataContext);
  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    values: formData,
    defaultValues,
  });

  const checkAlpha = (e) => {
    if (!/[A-Za-z\s.]/.test(e.key)) {
      e.preventDefault();
    }
  };


  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi();
    getCityApi();
    getStateApi();
  }, []);
  const [fileUploadId, setFileUploadId] = useState(0);
  const [nonEditAble, SetNonEditAble] = useState(false);
  const [patentsShow, SetPatentsShow] = useState(false);
  const getApi = async () => {
    setPageLoading(true);
    await apiService(`startup/ldd/sectionone/get`, "", "get").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        var data = res.data;
        SetNonEditAble(true);
        // if (data.editForm === false) {
        //     setReadyOnlyValue(true)
        // }
        if (data.editForm === false && data.superEdit === false) {
          setReadyOnlyValue(true);
        }
        if (data.editForm === false && data.superEdit === true) {
          setReadyOnlyValue(false);
        }
        if (data.editForm === true && data.superEdit === false) {
          setReadyOnlyValue(false);
        }
        if (res.data.startupLabourLaw) {
          res.data.startupLabourLaw.forEach((element) => {
            if (element.validity) {
              element.validity = dayjs(element.validity, "YYYY-MM-DD");
            }
          });
        }
        // if (data.patents) {
        //   SetPatentsShow(true);
        // }
        if (data.id) {
          setFileUploadId(res.data.id);
        }
        if (data.nonResidentShareholder) {
          setResidentShareholder(data.nonResidentShareholder);
        }
        if (data.technologyUsageFees) {
          setTechnology(data.technologyUsageFees);
        }
        if (data.companyAuditReport) {
          setAuditReport(data.companyAuditReport)
        }
        // if(data.obtained){
        //   setAuditReport(data.obtained)
        // }

        // if (res.data.leaseRentalAgreements) {
        //   res.data.leaseRentalAgreements.forEach((element) => {
        //     if (element.fileName) {
        //       element.fileURL = element.fileName
        //     }
        //   });
        // }

        if (res.data.domain !== undefined && res.data.domain !== null) {
          res.data.domain = res.data.domain.toString();
        }

        // if (res.data.businessSpecificLicenses) {
        //   res.data.businessSpecificLicenses.forEach((element) => {
        //     if (element.obtained) {
        //       element.obtained = element.obtained.toString()
        //     } else{
        //       element.obtained = element.obtained.toString()
        //     }
        //   });
        // }
        if (res.data.businessSpecificLicenses) {
          res.data.businessSpecificLicenses.forEach((element) => {
            if (element.obtained !== undefined && element.obtained !== null) {
              // Ensure it is treated as a string
              element.obtained = (element.obtained === true || element.obtained === false)
                ? element.obtained.toString()
                : element.obtained;
            }
          });
        }
        // if (res.data.startupLabourLaw) {
        //   res.data.startupLabourLaw.forEach((element) => {
        //     if (element.obtained) {
        //       element.obtained = element.obtained.toString()
        //     } else{
        //       element.obtained = element.obtained.toString()
        //     }
        //   });
        // }
        if (res.data.startupLabourLaw) {
          res.data.startupLabourLaw.forEach((element) => {
            if (element.obtained !== undefined && element.obtained !== null) {
              // Ensure it is treated as a string
              element.obtained = (element.obtained === true || element.obtained === false)
                ? element.obtained.toString()
                : element.obtained;
            }
          });
        }
        if (res.data.startupOrganizationChart) {
          res.data.startupOrganizationChart.forEach((element) => {
            if (element.coveredUnderPF !== undefined && element.coveredUnderPF !== null) {
              // Ensure it is treated as a string
              element.coveredUnderPF = (element.coveredUnderPF === true || element.coveredUnderPF === false)
                ? element.coveredUnderPF.toString()
                : element.coveredUnderPF;
            }
          });
        }
        if (res.data.immovableProperty) {
          res.data.immovableProperty.forEach((element) => {
            if (element.stampingAndRegistration !== undefined && element.stampingAndRegistration !== null) {
              // Ensure it is treated as a string
              element.stampingAndRegistration = (element.stampingAndRegistration === true || element.stampingAndRegistration === false)
                ? element.stampingAndRegistration.toString()
                : element.stampingAndRegistration;
            }
          });
        }
        // if (res.data.startupOrganizationChart) {
        //   res.data.startupOrganizationChart.forEach((element) => {
        //     if (element.coveredUnderPF) {
        //       element.coveredUnderPF = element.coveredUnderPF.toString()
        //     }else{
        //       element.coveredUnderPF = element.coveredUnderPF.toString()
        //     }
        //   });
        // }
        if (res.data.shareHoldingPatterns) {
          res.data.shareHoldingPatterns.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.businessSpecificLicenses) {
          res.data.businessSpecificLicenses.forEach((element) => {
            if (element.validityPeriod) {
              element.validityPeriod = dayjs(element.validityPeriod, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.companyInsurancePolicies) {
          res.data.companyInsurancePolicies.forEach((element) => {
            if (element.validity) {
              element.validity = dayjs(element.validity, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.materialAgreements) {
          res.data.materialAgreements.forEach((element) => {
            if (element.validityPeriod) {
              element.validityPeriod = dayjs(element.validityPeriod, "YYYY-MM-DD");
            }
          });
        }


        setFormData(res.data);
        // if (res.data.businessSpecificLicenses && res.data.businessSpecificLicenses.length > 0) {
        //   debugger
        //   res.data.businessSpecificLicenses.forEach((element,index) => {
        //     if (element.obtained === false || element.obtained === "false") {
        //       debugger
        //       setValue(`businessSpecificLicenses.${index}.obtained`, false); 
        //     }
        //   });
        // }
        console.log(getValues());

      }
    });
  };
  const handleDetailsCertificate = (e) => {
    handleFileUpload(
      e,
      "nonResidentShareholderDoc",
      "nonResidentShareholderDocName",
      "nonResidentShareholderDocURL",
      "file"
    );
  };
  const handleauditReportDetails = (e) => {
    handleFileUpload(
      e,
      "companyAuditReportDoc",
      "companyAuditReportDocName",
      "companyAuditReportDocURL",
      "file"
    );
  };
  const handleMoaDocName = (e) => {
    handleFileUpload(e, "moaDoc", "moaDocName", "moaDocURL", "file");
  };
  const handleAoaDoc = (e) => {
    handleFileUpload(e, "aoaDoc", "aoaDocName", "aoaDocURL", "file");
  };
  const handleTechnologyUsageFeesDoc = (e) => {
    handleFileUpload(
      e,
      "technologyUsageFeesDoc",
      "technologyUsageFeesDocName",
      "technologyUsageFeesDocURL",
      "file"
    );
  };
  const handleEmployeeStockOptionPlanDoc = (e) => {
    handleFileUpload(
      e,
      "employeeStockOptionPlanDoc",
      "employeeStockOptionPlanDocName",
      "employeeStockOptionPlanDocURL",
      "file"
    );
  };
  const handleShareholderAgreementsDoc = (e) => {
    handleFileUpload(
      e,
      "shareholderAgreementsDoc",
      "shareholderAgreementsDocName",
      "shareholderAgreementsDocURL",
      "file"
    );
  };
  const handleEmployeeAgreementDoc = (e) => {
    handleFileUpload(
      e,
      "employmentAgreeDoc",
      "employmentAgreeDocName",
      "employmentAgreeDocURL",
      "file"
    );
  };
  const handleFinancialStatementDoc = (e) => {
    handleFileUpload(
      e,
      "financialStatementDoc",
      "financialStatementDocName",
      "financialStatementDocURL",
      "file"
    );
  };
  const handleInsurancePolicyDoc = (e) => {
    handleFileUpload(
      e,
      "insurancePolicyDoc",
      "insurancePolicyDocName",
      "insurancePolicyDocURL",
      "file"
    );
  };
  const handleEmployeeAggrementDoc = (e) => {
    handleFileUpload(
      e,
      "employmentAgreeForEmployDoc",
      "employmentAgreeForEmployDocName",
      "employmentAgreeForEmployDocURL",
      "file"
    );
  };
  const handleinsuranceDocUpload = (e) => {
    handleFileUpload(
      e,
      "insuranceDoc",
      "insuranceDocName",
      "insuranceDocURL",
      "file"
    );
  }

  const handleFileUpload = (event, filekey, fileName, fileURL, format, val) => {
    debugger
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];

        if (
          (format === "file" && img_crt_type === "pdf") ||
          img_crt_type === "vnd.ms-excel" ||
          img_crt_type ===
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          img_crt_type ===
          "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setValue(filekey, "");
          setValue(fileName, "");

          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setValue(fileURL, logourl1);
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            if (val !== 'nosave') {
              var req = {
                [filekey]: ImageValue,
                [fileName]: img_name,
                id: fileUploadId,
              };
              console.log(req);

              saveData(req);
            }
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    }
  };
  const saveData = (req) => {
    debugger
    setPageLoading(true);
    apiService("startup/ldd/sectionone/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          getApi();
        }
      }
    });
  };
  const handleBlur = () => {
    const req = {
      companySizeType: getValues('companySizeType'),
      id: fileUploadId,
    };
    saveData(req);
  };

  //   const [open, setOpen] = useState(false);
  //   const [viewImage, setViewImage] = useState("");
  //   const handleClickOpen = (value) => {
  //     if (value) {
  //       setViewImage(value);
  //       setOpen(true);
  //     }
  //   };
  // const handleClickOpens = (url) => {
  //     if (url) {
  //
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.download = 'filename.xlsx';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //     }

  // };
  // const handleClickOpens = (url) => {
  //   var filSplit  = url.split(":");
  //   debugger
  //   if(filSplit[0] === "data"){
  //         const pdfUrl = `data:application/pdf;base64,${url}`;

  //         const link = document.createElement("a");
  //         link.href = pdfUrl;
  //         link.download = "Pitchdeck.pdf"; 
  //         document.body.appendChild(link);

  //         link.click();

  //         document.body.removeChild(link);
  //         debugger
  //   }
  //   else{
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.target = "_blank";
  //     link.rel = "noopener noreferrer";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }



  // };
  const handleClickOpens = (url) => {
    debugger
    if (url !== undefined) {
      var filSplit = url.split(":");

      if (filSplit[0] === "data") {
        try {
          // Check the type of file from the base64 URL
          if (url.includes("application/pdf")) {
            // PDF file
            const pdfUrl = url;
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = "Document.pdf"; // Default name for PDF download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else if (url.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || url.includes("application/vnd.ms-excel")) {
            // Excel file (.xlsx or .xls)
            const excelUrl = url;
            const link = document.createElement("a");
            link.href = excelUrl;
            link.download = "Spreadsheet.xlsx"; // Default name for Excel download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else if (url.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || url.includes("application/msword")) {
            // Word document (.docx or .doc)
            const docUrl = url;
            const link = document.createElement("a");
            link.href = docUrl;
            link.download = "Document.docx"; // Default name for Word download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            alert("Unsupported file type.");
          }
        } catch (error) {
          console.error("Error while downloading the file:", error);
          alert("An error occurred while trying to download the file. Please check your internet connection and try again.");
        }
      } else {
        try {
          // For non-base64 URLs, open the link in a new tab
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error while opening the link:", error);
          alert("An error occurred while trying to open the link. Please check your internet connection and try again.");
        }
      }
    }
  };



  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  //   const onPdfClick = () => {
  //     const pdfUrl =
  //       "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Business_Plan_Template.xlsx";
  //     const link = document.createElement("a");
  //     link.href = pdfUrl;
  //     link.download = "Business_Plan_Template.xlsx";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };
  // const onSubmit = (data) => {
  //
  //     setPageLoading(true);
  //     if (data.shareHoldingPatterns) {
  //         data.shareHoldingPatterns.forEach(element => {
  //             if (element.date) {
  //                 element.date = element.date.format('YYYY-MM-DD');
  //             }
  //         });
  //     }
  //     var req = {
  //         id: fileUploadId,
  //         directorsInterestedCompanies: data.directorsInterestedCompanies,
  //         shareHoldingPatterns: data.shareHoldingPatterns,
  //         sharesSubjectedToPledge: data.sharesSubjectedToPledge
  //     }
  //     console.log(req);
  //     apiService('startup/ldd/sectionone/save', req, 'post').then((res) => {
  //         setPageLoading(false);
  //         if (res && res.data) {
  //             if (res.data.responseStatus === "Success") {
  //                 notifyService('success', 'Success', 'Saved successfully !!')
  //                 handleBackapp()
  //             }
  //         }
  //     })
  // }
  const handleDateFormat = (newValue, index) => {
    setValue(`businessSpecificLicenses.${index}.validityPeriod`, newValue);
    clearErrors(`businessSpecificLicenses.${index}.validityPeriod`);
  };
  const handleDateFormat1 = (newValue, index) => {
    setValue(`companyInsurancePolicies.${index}.validity`, newValue);
    clearErrors(`companyInsurancePolicies.${index}.validity`);
  };
  const handleDateFormat2 = (newValue, index) => {
    setValue(`materialAgreements.${index}.validityPeriod`, newValue);
    clearErrors(`materialAgreements.${index}.validityPeriod`);
  };
  const handleDateFormat3 = (newValue, index) => {
    setValue(`startupLabourLaw.${index}.validity`, newValue);
    clearErrors(`startupLabourLaw.${index}.validity`);
  };
  const onSubmit = (data) => {

    setPageLoading(true);
    if (data.companyInsurancePolicies) {
      data.companyInsurancePolicies.forEach((element) => {
        if (element.validity) {
          element.validity = element.validity.format("YYYY-MM-DD");
        }
      });
    }
    if (data.businessSpecificLicenses) {
      data.businessSpecificLicenses.forEach((element) => {
        if (element.validityPeriod) {
          element.validityPeriod = element.validityPeriod.format("YYYY-MM-DD");
        }
      });
    }
    if (data.materialAgreements) {
      data.materialAgreements.forEach((element) => {
        if (element.validityPeriod) {
          element.validityPeriod = element.validityPeriod.format("YYYY-MM-DD");
        }
      });
    }
    if (data.startupLabourLaw) {
      data.startupLabourLaw.forEach((element) => {
        if (element.validity) {
          element.validity = element.validity.format("YYYY-MM-DD");
        }
      });
    }
    debugger;
    apiService("startup/ldd/sectionone/save", data, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          // handleBackapp()
          navigator("/startup/ldd-document1/scst");
        }
      }
    });
  };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  const [residentShareholder, setResidentShareholder] = useState();
  const Shareholders = (val) => {
    setResidentShareholder(val);
    debugger
    var req = {
      nonResidentShareholder: val,
      id: fileUploadId,
    };
    saveData(req);
  };
  const [auditReport, setAuditReport] = useState();
  const handleAuditReport = (val) => {
    setAuditReport(val);
    debugger
    var req = {
      companyAuditReport: val,
      id: fileUploadId,
    };
    saveData(req);
  };
  const [domainShow, setDomainShow] = useState(false);
  const handleDomainshow = (val) => {
    setDomainShow(val);
    debugger
    if (val === false || val === "false") {
      setValue("domainDoc", "");
      setValue("domainName", "");
      setValue("domainDocURL", undefined);
      setValue("domainStatus", "");
      setValue("domainOwnedBy", "");
    }
  };
  const [obtainedReportShow, setObtainedReportShow] = useState()
  const handleObtainedReport = (val, index) => {
    console.log(val);

    setObtainedReportShow(val)
    setValue(`businessSpecificLicenses.${index}.obtained`, val)
    debugger
    clearErrors(`businessSpecificLicenses.${index}.obtained`);
    if (val === "false" || val === false) {
      clearErrors(`businessSpecificLicenses.${index}.validityPeriod`);
      clearErrors(`businessSpecificLicenses.${index}.statusOfCompliance`);
      setValue(`businessSpecificLicenses.${index}.validityPeriod`, "");
      setValue(`businessSpecificLicenses.${index}.statusOfCompliance`, "");
      setValue(`businessSpecificLicenses.${index}.obtained`, "false")
    }

  }
  const handleLabourLawObtained = (val, index) => {
    console.log(val);
    setValue(`startupLabourLaw.${index}.obtained`, val)
    debugger
    clearErrors(`startupLabourLaw.${index}.obtained`);
    if (val === "false" || val === false) {
      clearErrors(`startupLabourLaw.${index}.validity`);
      clearErrors(`startupLabourLaw.${index}.status`);
      setValue(`startupLabourLaw.${index}.validity`, "");
      setValue(`startupLabourLaw.${index}.status`, "");
      setValue(`startupLabourLaw.${index}.obtained`, "false")
    }

  }
  const [technology, setTechnology] = useState();
  const Technology = (val) => {
    setTechnology(val);
    var req = {
      technologyUsageFees: val,
      id: fileUploadId,
    };
    saveData(req);
  };
  //   const [compliances, setCompliances] = useState();
  const FEMA = (val) => {
    // setCompliances(val);
    var req = {
      femaCompliance: val,
      id: fileUploadId,
    };
    saveData(req);
  };

  //   const [licensedSoftware, setLicensedSoftware] = useState();
  const LicensedCheck = (val) => {
    // setLicensedSoftware(val);
    var req = {
      companyLicensesNecessary: val,
      id: fileUploadId,
    };
    saveData(req);
  };

  const empRestricCheck = (val) => {
    var req = {
      employmentRestrictions: val,
      id: fileUploadId,
    };
    saveData(req);
  }
  const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
  const [isAccordionOpen2, setIsAccordionOpen2] = useState(true);
  const [isAccordionOpen3, setIsAccordionOpen3] = useState(true);
  const [isAccordionOpen4, setIsAccordionOpen4] = useState(true);
  const [isAccordionOpen5, setIsAccordionOpen5] = useState(true);
  const [isAccordionOpen6, setIsAccordionOpen6] = useState(true);
  const [isAccordionOpen7, setIsAccordionOpen7] = useState(true);
  const [isAccordionOpen8, setIsAccordionOpen8] = useState(true);
  const [isAccordionOpen9, setIsAccordionOpen9] = useState(true);
  const [isAccordionOpen10, setIsAccordionOpen10] = useState(true);
  const [isAccordionOpen11, setIsAccordionOpen11] = useState(true);
  const [isAccordionOpen12, setIsAccordionOpen12] = useState(true);
  const [isAccordionOpen13, setIsAccordionOpen13] = useState(true);
  const {
    fields: directorsFields,
    append: directorsAppend,
    remove: directorsRemove,
  } = useFieldArray({
    control,
    name: "branches",
  });
  const adddirectorsFieldsBtn = () => {
    directorsAppend({
      id: 0,
      address: "",
      state: "",
      district: "",
      pincode: "",
    });
  };
  const deleteDirectorsInterested = async (index) => {
    const id = getValues(`branches.${index}.id`);

    if (id !== 0 && id !== undefined) {
      apiService(`startup/ldd/branches/remove?id=${id}`, "", "delete").then(
        (res) => {
          if (res) {
            if (res.data.responseStatus === "Success") {
              indexBaseddeleteDirectorsInterested(index);
            }
          }
        }
      );
    } else {
      indexBaseddeleteDirectorsInterested(index);
    }
  };
  const indexBaseddeleteDirectorsInterested = (index) => {
    var startupTeams = getValues("branches");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.branches = [
        {
          id: 0,
          address: "",
          state: "",
          district: "",
          pincode: "",
        },
      ];
      setFormData(val);
    } else {
      directorsRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };
  const {
    fields: companySecretaryFields,
    append: companySecretaryAppend,
    remove: companySecretaryRemove,
  } = useFieldArray({
    control,
    name: "materialAgreements",
  });

  const addCompanySecretaryBtn = () => {
    companySecretaryAppend({
      id: 0,
      partyName: "",
      contractType: "",
      valuePerAnnum: "",
      validityPeriod: "",
    });
  };

  const deleteCompanySecretaryBtn = async (index) => {
    const id = getValues(`materialAgreements.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/materialAgreements/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteCompanySecretary(index);
          }
        }
      });
    } else {
      indexBaseddeleteCompanySecretary(index);
    }
  };

  const indexBaseddeleteCompanySecretary = (index) => {
    var startupTeams = getValues("materialAgreements");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.materialAgreements = [
        {
          id: 0,
          partyName: "",
          contractType: "",
          valuePerAnnum: "",
          validityPeriod: "",
        },
      ];
      setFormData(val);
    } else {
      companySecretaryRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };
  const {
    fields: intellectualPropertyFields,
    // append: intellectualPropertyAppend,
    // remove: intellectualPropertyRemove,
  } = useFieldArray({
    control,
    name: "intellectualProperty",
  });
  //   const addIntellectualBtn = () => {
  //     intellectualPropertyAppend({
  //       id: 0,
  //       property: "",
  //       details: "",
  //       status: "",
  //     });
  //   };
  //   const [uploadedPatents, setUploadedPatents] = useState({});

  //   const deleteIntellectualBtn = async (index) => {
  //     const id = getValues(`intellectualProperty.${index}.id`);
  //     if (id !== 0 && id !== undefined) {
  //       apiService(
  //         `startup/ldd/intellectualProperty/remove?id=${id}`,
  //         "",
  //         "delete"
  //       ).then((res) => {
  //         if (res) {
  //           if (res.data.responseStatus === "Success") {
  //             indexBaseddeleteIntellectual(index);
  //           }
  //         }
  //       });
  //     } else {
  //       indexBaseddeleteIntellectual(index);
  //     }
  //   };
  //   const indexBaseddeleteIntellectual = (index) => {
  //     var startupTeams = getValues("intellectualProperty");
  //     if (startupTeams.length === 0 && index === 0) {
  //       var val = getValues();
  //       val.intellectualProperty = [
  //         {
  //           id: 0,
  //           property: "",
  //           details: "",
  //           status: "",
  //         },
  //       ];
  //       setFormData(val);
  //     } else {
  //       intellectualPropertyRemove(index);
  //       var val = getValues();
  //       setFormData(val);
  //     }
  //   };
  const {
    fields: immovablePropertiesFields,
    append: immovablePropertiesAppend,
    remove: immovablePropertiesRemove,
  } = useFieldArray({
    control,
    name: "immovableProperty",
  });

  const addImmovableBtn = () => {
    immovablePropertiesAppend({
      id: 0,
      address: "",
      lessor: "",
      lease: "",
      leaseTerm: "",
      stampingAndRegistration: ""
    });
  };
  const deleteImmovableBtn = async (index) => {
    const id = getValues(`immovableProperty.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/immovableProperty/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteImmovable(index);
          }
        }
      });
    } else {
      indexBaseddeleteImmovable(index);
    }
  };
  const indexBaseddeleteImmovable = (index) => {
    var startupTeams = getValues("immovableProperty");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.immovableProperty = [
        {
          id: 0,
          address: "",
          lessor: "",
          lease: "",
          leaseTerm: "",
        },
      ];
      setFormData(val);
    } else {
      immovablePropertiesRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };


  // companyInsurancePolicies Accordion

  const {
    fields: companyInsurancePoliciesFields,
    append: companyInsurancePoliciesAppend,
    remove: companyInsurancePoliciesRemove,
  } = useFieldArray({
    control,
    name: "companyInsurancePolicies",
  });

  const addcompanyInsurancePoliciesBtn = () => {
    companyInsurancePoliciesAppend({
      id: 0,
      name: "",
      type: "",
      covered: "",
      insuranceDoc: "",
      insuranceDocName: ""
    });
  };
  const deletecompanyInsurancePoliciesBtn = async (index) => {
    const id = getValues(`companyInsurancePolicies.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/companyinsurancepolicy?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletecompanyInsurancePolicies(index);
          }
        }
      });
    } else {
      indexBaseddeletecompanyInsurancePolicies(index);
    }
  };
  const indexBaseddeletecompanyInsurancePolicies = (index) => {
    var startupTeams = getValues("companyInsurancePolicies");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.companyInsurancePolicies = [
        {
          id: 0,
          name: "",
          type: "",
          covered: "",
          insuranceDoc: "",
          insuranceDocName: ""
        },
      ];
      setFormData(val);
    } else {
      companyInsurancePoliciesRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };

  // Business specific licenses Accordion

  const {
    fields: businessSpecificLicensesFields,
    append: businessSpecificLicensesAppend,
    remove: businessSpecificLicensesRemove,
  } = useFieldArray({
    control,
    name: "businessSpecificLicenses",
  });

  const addbusinessSpecificLicensesBtn = () => {
    businessSpecificLicensesAppend({
      id: 0,
      name: "",
      obtained: "",
      validityPeriod: "",
      statusOfCompliance: ""
    });
  };

  const deletebusinessSpecificLicensesBtn = async (index) => {
    const id = getValues(`businessSpecificLicenses.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/businessspeclicense?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletebusinessSpecificLicenses(index);
          }
        }
      });
    } else {
      indexBaseddeletebusinessSpecificLicenses(index);
    }
  };
  const indexBaseddeletebusinessSpecificLicenses = (index) => {
    var startupTeams = getValues("businessSpecificLicenses");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.companyInsurancePolicies = [
        {
          id: 0,
          name: "",
          obtained: "",
          validityPeriod: "",
          statusOfCompliance: ""
        },
      ];
      setFormData(val);
    } else {
      businessSpecificLicensesRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };

  const {
    fields: complaintsAndLitigationFields,
    append: complaintsAndLitigationAppend,
    remove: complaintsAndLitigationRemove,
  } = useFieldArray({
    control,
    name: "complaintsAndLitigation",
  });
  const addcomplaintsAndLitigation = () => {
    complaintsAndLitigationAppend({
      id: 0,
      litigationNature: "",
      party: "",
      indicativeAmount: "",
    });
  };
  const deletecomplaintsAndLitigationBtn = async (index) => {
    const id = getValues(`complaintsAndLitigation.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/complaintsAndLitigation/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteComplaintsAndLitigation(index);
          }
        }
      });
    } else {
      indexBaseddeleteComplaintsAndLitigation(index);
    }
  };
  const indexBaseddeleteComplaintsAndLitigation = (index) => {
    var startupTeams = getValues("complaintsAndLitigation");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.complaintsAndLitigation = [
        {
          id: 0,
          litigationNature: "",
          party: "",
          indicativeAmount: "",
        },
      ];
      setFormData(val);
    } else {
      complaintsAndLitigationRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };
  const {
    fields: startupTeamsFields,
    append: startupTeamsAppend,
    remove: startupTeamsRemove,
  } = useFieldArray({
    control,
    name: "startupOrganizationChart",
  });
  const addStartupTeamDetails = () => {
    startupTeamsAppend({
      id: 0,
      type: "",
      totalEmployees: "",
      coveredUnderPF: "",
    });
  };
  const deleteStartupTeamDetailsBtn = async (index) => {
    const id = getValues(`startupOrganizationChart.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/organization/chart/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteStartupTeamDetails(index);
          }
        }
      });
    } else {
      indexBaseddeleteStartupTeamDetails(index);
    }
  };
  const indexBaseddeleteStartupTeamDetails = (index) => {
    var startupTeams = getValues("startupOrganizationChart");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.startupTeams = [
        {
          id: 0,
          type: "",
          totalEmployees: "",
          coveredUnderPF: "",
        },
      ];
      setFormData(val);
    } else {
      startupTeamsRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };



  // lease aggrement accordion
  const {
    fields: leaseRentalAgreementsFields,
    append: leaseRentalAgreementsAppend,
    remove: leaseRentalAgreementsRemove,
  } = useFieldArray({
    control,
    name: "leaseRentalAgreements",
  });

  const addleaserentalDetails = () => {
    leaseRentalAgreementsAppend({
      id: 0,
      address: "",
      file: "",
      fileName: ""
    });
  };
  const deleteleaseAggrementBtn = async (index) => {
    const id = getValues(`leaseRentalAgreements.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/leaserentalagreement?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteleaseAggrementDetails(index);
          }
        }
      });
    } else {
      indexBaseddeleteleaseAggrementDetails(index);
    }
  };
  const indexBaseddeleteleaseAggrementDetails = (index) => {
    var startupTeams = getValues("leaseRentalAgreements");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.leaseRentalAgreements = [
        {
          id: 0,
          address: "",
          file: "",
          fileName: ""
        },
      ];
      setFormData(val);
    } else {
      leaseRentalAgreementsRemove(index);
      var value = getValues();
      setFormData(value);
    }
  };



  const {
    fields: startupLabourLawFields,
    // append: startupLabourLawAppend,
    // remove: startupLabourLawRemove,
  } = useFieldArray({
    control,
    name: "startupLabourLaw",
  });
  //   const addStartupLabourLaw = () => {
  //     startupLabourLawAppend({
  //       id: 0,
  //       act: "",
  //       obtained: "",
  //       validity: "",
  //       status: "",
  //     });
  //   };
  //   const deleteStartupLabourLawBtn = async (index) => {
  //     const id = getValues(`startupLabourLaw.${index}.id`);
  //     if (id !== 0 && id !== undefined) {
  //       apiService(
  //         `startup/ldd/startupLabourLaw/remove?id=${id}`,
  //         "",
  //         "delete"
  //       ).then((res) => {
  //         if (res) {
  //           if (res.data.responseStatus === "Success") {
  //             indexBaseddeleteStartupLabourLaw(index);
  //           }
  //         }
  //       });
  //     } else {
  //       indexBaseddeleteStartupLabourLaw(index);
  //     }
  //   };
  //   const indexBaseddeleteStartupLabourLaw = (index) => {
  //     var startupTeams = getValues("startupLabourLaw");
  //     if (startupTeams.length === 0 && index === 0) {
  //       var val = getValues();
  //       val.startupLabourLaw = [
  //         {
  //           id: 0,
  //           act: "",
  //           obtained: "",
  //           validity: "",
  //           status: "",
  //         },
  //       ];
  //       setFormData(val);
  //     } else {
  //       startupLabourLawRemove(index);
  //       var val = getValues();
  //       setFormData(val);
  //     }
  //   };
  const {
    fields: startupPolicyFields,
    // append: startupPolicyAppend,
    // remove: startupPolicyRemove,
  } = useFieldArray({
    control,
    name: "startupPolicy",
  });
  //   const addStartupPolicy = () => {
  //     startupPolicyAppend({
  //       id: 0,
  //       name: "",
  //       status: "",
  //     });
  //   };

  //   const deleteStartupPolicyBtn = async (index) => {
  //     const id = getValues(`startupPolicy.${index}.id`);
  //     if (id !== 0 && id !== undefined) {
  //       apiService(
  //         `startup/ldd/startupPolicy/remove?id=${id}`,
  //         "",
  //         "delete"
  //       ).then((res) => {
  //         if (res) {
  //           if (res.data.responseStatus === "Success") {
  //             indexBaseddeleteStartupPolicy(index);
  //           }
  //         }
  //       });
  //     } else {
  //       indexBaseddeleteStartupPolicy(index);
  //     }
  //   };
  //   const indexBaseddeleteStartupPolicy = (index) => {
  //     var startupTeams = getValues("startupPolicy");
  //     if (startupTeams.length === 0 && index === 0) {
  //       var val = getValues();
  //       val.startupPolicy = [
  //         {
  //           id: 0,
  //           name: "",
  //           status: "",
  //         },
  //       ];
  //       setFormData(val);
  //     } else {
  //       startupPolicyRemove(index);
  //       var val = getValues();
  //       setFormData(val);
  //     }
  //   };
  const {
    fields: patentsFields,
    // append: patentsAppend,
    // remove: patentsRemove,
  } = useFieldArray({
    control,
    name: "patents",
  });
  //   const addPatentsDetailsBtn = () => {
  //     patentsAppend({
  //       id: 0,
  //       name: "",
  //       stage: "",
  //       number: "",
  //       doc: "",
  //       docname: "",
  //     });
  //   };
  //   const deletePatentsDetails = async (index) => {
  //     const id = getValues(`patents.${index}.id`);
  //     if (id !== 0 && id !== undefined) {
  //       apiService(`startup/patent/remove?id=${id}`, "", "delete").then((res) => {
  //         if (res) {
  //           if (res.data.responseStatus === "Success") {
  //             indexBaseddeletepatentsRemove(index);
  //           }
  //         }
  //       });
  //     } else {
  //       indexBaseddeletepatentsRemove(index);
  //     }
  //   };
  //   const indexBaseddeletepatentsRemove = (index) => {
  //     var startupTeams = getValues("patents");
  //     if (startupTeams.length === 0 && index === 0) {
  //       var val = getValues();
  //       val.patents = [
  //         {
  //           id: 0,
  //           name: "",
  //           stage: "",
  //           number: "",
  //           doc: "",
  //           docname: "",
  //         },
  //       ];
  //       setFormData(val);
  //     } else {
  //       patentsRemove(index);
  //       var val = getValues();
  //       setFormData(val);
  //     }
  //   };
  //   const [selectedType, setSelectedType] = useState("");

  const handleSelectChange = (event) => {
    // const value = event.target.value;
    // setSelectedType(value);
  };
  const [stateList, setStateList] = useState([]);
  const getStateApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=state`, "", "get").then(
      (res) => {
        if (res && res.data && res.data.dropDownValues) {
          var val = res.data.dropDownValues;
          setStateList(val.districts);
        }
      }
    );
  };
  const [cityList, setCityList] = useState([]);
  const getCityApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=district`, "", "get").then(
      (res) => {
        if (res && res.data && res.data.dropDownValues) {
          var val = res.data.dropDownValues;
          setCityList(val.districts);
        }
      }
    );
  };
  const handleSelectStateType = (value, index) => {
    setValue(`branches.${index}.state`, value);
    clearErrors(`branches.${index}.state`);
    setValue(`branches.${index}.district`, "");
  };
  const handleSelectCity = (value, index) => {
    setValue(`branches.${index}.district`, value);
    clearErrors(`branches.${index}.district`);
  };
  //   const [valuePincode, setValuePincode] = useState("");
  const validatePincode = (value) => {
    if (value.length) {
      if (value.length !== 6) {
        return "Pincode must be 6 digits";
      }
      const matches = value.match(
        /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
      );
      if (matches === null) {
        return "Only numbers allowed";
      }
    }
  };
  const chartList = [
    "Full time Employees",
    "Contract employees (engaged through another entity)",
    "Consultants (where professional fees are paid)",
  ];
  const handleChartList = (value, index) => {
    setValue(`startupOrganizationChart.${index}.type`, value);
    clearErrors(`startupOrganizationChart.${index}.type`);
  };

  //   const handleActList = (value, index) => {
  //     setValue(`startupLabourLaw.${index}.act`, value);
  //     clearErrors(`startupLabourLaw.${index}.act`);
  //   };

  //   const handlepropertyList = (value, index) => {
  //     setValue(`intellectualProperty.${index}.property`, value);
  //     clearErrors(`intellectualProperty.${index}.property`);
  //   };

  //   const handleNameList = (value, index) => {
  //     setValue(`startupPolicy.${index}.name`, value);
  //     clearErrors(`startupPolicy.${index}.name`);
  //   };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src="/images/Fig-back.png" className="img-fluid" alt="" />
            </span>
            <h6 className="ml-2 mb-0">Information from the Company:</h6>
          </div>
          <hr />
          {/* <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className='form_div2'>
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Corporate Records :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>All statutory registers maintained by the Company *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="statutoryRegistersDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "statutoryRegistersDoc",
                                                            "statutoryRegistersDocName",
                                                            "statutoryRegistersDocURL",
                                                            'file')}
                                                        value={getValues('statutoryRegistersDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('statutoryRegistersDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('statutoryRegistersDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('statutoryRegistersDoc') !== '' ? 'Uploaded (Click to Change)' : 'Statutory Registers Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.statutoryRegistersDoc && errors.statutoryRegistersDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('statutoryRegistersDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Copies of all forms filed with RoC – both event based as well as periodical for the past 3 financial years (including current FY) *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="rocDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "rocDoc",
                                                            "rocDocName",
                                                            "rocDocURL",
                                                            'file')}
                                                        value={getValues('rocDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('rocDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('rocDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('rocDoc') !== '' ? 'Uploaded (Click to Change)' : 'RoC Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.rocDoc && errors.rocDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('rocDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Copies of minutes of Board & Shareholders meetings for the past 3 financial years (including current FY) *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="shareholderMinutesDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "shareholderMinutesDoc",
                                                            "shareholderMinutesDocName",
                                                            "shareholderMinutesDocURL",
                                                            'file')}
                                                        value={getValues('shareholderMinutesDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('shareholderMinutesDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('shareholderMinutesDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('shareholderMinutesDoc') !== '' ? 'Uploaded (Click to Change)' : 'Share Holder Minutes Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareholderMinutesDoc && errors.shareholderMinutesDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('shareholderMinutesDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Shareholders :</h6>
                                    </div>
                                </div>                                
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of any shareholder’s agreement or arrangements or understandings (whether legally binding or not) between the Promoters; or the Company and any shareholder of the Company *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="shareHolderAgreementDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "shareHolderAgreementDoc",
                                                            "shareHolderAgreementDocName",
                                                            "shareHolderAgreementDocURL",
                                                            'file')}
                                                        value={getValues('shareHolderAgreementDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('shareHolderAgreementDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('shareHolderAgreementDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('shareHolderAgreementDoc') !== '' ? 'Uploaded (Click to Change)' : 'Share Holder Agreement Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareHolderAgreementDoc && errors.shareHolderAgreementDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('shareHolderAgreementDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Copies of Share Certificates, Share Transfer Forms along with details of stamp duty paid on issue / transfer of shares. *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="shareCertfDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "shareCertfDoc",
                                                            "shareCertfDocName",
                                                            "shareCertfDocURL",
                                                            'file')}
                                                        value={getValues('shareCertfDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('shareCertfDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('shareCertfDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('shareCertfDoc') !== '' ? 'Uploaded (Click to Change)' : 'Share Certificates Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareCertfDoc && errors.shareCertfDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('shareCertfDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Directors :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of Remuneration drawn by the Directors, if any and date of Board/ Shareholders approval of the same *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="remunerationDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "remunerationDoc",
                                                            "remunerationDocName",
                                                            "remunerationDocURL",
                                                            'file')}
                                                        value={getValues('remunerationDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('remunerationDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('remunerationDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('remunerationDoc') !== '' ? 'Uploaded (Click to Change)' : 'Remuneration Document *'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.remunerationDoc && errors.remunerationDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('remunerationDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of all related party transactions, including payments made to Directors & related entities *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="relatedPartyTransDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "relatedPartyTransDoc",
                                                            "relatedPartyTransDocName",
                                                            "relatedPartyTransDocURL",
                                                            'file')}
                                                        value={getValues('relatedPartyTransDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('relatedPartyTransDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('relatedPartyTransDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('rocDoc') !== '' ? 'Uploaded (Click to Change)' : 'Related Party Trans Document *'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.relatedPartyTransDoc && errors.relatedPartyTransDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('relatedPartyTransDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Share Capital :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of authorised share capital *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="authorizedSharedCaptialDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "authorizedSharedCaptialDoc",
                                                            "authorizedSharedCaptialDocName",
                                                            "authorizedSharedCaptialDocURL",
                                                            'file')}
                                                        value={getValues('authorizedSharedCaptialDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('authorizedSharedCaptialDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('authorizedSharedCaptialDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('authorizedSharedCaptialDoc') !== '' ? 'Uploaded (Click to Change)' : 'Authorized Shared Captial Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.authorizedSharedCaptialDoc && errors.authorizedSharedCaptialDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('authorizedSharedCaptialDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of issued, subscribed and paid up share capital *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="issuredCapitalDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "issuredCapitalDoc",
                                                            "issuredCapitalDocName",
                                                            "issuredCapitalDocURL",
                                                            'file')}
                                                        value={getValues('issuredCapitalDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('issuredCapitalDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('issuredCapitalDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('issuredCapitalDoc') !== '' ? 'Uploaded (Click to Change)' : 'Issured Capital Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.issuredCapitalDoc && errors.issuredCapitalDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('issuredCapitalDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of Employee Stock Option Plan or Sweat Equity Shares issued or  proposed to be issued by the Company *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="employeeStockOptionDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "employeeStockOptionDoc",
                                                            "employeeStockOptionDocName",
                                                            "employeeStockOptionDocURL",
                                                            'file')}
                                                        value={getValues('employeeStockOptionDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('employeeStockOptionDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('employeeStockOptionDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('employeeStockOptionDoc') !== '' ? 'Uploaded (Click to Change)' : 'Employee Stock Option Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.employeeStockOptionDoc && errors.employeeStockOptionDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('employeeStockOptionDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of Foreign Direct Investments received / Overseas Investments made and their compliance with regard to Foreign Exchange Management Act / Reserve Bank of India regulations / notifications *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="foreignDirectInvestmentDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "foreignDirectInvestmentDoc",
                                                            "foreignDirectInvestmentDocName",
                                                            "foreignDirectInvestmentDocURL",
                                                            'file')}
                                                        value={getValues('foreignDirectInvestmentDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('foreignDirectInvestmentDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('foreignDirectInvestmentDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('foreignDirectInvestmentDoc') !== '' ? 'Uploaded (Click to Change)' : 'Foreign Direct Investment Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.foreignDirectInvestmentDoc && errors.foreignDirectInvestmentDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('foreignDirectInvestmentDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PreliminaryTextPart1 {...{ control, getValues, errors, setValue, clearErrors }} setFormData={setFormData} formData={formData} readyOnlyValue={readyOnlyValue} />
                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <Button
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </Button>
                                ) : (<></>)
                            }

                        </div>
                    </form> */}
          <form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="row doc-row-p1">
              <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                <FormLabel>
                  Details about the Company – Address of the Registered office of
                  the Company and other places (branch offices) from where the
                  Company is carrying on its activities;
                </FormLabel>

                <div className="form-group mt-2">
                  <Controller
                    name="mainAddress"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Main Address"
                        placeholder="Enter Main Address"
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{ readOnly: nonEditAble }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.mainAddress && errors.mainAddress.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                <FormLabel>
                  Whether the Company is a Small Company / Start up Company as defined under the Companies Act, 2013 ?
                </FormLabel>

                <div className="form-group mt-2">
                  <Controller
                    name="companySizeType"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Details"
                        placeholder="Enter Details"
                        fullWidth
                        multiline
                        rows={3}
                        {...field}
                        onBlur={handleBlur}
                        disabled={readyOnlyValue}
                        inputProps={{ maxLength: 10000 }}
                      />
                    )}
                  />
                  <FormHelperText sx={{ float: 'right' }}>
                    Max 10000 Characters
                  </FormHelperText>
                  <FormHelperText className="text-danger">
                    {errors.companySizeType && errors.companySizeType.message}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-lg-4 col-12 mt-2">
                <div className="form-group mt-2">
                  <Controller
                    name="incorporatedDate"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Date of incorporation"
                        placeholder="Enter Date of incorporation"
                        fullWidth
                        {...field}
                        disabled={nonEditAble}

                      />
                    )}
                  />

                  <FormHelperText className="text-danger">
                    {errors.incorporatedDate && errors.incorporatedDate.message}
                  </FormHelperText>
                </div>
              </div>
              {getValues("moaDocURL") && (
                <div className="col-lg-4  col-12 mt-2">
                  <FormLabel>Copy of latest MOA </FormLabel>
                  <div className="d-flex align-items-center">
                    <div className="form-group my-1">
                      <Controller
                        name="moaDoc"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FileUploader
                            {...field}
                            handleChange={handleMoaDocName}
                            value={getValues("moaDoc")}
                            name="file"
                            types={fileTypesAll}
                            children={
                              <div
                                className={
                                  getValues("moaDoc") !== ""
                                    ? "fileupload-pdf-dpiit-bg1-active"
                                    : "fileupload-pdf-dpiit-bg1"
                                }
                              >
                                <center>
                                  <p
                                    className={
                                      getValues("moaDoc") !== ""
                                        ? "fileupload-pdf-active"
                                        : "fileupload-pdf"
                                    }
                                    style={{
                                      backgroundColor: nonEditAble
                                        ? getValues("moaDoc") !== ""
                                          ? "gray"
                                          : "gray"
                                        : "",
                                    }}
                                  >
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues("moaDoc") !== ""
                                        ? "Uploaded (Click to Change)"
                                        : "MOA Document"}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={nonEditAble}
                          />
                        )}
                      />
                      <FormHelperText className="ml-2">
                        Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                      </FormHelperText>
                      <FormHelperText
                        sx={{ marginLeft: "5px" }}
                        className="text-danger"
                      >
                        {errors.moaDoc && errors.moaDoc.message}
                      </FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues("moaDocURL"))}>
                      <VisibilityIcon className="icon-size mr-1" />
                      <Link                       
                        style={{ color: "#80809e" }}
                      >
                        {" "}
                        Download
                      </Link>
                    </span>
                  </div>
                </div>
              )}
              {getValues("aoaDocURL") && (
                <div className="col-lg-4  col-12 mt-2">
                  <FormLabel>Copy of latest AOA </FormLabel>
                  <div className="d-flex align-items-center">
                    <div className="form-group my-1">
                      <Controller
                        name="aoaDoc"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FileUploader
                            {...field}
                            handleChange={handleAoaDoc}
                            value={getValues("aoaDoc")}
                            name="file"
                            types={fileTypesAll}
                            children={
                              <div
                                className={
                                  getValues("aoaDoc") !== ""
                                    ? "fileupload-pdf-dpiit-bg1-active"
                                    : "fileupload-pdf-dpiit-bg1"
                                }
                              >
                                <center>
                                  <p
                                    className={
                                      getValues("aoaDoc") !== ""
                                        ? "fileupload-pdf-active"
                                        : "fileupload-pdf"
                                    }
                                    style={{
                                      backgroundColor: nonEditAble
                                        ? getValues("aoaDoc") !== ""
                                          ? "gray"
                                          : "gray"
                                        : "",
                                    }}
                                  >
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues("aoaDoc") !== ""
                                        ? "Uploaded (Click to Change)"
                                        : "AOA Document"}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={nonEditAble}
                          />
                        )}
                      />
                      <FormHelperText className="ml-2">
                        Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                      </FormHelperText>
                      <FormHelperText
                        sx={{ marginLeft: "5px" }}
                        className="text-danger"
                      >
                        {errors.aoaDoc && errors.aoaDoc.message}
                      </FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues("aoaDocURL"))}>
                      <VisibilityIcon className="icon-size mr-1" />
                      <Link                       
                        style={{ color: "#80809e" }}
                      >
                        {" "}
                        Download
                      </Link>
                    </span>
                  </div>
                </div>
              )}
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    If there are any “non-residents” who are shareholders,
                    please provide the said details including any forms filed
                    with RBI for the same;
                  </FormLabel>
                  <Controller
                    name="nonResidentShareholder"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            Shareholders(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            Shareholders(e.target.value);
                            setValue("nonResidentShareholderDoc", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.nonResidentShareholder &&
                      errors.nonResidentShareholder.message}
                  </FormHelperText>
                </div>
              </div>
              {(residentShareholder === "true" ||
                residentShareholder === true) && (
                  <>
                    <div className="col-lg-4  col-12 mt-2">
                      <div className="d-flex align-items-center">
                        <div className="form-group my-1">
                          <Controller
                            name="nonResidentShareholderDoc"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Document is required" }}
                            render={({ field }) => (
                              <FileUploader
                                {...field}
                                handleChange={handleDetailsCertificate}
                                value={getValues("nonResidentShareholderDoc")}
                                name="file"
                                types={fileTypesAll}
                                children={
                                  <div
                                    className={
                                      getValues("nonResidentShareholderDoc") !==
                                        ""
                                        ? "fileupload-pdf-dpiit-bg1-active"
                                        : "fileupload-pdf-dpiit-bg1"
                                    }
                                  >
                                    <center>
                                      <p
                                        className={
                                          getValues(
                                            "nonResidentShareholderDoc"
                                          ) !== ""
                                            ? "fileupload-pdf-active"
                                            : "fileupload-pdf"
                                        }
                                      >
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues(
                                            "nonResidentShareholderDoc"
                                          ) !== ""
                                            ? "Uploaded (Click to Change)"
                                            : "Non-Residents Document"}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            )}
                          />
                          <FormHelperText className="ml-2">
                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                          </FormHelperText>
                          <FormHelperText
                            sx={{ marginLeft: "5px" }}
                            className="text-danger"
                          >
                            {errors.nonResidentShareholderDoc &&
                              errors.nonResidentShareholderDoc.message}
                          </FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"  onClick={() =>
                              handleClickOpens(
                                getValues("nonResidentShareholderDocURL")
                              )
                            }>
                          <VisibilityIcon className="icon-size mr-1" />
                          <Link
                           
                            style={{ color: "#80809e" }}
                          >
                            {" "}
                            Download
                          </Link>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Are there any FEMA compliances in relation to
                    exports/imports -please confirm;
                  </FormLabel>
                  <Controller
                    name="femaCompliance"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            FEMA(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            FEMA(e.target.value);
                            setValue("femaCompliance", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.femaCompliance && errors.femaCompliance.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Details of Employee Stock Option Plan or Sweat Equity Shares
                  issued or proposed to be issued by the Company;
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="employeeStockOptionPlanDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleEmployeeStockOptionPlanDoc}
                          value={getValues("employeeStockOptionPlanDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("employeeStockOptionPlanDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("employeeStockOptionPlanDoc") !==
                                      ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("employeeStockOptionPlanDoc") !==
                                      ""
                                      ? "Uploaded (Click to Change)"
                                      : "Proposed Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.employeeStockOptionPlanDoc &&
                        errors.employeeStockOptionPlanDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"  onClick={() =>
                        handleClickOpens(
                          getValues("employeeStockOptionPlanDocURL")
                        )
                      }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                    
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Is the Company required to pay royalty / fees to anyone else
                    for use of their IP/technology? Please provide details;
                  </FormLabel>
                  <Controller
                    name="technologyUsageFees"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            Technology(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            Technology(e.target.value);
                            setValue("nonResidentShareholderDoc", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.technologyUsageFees &&
                      errors.technologyUsageFees.message}
                  </FormHelperText>
                </div>
              </div>
              {(technology === "true" || technology === true) && (
                <>
                  <div className="col-lg-4  col-12 mt-2">
                    <div className="d-flex align-items-center">
                      <div className="form-group my-1">
                        <Controller
                          name="technologyUsageFeesDoc"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Document iS Required" }}
                          render={({ field }) => (
                            <FileUploader
                              {...field}
                              handleChange={handleTechnologyUsageFeesDoc}
                              value={getValues("technologyUsageFeesDoc")}
                              name="file"
                              types={fileTypesAll}
                              children={
                                <div
                                  className={
                                    getValues("technologyUsageFeesDoc") !== ""
                                      ? "fileupload-pdf-dpiit-bg1-active"
                                      : "fileupload-pdf-dpiit-bg1"
                                  }
                                >
                                  <center>
                                    <p
                                      className={
                                        getValues("technologyUsageFeesDoc") !==
                                          ""
                                          ? "fileupload-pdf-active"
                                          : "fileupload-pdf"
                                      }
                                    >
                                      <span>
                                        <AttachFileIcon className="file-icon mr-2" />
                                        {getValues("technologyUsageFeesDoc") !==
                                          ""
                                          ? "Uploaded (Click to Change)"
                                          : "Required Document"}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          )}
                        />
                        <FormHelperText className="ml-2">
                          Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                        </FormHelperText>
                        <FormHelperText
                          sx={{ marginLeft: "5px" }}
                          className="text-danger"
                        >
                          {errors.technologyUsageFeesDoc &&
                            errors.technologyUsageFeesDoc.message}
                        </FormHelperText>
                      </div>
                      <span className="pre-btn-dpiit"  onClick={() =>
                            handleClickOpens(
                              getValues("technologyUsageFeesDocURL")
                            )
                          }>
                        <VisibilityIcon className="icon-size mr-1" />
                        <Link
                         
                          style={{ color: "#80809e" }}
                        >
                          {" "}
                          Download
                        </Link>
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Details of any inter-se shareholder’s agreement or
                  arrangements or understandings between the Promoters; or the
                  Company and any shareholder of the Company;
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="shareholderAgreementsDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleShareholderAgreementsDoc}
                          value={getValues("shareholderAgreementsDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("shareholderAgreementsDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("shareholderAgreementsDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("shareholderAgreementsDoc") !==
                                      ""
                                      ? "Uploaded (Click to Change)"
                                      : "Shareholder Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.shareholderAgreementsDoc &&
                        errors.shareholderAgreementsDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"   onClick={() =>
                        handleClickOpens(
                          getValues("shareholderAgreementsDocURL")
                        )
                      }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                    
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              {/* <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Whether there is an Employment Agreement with the Directors and if so please provide a copy of the same</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="employmentAgreeDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleEmployeeAgreementDoc}
                                                    value={getValues('employmentAgreeDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('employmentAgreeDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('employmentAgreeDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('employmentAgreeDoc') !== '' ? 'Uploaded (Click to Change)' : 'Employment Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={nonEditAble}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.employmentAgreeDoc && errors.employmentAgreeDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('employmentAgreeDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div> */}
              {getValues("employmentAgreeDoc") && (
                <div className="col-lg-4 col-12 mt-2">
                  <FormLabel>
                    {/* Whether there is an Employment Agreement with the Directors
                  and if so please provide a copy of the same. */}
                    Whether there is an Employment Agreement (duly signed and executed on stamp paper) with the Directors and if so please provide a copy of the same;
                  </FormLabel>
                  <div className="d-flex align-items-center">
                    <div className="form-group my-1">
                      <Controller
                        name="employmentAgreeDoc"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FileUploader
                            {...field}
                            handleChange={handleEmployeeAgreementDoc}
                            value={getValues("employmentAgreeDoc")}
                            name="file"
                            types={fileTypesAll}
                            children={
                              <div
                                className={
                                  getValues("employmentAgreeDoc") !== ""
                                    ? "fileupload-pdf-dpiit-bg1-active"
                                    : "fileupload-pdf-dpiit-bg1"
                                }
                                style={{
                                  backgroundColor: nonEditAble ? "#f0f0f0" : "",
                                }}
                              // style={{
                              //     backgroundColor: nonEditAble
                              //         ? (getValues('employmentAgreeDoc') !== '' ? 'green' : 'red')
                              //         : ''
                              // }}
                              >
                                <center>
                                  <p
                                    className={
                                      getValues("employmentAgreeDoc") !== ""
                                        ? "fileupload-pdf-active"
                                        : "fileupload-pdf"
                                    }
                                    // style={{ color: nonEditAble ? '#a9a9a9' : '' }}
                                    style={{
                                      backgroundColor: nonEditAble
                                        ? getValues("employmentAgreeDoc") !== ""
                                          ? "gray"
                                          : "gray"
                                        : "",
                                    }}
                                  >
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues("employmentAgreeDoc") !== ""
                                        ? "Uploaded (Click to Change)"
                                        : "Employment Document"}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={nonEditAble}
                          />
                        )}
                      />
                      <FormHelperText
                        className="ml-2"
                        style={{ color: nonEditAble ? "#a9a9a9" : "" }}
                      >
                        Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                      </FormHelperText>
                      <FormHelperText
                        sx={{ marginLeft: "5px" }}
                        className="text-danger"
                      >
                        {errors.employmentAgreeDoc &&
                          errors.employmentAgreeDoc.message}
                      </FormHelperText>
                    </div>
                    <span
                      className="pre-btn-dpiit"
                      style={{ color: nonEditAble ? "#a9a9a9" : "" }}
                      onClick={() =>
                        handleClickOpens(getValues("employmentAgreeDocURL"))
                      }
                    >
                      <VisibilityIcon className="icon-size mr-1" />
                      <Link
                     
                        style={{ color: nonEditAble ? "#a9a9a9" : "#80809e" }}
                      >
                        Download
                      </Link>
                    </span>
                  </div>
                </div>
              )}

              <div className="col-lg-4 col-12 mt-2">
                <FormLabel>Whether there are restrictions (in relation to Non-Compete, Confidentiality, being engaged in other entities etc.) in the Employment Agreement with its Directors / KMP</FormLabel>
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="employmentRestrictions"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            empRestricCheck(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            empRestricCheck(e.target.value);
                            setValue("employmentRestrictions", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.employmentRestrictions && errors.employmentRestrictions.message}
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Financial statements of the Company (including balance sheets,
                  profit and loss accounts, cash flow statements etc) for last 3
                  financial years
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="financialStatementDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleFinancialStatementDoc}
                          value={getValues("financialStatementDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("financialStatementDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("financialStatementDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("financialStatementDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Financial Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.financialStatementDoc &&
                        errors.financialStatementDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"  onClick={() =>
                        handleClickOpens(getValues("financialStatementDocURL"))
                      }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                     
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              {/* <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Details of insurance policies obtained by the Company for its
                  business/ assets including any liability related insurance
                  policies
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="insurancePolicyDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleInsurancePolicyDoc}
                          value={getValues("insurancePolicyDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("insurancePolicyDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("insurancePolicyDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("insurancePolicyDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Insurance Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.insurancePolicyDoc &&
                        errors.insurancePolicyDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit">
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      onClick={() =>
                        handleClickOpens(getValues("insurancePolicyDocURL"))
                      }
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div> */}
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Please provide a copy of the employment agreement (sample) of the employees for our review.
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="employmentAgreeForEmployDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleEmployeeAggrementDoc}
                          value={getValues("employmentAgreeForEmployDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("employmentAgreeForEmployDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("employmentAgreeForEmployDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("employmentAgreeForEmployDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Employee Agreement"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.employmentAgreeForEmployDoc &&
                        errors.employmentAgreeForEmployDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"   onClick={() =>
                        handleClickOpens(getValues("employmentAgreeForEmployDocURL"))
                      }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                    
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Whether the Company has necessary licenses for use of the software it is using.
                  </FormLabel>
                  <Controller
                    name="companyLicensesNecessary"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            LicensedCheck(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            LicensedCheck(e.target.value);
                            setValue("companyLicensesNecessary", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.companyLicensesNecessary && errors.companyLicensesNecessary.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Whether the Company has any audit report certifying compliance with Data Security standards of the Company
                  </FormLabel>
                  <Controller
                    name="companyAuditReport"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            handleAuditReport(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            handleAuditReport(e.target.value);
                            setValue("auditReportDoc", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.companyAuditReport &&
                      errors.companyAuditReport.message}
                  </FormHelperText>
                </div>
              </div>
              {(auditReport === "true" ||
                auditReport === true) && (
                  <>
                    <div className="col-lg-4  col-12 mt-2">
                      <div className="d-flex align-items-center">
                        <div className="form-group my-1">
                          <Controller
                            name="companyAuditReportDoc"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Document is required" }}
                            render={({ field }) => (
                              <FileUploader
                                {...field}
                                handleChange={handleauditReportDetails}
                                value={getValues("companyAuditReportDoc")}
                                name="file"
                                types={fileTypesAll}
                                children={
                                  <div
                                    className={
                                      getValues("companyAuditReportDoc") !==
                                        ""
                                        ? "fileupload-pdf-dpiit-bg1-active"
                                        : "fileupload-pdf-dpiit-bg1"
                                    }
                                  >
                                    <center>
                                      <p
                                        className={
                                          getValues(
                                            "companyAuditReportDoc"
                                          ) !== ""
                                            ? "fileupload-pdf-active"
                                            : "fileupload-pdf"
                                        }
                                      >
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues(
                                            "companyAuditReportDoc"
                                          ) !== ""
                                            ? "Uploaded (Click to Change)"
                                            : "Audit report Document *"}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            )}
                          />
                          <FormHelperText className="ml-2">
                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                          </FormHelperText>
                          <FormHelperText
                            sx={{ marginLeft: "5px" }}
                            className="text-danger"
                          >
                            {errors.companyAuditReportDoc &&
                              errors.companyAuditReportDoc.message}
                          </FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"   onClick={() =>
                              handleClickOpens(
                                getValues("companyAuditReportDocURL")
                              )
                            }>
                          <VisibilityIcon className="icon-size mr-1" />
                          <Link
                          
                            style={{ color: "#80809e" }}
                          >
                            {" "}
                            Download
                          </Link>
                        </span>
                      </div>
                    </div>
                  </>
                )}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen1}
                  onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Branch
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {directorsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {directorsFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "30%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <Controller
                                            name={`branches.${index}.address`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required: "Address is required",
                                            }}
                                            render={({ field }) => (
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Address *"
                                                placeholder="Enter address"
                                                fullwidth
                                                {...field}
                                                inputProps={{
                                                  readOnly: readyOnlyValue,
                                                }}

                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.branches?.[index]
                                              ?.address &&
                                              errors.branches?.[index]?.address
                                                .message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          width: "20%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <Controller
                                            name={`branches.${index}.state`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required: "State is required",
                                            }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                // limitTags={2}
                                                // value={stateName}
                                                options={stateList}
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleSelectStateType(
                                                    selectedOptions,
                                                    index
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="State *"
                                                  />
                                                )}
                                                disabled={readyOnlyValue}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.branches?.[index]?.state &&
                                              errors.branches?.[index]?.state
                                                .message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "20%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        {getValues(
                                          `branches.${index}.state`
                                        ) === "Tamil Nadu" ? (
                                          <>
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`branches.${index}.district`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required:
                                                    "District is required",
                                                }}
                                                render={({ field }) => (
                                                  <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    // limitTags={2}
                                                    options={cityList}
                                                    onChange={(
                                                      e,
                                                      selectedOptions
                                                    ) =>
                                                      handleSelectCity(
                                                        selectedOptions,
                                                        index
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="District *"
                                                      />
                                                    )}
                                                    disabled={readyOnlyValue}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.branches?.[index]
                                                  ?.district &&
                                                  errors.branches?.[index]
                                                    ?.district.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </>
                                        ) : (
                                          <>
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`branches.${index}.district`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required:
                                                    "District is required",
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    label="District *"
                                                    placeholder="Enter District"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                    }}
                                                    onKeyDown={(e) => checkAlpha(e)}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.branches?.[index]
                                                  ?.district &&
                                                  errors.branches?.[index]
                                                    ?.district.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "20%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <Controller
                                            name={`branches.${index}.pincode`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required: "Pincode is required",
                                              validate: validatePincode,
                                            }}
                                            render={({ field }) => (
                                              <TextField
                                                variant="outlined"
                                                label="Pincode *"
                                                placeholder="Enter Pincode"
                                                fullWidth
                                                multiline
                                                inputProps={{
                                                  readOnly: readyOnlyValue,
                                                  maxLength: 6,
                                                }}
                                                {...field}
                                                onKeyDown={(e) =>
                                                  validateNumberonly(e)
                                                }
                                              // value={valuePincode}
                                              // onInput={(e) => {
                                              //     const { value } = e.target;
                                              //     if (value === "" || (/^\d{1,6}$/.test(value))) {
                                              //         setValuePincode(value);
                                              //     }
                                              // }}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.branches?.[index]
                                              ?.pincode &&
                                              errors.branches?.[index]?.pincode
                                                .message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                        align="center"
                                      >
                                        <DeleteIcon
                                          sx={{
                                            backgroudColor: "#F4F4F4",
                                            fontSize: "24px",
                                            fill: "#808080",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            readyOnlyValue === false ? (
                                              deleteDirectorsInterested(index)
                                            ) : (
                                              <></>
                                            );
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => adddirectorsFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen12}
                  onChange={() => setIsAccordionOpen12(!isAccordionOpen12)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Whether there is any lease/rental agreement for the registered office of the Company. If so please share a copy of the same and fill in the details of the same below.
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {leaseRentalAgreementsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {leaseRentalAgreementsFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "30%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          {/* <Controller
                                            name={`leaseRentalAgreements.${index}.address`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required: "Type is required",
                                            }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                // limitTags={2}
                                                // value={stateName}
                                                options={chartList}
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleChartList(
                                                    selectedOptions,
                                                    index
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Type *"
                                                  />
                                                )}
                                                // disabled={nonEditAble}
                                                disabled={readyOnlyValue}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.leaseRentalAgreements?.[
                                              index
                                            ]?.address &&
                                              errors.leaseRentalAgreements?.[
                                                index
                                              ]?.address.message}
                                          </FormHelperText> */}
                                          <Controller
                                            name={`leaseRentalAgreements.${index}.address`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Address is Required" }}
                                            render={({ field }) => (
                                              <TextField
                                                variant="outlined"
                                                label="Address *"
                                                placeholder="Enter Address"
                                                fullWidth
                                                // inputProps={{ readOnly: nonEditAble }}
                                                inputProps={{
                                                  readOnly: readyOnlyValue,
                                                  maxLength: 5000
                                                }}
                                                {...field}
                                              />
                                            )}
                                          />
                                          <FormHelperText sx={{ textAlign: 'right' }}>Max 5000 Characters</FormHelperText>
                                          <FormHelperText className="text-danger">
                                            {errors.leaseRentalAgreements?.[
                                              index
                                            ]?.address &&
                                              errors.leaseRentalAgreements?.[
                                                index
                                              ]?.address.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "25%",
                                          borderBottom: "none",
                                          padding: "5px",
                                          marginTop: '10px'
                                        }}
                                      >
                                        {
                                          getValues(`leaseRentalAgreements.${index}.fileName`) !== '' && <span>Upload lease/rental agreement <span className='text-danger'>*</span></span>
                                        }
                                        <div className="d-flex align-items-center">
                                          <div className='form-group my-1'>
                                            <Controller name={`leaseRentalAgreements.${index}.file`} control={control} defaultValue="" rules={{ required: "lease/rental agreement  is Required" }}
                                              render={({ field }) =>

                                                <FileUploader
                                                  {...field}
                                                  handleChange={(e) => handleFileUpload(e,
                                                    `leaseRentalAgreements.${index}.file`,
                                                    `leaseRentalAgreements.${index}.fileName`,
                                                    `leaseRentalAgreements.${index}.fileURL`,
                                                    'file',
                                                    'nosave'
                                                  )}
                                                  value={getValues(`leaseRentalAgreements.${index}.file`)}
                                                  name="file"
                                                  types={fileTypesAll}
                                                  children={

                                                    <div className={getValues(`leaseRentalAgreements.${index}.file`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                      <center>
                                                        <p className={getValues(`leaseRentalAgreements.${index}.file`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                        // style={{
                                                        //   backgroundColor: nonEditAble
                                                        //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                        //     : ''
                                                        // }}
                                                        >
                                                          <span>
                                                            <AttachFileIcon className="file-icon mr-2" />
                                                            {getValues(`leaseRentalAgreements.${index}.file`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                          </span>
                                                        </p>
                                                      </center>
                                                    </div>
                                                  }
                                                  disabled={readyOnlyValue}
                                                // disabled={nonEditAble}
                                                />
                                              } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.leaseRentalAgreements?.[index]?.file && errors.leaseRentalAgreements?.[index]?.file.message}</FormHelperText>

                                          </div>
                                          <span className="pre-btn-dpiit"
                                              onClick={() => handleClickOpens(getValues(`leaseRentalAgreements.${index}.fileURL`))}
                                          ><VisibilityIcon className='icon-size mr-1' /><Link
                                        
                                            style={{ color: "#80809e" }}
                                          > Download
                                          </Link>
                                          </span>
                                        </div>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                        align="center"
                                      >
                                        <DeleteIcon
                                          sx={{
                                            backgroudColor: "#F4F4F4",
                                            fontSize: "24px",
                                            fill: "#808080",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            readyOnlyValue === false ? (
                                              deleteleaseAggrementBtn(index)
                                            ) : (
                                              <></>
                                            );
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addleaserentalDetails("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen2}
                  onChange={() => setIsAccordionOpen2(!isAccordionOpen2)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please list out all material Agreements / MoUs / Contracts
                      entered into by the Company with the vendors/ business
                      associates of the Company which are currently in existence
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companySecretaryFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {companySecretaryFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`materialAgreements.${index}.partyName`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Party Name is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Party Name *"
                                                  placeholder="Enter Party Name"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.materialAgreements?.[
                                                index
                                              ]?.partyName &&
                                                errors.materialAgreements?.[
                                                  index
                                                ]?.partyName.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`materialAgreements.${index}.contractType`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Type of contract / Nature of services to be rendered is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Type of contract / Nature of services to be rendered *"
                                                  placeholder="Enter Type of contract / Nature of services to be rendered"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.materialAgreements?.[
                                                index
                                              ]?.contractType &&
                                                errors.materialAgreements?.[
                                                  index
                                                ]?.contractType.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`materialAgreements.${index}.valuePerAnnum`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Value per Annum is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Value per Annum *"
                                                  placeholder="Enter Value per Annum"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                    maxLength: 13
                                                  }}
                                                  onKeyDown={(e) =>
                                                    validateDecimalNumberonly(e)
                                                  }
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.materialAgreements?.[
                                                index
                                              ]?.valuePerAnnum &&
                                                errors.materialAgreements?.[
                                                  index
                                                ]?.valuePerAnnum.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {/* <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`materialAgreements.${index}.validityPeriod`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Validity period is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Validity period *"
                                                  placeholder="Enter Validity period"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.materialAgreements?.[
                                                index
                                              ]?.validityPeriod &&
                                                errors.materialAgreements?.[
                                                  index
                                                ]?.validityPeriod.message}
                                            </FormHelperText>
                                          </FormControl> */}
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`materialAgreements.${index}.validityPeriod`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Validity is required",
                                              }}
                                              render={({ field }) => (
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                    fullWidth
                                                  >
                                                    <DatePicker
                                                      label="Validity Period *"
                                                      format="DD/MM/YYYY"
                                                      value={
                                                        field.value || null
                                                      }
                                                      sx={{ width: "100%" }}
                                                      inputFormat="DD/MM/YYYY"
                                                      onChange={(newValue) =>
                                                        handleDateFormat2(
                                                          newValue,
                                                          index
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                      disabled={readyOnlyValue}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.materialAgreements?.[
                                                index
                                              ]?.validityPeriod &&
                                                errors.materialAgreements?.[
                                                  index
                                                ]?.validityPeriod.message}
                                            </FormHelperText>
                                          </FormControl>

                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteCompanySecretaryBtn(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addCompanySecretaryBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen13}
                  onChange={() => setIsAccordionOpen13(!isAccordionOpen13)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Domain Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            1
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className="form-group">
                              <FormLabel>
                                Whether the Company has any Domain
                              </FormLabel>
                              <Controller
                                name="domain"
                                control={control}
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <RadioGroup row name="position" {...field}>
                                    <FormControlLabel
                                      disabled={readyOnlyValue}
                                      onChange={(e) => {
                                        handleDomainshow(e.target.value);
                                      }}
                                      defaultValue="null"
                                      value="true"
                                      control={<Radio />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      disabled={readyOnlyValue}
                                      onChange={(e) => {
                                        handleDomainshow(e.target.value);

                                      }}
                                      defaultValue="null"
                                      value="false"
                                      control={<Radio />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.domain &&
                                  errors.domain.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {(getValues('domain') === "true" ||
                            getValues('domain') === true) && (
                              <>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name="domainName"
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required: "Domain Name is Required"

                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Domain Name *"
                                          placeholder="Enter Domain Name"
                                          fullwidth
                                          {...field}
                                          inputProps={{
                                            readOnly: readyOnlyValue,
                                          }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.domainName &&
                                        errors.domainName.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name="domainStatus"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: "Domain Status is required" }}
                                      render={({ field }) => (
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Domain Status *"
                                          placeholder="Enter Domain Status"
                                          fullwidth
                                          {...field}
                                          inputProps={{
                                            readOnly: readyOnlyValue,
                                          }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.domainStatus &&
                                        errors.domainStatus.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name="domainOwnedBy"
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required:
                                          "Domain Owned by is Required"
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Domain Owned by *"
                                          placeholder="Enter Domain Owned by"
                                          fullwidth
                                          {...field}
                                          inputProps={{
                                            readOnly: readyOnlyValue,
                                          }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.domainOwnedBy &&
                                        errors.domainOwnedBy.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  {
                                    getValues(`domainDocName`) !== '' && <span>Upload Payment Receipt Document <span className='text-danger'>*</span></span>
                                  }
                                  <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                      <Controller name={`domainDoc`} control={control} defaultValue="" rules={{ required: "Document is Required" }}
                                        render={({ field }) =>

                                          <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                              `domainDoc`,
                                              `domainDocName`,
                                              `domainDocURL`,
                                              'file',
                                              'nosave'
                                            )}
                                            value={getValues(`domainDoc`)}
                                            name="file"
                                            types={fileTypesAll}
                                            children={

                                              <div className={getValues(`domainDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                <center>
                                                  <p className={getValues(`domainDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                  // style={{
                                                  //   backgroundColor: nonEditAble
                                                  //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                  //     : ''
                                                  // }}
                                                  >
                                                    <span>
                                                      <AttachFileIcon className="file-icon mr-2" />
                                                      {getValues(`domainDoc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                    </span>
                                                  </p>
                                                </center>
                                              </div>
                                            }
                                            disabled={readyOnlyValue}
                                          // disabled={nonEditAble}
                                          />
                                        } />
                                      <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                      <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.domainDoc && errors.domainDoc.message}</FormHelperText>

                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                      onClick={() => handleClickOpens(getValues(`domainDocURL`))}
                                      style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                  </div>
                                </Grid>
                              </>
                            )}
                        </Grid>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div> */}
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen3}
                  onChange={() => setIsAccordionOpen3(!isAccordionOpen3)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      List of trademarks, copyrights, patents, designs and other intellectual property of the Company (including any pending applications)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {intellectualPropertyFields.length >= 0 && (

                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {intellectualPropertyFields.map(
                                    (field, index) => (
                                      <TableRow key={field.id}>
                                        {/* Index */}
                                        <TableCell
                                          sx={{
                                            width: "3%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>

                                        {/* Property Field */}
                                        <TableCell
                                          sx={{
                                            width: "30%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`intellectualProperty.${index}.property`} // Track the property field
                                              control={control}
                                              defaultValue={
                                                field.property || ""
                                              }
                                              rules={{ required: false }} 
                                              render={({ field }) =>
                                                field.value && (
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      padding: "8px 0",
                                                    }}
                                                  >
                                                    {field.value || "N/A"}
                                                  </span>
                                                )
                                              }
                                            />

                                            <FormHelperText className="text-danger">
                                              {errors.intellectualProperty?.[
                                                index
                                              ]?.property &&
                                                errors.intellectualProperty[
                                                  index
                                                ].property.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        {/* DetailsNew Field */}
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`intellectualProperty.${index}.details`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <TextField
                                                  label="Details"
                                                  placeholder="Enter details"
                                                  fullWidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.intellectualProperty?.[
                                                index
                                              ]?.details &&
                                                errors.intellectualProperty[
                                                  index
                                                ].details.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        {/* Status Field */}
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`intellectualProperty.${index}.status`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <TextField
                                                  label="Status"
                                                  placeholder="Enter Status"
                                                  fullWidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.intellectualProperty?.[
                                                index
                                              ]?.status &&
                                                errors.intellectualProperty[
                                                  index
                                                ].status.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        {/* ownedby Field */}
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`intellectualProperty.${index}.ownedBy`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label="Owned by"
                                                  placeholder={`Enter Owned by`}
                                                  fullWidth
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.intellectualProperty?.[
                                                index
                                              ]?.ownedBy &&
                                                errors.intellectualProperty[
                                                  index
                                                ].ownedBy.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>


                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {/* {
                                            getValues(`intellectualProperty.${index}.fileDocName`) !== '' && <span>Upload Insurance Document <span className='text-danger'>*</span></span>
                                          } */}
                                          <div className="d-flex align-items-center">
                                            <div className='form-group my-1'>
                                              <Controller name={`intellectualProperty.${index}.fileDoc`} control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                  <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                      `intellectualProperty.${index}.fileDoc`,
                                                      `intellectualProperty.${index}.fileDocName`,
                                                      `intellectualProperty.${index}.fileDocURL`,
                                                      'file',
                                                      'nosave'
                                                    )}
                                                    value={getValues(`intellectualProperty.${index}.fileDoc`)}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={

                                                      <div className={getValues(`intellectualProperty.${index}.fileDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                        <center>
                                                          <p className={getValues(`intellectualProperty.${index}.fileDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                          // style={{
                                                          //   backgroundColor: nonEditAble
                                                          //     ? (getValues(`intellectualProperty.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                          //     : ''
                                                          // }}
                                                          >
                                                            <span>
                                                              <AttachFileIcon className="file-icon mr-2" />
                                                              {getValues(`intellectualProperty.${index}.fileDoc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                            </span>
                                                          </p>
                                                        </center>
                                                      </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                  // disabled={nonEditAble}
                                                  />
                                                } />
                                              <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                              <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.intellectualProperty?.[index]?.fileDoc && errors.intellectualProperty?.[index]?.fileDoc.message}</FormHelperText>

                                            </div>
                                            <span className="pre-btn-dpiit"
                                              onClick={() => handleClickOpens(getValues(`intellectualProperty.${index}.fileDocURL`))}
                                            ><VisibilityIcon className='icon-size mr-1' /><Link
                                            
                                              style={{ color: "#80809e" }}
                                            > Download
                                            </Link>
                                            </span>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* {patentsShow === true && (
                <div className="col-lg-12 col-12 mb-3 pl-3">
                  <Accordion
                    className="Addfundacc-bor"
                    expanded={isAccordionOpen9}
                    onChange={() => setIsAccordionOpen9(!isAccordionOpen9)}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="Addfundacc"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 0,
                        }}
                      >
                        <AddCircleIcon className="add-icon mr-3" /> List of trademarks, copyrights, patents, designs</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="col-lg-12 col-12">
                          {patentsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                              sx={{ overflowX: "auto", maxWidth: "100%" }}
                            >
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableBody>
                                  {patentsFields.map((field, index, type) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ borderBottom: "none" }}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "20%",
                                          borderBottom: "none",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <InputLabel id="dropdown-label">
                                            Choose *
                                          </InputLabel>
                                          <Controller
                                            name={`patents.${index}.name`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required: "Name is required",
                                            }}
                                            render={({ field }) => (
                                              <Select
                                                {...field}
                                                variant="outlined"
                                                onChange={(e) => {
                                                  handleSelectChange(e);
                                                  field.onChange(e);
                                                }}
                                                disabled={nonEditAble}
                                              >
                                                <MenuItem value={"Patent"}>
                                                  Patent
                                                </MenuItem>
                                                <MenuItem value={"Trademark"}>
                                                  Trademark
                                                </MenuItem>
                                                <MenuItem value={"Copyright"}>
                                                  Copyright
                                                </MenuItem>
                                              </Select>
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.patents?.[index]?.name &&
                                              errors.patents?.[index]?.name
                                                .message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "20%",
                                          borderBottom: "none",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <InputLabel id="dropdown-label">
                                            Stage *
                                          </InputLabel>
                                          <Controller
                                            name={`patents.${index}.stage`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required: "Name is required",
                                            }}
                                            render={({ field }) => (
                                              <Select
                                                {...field}
                                                variant="outlined"
                                                disabled={nonEditAble}
                                              >
                                                <MenuItem value={"0"}>
                                                  Process of Applying
                                                </MenuItem>
                                                <MenuItem value={"1"}>
                                                  Applied
                                                </MenuItem>
                                                <MenuItem value={"2"}>
                                                  Granted
                                                </MenuItem>
                                                <MenuItem value={"3"}>
                                                  Yes
                                                </MenuItem>
                                              </Select>
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.patents?.[index]?.stage &&
                                              errors.patents?.[index]?.stage
                                                .message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "25%",
                                          borderBottom: "none",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <Controller
                                            name={`patents.${index}.number`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required:
                                                "Description is required",
                                            }}
                                            render={({ field }) => (
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Description *"
                                                placeholder="Enter Description"
                                                fullwidth
                                                {...field}
                                                disabled={nonEditAble}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.patents?.[index]?.number &&
                                              errors.patents?.[index]?.number
                                                .message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "25%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        {getValues(
                                          `patents.${index}.docname`
                                        ) !== "" && (
                                            <span>
                                              Upload Document{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </span>
                                          )}
                                        <div className="d-flex align-items-center">
                                          <div className="form-group my-1">
                                            <Controller
                                              name={`patents.${index}.doc`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <FileUploader
                                                  {...field}
                                                  handleChange={(e) =>
                                                    handleFileUpload(
                                                      e,
                                                      `patents.${index}.doc`,
                                                      `patents.${index}.docName`,
                                                      `patents.${index}.docNameURL`,
                                                      "file"
                                                    )
                                                  }
                                                  value={getValues(
                                                    `patents.${index}.doc`
                                                  )}
                                                  name="file"
                                                  types={fileTypesAll}
                                                  children={
                                                    <div className={getValues(`patents.${index}.doc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                      <center>
                                                        <p className={getValues(`patents.${index}.doc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                          style={{
                                                            backgroundColor: nonEditAble
                                                              ? (getValues(`patents.${index}.doc`) !== '' ? 'gray' : 'gray')
                                                              : ''
                                                          }}>
                                                          <span>
                                                            <AttachFileIcon className="file-icon mr-2" />
                                                            {getValues(`patents.${index}.doc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                          </span>
                                                        </p>
                                                      </center>
                                                    </div>
                                                  }
                                                  disabled={nonEditAble}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="ml-2">
                                              Maximum size: 5 MB. File format .pdf
                                            </FormHelperText>
                                            <FormHelperText
                                              sx={{ marginLeft: "5px" }}
                                              className="text-danger"
                                            >
                                              {errors.patents?.[index]?.doc &&
                                                errors.patents?.[index]?.doc
                                                  .message}
                                            </FormHelperText>
                                          </div>
                                          <span className="pre-btn-dpiit">
                                            <VisibilityIcon className="icon-size mr-1" />
                                            <Link
                                              onClick={() =>
                                                handleClickOpens(
                                                  getValues(
                                                    `patents.${index}.docname`
                                                  )
                                                )
                                              }
                                              style={{ color: "#80809e" }}
                                            >
                                              {" "}
                                              Download
                                            </Link>
                                          </span>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )} */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen4}
                  onChange={() => setIsAccordionOpen4(!isAccordionOpen4)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of all immovable properties leased by the Company
                      for its business:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {immovablePropertiesFields.length >= 0 && (
                            <>
                              {immovablePropertiesFields.map((field, index) => (
                                <Grid container direction="row" justifyContent="flex-start" alignItems="space-between" key={index} spacing={3} sx={{ marginBottom: '10px !important' }}>
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                  >
                                    {index + 1}
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`immovableProperty.${index}.address`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Address is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Address *"
                                            placeholder="Enter Address"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                              maxLength: 5000
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText sx={{ textAlign: 'right' }}>Max 5000 Characters</FormHelperText>
                                      <FormHelperText className="text-danger">
                                        {errors.immovableProperty?.[index]
                                          ?.address &&
                                          errors.immovableProperty?.[
                                            index
                                          ]?.address.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`immovableProperty.${index}.lessor`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Lessor is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Lessor *"
                                            placeholder="Enter Lessor"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.immovableProperty?.[index]
                                          ?.lessor &&
                                          errors.immovableProperty?.[
                                            index
                                          ]?.lessor.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`immovableProperty.${index}.lease`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Lease rent p.m & Lease Deposit is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Lease rent p.m & Lease Deposit *"
                                            placeholder="Enter Lease rent p.m & Lease Deposit"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.immovableProperty?.[index]
                                          ?.lease &&
                                          errors.immovableProperty?.[
                                            index
                                          ]?.lease.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`immovableProperty.${index}.leaseTerm`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Lease term/duration is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Lease term/duration *"
                                            placeholder="Enter Lease term/duration"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.immovableProperty?.[index]
                                          ?.leaseTerm &&
                                          errors.immovableProperty?.[
                                            index
                                          ]?.leaseTerm.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <FormLabel>
                                        Whether the agreement is duly stamped & registered
                                      </FormLabel>
                                      <Controller
                                        name={`immovableProperty.${index}.stampingAndRegistration`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Duly stamped & registered agreement is Required",
                                        }}
                                        render={({ field }) => (
                                          <RadioGroup
                                            row
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value
                                              );

                                            }}
                                          >
                                            <FormControlLabel
                                              disabled={readyOnlyValue}
                                              value="true"
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              disabled={readyOnlyValue}
                                              value="false"
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.immovableProperty?.[index]
                                          ?.stampingAndRegistration &&
                                          errors.immovableProperty?.[index]
                                            ?.stampingAndRegistration.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                    <DeleteIcon
                                      sx={{
                                        backgroudColor: "#F4F4F4",
                                        fontSize: "24px",
                                        fill: "#808080",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        readyOnlyValue === false ? (
                                          deleteImmovableBtn(index)
                                        ) : (
                                          <></>
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addImmovableBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}

                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen5}
                  onChange={() => setIsAccordionOpen5(!isAccordionOpen5)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of any complaints and litigation in respect of the
                      Company or its Promoters/Directors
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {complaintsAndLitigationFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {complaintsAndLitigationFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "30%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`complaintsAndLitigation.${index}.litigationNature`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Nature of litigation & Venue/ Forum is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Nature of litigation & Venue/ Forum *"
                                                  placeholder="Enter Nature of litigation & Venue/ Forum"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.complaintsAndLitigation?.[
                                                index
                                              ]?.litigationNature &&
                                                errors
                                                  .complaintsAndLitigation?.[
                                                  index
                                                ]?.litigationNature.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`complaintsAndLitigation.${index}.party`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Party is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Party *"
                                                  placeholder="Enter Party"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.complaintsAndLitigation?.[
                                                index
                                              ]?.party &&
                                                errors
                                                  .complaintsAndLitigation?.[
                                                  index
                                                ]?.party.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`complaintsAndLitigation.${index}.indicativeAmount`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Indicative Amount is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Indicative Amount *"
                                                  placeholder="Enter Indicative Amount"
                                                  fullwidth
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateDecimalNumberonly(e)
                                                  }
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                    maxLength: 10
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.complaintsAndLitigation?.[
                                                index
                                              ]?.indicativeAmount &&
                                                errors
                                                  .complaintsAndLitigation?.[
                                                  index
                                                ]?.indicativeAmount.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deletecomplaintsAndLitigationBtn(
                                                  index
                                                )
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addcomplaintsAndLitigation("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen6}
                  onChange={() => setIsAccordionOpen6(!isAccordionOpen6)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Organizational Chart, Number of employees at each level &
                      other details:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {startupTeamsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {startupTeamsFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "30%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <Controller
                                            name={`startupOrganizationChart.${index}.type`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required: "Type is required",
                                            }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                // limitTags={2}
                                                // value={stateName}
                                                options={chartList}
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleChartList(
                                                    selectedOptions,
                                                    index
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Type *"
                                                  />
                                                )}
                                                // disabled={nonEditAble}
                                                disabled={readyOnlyValue}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.startupOrganizationChart?.[
                                              index
                                            ]?.type &&
                                              errors.startupOrganizationChart?.[
                                                index
                                              ]?.type.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "20%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <Controller
                                            name={`startupOrganizationChart.${index}.totalEmployees`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required:
                                                "Total number of employees is required",
                                            }}
                                            render={({ field }) => (
                                              <TextField
                                                variant="outlined"
                                                label="Total number of employees *"
                                                placeholder="Enter Total number of employees"
                                                fullWidth
                                                inputProps={{
                                                  readOnly: readyOnlyValue,
                                                  maxLength: 4,
                                                }}
                                                {...field}
                                                onKeyDown={(e) =>
                                                  validateNumberonly(e)
                                                }
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.startupOrganizationChart?.[
                                              index
                                            ]?.totalEmployees &&
                                              errors.startupOrganizationChart?.[
                                                index
                                              ]?.totalEmployees.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "20%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "100%" }}>
                                          <FormLabel>
                                            Covered under PF/ESI *
                                          </FormLabel>
                                          <Controller
                                            name={`startupOrganizationChart.${index}.coveredUnderPF`}
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                              required:
                                                "Covered under PF/ESI required",
                                            }}
                                            render={({ field }) => (
                                              <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                              >
                                                <FormControlLabel
                                                  disabled={readyOnlyValue}
                                                  defaultValue="null"
                                                  value="true"
                                                  control={<Radio />}
                                                  label="Yes"
                                                />
                                                <FormControlLabel
                                                  disabled={readyOnlyValue}
                                                  defaultValue="null"
                                                  value="false"
                                                  control={<Radio />}
                                                  label="No"
                                                />
                                              </RadioGroup>
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.startupOrganizationChart?.[
                                              index
                                            ]?.coveredUnderPF &&
                                              errors.startupOrganizationChart?.[
                                                index
                                              ]?.coveredUnderPF.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                        align="center"
                                      >
                                        <DeleteIcon
                                          sx={{
                                            backgroudColor: "#F4F4F4",
                                            fontSize: "24px",
                                            fill: "#808080",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            readyOnlyValue === false ? (
                                              deleteStartupTeamDetailsBtn(index)
                                            ) : (
                                              <></>
                                            );
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addStartupTeamDetails("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen10}
                  onChange={() => setIsAccordionOpen10(!isAccordionOpen10)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of insurance policies obtained by the Company for its business/ assets including any liability related insurance policies
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companyInsurancePoliciesFields.length >= 0 && (
                            <>
                              {companyInsurancePoliciesFields.map((field, index) => (
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index} spacing={3}>
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                  >
                                    {index + 1}
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`companyInsurancePolicies.${index}.name`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Name of Insurance is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Name of Insurance Co *"
                                            placeholder="Enter Name of Insurance"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.companyInsurancePolicies?.[index]
                                          ?.name &&
                                          errors.companyInsurancePolicies?.[
                                            index
                                          ]?.name.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`companyInsurancePolicies.${index}.type`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Type of Insurance Policy  is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Type of Insurance Policy  *"
                                            placeholder="Enter Type of Insurance Policy "
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.companyInsurancePolicies?.[index]
                                          ?.type &&
                                          errors.companyInsurancePolicies?.[
                                            index
                                          ]?.type.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`companyInsurancePolicies.${index}.covered`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "What is covered is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="What is covered *"
                                            placeholder="Enter What is covered"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.companyInsurancePolicies?.[index]
                                          ?.covered &&
                                          errors.companyInsurancePolicies?.[
                                            index
                                          ]?.covered.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    {/* <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`companyInsurancePolicies.${index}.validity`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Validity is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Validity *"
                                            placeholder="Enter Validity"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.companyInsurancePolicies?.[index]
                                          ?.validity &&
                                          errors.companyInsurancePolicies?.[
                                            index
                                          ]?.validity.message}
                                      </FormHelperText>
                                    </FormControl> */}
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`companyInsurancePolicies.${index}.validity`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Validity is required",
                                        }}
                                        render={({ field }) => (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["DatePicker"]}
                                              fullWidth
                                            >
                                              <DatePicker
                                                label="Validity *"
                                                format="DD/MM/YYYY"
                                                value={
                                                  field.value || null
                                                }
                                                sx={{ width: "100%" }}
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(newValue) =>
                                                  handleDateFormat1(
                                                    newValue,
                                                    index
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                  />
                                                )}
                                                disabled={readyOnlyValue}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.companyInsurancePolicies?.[
                                          index
                                        ]?.validity &&
                                          errors.companyInsurancePolicies?.[
                                            index
                                          ]?.validity.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                    {
                                      getValues(`companyInsurancePolicies.${index}.insuranceDocName`) !== '' && <span>Upload Insurance Document <span className='text-danger'>*</span></span>
                                    }
                                    <div className="d-flex align-items-center">
                                      <div className='form-group my-1'>
                                        <Controller name={`companyInsurancePolicies.${index}.insuranceDoc`} control={control} defaultValue="" rules={{ required: "Document is Required" }}
                                          render={({ field }) =>

                                            <FileUploader
                                              {...field}
                                              handleChange={(e) => handleFileUpload(e,
                                                `companyInsurancePolicies.${index}.insuranceDoc`,
                                                `companyInsurancePolicies.${index}.insuranceDocName`,
                                                `companyInsurancePolicies.${index}.insuranceDocURL`,
                                                'file',
                                                'nosave'
                                              )}
                                              value={getValues(`companyInsurancePolicies.${index}.insuranceDoc`)}
                                              name="file"
                                              types={fileTypesAll}
                                              children={

                                                <div className={getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                  <center>
                                                    <p className={getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                    // style={{
                                                    //   backgroundColor: nonEditAble
                                                    //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                    //     : ''
                                                    // }}
                                                    >
                                                      <span>
                                                        <AttachFileIcon className="file-icon mr-2" />
                                                        {getValues(`companyInsurancePolicies.${index}.insuranceDocName`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                      </span>
                                                    </p>
                                                  </center>
                                                </div>
                                              }
                                              disabled={readyOnlyValue}
                                            // disabled={nonEditAble}
                                            />
                                          } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.companyInsurancePolicies?.[index]?.insuranceDoc && errors.companyInsurancePolicies?.[index]?.insuranceDoc.message}</FormHelperText>

                                      </div>
                                      <span className="pre-btn-dpiit"
                                       onClick={() => handleClickOpens(getValues(`companyInsurancePolicies.${index}.insuranceDocURL`))}
                                      ><VisibilityIcon className='icon-size mr-1' /><Link
                                       
                                        style={{ color: "#80809e" }}
                                      > Download
                                      </Link>
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                    <DeleteIcon
                                      sx={{
                                        backgroudColor: "#F4F4F4",
                                        fontSize: "24px",
                                        fill: "#808080",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        readyOnlyValue === false ? (
                                          deletecompanyInsurancePoliciesBtn(index)
                                        ) : (
                                          <></>
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addcompanyInsurancePoliciesBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}

                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen7}
                  onChange={() => setIsAccordionOpen7(!isAccordionOpen7)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Status of compliance under various laws
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {startupLabourLawFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {startupLabourLawFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            width: "3%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`startupLabourLaw.${index}.act`} // Track the property field
                                              control={control}
                                              defaultValue={field.act || ""} // Ensure default value is set
                                              rules={{ required: false }} // Make the field optional if needed
                                              render={({ field }) =>
                                                field.value && (
                                                  // If read-only, display the value inside a <span> but still track it
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      padding: "8px 0",
                                                    }}
                                                  >
                                                    {field.value || "N/A"}
                                                  </span>
                                                )
                                              }
                                            />

                                            <FormHelperText className="text-danger">
                                              {errors.startupLabourLaw?.[index]
                                                ?.act &&
                                                errors.startupLabourLaw[index]
                                                  .act.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <FormLabel>
                                              Whether obtained / not
                                            </FormLabel>
                                            <Controller
                                              name={`startupLabourLaw.${index}.obtained`}
                                              control={control}
                                              defaultValue=""
                                              render={({ field }) => (
                                                <RadioGroup
                                                  row
                                                  {...field}
                                                  onChange={(e) => {
                                                    handleLabourLawObtained(e.target.value, index)
                                                    // field.onChange(
                                                    //   e.target.value
                                                    // );
                                                    // if (
                                                    //   e.target.value === "false"
                                                    // ) {
                                                    //   setValue(
                                                    //     `startupLabourLaw.${index}.validity`,
                                                    //     ""
                                                    //   );
                                                    //   setValue(
                                                    //     `startupLabourLaw.${index}.status`,
                                                    //     ""
                                                    //   );
                                                    //   clearErrors(
                                                    //     `startupLabourLaw.${index}.validity`
                                                    //   );
                                                    //   clearErrors(
                                                    //     `startupLabourLaw.${index}.status`
                                                    //   );
                                                    // }
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Yes"
                                                  />
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    value="false"
                                                    control={<Radio />}
                                                    label="No"
                                                  />
                                                </RadioGroup>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.startupLabourLaw?.[index]
                                                ?.obtained &&
                                                errors.startupLabourLaw?.[index]
                                                  ?.obtained.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        {(getValues(`startupLabourLaw.${index}.obtained`) === "true" || getValues(`startupLabourLaw.${index}.obtained`) === true) && (
                                          <>
                                            <TableCell
                                              sx={{
                                                width: "20%",
                                                borderBottom: "none",
                                                padding: "5px",
                                              }}
                                            >
                                           <FormControl sx={{ width: "100%" }}>
                                                <Controller
                                                  name={`startupLabourLaw.${index}.validity`}
                                                  control={control}
                                                  defaultValue=""
                                                  rules={{
                                                    required:
                                                      "validity period is required",
                                                  }}
                                                  render={({ field }) => (
                                                    <LocalizationProvider
                                                      dateAdapter={AdapterDayjs}
                                                    >
                                                      <DemoContainer
                                                        components={["DatePicker"]}
                                                        fullWidth
                                                      >
                                                        <DatePicker
                                                          label="validity period *"
                                                          format="DD/MM/YYYY"
                                                          value={
                                                            field.value || null
                                                          }
                                                          sx={{ width: "100%" }}
                                                          inputFormat="DD/MM/YYYY"
                                                          onChange={(newValue) =>
                                                            handleDateFormat3(
                                                              newValue,
                                                              index
                                                            )
                                                          }
                                                          renderInput={(params) => (
                                                            <TextField
                                                              {...params}
                                                            />
                                                          )}
                                                          disabled={readyOnlyValue}
                                                        />
                                                      </DemoContainer>
                                                    </LocalizationProvider>
                                                  )}
                                                />
                                                <FormHelperText className="text-danger">
                                                  {errors.startupLabourLaw?.[
                                                    index
                                                  ]?.validity &&
                                                    errors.startupLabourLaw?.[
                                                      index
                                                    ]?.validity.message}
                                                </FormHelperText>
                                              </FormControl>
                                            </TableCell>

                                            <TableCell
                                              sx={{
                                                width: "20%",
                                                borderBottom: "none",
                                                padding: "5px",
                                              }}
                                            >
                                              <FormControl sx={{ width: "100%" }}>
                                                <Controller
                                                  name={`startupLabourLaw.${index}.status`}
                                                  control={control}
                                                  defaultValue=""
                                                  rules={{ required: 'Status of compliance is required' }}
                                                  // rules={{
                                                  //   validate: (value) => {
                                                  //     const isObtained = getValues(
                                                  //       `startupLabourLaw.${index}.obtained`
                                                  //     );
                                                  //     if (
                                                  //       isObtained === "true" &&
                                                  //       !value
                                                  //     ) {
                                                  //       return "Status of compliance is required";
                                                  //     }
                                                  //     return true;  
                                                  //   },
                                                  // }}
                                                  // render={({ field }) =>
                                                  // <TextField
                                                  //     id="outlined-multiline-static"
                                                  //     label="Status of compliance *"
                                                  //     placeholder='Enter Status of compliance'
                                                  //     fullwidth
                                                  //     {...field}
                                                  //     inputProps={
                                                  //         { readOnly: readyOnlyValue, }
                                                  //     }
                                                  // />}
                                                  render={({ field }) => {
                                                    // const isObtained = getValues(
                                                    //   `startupLabourLaw.${index}.obtained`
                                                    // );
                                                    return (
                                                      <TextField
                                                        id="outlined-multiline-static"
                                                        // label={
                                                        //   isObtained === "true"
                                                        //     ? "Status of compliance *"
                                                        //     : "Status of compliance"
                                                        // }

                                                        label={"Status of compliance *"}
                                                        placeholder="Status of compliance"
                                                        fullWidth
                                                        {...field}
                                                        inputProps={{
                                                          readOnly: readyOnlyValue,
                                                        }}
                                                      />
                                                    );
                                                  }}
                                                />
                                                <FormHelperText className="text-danger">
                                                  {errors.startupLabourLaw?.[index]
                                                    ?.status &&
                                                    errors.startupLabourLaw?.[index]
                                                      ?.status.message}
                                                </FormHelperText>
                                              </FormControl>
                                            </TableCell>
                                          </>)
                                        }
                                        {/* <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteStartupLabourLawBtn(index) : <></> }} />
                                                                                </TableCell> */}
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              {/* <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addStartupLabourLaw('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center> */}
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen11}
                  onChange={() => setIsAccordionOpen11(!isAccordionOpen11)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Business specific licenses/registrations and their applicability to the Company:                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <div className="col-12 mb-3">
                        <div>
                          {businessSpecificLicensesFields.length >= 0 && (
                            <>
                              {businessSpecificLicensesFields.map((field, index) => (
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index} spacing={3}>
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                  >
                                    {index + 1}
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`businessSpecificLicenses.${index}.name`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Name of License is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Name of License *"
                                            placeholder="Enter Name of License"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.businessSpecificLicenses?.[index]
                                          ?.name &&
                                          errors.businessSpecificLicenses?.[
                                            index
                                          ]?.name.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <FormLabel>
                                        Whether obtained / not
                                      </FormLabel>
                                      <Controller
                                        name={`businessSpecificLicenses.${index}.obtained`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Whether obtained is required"
                                        }}
                                        render={({ field }) => (
                                          <RadioGroup
                                            row
                                            name="position"
                                            {...field}
                                            onChange={(e) => {
                                              handleObtainedReport(e.target.value, index);
                                            }}
                                          >
                                            <FormControlLabel
                                              disabled={readyOnlyValue}
                                              defaultValue="null"
                                              value={'true'}
                                              control={<Radio />}
                                              label="Yes"

                                            />
                                            <FormControlLabel
                                              disabled={readyOnlyValue}
                                              defaultValue="null"
                                              value={'false'}
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.businessSpecificLicenses?.[
                                          index
                                        ]?.obtained &&
                                          errors.businessSpecificLicenses?.[
                                            index
                                          ]?.obtained.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  {(getValues(`businessSpecificLicenses.${index}.obtained`) === "true" ||
                                    getValues(`businessSpecificLicenses.${index}.obtained`) === true) && (
                                      <>
                                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                          {/* <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`businessSpecificLicenses.${index}.validityPeriod`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Validity Period is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Validity Period *"
                                                  placeholder="Enter Validity Period"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.businessSpecificLicenses?.[index]
                                                ?.validityPeriod &&
                                                errors.businessSpecificLicenses?.[
                                                  index
                                                ]?.validityPeriod.message}
                                            </FormHelperText>
                                          </FormControl> */}
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`businessSpecificLicenses.${index}.validityPeriod`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Validity Period is required",
                                              }}
                                              render={({ field }) => (
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                    fullWidth
                                                  >
                                                    <DatePicker
                                                      label="Validity Period *"
                                                      format="DD/MM/YYYY"
                                                      value={
                                                        field.value || null
                                                      }
                                                      sx={{ width: "100%" }}
                                                      inputFormat="DD/MM/YYYY"
                                                      onChange={(newValue) =>
                                                        handleDateFormat(
                                                          newValue,
                                                          index
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                      disabled={readyOnlyValue}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.businessSpecificLicenses?.[
                                                index
                                              ]?.validityPeriod &&
                                                errors.businessSpecificLicenses?.[
                                                  index
                                                ]?.validityPeriod.message}
                                            </FormHelperText>
                                          </FormControl>

                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`businessSpecificLicenses.${index}.statusOfCompliance`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Status Of Compliance is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Status Of Compliance *"
                                                  placeholder="Enter Status Of Compliance"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.businessSpecificLicenses?.[index]
                                                ?.statusOfCompliance &&
                                                errors.businessSpecificLicenses?.[
                                                  index
                                                ]?.statusOfCompliance.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </Grid>
                                      </>)}
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                    <DeleteIcon
                                      sx={{
                                        backgroudColor: "#F4F4F4",
                                        fontSize: "24px",
                                        fill: "#808080",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        readyOnlyValue === false ? (
                                          deletebusinessSpecificLicensesBtn(index)
                                        ) : (
                                          <></>
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addbusinessSpecificLicensesBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}

                        </div>
                      </div>
                    </>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen8}
                  onChange={() => setIsAccordionOpen8(!isAccordionOpen8)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please update the status of the following and also share copies of the following policies:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {startupPolicyFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {startupPolicyFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          width: "3%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        {index + 1}
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          width: "10%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "80%" }}>
                                          <Controller
                                            name={`startupPolicy.${index}.name`} // Track the property field
                                            control={control}
                                            defaultValue={field.name || ""} // Ensure default value is set
                                            rules={{ required: false }} // Make the field optional if needed
                                            render={({ field }) =>
                                              field.value && (
                                                // If read-only, display the value inside a <span> but still track it
                                                <span
                                                  style={{
                                                    display: "block",
                                                    padding: "8px 0",
                                                  }}
                                                >
                                                  {field.value || "N/A"}
                                                </span>
                                              )
                                            }
                                          />

                                          <FormHelperText className="text-danger">
                                            {errors.startupPolicy?.[index]
                                              ?.name &&
                                              errors.startupPolicy[index].name
                                                .message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "18%",
                                          borderBottom: "none",
                                          padding: "5px",
                                        }}
                                      >
                                        <FormControl sx={{ width: "80%" }}>
                                          <Controller
                                            name={`startupPolicy.${index}.status`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Status (Available /Not)"
                                                placeholder="Enter Status "
                                                fullwidth
                                                {...field}
                                                inputProps={{
                                                  readOnly: readyOnlyValue,
                                                }}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.startupPolicy?.[index]
                                              ?.status &&
                                              errors.startupPolicy?.[index]
                                                ?.status.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      {/* <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteStartupPolicyBtn(index) : <></> }} />
                                                                                </TableCell> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              {/* <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addStartupPolicy('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center> */}
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button
                variant="contained"
                onClick={handleBackapp}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
        {/* <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        /> */}
      </section>
    </>
  );
};

export default PreliminaryDocumentPart1;
