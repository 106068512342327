import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material';
import React, { useContext, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { FiAlertCircle } from "react-icons/fi";
import { FcInfo } from "react-icons/fc";
import notifyService from '../../api/notifySerivce';
import apiService from '../../api/apiService';
import DataContext from '../../context/DataContext';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import CloseIcon from "@mui/icons-material/Close";
const CommentList = ({ idNumber, startupCheckList }) => {
    const { setPageLoading, tableIndexValue } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, getValues, setValue, clearErrors, reset, control, formState: { errors } } = useForm(
        { values: formData, }
    );
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const timeoutRef = useRef(null);

    const handleOpenModal = () => {
        clearTimeout(timeoutRef.current); // Clear any pending close event
        setOpenModal(true);
    };
    const isWhitespace = (value) => {
        return /^\s*$/.test(value);
    };
    const handleCloseModal = () => {
        timeoutRef.current = setTimeout(() => {
            setOpenModal(false);
        }, 300); // Delay closing so user has time to move cursor
    };
    const modalOpen = () => {
        setOpenModal2(true)
    }
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useDidMountEffect(() => {
        commentList()
    }, [page, rowsPerPage,]);
    const [tableData, settableData] = useState([])
    const commentList = () => {
        settableData([])
        setPageLoading(true)
        var id = Number(idNumber)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            startupId: id,
        }
        apiService('partner/scst/startup/current/stage/list', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setCount(res.data.count)
                if (res.data.startupCurrentStageDTOList) {
                    settableData(res.data.startupCurrentStageDTOList)
                }
            }
        })
    }
    const handleCloseModal2 = () => {
        setOpenModal2(false)
        reset()
        // setCount(0)
        setPage(0)
        setRowsPerPage(5)
    }
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const onSubmit = async (data) => {
        if (isSubmitting) return;  // Prevent multiple calls
        setIsSubmitting(true);

        try {
            setLoading(true);

            let getform = { ...data };
            getform.startupId = Number(idNumber);
            debugger
            const res = await apiService('partner/scst/startup/current/stage/save', getform, 'post');

            if (res?.data?.responseMessage === "Success") {
                notifyService('success', 'Success', 'Updated successfully !!');
                reset();
                setValue('comment', '')
                commentList()
            }
        } catch (error) {
            console.error("Error submitting form", error);
        } finally {
            setLoading(false);
            setIsSubmitting(false);  // Reset after API call
        }
    };
    const [isFullTextVisible, setIsFullTextVisible] = useState({})
    const toggleText = (index) => {
        debugger
        setIsFullTextVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
        debugger
    };
    const [isFullTextVisible2, setIsFullTextVisible2] = useState({})
    const toggleText2 = (index) => {
        setIsFullTextVisible2(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const truncateTexts = (text, charLimit) => {
        if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
        return text.length > charLimit ? text.substring(0, charLimit) + ".." : text;
    };
    return (
        <>
            <div>
                <button
                    className="viewdetails-Approve mb-1 mt-1"
                    onClick={() => modalOpen()}
                >
                    Update Current Progress
                </button>
                <Tooltip title="View">
                <span className='circle-font ml-2 c-pointer'
                onClick={handleOpenModal}
                >
                       
                        <FcInfo />
                      
                    
                </span>
                </Tooltip>
            </div>

            {/* <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                maxWidth={"xs"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ol className='text-dark'>
                            <li>This will Add your Comment</li>
                            <li>Once all question / fields have been Marked as Reviewed Submit Report option will be enable</li>
                        </ol>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={() => setOpenModal(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog
    open={openModal}
    onClose={() => setOpenModal(false)}
    maxWidth="xs"
    fullWidth
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle>
        <IconButton
            aria-label="close"
            onClick={() => setOpenModal(false)}
            sx={{ position: "absolute", right: 8, top: 8, color:'red'}}
        >
            <CloseIcon />
        </IconButton>
    </DialogTitle>
    
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            <ol className="text-dark">
                <li>This will add your comment.</li>
                <li>Once all questions/fields have been marked as reviewed, the "Submit Report" option will be enabled.</li>
            </ol>
        </DialogContentText>
    </DialogContent>
</Dialog>

            <Dialog
                open={openModal2}
                onClose={handleCloseModal2}
                maxWidth={"md"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
  <DialogTitle>
        <IconButton
            aria-label="close"
            onClick={() => setOpenModal2(false)}
            sx={{ position: "absolute", right: 8, top: 8, color:'red'}}
        >
            <CloseIcon />
        </IconButton>
    </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {(startupCheckList.status === 5 || startupCheckList.status === 8) && (
                            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                                <h4>Current Progress:</h4>
                                <div className='row align-item-center p-3'>


                                    <div className='col-lg-8 col-md-6 col-12 mt-3'>

                                        <Controller name="comment" control={control} defaultValue="" rules={{
                                            required: 'comment is requried',
                                            validate: {
                                                noWhitespace: (value) =>
                                                    !isWhitespace(value) || "Whitespace not allowed",
                                            },
                                        }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Comments *"
                                                    placeholder='Enter Comments'
                                                    fullWidth
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.comment && errors.comment.message}</FormHelperText>

                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 mt-3'>

                                        <Controller name="stage" control={control} defaultValue="" rules={{
                                            required: false,
                                            
                                        }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Stage "
                                                    placeholder='Enter Stage'
                                                    fullWidth
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.stage && errors.stage.message}</FormHelperText>

                                    </div>
                                    <div className='col-lg-12 col-md-6 col-12 text-center mt-2'>
                                        <Button
                                            type="submit"
                                            loading={loading}
                                            loadingPosition="start"
                                            variant="contained"
                                        >
                                            <span>Save</span>
                                        </Button>
                                    </div>

                                    {/* {
               getValues(`startupComments`) && (
                   <div className='col-lg-12 col-12 mt-3'>
                       <p>Comments from Startups : <b>{getValues(`startupComments`)}</b></p>
                   </div>
               )
           } */}
                                </div>
                            </form>

                        )}

                        <div style={{ placeItems: 'center' }}>
                            {tableData.length ? (
                                <TableContainer component={Paper} className="table-container">
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" className="table-header">
                                                    S.No
                                                </TableCell>
                                                <TableCell align="center" className="table-header">
                                                    Comments
                                                </TableCell>
                                                <TableCell align="center" className="table-header">
                                                    Stage
                                                </TableCell>
                                                <TableCell align="center" className="table-header">
                                                    Phase
                                                </TableCell>
                                                <TableCell align="center" className="table-header">
                                                   Last Updated
                                                </TableCell>
                                               
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableData && tableData.map((data, i) => (
                                                <TableRow className="table-row">
                                                    <TableCell align="center" className="table-cell">
                                                        {
                                                            tableIndexValue(rowsPerPage, page, i)
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center" sx={{width:'300px'}}>
                                                        {data.comment ? (
                                                            <>
                                                                {isFullTextVisible[i]
                                                                    ? data.comment
                                                                    : truncateTexts(data.comment || '', 15)}

                                                                {data.comment && data.comment.length > 15 && (
                                                                    <h6
                                                                        onClick={() => toggleText(i)}
                                                                        style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                    >
                                                                        {isFullTextVisible[i] ? " Read Less" : " Read More"}
                                                                    </h6>
                                                                )}
                                                            </>) : ((`-`))}
                                                    </TableCell>
                                                   
                                                    <TableCell align='center'>
                                                        {data.stage ? (
                                                            <>
                                                                {isFullTextVisible2[i] ? data.stage : truncateTexts(data.stage || '', 8)}
                                                                {data.stage && data.stage.length > 8 && (<h6
                                                                    onClick={() => toggleText2(i)}
                                                                    style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                >
                                                                    {isFullTextVisible2[i] ? " Read Less" : " Read More"}
                                                                </h6>)}
                                                            </>) : ((`-`))}
                                                    </TableCell>
                                                    <TableCell align="center" className="table-cell">
                                                        {data.phase}
                                                    </TableCell>
                                                    <TableCell align="center" className="table-cell">
                                                        {data.dateTime ?? "-"}
                                                    </TableCell>

                                                    
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <caption>
                                            {/* <TablePagination
                                                component="div"
                                                count={count}
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                style={{ float: 'left' }}
                                            /> */}
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    colSpan={3}
                                                    count={count}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    slotProps={{
                                                        select: {
                                                            inputProps: {
                                                                'aria-label': 'rows per page',
                                                            },
                                                            native: true,
                                                        },
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </caption>
                                    </Table>
                                </TableContainer>
                            ) : (<>
                                <div className="nodata_tag">
                                    <img
                                        src="/images/Tanfundlogo-black-1.png"
                                        className="no_data_logo"
                                        alt=""
                                    />
                                    <h4>No Records Found</h4>
                                </div>
                            </>)}
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </>
    )
}

export default CommentList