import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormHelperText,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import PropTypes from 'prop-types';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataContext from "../../context/DataContext";
import { Link, useParams } from "react-router-dom";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import apiService from "../../api/apiService";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Scsttab from "./Scsttab";
import { Stack } from "@mui/material";
// import Validatetab from './Validatetab';
import Scoremodal from "./Scoremodal";
import CloseIcon from "@mui/icons-material/Close";
// import Startuplistfilter from './Startuplistfilter';
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Startuplistfilter from "../investor-module/associated-startups-page/associated-details-page/Startuplistfilter";
import { AiFillSafetyCertificate } from "react-icons/ai";
import Viewimage from "../../api/Viewimage";
import CommonViewPitchDeck from "../common-page/CommonViewPitchDeck";
import BDDSubmitReport from "./BDDSubmitReport";
import ViewAllReports from "./view-reports/ViewAllReports";
import notifyService from "../../api/notifySerivce";
import IcPscApprovel from "./ic-psc-approvel/IcPscApprovel";
import { Controller, useForm } from "react-hook-form";
import Scsthubavpremarks from "../scsthubavp-module/scsthubavp-details/Scsthubavpremarks";
import CommentsModal from "./CommentsModal";
import CommentList from "./CommentList";
import { MuiFileInput } from "mui-file-input";
const ScstDetails = ({ path }) => {
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    getValues,
    control,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ values: formData });
  const roles = localStorage.getItem("role");
  const mentorNav = localStorage.getItem("mentorNav");
  const { navigator, setPageLoading, getSCSTStartupStatus, tableIndexValue } =
    useContext(DataContext);
  const handleBack = () => {
    if (path === "validation") {
      navigator(`/scst/startups/validation`);
    } else if (roles === "MENTORSHIP") {
      if (mentorNav === "current") {
        navigator(`/scstmentorship/startups/list/current`);
      } else {
        navigator(`/scstmentorship/startups/list/previous`);
      }
    } else {
      navigator(`/${path}/startups/list`);
    }
  };
  const [startupGetData, setStartupGetData] = useState({});
  const { id } = useParams();
  const { startupId } = useParams();
  let idNumber = atob(startupId);
  let partnerOrInvestorId = atob(id);
  const [startupCheckList, setStartupCheckList] = useState([]);
  useDidMountEffect(() => {
    // eslint-disable-next-line
    viewStartupDetails(idNumber, partnerOrInvestorId);
    listApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useDidMountEffect(() => {
    showStartupValue();
  }, []);
  const [meetingIndex, setMeetingIndex] = useState(1);
  const [progressIndex, setProgressIndex] = useState(1);
  const [progressIndex1, setProgressIndex1] = useState(1);
  // Only for Mentorship
  const [meetingMentorIndex, setMeetingMentorIndex] = useState(1);
  const [progressMentorIndex, setProgressMentorIndex] = useState(1);
  const [progressMentorIndex1, setProgressMentorIndex1] = useState(1);
  // only for BDD
  const [meetingBddIndex, setMeetingBddIndex] = useState(1);
  const [docmntRmBddIndex, setDocmntRmBddIndex] = useState(1);
  const [docmntRmMentorIndex, setDocmntRmMentorIndex] = useState(1);
  const [docmntRmlddandfddIndex, setDocmntRmlddandfddIndex] = useState(1);
  const [progressBddIndex, setProgressBddIndex] = useState(1);
  const [progressBddIndex1, setProgressBddIndex1] = useState(1);
  const [startupValue, setStartupValue] = useState({});
  const [scstAdminAllRemarks, setScstAdminAllRemarks] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useDidMountEffect(() => {
    AdminRemark()
  }, [page, rowsPerPage]);
  const AdminRemark = () => {
    setPageLoading(true);
    setScstAdminAllRemarks([])
    var req = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      startupId: idNumber,

    };
    apiService("partner/scst/admin/mentorship/remarks/list", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setCount(result.data.count);
          if (result.data.adminRemarksList) {
            setScstAdminAllRemarks(result.data.adminRemarksList);
          }
        }
      })
      .catch((err) => { });
  }
  const [scstMentorAllRemarks, setScstMentorAllRemarks] = useState([]);
  const [countMentor, setCountMentor] = useState(0);
  const [pageMentor, setPageMentor] = useState(0);
  const [rowsPerPageMentor, setRowsPerPageMentor] = useState(10);
  const handleChangePageMentor = (event, newPage) => {
    setPageMentor(newPage);
  };
  const handleChangeRowsPerPageMentor = (event) => {
    setRowsPerPageMentor(parseInt(event.target.value, 10));
    setPageMentor(0);
  };
  useDidMountEffect(() => {
    MentorRemark()
  }, [pageMentor, rowsPerPageMentor]);
  const MentorRemark = () => {
    setPageLoading(true);
    setScstMentorAllRemarks([])
    var req = {
      listSize: rowsPerPageMentor,
      pageNumber: pageMentor + 1,
      startupId: idNumber,

    };
    apiService("mentorship/scst/remarks/list", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setCountMentor(result.data.count);
          if (result.data.mentorRemarksList) {
            setScstMentorAllRemarks(result.data.mentorRemarksList);
          }
        }
      })
      .catch((err) => { });
  }

  const viewStartupDetails = async () => {
    setPageLoading(true);
    setNavTabValue("1");
    setStartupGetData({});
    setStartupValue({});
    // setScstAdminAllRemarks([])
    apiService(`partner/startups/get?id=${idNumber}`, "", "get").then((res) => {
      if (res) {
        if (res.data) {
          if (res.data.applicationForm) {
            setStartupGetData(res.data.applicationForm);
          }

          setStartupValue(res.data);

          // if (res.data.showRounds === true) {
          //     roundPartnerList()
          // }

          var startupValue = res.data;
          if(startupValue.status == 32){
             // only for ldd and fdd 
            setDocmntRmlddandfddIndex(7)
            setMeetingIndex(8);
            setProgressIndex(9)
            setProgressIndex1(10)

       // only for Mentor
            setDocmntRmMentorIndex(10)
            setMeetingMentorIndex(11)
            // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if(startupValue.status == 31){
             // only for ldd and fdd 
            setDocmntRmlddandfddIndex(6)
            setMeetingIndex(7);
            setProgressIndex(8)
            setProgressIndex1(9)

       // only for Mentor
            setDocmntRmMentorIndex(10)
            setMeetingMentorIndex(11)
            // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if (startupValue.status === 29 || startupValue.status === 30) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(5)
            setMeetingIndex(6);
            setProgressIndex(7)
            setProgressIndex1(8)
            // only for Mentor
            setDocmntRmMentorIndex(5)
            setMeetingMentorIndex(6)
            // setProgressMentorIndex(6)
            // setProgressMentorIndex1(7)
            // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if (startupValue.status >= 28) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(3)
            setMeetingIndex(4);
            setProgressIndex(5);
            setProgressIndex1(6);
            // only for Mentor
            setDocmntRmMentorIndex(3)
            setMeetingMentorIndex(4)
               // only for Bdd,ic,psc,
            setDocmntRmBddIndex(3)
            setMeetingBddIndex(4)
            setProgressBddIndex(5)
            setProgressBddIndex1(6)
          }
          else if (startupValue.status >= 26) {

            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(7)
            setMeetingIndex(8);
            setProgressIndex(9)
            setProgressIndex1(10)
            // only for Mentor
            setDocmntRmMentorIndex(10)
            setMeetingMentorIndex(11)
            // setProgressMentorIndex(9)
            // setProgressMentorIndex1(10)
             // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if (startupValue.status == 24) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(7)
            setMeetingIndex(8);
            setProgressIndex(9)
            setProgressIndex1(10)
            // only for Mentor
            setDocmntRmMentorIndex(9)
            setMeetingMentorIndex(10)
             // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if (startupValue.status >= 23) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(6)
            setMeetingIndex(7);
            setProgressIndex(8)
            setProgressIndex1(9)
            // only for Mentor
            setDocmntRmMentorIndex(9)
            setMeetingMentorIndex(10)
            // setProgressMentorIndex(9)
            // setProgressMentorIndex1(10)
             // only for Bdd,ic,psc,
            setDocmntRmBddIndex(6)
            setMeetingBddIndex(7)
            setProgressBddIndex(8)
            setProgressBddIndex1(9)
          }
          else if (startupValue.status >= 21) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(6)
            setMeetingIndex(7);
            setProgressIndex(8)
            setProgressIndex1(9)

            // only for Mentor
            setDocmntRmMentorIndex(9)
            setMeetingMentorIndex(10)
            // setProgressMentorIndex(10)
            // setProgressMentorIndex1(11)
            // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if (startupValue.status >= 20) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(6)
            setMeetingIndex(7);
            setProgressIndex(8)
            setProgressIndex1(9)
            // only for Mentor
            setDocmntRmMentorIndex(9)
            setMeetingMentorIndex(10)
            // setProgressMentorIndex(9)
            // setProgressMentorIndex1(10)
            // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          } else if (startupValue.status >= 19) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(5)
            setMeetingIndex(6);
            setProgressIndex(7)
            setProgressIndex1(8)
            // only for Mentor
            setDocmntRmMentorIndex(7)
            setMeetingMentorIndex(8)
            // setProgressMentorIndex(8)
            // setProgressMentorIndex1(9)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if (startupValue.status >= 17) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(5)
            setMeetingIndex(6);
            setProgressIndex(7)
            setProgressIndex1(8)
            // only for Mentor
            setDocmntRmMentorIndex(7)
            setMeetingMentorIndex(8)
            // setProgressMentorIndex(8)
            // setProgressMentorIndex1(9)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          } else if (startupValue.status == 16) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(3)
            setMeetingIndex(4);
            setProgressIndex(5)
            setProgressIndex1(6)
            // only for Mentor
            setDocmntRmMentorIndex(5)
            setMeetingMentorIndex(6)
            // setProgressMentorIndex(6)
            // setProgressMentorIndex1(7)
               // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          } else if (startupValue.status >= 10) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(5)
            setMeetingIndex(6);
            setProgressIndex(7)
            setProgressIndex1(8)
            // only for Mentor
            setDocmntRmMentorIndex(5)
            setMeetingMentorIndex(6)
            // setProgressMentorIndex(8)
            // setProgressMentorIndex1(9)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          }
          else if (startupValue.status == 9) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(4)
            setMeetingIndex(5);
            setProgressIndex(6)
            setProgressIndex1(7)
            // only for Mentor
            setDocmntRmMentorIndex(4)
            setMeetingMentorIndex(5)
            // setProgressMentorIndex(8)
            // setProgressMentorIndex1(9)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(4)
            setMeetingBddIndex(5)
            setProgressBddIndex(6)
            setProgressBddIndex1(7)
          }
          else if (startupValue.status >= 8) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(5)
            setMeetingIndex(6);
            setProgressIndex(7)
            setProgressIndex1(8)
            // only for Mentor
            setDocmntRmMentorIndex(5)
            setMeetingMentorIndex(6)
            // setProgressMentorIndex(8)
            // setProgressMentorIndex1(9)
               // only for Bdd,ic,psc,
            setDocmntRmBddIndex(5)
            setMeetingBddIndex(6)
            setProgressBddIndex(7)
            setProgressBddIndex1(8)
          } else if (startupValue.status >= 5) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(4)
            setMeetingIndex(5);
            setProgressIndex(6)
            setProgressIndex1(7)
            // only for Mentor
            setDocmntRmMentorIndex(4)
            setMeetingMentorIndex(5)
            // setProgressMentorIndex(7)
            // setProgressMentorIndex1(8)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(4)
            setMeetingBddIndex(5)
            setProgressBddIndex(6)
            setProgressBddIndex1(7)
          } else if (startupValue.status >= 3) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(3)
            setMeetingIndex(4);
            setProgressIndex(5)
            setProgressIndex1(6)
            // only for Mentor
            setDocmntRmMentorIndex(3)
            setMeetingMentorIndex(4)
            // setProgressMentorIndex(4)
            // setProgressMentorIndex1(5)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(3)
            setMeetingBddIndex(4)
            setProgressBddIndex(5)
            setProgressBddIndex1(6)
          } else if (startupValue.status >= 1) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(3)
            setMeetingIndex(4);
            setProgressIndex(5)
            setProgressIndex1(6)
            // only for Mentor
            setDocmntRmMentorIndex(5)
            setMeetingMentorIndex(6)
            // setProgressMentorIndex(6)
            // setProgressMentorIndex1(7)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(3)
            setMeetingBddIndex(4)
            setProgressBddIndex(5)
            setProgressBddIndex1(6)
          } else if (startupValue.status == 0) {
            // only for ldd and fdd 
            setDocmntRmlddandfddIndex(2)
            setMeetingIndex(3);
            setProgressIndex(4)
            setProgressIndex1(5)
            // only for Mentor
            setDocmntRmMentorIndex(2)
            setMeetingMentorIndex(3)
            // setProgressMentorIndex(5)
            // setProgressMentorIndex1(6)
              // only for Bdd,ic,psc,
            setDocmntRmBddIndex(2)
            setMeetingBddIndex(3)
            setProgressBddIndex(4)
            setProgressBddIndex1(5)
          }
        } else {
          setStartupGetData({});
        }
      }
      setPageLoading(false);
    });
  };

  const [comments, setComments] = useState([]);
  const showStartupValue = async () => {
    // setStartupCheckList([])
    apiService(
      `startup/clarifications/status/get?id=${idNumber}`,
      "",
      "get"
    ).then((res) => {
      setStartupCheckList([]);
      if (res) {
        if (res.data) {
          if (res.data && res.data.reportComments) {
            setComments(res.data.reportComments);
          }
          setStartupCheckList(res.data);
        }
      }
    });
  };
  const [navTabValue, setNavTabValue] = useState("1");
  const handleNavTabChange = (event, newValue) => {
    setNavTabValue(newValue);
  };
  const [openList, setOpenList] = React.useState(false);
  const handleStartupList = () => {
    setOpenList(true);
  };

  const handleClose = (value) => {
    setOpenList(false);
  };

  const isRegistered = startupGetData.dpiitRegistered;

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  const [listData, setListData] = useState({});
  const listApi = () => {
    setPageLoading(true);
    var req = {
      partnerOrInvestorId: partnerOrInvestorId,
      startupId: idNumber,
    };
    apiService("partner/scst/admin/startup/get", req, "post")
      .then((result) => {
        if (result) {
          if (result.data.startupRounds) {
            setListData(result.data.startupRounds[1]);
          }
        }
        setPageLoading(false);
      })
      .catch((err) => { });
  };
  const [openReportImage, setOpenReportImage] = useState(false);
  const [viewReportImage, setViewReportImage] = useState("");
  const handleClickOpenImage = (value) => {
    setViewReportImage(value);
    setOpenReportImage(true);
  };
  const handleCloseImage = () => {
    setOpenReportImage(false);
  };
  const onStatusUpdate = (data) => {
    setPageLoading(true);
    var getform = {
      status: data,
      startupId: idNumber,
    };
    apiService("partner/scst/status/save", getform, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          viewStartupDetails(idNumber, partnerOrInvestorId);
        }
      }
    });
  };
  const [openView, setOpenView] = useState(false);
  const handleViewOpen = () => {
    setOpenView(true);
  };
  const handleViewClose = () => {
    setOpenView(false);
  };
  let roundDet;
  const handledownloadpdf = () => {
    setPageLoading(true);
    // if (roles === "SCSTHUB") {
    //   roundDet = "hub";
    // } else if (roles === "BDD") {
    //   roundDet = "bdd";
    // } else if (roles === "LDD") {
    //   roundDet = "ldd";
    // } else if (roles === "FDD") {
    //   roundDet = "fdd";
    // }
    var req = {
      id: idNumber,
      // round: roundDet,
    };

    apiService("partner/export/scst/startup/application", req, "post")
      .then((res) => {
        setPageLoading(false);
        if (res.data && res.data.responseStatus === "Success") {
          notifyService("success", "Success", "PDF sent to the email");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMentorApprove = () => {
    setOpenReport(true);
  };
  const [openReport, setOpenReport] = useState(false);
  const handleReportClose = () => {
    setOpenReport(false);
    clearErrors("comments", '');
    setValue("comments", '');
    clearErrors("document", '');
    clearErrors("documentname", '');
    setAdditionalDocValue(null);
    setAdditionalDocURL('');
  };
  const onSubmit = (data) => {

    var req = {
      startupId: idNumber,
      status: 1,
      comments: data.comments,
      document: data.document,
      documentname: data.documentname
    };
    debugger;
    setPageLoading(true);
    apiService("mentorship/scst/status/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          setOpenReport(false);
          reset();
          listApi();
          AdminRemark()
          MentorRemark()
          showStartupValue();
          viewStartupDetails();
        }
      }
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const handleCommentsopen = () => {
    setOpenModal(true);
  };
  // const handleClickDownload = () => {
  //   apiService(`startup/pitchdeckurl/get?startupid=${idNumber}`, "", "get")
  //     .then((result) => {
  //       if (result?.data?.pitchDeckName) {
  //         const link = document.createElement("a");
  //         link.href = result.data.pitchDeckName;
  //         link.target = "_blank";
  //         link.rel = "noopener noreferrer";
  //         link.setAttribute("download", "Pitchdeck");
  //         document.body.appendChild(link);
  //         link.click();
  //       } else {
  //         notifyService("info", "File Not Found", "PitchDeck file not found");
  //       }
  //     })
  //     .catch((err) => { });
  // };
  const handleClickDownload = (data) => {
    const pdfUrl = `data:application/pdf;base64,${data}`;
    // Create an anchor element for downloading
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Pitchdeck.pdf";
    document.body.appendChild(link);
    // Trigger download
    link.click();
    // Cleanup
    document.body.removeChild(link);
  };
  const [isFullTextVisible, setIsFullTextVisible] = useState({})
  const toggleText = (index) => {
    debugger
    setIsFullTextVisible(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    debugger
  };
  const truncateTexts = (text, charLimit) => {
    if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
    return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
  };
  const [remarks, setRemarks] = useState(0);
  const handleOpenModal4 = (val) => {
    setOpenModal4(true);
    setRemarks(0)
  }
  const [openModal4, setOpenModal4] = useState(false);

  const handleCloseModal4 = () => {
    setOpenModal4(false)
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



  const handleChange = (event, newValue) => {
    setRemarks(newValue);
  };
  const [additionalDocURL, setAdditionalDocURL] = useState(null)
  const [additionalDocValue, setAdditionalDocValue] = useState(null)
  let logoselecetdFile
  const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
    debugger
    if (event) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 10 * 1024 * 1024;
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type
          var imagedatatype = imagetype.split("/")
          var img_crt_type = imagedatatype[1]
          if (img_crt_type === "pdf") {
            setValue(filekey, "")
            setValue(fileName, "")
            setdata('')
            setUrl('')
            if (event.target === undefined) {
              setdata(event)
            } else {
              setdata(event.target.files[0])
            }
            var fileValue = logoselecetdFile
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(',')
              var ImageValue = spl[1]
              var img_name = fileValue.name
              setUrl(logourl1)
              setValue(filekey, ImageValue)
              setValue(fileName, img_name)
              clearErrors(filekey);
              clearErrors(fileName);
              debugger
            }

          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 10MB",
            "Please check your file size"
          );
        }
      }
    }
  }
  return (
    <div className="box-show">
      <section className="bg-white">
        <div className="row">

          <div className="col-lg-12 p-0">
            <div className="p-relative">
              <div className="p-3">
                <div className="row">
                  <div className="col-lg-10">
                    <div className="dflex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div>
                          <Button onClick={handleBack}>
                            <ArrowBackIcon />
                          </Button>
                        </div>
                        <img
                          src={startupGetData.startupLogoName}
                          className="img-fluid user_logos"
                          alt=""
                        />
                        <div className="content">
                          <div className="d-flex align-items-center flex-wrap mb-3">
                            <h3 className="mr-3 mb-0" style={{ wordBreak: 'break-word' }}>
                              {startupValue.startupName} ({startupValue.applicationNo})
                            </h3>
                            <button
                              className={`dpi-button ${isRegistered ? "register-btn" : "not-register-btn"
                                }`}
                            >
                              {isRegistered && (
                                <AiFillSafetyCertificate className="icon" />
                              )}
                              {isRegistered
                                ? "DPIIT Recognised"
                                : "DPIIT Not Registered"}
                            </button>
                            <button
                              // className="viewdetails-surpitch"
                              className="downloaddetails-surpitch"
                              onClick={() => {
                                handleClickOpen(startupGetData.pitchDeck);
                              }}
                            >
                              View PitchDeck
                            </button>
                            <button
                              className="downloaddetails-surpitch"
                              onClick={() => {
                                handleClickDownload(startupGetData.pitchDeck);
                              }}
                            >
                              Download PitchDeck
                            </button>
                            {(roles === "SCSTHUB" ||
                              roles === "BDD" ||
                              roles === "FDD" ||
                              roles === "LDD" || roles === "SCSTADMIN" || roles === "PSC" || roles === "IC" || roles === "MENTORSHIP") && (
                                <div>
                                  <button
                                    className="downloaddetails-surpitch"
                                    onClick={() => handledownloadpdf()}
                                  >
                                    Export as PDF
                                  </button>
                                </div>
                              )}
                          </div>

                          <span className="black-clr-sur">
                            <img src="/images/phone.png" alt="" className="mr-2" />{" "}
                            {startupValue.startupPhone
                              ? startupValue.startupPhone
                              : "-"}
                          </span>
                          <span className="black-clr-sur ml-3">
                            <img src="/images/email.png" alt="" className="mr-2" />{" "}
                            {startupValue.startupEmail
                              ? startupValue.startupEmail
                              : "-"}
                          </span>
                          <span className="black-clr-sur ml-3">
                            Member Since : {startupValue.date}
                          </span>
                          <div className="mt-3">
                            Current Status :{" "}
                            <span className="status_startup">
                              {getSCSTStartupStatus(startupCheckList.status)}

                            </span>
                            <div className="row" style={{ marginLeft: '0px' }}>
                              <div >
                                {(path === "scstbdd" && [5, 6, 7, 8, 9, 10].includes(startupCheckList.status)) && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <div className="mt-2">
                                        <span>Actions: {""}</span>
                                        <img src="/images/img/gif right hand.gif" alt="hand-icon" className="hand-icon" />
                                        {(path === "scstbdd" && startupCheckList.status === 5 || startupCheckList.status === 8) && (
                                          <>
                                            {path === "scstbdd" && (
                                              <>
                                                {startupCheckList.bddSubmitButton === true && (
                                                  <BDDSubmitReport
                                                    idNumber={idNumber}
                                                    viewStartupDetails={viewStartupDetails}
                                                    showStartupValue={showStartupValue}
                                                    lbl="BDD First Connect"
                                                    status={6}
                                                  />
                                                )}
                                                {startupCheckList.bddDeepDiveSubmitButton === true && (
                                                  <BDDSubmitReport
                                                    idNumber={idNumber}
                                                    viewStartupDetails={viewStartupDetails}
                                                    showStartupValue={showStartupValue}
                                                    lbl="BDD Deep Dive"
                                                    status={10}
                                                  />
                                                )}


                                              </>
                                            )}
                                          </>
                                        )
                                        }
                                      </div>
                                      <div className="ml-3">
                                        <CommentList idNumber={idNumber} startupCheckList={startupCheckList} />
                                      </div>
                                    </div>
                                  </>
                                )
                                }
                                {(path === "scstmentorship" && [4, 9, 12, 14, 19, 23, 30].includes(startupCheckList.status)) && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <div className="mt-2">
                                        <span>Actions: {""}</span>
                                        <img src="/images/img/gif right hand.gif" alt="hand-icon" className="hand-icon" />
                                        {roles === "MENTORSHIP" &&
                                          [4, 9, 12, 14, 19, 23, 30].includes(startupCheckList.status) && (
                                            <button
                                              className="viewdetails-Approve mt-1"
                                              onClick={() => handleMentorApprove()}
                                            >
                                              Approve
                                            </button>
                                          )}

                                      </div>
                                    </div>
                                  </>

                                )}





                                <div className="d-flex align-items-center flex-wrap">
                                  {((path === "ic" && startupCheckList.status === 11) || (path === "psc" && startupCheckList.status === 13)) && (
                                    <>
                                      <span>Actions: {""}</span>
                                      <img src="/images/img/gif right hand.gif" alt="hand-icon" className="hand-icon" /></>)}
                                  {path === "ic" && startupCheckList.status === 11 && (
                                    <>
                                      <div className="mt-3">
                                        <IcPscApprovel
                                          className={"viewdetails-Approve mb-2"}
                                          btnName={"Approve for PSC"}
                                          idNumber={idNumber}
                                          viewStartupDetails={viewStartupDetails}
                                          showStartupValue={showStartupValue}
                                          lbl="IC"
                                          status={13}
                                        />
                                        <span className="mx-2">or</span>
                                        <IcPscApprovel
                                          className={"viewdetails-Schemes"}
                                          btnName={"Recommended to Mentorship"}
                                          idNumber={idNumber}
                                          mentorShip={"mentorShip"}
                                          viewStartupDetails={viewStartupDetails}
                                          showStartupValue={showStartupValue}
                                          lbl="IC"
                                          status={14}
                                        />
                                        <span className="mx-2">or</span>
                                        <IcPscApprovel
                                          className={
                                            "viewdetails-Schemes bg-danger text-light "
                                          }
                                          btnName={"Reject"}
                                          idNumber={idNumber}
                                          viewStartupDetails={viewStartupDetails}
                                          showStartupValue={showStartupValue}
                                          lbl="IC"
                                          status={29}
                                        />
                                      </div>
                                    </>
                                  )}
                                  {path === "psc" && startupCheckList.status === 13 && (
                                    <>
                                      <div className="mt-3">
                                        <IcPscApprovel
                                          className={"viewdetails-Approve mb-2"}
                                          btnName={"Approve for DD"}
                                          idNumber={idNumber}
                                          viewStartupDetails={viewStartupDetails}
                                          showStartupValue={showStartupValue}
                                          lbl="PSC"
                                          status={16}
                                        />
                                        <span className="mx-2">or</span>
                                        <IcPscApprovel
                                          className={"viewdetails-Schemes mb-2"}
                                          btnName={"Recommended to Mentorship"}
                                          idNumber={idNumber}
                                          mentorShip={"mentorShip"}
                                          viewStartupDetails={viewStartupDetails}
                                          showStartupValue={showStartupValue}
                                          lbl="PSC"
                                          status={30}
                                        />
                                        <span className="mx-2">or</span>
                                        {/* <IcPscApprovel className={'viewdetails-Schemes'} btnName={'Recommended to Mentorship'} idNumber={idNumber} viewStartupDetails={viewStartupDetails} showStartupValue={showStartupValue} lbl="PSC" status={19} /> */}
                                        <IcPscApprovel
                                          className={
                                            "viewdetails-Schemes-danger"
                                          }
                                          btnName={"Reject"}
                                          idNumber={idNumber}
                                          viewStartupDetails={viewStartupDetails}
                                          showStartupValue={showStartupValue}
                                          lbl="PSC"
                                          status={15}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div

                    >
                      {/* {path === "scstbdd" && (
                        <>
                          {startupCheckList.bddSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="BDD First Connect"
                              status={6}
                            />
                          )}
                          {startupCheckList.bddDeepDiveSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="BDD Deep Dive"
                              status={10}
                            />
                          )}
                        </>
                      )} */}
                      {path === "scstldd" && (
                        <>
                          {startupCheckList.lddSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="LDD"
                              status={18}
                            />
                          )}
                          {startupCheckList.lddCpSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="CP LDD"
                              status={21}
                            />
                          )}
                          {startupCheckList.lddCsSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="CS LDD"
                              status={27}
                            />
                          )}
                        </>
                      )}
                      {path === "scstfdd" && (
                        <>
                          {startupCheckList.fddSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="FDD"
                              status={18}
                            />
                          )}
                          {startupCheckList.fddCpSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="CP FDD"
                              status={21}
                            />
                          )}
                          {startupCheckList.fddCsSubmitButton === true && (
                            <BDDSubmitReport
                              idNumber={idNumber}
                              viewStartupDetails={viewStartupDetails}
                              showStartupValue={showStartupValue}
                              lbl="CS FDD"
                              status={27}
                            />
                          )}
                        </>
                      )}
                      {roles !== "SCSTHUB" && startupValue.additionalDocName && (
                        <>
                          <button
                            className="downloaddetails-surpitch"
                            onClick={() =>
                              handleClickOpenImage(
                                startupValue.additionalDocName
                              )
                            }
                          >
                            View Report
                          </button>
                        </>
                      )}
                      {/* {(roles === "MENTORSHIP" && startupValue.mentorRemarks) && (
                        <>
                          <Tooltip title="View Remarks" className="c-pointer">
                            <img
                              src="/images/comment.png"
                              alt="Comment Icon"
                              className="img-fluid "

                              onClick={() => handleCommentsopen()}
                              style={{
                                width: "20px",
                                height: "20px",
                                marginLeft: "120px",
                                filter:
                                  "invert(33%) sepia(92%) saturate(1109%) hue-rotate(202deg) brightness(94%) contrast(89%)",
                              }}
                            />
                          </Tooltip>
                          <CommentsModal
                            remarks={startupValue}
                            style={{ color: "white" }}
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                          />
                        </>
                      )} */}
                      {/* view reports */}
                      {roles !== "SCSTHUB" && (
                        <>
                          <div className="mt-3">
                            {startupCheckList.bddDeepDiveReportDoc ||
                              startupCheckList.icReportDoc ||
                              startupCheckList.pscReportDoc ||
                              startupCheckList.lddReportDoc ||
                              startupCheckList.fddReportDoc ||
                              startupCheckList.cpLddReportDoc ||
                              startupCheckList.cpFddReportDoc ||
                              startupCheckList.csLddReportDoc ||
                              startupCheckList.csFddReportDoc ? (
                              <ViewAllReports
                                startupCheckList={startupCheckList}
                              />
                            ) : (
                              <>
                                {roles !== "MENTORSHIP" && (
                                  <>
                                    {startupCheckList.bddFirstConnectReportDoc && (
                                      <>
                                        <button
                                          className="downloaddetails-surpitch-bbdd my-1 mr-1"
                                          onClick={() =>
                                            handleClickOpenImage(
                                              startupCheckList.bddFirstConnectReportDoc
                                            )
                                          }
                                        >
                                          BDD First Connect Report
                                        </button>

                                        {comments?.[0]?.comment && (
                                          <span
                                            className="c-pointer"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="View BDD First Connect Comment"
                                            onClick={() => handleViewOpen()}
                                          >
                                            <img
                                              src="/images/comment.png"
                                              alt="Comment Icon"
                                              className="img-fluid"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                filter:
                                                  "invert(33%) sepia(92%) saturate(1109%) hue-rotate(202deg) brightness(94%) contrast(89%)",
                                              }}
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {(scstAdminAllRemarks.length > 0 || scstMentorAllRemarks.length > 0) && (
                              // <button className="downloaddetails-surpitch" onClick={() => handleOpenModal4()}>
                              //   View Remarks
                              // </button>
                              <Tooltip title="Admin & Mentorship Remarks" className="text-right">
                                <img src="/images/chat.gif" alt="" style={{ width: '50px', cursor: 'pointer' }} onClick={() => handleOpenModal4()} />
                              </Tooltip>
                            )}
                          </div>

                        </>
                      )}

                    </div>
                  </div>
                </div>


                <hr />

                <Scsttab
                  listApi={listApi}
                  meetingIndex={meetingIndex}
                  progressIndex={progressIndex}
                  progressIndex1={progressIndex1}
                  meetingMentorIndex={meetingMentorIndex}
                  progressMentorIndex={progressMentorIndex}
                  progressMentorIndex1={progressMentorIndex1}
                  meetingBddIndex={meetingBddIndex}
                  progressBddIndex={progressBddIndex}
                  progressBddIndex1={progressBddIndex1}
                  docmntRmMentorIndex={docmntRmMentorIndex}
                  docmntRmlddandfddIndex = {docmntRmlddandfddIndex}

                  startupGetData={startupGetData}
                  startupValue={startupValue}
                  idNumber={idNumber}
                  viewStartupDetails={viewStartupDetails}
                  listData={listData}
                  path={path}
                  partnerOrInvestorId={partnerOrInvestorId}
                  startupCheckList={startupCheckList}
                  showStartupValue={showStartupValue}
                  docmntRmBddIndex={docmntRmBddIndex} setDocmntRmBddIndex={setDocmntRmBddIndex}
                />
              </div>

              <Drawer
                anchor="left"
                open={openList}
                onClose={handleClose}
                className="scroe_module"
              >
                <Box sx={{ width: 250 }} role="presentation">
                  <div className="box-line-sm">
                    <Startuplistfilter
                      path={path}
                      startupName={startupValue.startupName}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      handleClose={handleClose}
                    />
                  </div>
                </Box>
              </Drawer>
            </div>
          </div>
        </div>
      </section >
      <CommonViewPitchDeck
        viewImage={viewImage}
        open={open}
        handleClose={handleClose1}
      />
      <Viewimage
        viewImage={viewReportImage}
        open={openReportImage}
        handleClose={handleCloseImage}
      />
      <Dialog
        open={openView}
        maxWidth="sm"
        fullWidth={true}
        onClose={handleViewClose}
      >
        <DialogContent>
          <h1 className="views-scomm">View Comments</h1>

          {/* <p className='mt-4'>{comments}</p> */}
          {comments.map((report, index) => (
            <p key={index} className="mt-4">
              {report.comment}
            </p>
          ))}
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={openReport}
        onClose={handleReportClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <h4>Remarks</h4>
        </DialogTitle>
        <DialogContent>
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="comments"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Remarks is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        multiline
                        label="Enter Remarks *"
                        placeholder="Enter Remarks"
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.comments && errors.comments.message}
                  </FormHelperText>
                </div>
              </div>
              <div className='col-lg-12 col-12'>
                <div className="form-group mt-2">
                  <Controller name="document" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field: { value, onChange, ...field } }) =>
                      <MuiFileInput
                        {...field}
                        onChange={(file) => {
                          if (file) {
                            handleFileUpload(file, "document", "documentname", setAdditionalDocValue, setAdditionalDocURL);
                          } else {
                            setAdditionalDocValue('');
                            setValue('document', '');
                          }
                          // onChange(file);
                        }}
                        value={additionalDocValue}
                        variant='outlined'
                        fullWidth={true}
                        label={`Upload Document`}
                        placeholder='Upload Document' />
                    } />
                  {/* <FormHelperText className='text-danger'>{errors.additionalDoc && errors.additionalDoc.message}</FormHelperText> */}
                  <span className='font-sm'>Maximum 10 mb allowed doc (pdf)</span>
                </div>
              </div>
            </div>
            <div className="text-center p-3">
              <Button
                className="mr-3"
                onClick={handleReportClose}
                variant="outlined"
              >
                Review
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal4}
        onClose={handleCloseModal4}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpenModal4(false)}
            sx={{ position: "absolute", right: 8, top: 8, color: 'red' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <div >

              <>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={remarks} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Admin Remarks" {...a11yProps(0)} />
                      <Tab label="Mentorship Remarks" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={remarks} index={0}>
                    {scstAdminAllRemarks.length ? (
                      <TableContainer component={Paper} className="table-container">
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                S.No
                              </TableCell>
                              <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                Phase
                              </TableCell>
                              <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                Submitted Time
                              </TableCell>
                              <TableCell align="left" className="table-header" sx={{ width: '350px', fontWeight: 600 }}>
                                Remarks
                              </TableCell>



                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {scstAdminAllRemarks && scstAdminAllRemarks.map((data, i) => (
                              <TableRow className="table-row" key={i}>
                                <TableCell component="th" scope="row" align="center">
                                  {/* {i + 1} */}
                                  {
                                    tableIndexValue(rowsPerPage, page, i)
                                  }
                                </TableCell>
                                <TableCell align="center" className="table-cell">
                                  {data.phase ?? "-"}
                                </TableCell>
                                <TableCell align="center" className="table-cell">
                                  {data.submittedTime ?? "-"}
                                </TableCell>
                                <TableCell align="left" sx={{ width: '300px' }}>
                                  {data.remarks ? (
                                    <>
                                      {isFullTextVisible[i]
                                        ? data.remarks
                                        : truncateTexts(data.remarks || '', 60)}

                                      {data.remarks && data.remarks.length > 60 && (
                                        <h6
                                          onClick={() => toggleText(i)}
                                          style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                        >
                                          {isFullTextVisible[i] ? " Read Less" : " Read More"}
                                        </h6>
                                      )}
                                    </>) : ((`-`))}
                                </TableCell>

                              </TableRow>
                            ))}
                          </TableBody>
                          <caption>
                            <TablePagination
                              component="div"
                              count={count}
                              page={page}
                              onPageChange={handleChangePage}
                              rowsPerPage={rowsPerPage}
                              onRowsPerPageChange={
                                handleChangeRowsPerPage
                              }
                              style={{ float: 'left' }}
                            />
                          </caption>
                        </Table>
                      </TableContainer>
                    ) : (<>
                      <div className="nodata_tag">
                        <img
                          src="/images/Tanfundlogo-black-1.png"
                          className="no_data_logo"
                          alt=""
                        />
                        <h4>No Records Found</h4>
                      </div>
                    </>)}
                  </CustomTabPanel>
                  <CustomTabPanel value={remarks} index={1}>
                    {scstMentorAllRemarks.length ? (
                      <TableContainer component={Paper} className="table-container">
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                S.No
                              </TableCell>
                              <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                Phase
                              </TableCell>
                              <TableCell align="center" className="table-header" sx={{ fontWeight: 600 }}>
                                Submitted Time
                              </TableCell>
                              <TableCell align="left" className="table-header" sx={{ width: '200px', fontWeight: 600 }}>
                                Remarks
                              </TableCell>
                              <TableCell align="left" className="table-header" sx={{ fontWeight: 600 }}>
                                Attachment
                              </TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {scstMentorAllRemarks && scstMentorAllRemarks.map((data, i) => (
                              <TableRow className="table-row" key={i}>
                                <TableCell component="th" scope="row" align="center">
                                  {
                                    tableIndexValue(rowsPerPageMentor, pageMentor, i)
                                  }
                                </TableCell>
                                <TableCell align="center" className="table-cell">
                                  {data.phase ?? "-"}
                                </TableCell>
                                <TableCell align="center" className="table-cell">
                                  {data.submittedTime ?? "-"}
                                </TableCell>
                                <TableCell align="left" sx={{ width: '200px' }}>
                                  {data.remarks ? (
                                    <>
                                      {isFullTextVisible[i]
                                        ? data.remarks
                                        : truncateTexts(data.remarks || '', 60)}

                                      {data.remarks && data.remarks.length > 60 && (
                                        <h6
                                          onClick={() => toggleText(i)}
                                          style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                        >
                                          {isFullTextVisible[i] ? " Read Less" : " Read More"}
                                        </h6>
                                      )}
                                    </>) : ((`-`))}
                                </TableCell>
                                <TableCell className="table-cell">

                                  <div style={{ marginLeft: '30px' }}>
                                    {data.documentname ? (
                                      <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickOpenImage(data.documentname)} />
                                    ) : (
                                      <>
                                        {"-"}
                                      </>
                                    )}
                                  </div>

                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <caption>
                            <TablePagination
                              component="div"
                              count={countMentor}
                              page={pageMentor}
                              onPageChange={handleChangePageMentor}
                              rowsPerPage={rowsPerPageMentor}
                              onRowsPerPageChange={
                                handleChangeRowsPerPageMentor
                              }
                              style={{ float: 'left' }}
                            />
                          </caption>
                        </Table>
                      </TableContainer>
                    ) : (<>
                      <div className="nodata_tag">
                        <img
                          src="/images/Tanfundlogo-black-1.png"
                          className="no_data_logo"
                          alt=""
                        />
                        <h4>No Records Found</h4>
                      </div>
                    </>)}
                  </CustomTabPanel>
                </Box>

              </>

            </div>
          </DialogContentText>
        </DialogContent>

      </Dialog>
    </div >
  );
};

export default ScstDetails;
