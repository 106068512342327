import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import IdentifyingInformation from "./pages/IdentifyingInformation";
import ProblemIdentification from "./pages/ProblemIdentification";
import SolutionIdentification from "./pages/SolutionIdentification";
import IPIncubation from "./pages/IPIncubation";
import MarketCompetitors from "./pages/MarketCompetitors";
import FoundersBackground from "./pages/FoundersBackground";
import BusinessModel from "./pages/BusinessModel";
import FundsRaised from "./pages/FundsRaised";
import BddClarification from "./BddClarification";
import Zoomlist from "../../jury-module/zoom-page/Zoomlist";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import PriorDocuments from "./pages/PriorDocuments";
import BddAdditionalInfo from "./pages/BddAdditionalInfo";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const ScstBddVerificationLayout = ({
  idNumber,
  path,
  viewStartupDetails,
  showStartupValue,
  startupCheckList,
  startupValue,
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue1("");
    setValue(newValue);
  };
  const roles = localStorage.getItem('role')
  const tabIndex = roles === 'SCSTADMIN' ? 4 : 5;
  const [value1, setValue1] = useState();
  const handleChange1 = (event, newValue) => {
    setValue("");
    setValue1(newValue);
  };
  // useDidMountEffect(() => {

  //     showStartupValue()
  // }, [])
  // const [startupCheckList,setStartupCheckList]= useState([])
  // const showStartupValue = async () =>{
  //     apiService(`startup/clarifications/status/get?id=${idNumber}`, '', 'get').then((res) => {

  //         if (res) {
  //             if (res.data) {
  //                 debugger
  //                 setStartupCheckList(res.data)
  //             }
  //         }
  //     })
  // }
  return (
    <>
      <div className="overallTab">
        <Tabs
          className="verticalTabCSS"
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab
            className="verticalLable"
            label="Prior Documents"
            {...a11yProps(0)}
          />
          <Tab
            className="verticalLable"
            label="Identifying Information"
            {...a11yProps(1)}
          />
          <Tab
            className="verticalLable"
            label="Problem Identification"
            {...a11yProps(2)}
          />
          <Tab
            className="verticalLable"
            label="Solution Identification"
            {...a11yProps(3)}
          />
          <Tab
            className="verticalLable"
            label="IP & Incubation"
            {...a11yProps(4)}
          />
        </Tabs>
        <Tabs
          className="verticalTabCSS"
          orientation="horizontal"
          variant="scrollable"
          value={value1}
          onChange={handleChange1}
          aria-label="Vertical tabs example"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab
            className="verticalLable"
            label="Market & Competitors"
            {...a11yProps(0)}
          />
          <Tab
            className="verticalLable"
            label="Founders Background"
            {...a11yProps(1)}
          />
          <Tab
            className="verticalLable"
            label="Business Model"
            {...a11yProps(2)}
          />
          <Tab
            className="verticalLable"
            label="Funds Raised"
            {...a11yProps(3)}
          />
          {path === "scstbdd" && (
            <Tab
              className="verticalLable"
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Field Clarification
                  { startupCheckList.bddClarificationRequested && startupCheckList.bddClarificationRequested === true  ? (
                    <span className="ml-2">
                      <img
                        src="/images/giphy.gif"
                        alt="gif"
                        className="gif-bell img-fluid"
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                  
                </div>
              }
              {...a11yProps(4)}
            />
          )}
          {(path === "scstbdd" || roles === 'SCSTADMIN')  && (
            <Tab
              className="verticalLable"
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  General Clarification
                  {startupCheckList?.generalBddadditionalInfoRequested === true || startupCheckList?.generalBddDDadditionalInfoRequested === true  ? (
                    <span className="ml-2">
                      <img
                        src="/images/giphy.gif"
                        alt="gif"
                        className="gif-bell img-fluid"
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              }
              {...a11yProps(5)}
            />
          )}
          {/* {
                    path === 'scstbdd' && (
                        <Tab className='verticalLable' label="Schedule Meeting" {...a11yProps(4)} />
                    )
                } */}
        </Tabs>
        <Box sx={{ p: 3 }}>
          <TabPanel value={value} index={0}>
            <PriorDocuments
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <IdentifyingInformation
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ProblemIdentification
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <SolutionIdentification
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <IPIncubation
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value1} index={0}>
            <MarketCompetitors
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value1} index={1}>
            <FoundersBackground
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value1} index={2}>
            <BusinessModel
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value1} index={3}>
            <FundsRaised
              idNumber={idNumber}
              path={path}
              startupValue={startupValue}
              viewStartupDetails={viewStartupDetails}
              showStartupValue={showStartupValue}
              startupCheckList={startupCheckList}
            />
          </TabPanel>
          <TabPanel value={value1} index={roles === 'SCSTADMIN' ? 5:4 }>
            <BddClarification
              idNumber={idNumber}
              path={path}
              viewStartupDetails={viewStartupDetails}
              startupValue={startupValue}
              startupCheckList={startupCheckList}
              showStartupValue={showStartupValue}
            />
          </TabPanel>
          <TabPanel value={value1} index={roles === 'SCSTADMIN' ? 4 : 5}>
            <BddAdditionalInfo
              idNumber={idNumber}
              path={''}
              viewStartupDetails={viewStartupDetails}
              startupValue={startupValue}
              startupCheckList={startupCheckList}
              showStartupValue={showStartupValue}
              showData={'bddFirstConnect'}
            />
          </TabPanel>
          {/* <TabPanel value={value1} index={4}><Zoomlist startupId={idNumber} /></TabPanel> */}
        </Box>
      </div>
      {/* {
        (startupCheckList.status >= 4) ? (
        )  : (
            <div className='nodata_tag'>
                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                <h4>BDD First Connect list is empty</h4>
            </div>
        )} */}
    </>
  );
};

export default ScstBddVerificationLayout;
