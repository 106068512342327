import React, { useContext, useState } from "react";
import apiService from "../../../api/apiService";
import { useLocation, useParams } from "react-router-dom";
import { Box, Button, Tab } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataContext from "../../../context/DataContext";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Associatedrounds from "../../investor-module/associated-startups-page/associated-details-page/Associatedrounds";
import Associatedtab from "../../investor-module/associated-startups-page/associated-details-page/pages/Associatedtab";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { AiFillSafetyCertificate } from "react-icons/ai";
import CommonViewPitchDeck from "../../common-page/CommonViewPitchDeck";
import notifyService from "../../../api/notifySerivce";
import FavMark from "../../admin-module/startup-page/FavMark";
import AddComments from "../../admin-module/startup-page/AddComments";

const TanseedAssociateddetails = ({ name, path, versionId, route,showpdf }) => {
  const { navigator, setPageLoading, zoomToken } = useContext(DataContext);
  const { startupId } = useParams();
  const { id } = useParams();
  const { investorId } = useParams();
  let idNumber = atob(startupId);
  //   let ids;
  //   if (id) {
  //     ids = atob(id);
  //   }
  let partnerOrInvestorId;
  if (investorId) {
    partnerOrInvestorId = atob(investorId);
  }
  const [startupGetData, setStartupGetData] = useState({});
  useDidMountEffect(() => {
    // eslint-disable-next-line
    var zoomTokenlet = localStorage.getItem("zoomToken");
    if (zoomTokenlet !== null) {
      zoomToken();
    }
    if (route === "currentdetails") {
      viewcurrentStartupDetails();
    } else if (route === "PartnerTanseed" || route === "JuriesTanseed") {
      viewcurrentStartupDetails();
    } else if (route === "AdminTanfund" || route === "AdminSCST") {
      viewAdminStartupDetails();
    } else {
      viewStartupDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);
  const [startupValue, setStartupValue] = useState({});
  const [roundNo, setRoundNo] = useState(1);
  const viewAdminStartupDetails = async () => {
    setPageLoading(true);
    setStartupGetData({});
    setStartupValue({});
    // var req = {
    //   configId: versionId,
    //   startupId: idNumber,
    // };
    // let URL;
    // if (route === "Admin") {
    //   URL = ;
    // } else {
    //   URL = `partner/tanseed/admin/startups/get`;
    // }
    apiService(`admin/startups/get?id=${idNumber}`, "", "get").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.applicationForm) {
            setStartupGetData(res.data.applicationForm);
          }
          setStartupValue(res.data);
          if (res.data.roundNo) {
            setRoundNo(res.data.roundNo);
            setNavTabValue(
              path === "jury/tanseed" && res.data.roundNo > 1 ? "2" : "1"
            );
          }
        } else {
          setStartupGetData({});
        }
      }
    });
  };
  const viewStartupDetails = async () => {
    setPageLoading(true);
    setStartupGetData({});
    setStartupValue({});
    var req = {
      configId: versionId,
      startupId: idNumber,
    };
    // let URL;
    // if (route === "Admin") {
    //   URL = `admin/startups/get?id=${idNumber}`;
    // } else {
    //   URL = ;
    // }
    apiService(`partner/tanseed/admin/startups/get`, req, "post").then(
      (res) => {
        setPageLoading(false);
        if (res) {
          if (res.data) {
            if (res.data.applicationForm) {
              setStartupGetData(res.data.applicationForm);
            }
            setStartupValue(res.data);
            if (res.data.roundNo) {
              setRoundNo(res.data.roundNo);
              setNavTabValue(
                path === "jury/tanseed" && res.data.roundNo > 1 ? "2" : "1"
              );
            }
          } else {
            setStartupGetData({});
          }
        }
      }
    );
  };
  const viewcurrentStartupDetails = async () => {
    setPageLoading(true);
    setStartupGetData({});
    setStartupValue({});
    apiService(
      `partner/tanseed/current/startups/get?id=${idNumber}`,
      "",
      "get"
    ).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.applicationForm) {
            setStartupGetData(res.data.applicationForm);
          }
          setStartupValue(res.data);
          if (res.data.roundNo) {
            setRoundNo(res.data.roundNo);
            setNavTabValue(
              path === "jury/tanseed" && res.data.roundNo > 1 ? "2" : "1"
            );
          }
        } else {
          setStartupGetData({});
        }
      }
    });
  };
  const location = useLocation();
  let role = localStorage.getItem("role");
  const handleBack = () => {
    if (role === "ADMIN") {
      if (investorId === undefined) {
        var val = location.pathname.split("/");
        if (val[2] === "tanseed") {
          navigator(`admin/tanseed`);
        } else {
          navigator(`admin/startup`);
        }
      } else {
        navigator(`admin/associated-startups`);
      }
    } else {
      navigator(`${path}/${id}`);
    }
  };

  const [navTabValue, setNavTabValue] = useState(
    path === "jury/tanseed" && roundNo > 1 ? "2" : "1"
  );
  const handleNavTabChange = (event, newValue) => {
    setNavTabValue(newValue);
  };
  const isRegistered = startupGetData.dpiitRegistered;
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  const handleClickDownload = (data) => {
    // apiService(`startup/pitchdeckurl/get?startupid=${idNumber}`, "", "get")
    //   .then((result) => {
    //     if (result?.data?.pitchDeckName) {
      const pdfUrl = `data:application/pdf;base64,${data}`;

    
  
      // Create an anchor element for downloading
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Pitchdeck.pdf"; // File name
      document.body.appendChild(link);
  
      // Trigger download
      link.click();
  
      // Cleanup
      document.body.removeChild(link);
      //   } else {
      //     notifyService("info", "File Not Found", "PitchDeck file not found");
      //   }
      // })
      // .catch((err) => {});
  };
  return (
    <div className="box-show">
      <section className="bg-white">
        <div className="row">
          <div className="col-lg-12 p-3">
            <div className="p-relative">
              <div className="p-3">
                <div className="dflex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div>
                      <Button onClick={handleBack}>
                        <ArrowBackIcon />
                      </Button>
                    </div>
                    <img
                      src={startupGetData.startupLogoName}
                      className="img-fluid user_logos"
                      alt=""
                    />
                    <div className="content">
                      <div className="d-flex align-items-center mb-3">
                        <h3 className="mr-3 mb-0">
                          {startupValue.startupName}
                        </h3>
                        {route === "AdminTanfund" && (
                          <div className="mr-3">
                            <FavMark
                              id={idNumber}
                              fav={startupGetData.fav}
                              userListItem={viewAdminStartupDetails}
                            />
                            <AddComments
                              id={idNumber}
                              comments={startupGetData.comments}
                              userListItem={viewAdminStartupDetails}
                            />
                          </div>
                        )}

                        <button
                          className={`dpi-button ${
                            isRegistered ? "register-btn" : "not-register-btn"
                          }`}
                        >
                          {isRegistered && (
                            <AiFillSafetyCertificate className="icon" />
                          )}
                          {isRegistered
                            ? "DPIIT REGISTERED"
                            : "Non DPIIT REGISTERED"}
                        </button>
                        {startupGetData?.pitchDeck && (
                          <>
                            <button
                              className="viewdetails-surpitch"
                              onClick={() => {
                                handleClickOpen(startupGetData.pitchDeck);
                              }}
                            >
                              View PitchDeck
                            </button>
                            {/* {showpdf !== "showpdf"&& ( */}
                              <button
                              className="downloaddetails-surpitch"
                              onClick={() => {
                                handleClickDownload(startupGetData.pitchDeck);
                              }}
                            >
                              Download PitchDeck
                            </button>
                           {/* )} */}
                          </>
                        )}
                      </div>

                      <span className="black-clr-sur">
                        <img src="/images/phone.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupPhone
                          ? startupValue.startupPhone
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        <img src="/images/email.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupEmail
                          ? startupValue.startupEmail
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        Member Since : {startupValue.date}
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={navTabValue}>
                    {investorId !== undefined && role !== "ADMIN" && (
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleNavTabChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Startups" value="1" />
                          <Tab label="Round Status & Score" value="2" />
                        </TabList>
                      </Box>
                    )}
                    <TabPanel value="1" sx={{ padding: "10px 0px" }}>
                      <Associatedtab
                        startupGetData={startupGetData}
                        startupValue={startupValue}
                        idNumber={idNumber}
                        name={name}
                      />
                    </TabPanel>
                    {investorId !== undefined && (
                      <TabPanel value="2" sx={{ padding: "10px 0px" }}>
                        <Associatedrounds
                          startupId={idNumber}
                          partnerOrInvestorId={partnerOrInvestorId}
                          name={name}
                        />
                      </TabPanel>
                    )}
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CommonViewPitchDeck
        viewImage={viewImage}
        open={open}
        handleClose={handleClose1}
      />
    </div>
  );
};
export default TanseedAssociateddetails;
