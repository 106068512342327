import React, { useContext, useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useForm, Controller } from "react-hook-form";

import DataContext from "../../../context/DataContext";
import CustomLink from "../../common-page/CustomLink";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import BddOnboarded from "../../scstBdd-page/BddOnboarded-details/BddOnboarded";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const ScstCurrentMentor = ({ role, stage }) => {
  //  const role =  localStorage.getItem('role')
  const { setPageLoading, tableIndexValue } = useContext(DataContext);
  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scstMentorList, setScstMentorList] = useState([]);
  var pageNumberBDDStartup = Number(localStorage.getItem('pageNumberBDDStartup'))
  const [page, setPage] = useState(pageNumberBDDStartup ? pageNumberBDDStartup : 0);

  var listSizeBDDStartup = Number(localStorage.getItem('listSizeBDDStartup'))
  const [rowsPerPage, setRowsPerPage] = useState(listSizeBDDStartup ? listSizeBDDStartup : 10);

  var districtNameBDDStartup = localStorage.getItem('districtNameBDDStartup')
  const [districtName, setDistrictName] = useState(districtNameBDDStartup ? districtNameBDDStartup : "All");

  var scsthubBDDStartup = localStorage.getItem('scsthubBDDStartup')
  const [scsthub, setScsthub] = useState(scsthubBDDStartup ? scsthubBDDStartup : "All");

  var yearNameBDDStartup = Number(localStorage.getItem('yearNameBDDStartup'))
  const [yearName, setYearName] = useState(yearNameBDDStartup ? yearNameBDDStartup : '2022');

  let toYearNameScst = Number(localStorage.getItem('toYearNameScst'))
  const [toYearName, setToYearName] = useState(toYearNameScst ? toYearNameScst : '2022');
  var monthNameBDDStartup = Number(localStorage.getItem('monthNameBDDStartup'))
  const [monthName, setMonthName] = useState(monthNameBDDStartup ? monthNameBDDStartup : 0);

  var manualNameBDDStartup = Number(localStorage.getItem('manualNameBDDStartup'))
  const [manualName, setManualName] = useState(manualNameBDDStartup ? manualNameBDDStartup : 0)

  var searchInputValueBDDStartup = localStorage.getItem('searchInputValueBDDStartup')
  const [searchInputValue, setSearchInputValue] = useState(searchInputValueBDDStartup ? searchInputValueBDDStartup : '');
  var searchInputApplicationValueBDDStartup = localStorage.getItem(
    "searchInputApplicationValueBDDStartup"
  );
  const [searchInputApplicationValue, setSearchInputApplicationValue] = useState(
    searchInputApplicationValueBDDStartup ? searchInputApplicationValueBDDStartup : ""
  );
  var phaseValueBDDStartup = localStorage.getItem('phaseValueBDDStartup')
  const [phaseValue, setPhaseValue] = useState(phaseValueBDDStartup ? phaseValueBDDStartup : 'All')
  // console.log(phaseValueScst);
  let toMonthNameScst = Number(localStorage.getItem('toMonthNameScst'))
  const [toMonthName, setToMonthName] = useState(toMonthNameScst ? toMonthNameScst : '');
  var statusValueBDDStartup = Number(localStorage.getItem('statusValueBDDStartup'))
  const [statusValue, setStatusValue] = useState(statusValueBDDStartup ? statusValueBDDStartup : 100)
  useDidMountEffect(() => {
    (async () => await scstMentorlist())();
    // eslint-disable-next-line
  }, [page, rowsPerPage, districtName, scsthub, yearName, toYearName, monthName, manualName, searchInputValue,searchInputApplicationValue, phaseValue, toMonthName, statusValue]);
  useEffect(() => {
    setSearchInputValue('')
    setSearchInputApplicationValue("")
  }, [])
  useDidMountEffect(() => {
    notificationClear2()
    debugger
  }, []);
  const notificationClear2 = () => {
    localStorage.setItem("phaseValueBDDStartup", "All");
    setPhaseValue("All");
    setStatusValue(statusValueBDDStartup);
    localStorage.setItem("scsthubBDDStartup", "All");
    setScsthub("All");
    localStorage.setItem("districtNameBDDStartup", "All");
    setDistrictName("All");
    localStorage.setItem("yearNameBDDStartup", "2022");
    setYearName("2022");
    localStorage.setItem("toYearNameBDDStartup", "");
    setToYearName("");
    localStorage.setItem("monthNameBDDStartup", 0);
    setMonthName(0);
    localStorage.setItem("toMonthNameBDDStartup", "");
    setToMonthName("");
    setSelectedMonth("All");
    setSearchApplication('')
    setSearchName('')
  }
  const [searchName, setSearchName] = useState("");
  const [searchApplication, setSearchApplication] = useState("");
  const scstMentorlist = () => {
    setPageLoading(true)
    setScstMentorList([]);
    var req = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      district: districtName,
      hub: scsthub,
      startYear: yearName === 'All' ? 0 : yearName,
      endYear: toYearName === 'All' ? 0 : toYearName,
      startMonth: monthName,
      endMonth: toMonthName,
      // searchString: searchInputValue,
      searchString: searchName !== "" ? searchInputValue : "",
      appNoSearchString: searchApplication !== "" ? searchInputApplicationValue : "",
      phaseValue: phaseValue,
      status: 1
      // status: statusValue
    }
    apiService("mentorship/scst/startup/list", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        debugger
        setCount(res.data.count);
        if (res.data.startups) {
          debugger
          setScstMentorList(res.data.startups);
        } else {
          setScstMentorList([]);
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
  };
  const [selectedMonth, setSelectedMonth] = useState("All");
  return (
    <div className="scsthub-div chart">
      <BddOnboarded
        setPage={setPage}
        setSelectedMonth={setSelectedMonth}
        selectedMonth={selectedMonth}
        districtName={districtName} setDistrictName={setDistrictName}
        scsthub={scsthub} setScsthub={setScsthub}
        yearName={yearName} setYearName={setYearName}
        monthName={monthName} setMonthName={setMonthName}
        manualName={manualName} setManualName={setManualName}
        path={role} toYearName={toYearName} setToYearName={setToYearName}
        toMonthName={toMonthName} setToMonthName={setToMonthName}
        searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
        phaseValue={phaseValue} setPhaseValue={setPhaseValue}
        statusValue={statusValue} setStatusValue={setStatusValue}
        lblName='Mentorship Startups'
        searchName={searchName}
        searchApplication={searchApplication}
        setSearchName={setSearchName}
        setSearchInputApplicationValue={setSearchInputApplicationValue}
        setSearchApplication={setSearchApplication}
        stage={stage}
      />
      <div className="table_tag">
        {scstMentorList.length ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Startup Name</TableCell>
                  <TableCell>Application Number</TableCell>
                  <TableCell>District</TableCell>
                  <TableCell>Assigned to hub</TableCell>
                  <TableCell>Entity</TableCell>
                  <TableCell >Onboarded Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align='center'>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scstMentorList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {
                        tableIndexValue(rowsPerPage, page, index)
                      }
                    </TableCell>
                    <TableCell>
                      <CustomLink to={`/${role}/currentmentor/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink>
                      {/* <CustomLink to={`/${role}/startups/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink> */}
                      {/* {row.startupName} */}
                    </TableCell>
                    <TableCell>{row.applicationNo}</TableCell>
                    <TableCell>{row.district}</TableCell>
                    <TableCell>{row.investorName}</TableCell>
                    <TableCell>{row.entityType}</TableCell>
                    <TableCell>
                      {
                        row.onboardType === 1 ? 'Manual' : 'Portal'
                      }
                    </TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell align='center'>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <caption>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ float: 'left' }}
                />
              </caption>
            </Table>
          </TableContainer>
        ) : (
          <div className="nodata_tag">
            <img
              src="/images/Tanfundlogo-black-1.png"
              className="no_data_logo"
              alt=""
            />
            <h4>No Records Found</h4>
          </div>
        )}
      </div>
    </div>
  )
}

export default ScstCurrentMentor