import React, { useContext, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
// import Viewimage from "../../../../api/Viewimage";
import DeleteIcon from "@mui/icons-material/Delete";
// import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
// import PreliminaryTextPart1 from "./PreliminaryTextPart1";
// import PreliminaryTextPart2 from "./PreliminaryTextPart2";
// const fileTypes = ["pdf", "XLSX", "zip", "doc", "docx"];
const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];
// const fileTypesExcel = ["pdf", "XLSX"];

const PreliminaryDocumentPart2 = () => {

  const [formData, setFormData] = useState({});
  const {
    handleBackapp,
    setPageLoading,
    validateNumberonly,
    validateDecimalNumberonly,
    navigator,
  } = useContext(DataContext);
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors }, } = useForm({ values: formData, });

  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi();
  }, []);

  const [fileUploadId, setFileUploadId] = useState(0);
  const [complianceCheck, setComplianceCheck] = useState();
  const [firmissue1, setFirmissue1] = useState()
  const [partyToDoc, setpartyToDoc] = useState();
  const [formFiled, setFormFiled] = useState()

  const getApi = async () => {
    setPageLoading(true);
    await apiService(`startup/ldd/sectiontwo/get`, "", "get").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        var data = res.data;
        // if (data.editForm === false) {
        //   setReadyOnlyValue(true);
        // }
        if (data.editForm === false && data.superEdit === false) {
          setReadyOnlyValue(true);
        }
        if (data.editForm === false && data.superEdit === true) {
          setReadyOnlyValue(false);
        }
        if (data.editForm === true && data.superEdit === false) {
          setReadyOnlyValue(false);
        }

        if (res.data.annualReturns) {
          setFirmissue1(res.data.annualReturns)
        }
        // if (data.gstLogin) {
        //     data.gstLogin = cryptodecryptJS(data.gstLogin)
        // }
        // if (data.gstPassword) {
        //     data.gstPassword = cryptodecryptJS(data.gstPassword)
        // }
        // if (data.incTaxLogin) {
        //     data.incTaxLogin = cryptodecryptJS(data.incTaxLogin)
        // }
        // if (data.incTaxPassword) {
        //     data.incTaxPassword = cryptodecryptJS(data.incTaxPassword)
        // }
        if (res.data.id) {
          setFileUploadId(res.data.id);
        }
        if (res.data.section185186ComplianceCheck) {
          setComplianceCheck(res.data.section185186ComplianceCheck)
        }
        if (res.data.companyGuarantee) {
          setpartyToDoc(res.data.companyGuarantee)
        }
        if (res.data.shareHoldingPatterns) {
          res.data.shareHoldingPatterns.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
            // if (element.approvedDate) {
            //     element.approvedDate = dayjs(element.date, "YYYY-MM-DD");
            // }
          });
        }
        if (res.data.remunerationPaidToDirectors) {
          res.data.remunerationPaidToDirectors.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.relatedPartyTransactions) {
          res.data.relatedPartyTransactions.forEach((element) => {
            if (element.boardAuthDate) {
              element.boardAuthDate = dayjs(element.boardAuthDate, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.annualFilingsLast3Years) {
          res.data.annualFilingsLast3Years.forEach((element) => {
            if (element.dateOfFiling) {
              element.dateOfFiling = dayjs(element.dateOfFiling, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.companyBoardMeetings) {
          res.data.companyBoardMeetings.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.companyGeneralMeetings) {
          res.data.companyGeneralMeetings.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.existingAuditorAppointments) {
          res.data.existingAuditorAppointments.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.detailsOfBorrowings) {
          res.data.detailsOfBorrowings.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
            if (element.boardAuthDate) {
              element.boardAuthDate = dayjs(element.boardAuthDate, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.directorsInterestedCompanies) {
          res.data.directorsInterestedCompanies.forEach((element) => {
            if (element.dateOfFiling) {
              element.dateOfFiling = dayjs(element.dateOfFiling, "YYYY-MM-DD");
            }
          });
        }
        // if (res.data.companyRocFiled) {
        //   res.data.companyRocFiled.forEach((element) => {
        //     if (element.formFiled) {
        //       element.formFiled = element.formFiled.toString()
        //     } else{
        //       element.formFiled = element.formFiled.toString()
        //     }
        //   });
        // }
        if (res.data.companyRocFiled) {
          res.data.companyRocFiled.forEach((element) => {
            if (element.formFiled !== undefined && element.formFiled !== null) {
              // Ensure it is treated as a string
              element.formFiled = (element.formFiled === true || element.formFiled === false)
                ? element.formFiled.toString()
                : element.formFiled;
            }
          });
        }

        if (res.data.relatedPartyTransactions) {
          res.data.relatedPartyTransactions.forEach((element) => {
            if (element.dpt3Filed !== undefined && element.dpt3Filed !== null) {
              // Ensure it is treated as a string
              element.dpt3Filed = (element.dpt3Filed === true || element.dpt3Filed === false)
                ? element.dpt3Filed.toString()
                : element.dpt3Filed;
            }
          });
        }
        if (res.data.shareHoldingPatterns) {
          res.data.shareHoldingPatterns.forEach((element) => {
            if (element.dulyStamped !== undefined && element.dulyStamped !== null) {
              // Ensure it is treated as a string
              element.dulyStamped = (element.dulyStamped === true || element.dulyStamped === false)
                ? element.dulyStamped.toString()
                : element.dulyStamped;
            }
          });
        }
        if (res.data.directorsInterestedCompanies) {
          res.data.directorsInterestedCompanies.forEach((element) => {
            if (element.interested !== undefined && element.interested !== null) {
              // Ensure it is treated as a string
              element.interested = (element.interested === true || element.interested === false)
                ? element.interested.toString()
                : element.interested;
            }
          });
        }

        setFormData(res.data);
      }
    });
  };


  const handleFileUpload = (event, filekey, fileName, fileURL, format, val) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          (format === "file" && img_crt_type === "pdf") ||
          img_crt_type === "vnd.ms-excel" ||
          img_crt_type ===
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          img_crt_type ===
          "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setValue(filekey, "");
          setValue(fileName, "");

          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setValue(fileURL, logourl1);
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            if (val !== 'nosave') {
              var req = {
                [filekey]: ImageValue,
                [fileName]: img_name,
                id: fileUploadId,
              };
              // console.log(req);
              saveData(req);
            }
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    }
  };
  const saveData = (req) => {
    console.log(req);
    setPageLoading(true);
    apiService("startup/ldd/sectiontwo/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          getApi();
        }
      }
    });
  };

  // const [open, setOpen] = useState(false);
  // const [viewImage, setViewImage] = useState("");
  // const handleClickOpen = (value) => {
  //   if (value) {
  //     setViewImage(value);
  //     setOpen(true);
  //   }
  // };

  // NOW UnWanted code
  const handlestatutoryRegistersPcsDoc = (e) => {
    handleFileUpload(
      e,
      "statutoryRegistersPcsDoc",
      "statutoryRegistersPcsDocName",
      "statutoryRegistersDocPcsURL",
      "file"
    );
  };
  const handleRocPcsDoc = (e) => {
    handleFileUpload(e, "rocPcsDoc", "rocDocPcsName", "rocDocPcsURL", "file");
  };
  const handleShareholderMinutesPcsDoc = (e) => {
    handleFileUpload(
      e,
      "shareholderMinutesPcsDoc",
      "shareholderMinutesDocPcsName",
      "shareholderMinutesDocPcsURL",
      "file"
    );
  };
  const handleClickOpens = (url) => {
    debugger
    if (url !== undefined) {
      var filSplit = url.split(":");

      if (filSplit[0] === "data") {
        try {
          // Check the type of file from the base64 URL
          if (url.includes("application/pdf")) {
            // PDF file
            const pdfUrl = url;
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = "Document.pdf"; // Default name for PDF download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else if (url.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || url.includes("application/vnd.ms-excel")) {
            // Excel file (.xlsx or .xls)
            const excelUrl = url;
            const link = document.createElement("a");
            link.href = excelUrl;
            link.download = "Spreadsheet.xlsx"; // Default name for Excel download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else if (url.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || url.includes("application/msword")) {
            // Word document (.docx or .doc)
            const docUrl = url;
            const link = document.createElement("a");
            link.href = docUrl;
            link.download = "Document.docx"; // Default name for Word download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            alert("Unsupported file type.");
          }
        } catch (error) {
          console.error("Error while downloading the file:", error);
          alert("An error occurred while trying to download the file. Please check your internet connection and try again.");
        }
      } else {
        try {
          // For non-base64 URLs, open the link in a new tab
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error while opening the link:", error);
          alert("An error occurred while trying to open the link. Please check your internet connection and try again.");
        }
      }
    }
  };
  // const handleClickOpens = (url) => {
  //   if (url) {
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.target = "_blank";
  //     link.rel = "noopener noreferrer";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };
  // Save Api

  const onSubmit = (data) => {
    if (data.shareHoldingPatterns) {
      data.shareHoldingPatterns.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    if (data.remunerationPaidToDirectors) {
      data.remunerationPaidToDirectors.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    if (data.detailsOfBorrowings) {
      data.detailsOfBorrowings.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
        if (element.boardAuthDate) {
          element.boardAuthDate = element.boardAuthDate.format("YYYY-MM-DD");
        }
      });
    }
    if (data.companyBoardMeetings) {
      data.companyBoardMeetings.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    if (data.companyGeneralMeetings) {
      data.companyGeneralMeetings.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    if (data.relatedPartyTransactions) {
      data.relatedPartyTransactions.forEach((element) => {
        if (element.boardAuthDate) {
          element.boardAuthDate = element.boardAuthDate.format("YYYY-MM-DD");
        }
      });
    }

    if (data.existingAuditorAppointments) {
      data.existingAuditorAppointments.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    if (data.directorsInterestedCompanies) {
      data.directorsInterestedCompanies.forEach((element) => {
        debugger
        if (element.dateOfFiling) {
          element.dateOfFiling = element.dateOfFiling.format("YYYY-MM-DD");
        }
      });
    }
    if (data.annualFilingsLast3Years) {
      data.annualFilingsLast3Years.forEach((element) => {
        if (element.dateOfFiling) {
          element.dateOfFiling = element.dateOfFiling.format("YYYY-MM-DD");
        }
      });
    }

    setPageLoading(true);
    apiService("startup/ldd/sectiontwo/save", data, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          navigator("/startup/scst/fdd-document/priority");
          // handleBackapp();
        }
      }
    });
  };

  // Date Format Function

  const handleDateFormat = (newValue, index) => {
    setValue(`shareHoldingPatterns.${index}.date`, newValue);
    clearErrors(`shareHoldingPatterns.${index}.date`);
  };
  const handleDateFormat2 = (newValue, index) => {
    setValue(`remunerationPaidToDirectors.${index}.date`, newValue);
    clearErrors(`remunerationPaidToDirectors.${index}.date`);
  };
  const handleDateFormat3 = (newValue, index) => {
    setValue(`detailsOfBorrowings.${index}.date`, newValue);
    clearErrors(`detailsOfBorrowings.${index}.date`);
  };
  const handleDateFormat4 = (newValue, index) => {
    setValue(`companyBoardMeetings.${index}.date`, newValue);
    clearErrors(`companyBoardMeetings.${index}.date`);
  };
  const handleDateFormat5 = (newValue, index) => {
    setValue(`companyGeneralMeetings.${index}.date`, newValue);
    clearErrors(`companyGeneralMeetings.${index}.date`);
  };
  const handleDateFormat6 = (newValue, index) => {
    setValue(`relatedPartyTransactions.${index}.boardAuthDate`, newValue);
    clearErrors(`relatedPartyTransactions.${index}.boardAuthDate`);
  };
  const handleDateFormat7 = (newValue, index) => {
    setValue(`detailsOfBorrowings.${index}.boardAuthDate`, newValue);
    clearErrors(`detailsOfBorrowings.${index}.boardAuthDate`);
  };
  const handleDateFormat8 = (newValue, index) => {
    setValue(`existingAuditorAppointments.${index}.date`, newValue);
    clearErrors(`existingAuditorAppointments.${index}.date`);
  };
  const handleDateFormat9 = (newValue, index) => {
    setValue(`directorsInterestedCompanies.${index}.dateOfFiling`, newValue);
    clearErrors(`directorsInterestedCompanies.${index}.dateOfFiling`);
  };
  const handleDateFormat10 = (newValue, index) => {
    setValue(`annualFilingsLast3Years.${index}.dateOfFiling`, newValue);
    clearErrors(`annualFilingsLast3Years.${index}.dateOfFiling`);
  };


  // Accordion Open states

  const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
  const [isAccordionOpen2, setIsAccordionOpen2] = useState(true);
  const [isAccordionOpen3, setIsAccordionOpen3] = useState(true);
  const [isAccordionOpen4, setIsAccordionOpen4] = useState(true);
  const [isAccordionOpen5, setIsAccordionOpen5] = useState(true);
  const [isAccordionOpen6, setIsAccordionOpen6] = useState(true);
  const [isAccordionOpen7, setIsAccordionOpen7] = useState(true);
  const [isAccordionOpen8, setIsAccordionOpen8] = useState(true);
  const [isAccordionOpen9, setIsAccordionOpen9] = useState(true);
  const [isAccordionOpen10, setIsAccordionOpen10] = useState(true);
  const [isAccordionOpen11, setIsAccordionOpen11] = useState(true);
  const [isAccordionOpen12, setIsAccordionOpen12] = useState(true);

  // shareHoldingPatterns

  const {
    fields: shareHoldingPatternsFields,
    append: shareHoldingPatternsAppend,
    remove: shareHoldingPatternsRemove,
  } = useFieldArray({
    control,
    name: "shareHoldingPatterns",
  });

  const adddirectorsFieldsBtn = () => {
    shareHoldingPatternsAppend({
      id: 0,
      name: "",
      date: "",
      shares: "",
      certfNo: "",
      dulyStamped: "",
    });
  };
  const deleteDirectorsInterested = async (index) => {
    const id = getValues(`shareHoldingPatterns.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/startupShareHoldPattern/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteDirectorsInterested(index);
          }
        }
      });
    } else {
      indexBaseddeleteDirectorsInterested(index);
    }
  };
  const indexBaseddeleteDirectorsInterested = (index) => {
    var startupTeams = getValues("shareHoldingPatterns");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.shareHoldingPatterns = [
        {
          id: 0,
          name: "",
          date: "",
          shares: "",
          certfNo: "",
          dulyStamped: "",
        },
      ];
      setFormData(val);
    } else {
      shareHoldingPatternsRemove(index);
      var Teams = getValues();
      setFormData(Teams);
    }
  };


  // companyStatutoryRegisters accordion

  const {
    fields: companyStatutoryRegistersFields,
    append: companyStatutoryRegistersAppend,
    remove: companyStatutoryRegistersRemove,
  } = useFieldArray({
    control,
    name: "companyStatutoryRegisters",
  });

  const addcompanyRegisterFieldsBtn = () => {
    companyStatutoryRegistersAppend({
      id: 0,
      details: "",
      remarks: "",
      statutoryRegisterDoc: "",
      statutoryRegisterDocName: "",
    });
  };

  const deletecompanyRegister = async (index) => {
    const id = getValues(`companyStatutoryRegisters.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/companystatutoryregisters?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletecompanyRegister(index);
          }
        }
      });
    } else {
      indexBaseddeletecompanyRegister(index);
    }
  };
  const indexBaseddeletecompanyRegister = (index) => {
    var startupTeams = getValues("companyStatutoryRegisters");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.companyStatutoryRegisters = [
        {
          id: 0,
          details: "",
          remarks: "",
          statutoryRegisterDoc: "",
          statutoryRegisterDocName: "",
        },
      ];
      setFormData(val);
    } else {
      companyStatutoryRegistersRemove(index);
      var Teams = getValues();
      setFormData(Teams);
    }
  };

  // companyBoardMeetings

  const {
    fields: companyBoardMeetingsFields,
    append: companyBoardMeetingsAppend,
    remove: companyBoardMeetingsRemove,
  } = useFieldArray({
    control,
    name: "companyBoardMeetings",
  });

  const addcompanyBoardMeetingsFieldsBtn = () => {
    companyBoardMeetingsAppend({
      id: 0,
      financialYear: "",
      date: "",
      remarks: "",
      type: "Board",
      maintainedBoardDoc: "",
      maintainedBoardDocName: ""
    });
  };
  const deletecompanyBoardMeetings = async (index) => {
    const id = getValues(`companyBoardMeetings.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/companymeeting?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletecompanyBoardMeetings(index);
          }
        }
      });
    } else {
      indexBaseddeletecompanyBoardMeetings(index);
    }
  };
  const indexBaseddeletecompanyBoardMeetings = (index) => {
    var startupTeams = getValues("companyBoardMeetings");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.companyBoardMeetings = [
        {
          id: 0,
          financialYear: "",
          date: "",
          remarks: "",
          type: "Board",
          maintainedBoardDoc: "",
          maintainedBoardDocName: ""
        },
      ];
      setFormData(val);
    } else {
      companyBoardMeetingsRemove(index);
      var Teams = getValues();
      setFormData(Teams);
    }
  };

  // companyGeneralMeetings

  const {
    fields: companyGeneralMeetingsFields,
    append: companyGeneralMeetingsAppend,
    remove: companyGeneralMeetingsRemove,
  } = useFieldArray({
    control,
    name: "companyGeneralMeetings",
  });

  const addcompanyGeneralMeetingsFieldsBtn = () => {
    companyGeneralMeetingsAppend({
      id: 0,
      financialYear: "",
      date: "",
      remarks: "",
      type: "General",
      maintainedBoardDoc: "",
      maintainedBoardDocName: ""
    });
  };
  const deletecompanyGeneralMeetings = async (index) => {
    const id = getValues(`companyGeneralMeetings.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/companymeeting?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletecompanyGeneralMeetings(index);
          }
        }
      });
    } else {
      indexBaseddeletecompanyGeneralMeetings(index);
    }
  };
  const indexBaseddeletecompanyGeneralMeetings = (index) => {
    var startupTeams = getValues("companyGeneralMeetings");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.companyGeneralMeetings = [
        {
          id: 0,
          financialYear: "",
          date: "",
          remarks: "",
          type: "Board",
          maintainedBoardDoc: "",
          maintainedBoardDocName: ""
        },
      ];
      setFormData(val);
    } else {
      companyGeneralMeetingsRemove(index);
      var Teams = getValues();
      setFormData(Teams);
    }
  };


  // companyRocFiled

  const {
    fields: companyRocFiledFields,
    append: companyRocFiledAppend,
    remove: companyRocFiledRemove,
  } = useFieldArray({
    control,
    name: "companyRocFiled",
  });

  const addcompanyRocFiledFieldsbtn = () => {
    companyRocFiledAppend({
      id: 0,
      financialYear: "",
      formFiled: "",
      remarks: "",
      filedRocDoc: "",
      filedRocDocName: ""
    });
  };

  const deletecompanyRocFiled = async (index) => {
    const id = getValues(`companyRocFiled.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/companyrocfiled?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletecompanyRocFiled(index);
          }
        }
      });
    } else {
      indexBaseddeletecompanyRocFiled(index);
    }
  };
  const indexBaseddeletecompanyRocFiled = (index) => {
    var startupTeams = getValues("companyRocFiled");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.companyRocFiled = [
        {
          id: 0,
          financialYear: "",
          formFiled: "",
          remarks: "",
          filedRocDoc: "",
          filedRocDocName: ""
        },
      ];
      setFormData(val);
    } else {
      companyRocFiledRemove(index);
      var Teams = getValues();
      setFormData(Teams);
    }
  };

  // directorsInterestedCompanies

  const {
    fields: directorsInterestedCompaniesFields,
    append: directorsInterestedCompaniesAppend,
    remove: directorsInterestedCompaniesRemove,
  } = useFieldArray({
    control,
    name: "directorsInterestedCompanies",
  });
  const addRemunerationPaidToDirectorsBtn = () => {
    directorsInterestedCompaniesAppend({
      id: 0,
      name: "",
      address: "",
      din: "",
      interested: "",
    });
  };
  const deleteDirectorsInterestedCompanies = async (index) => {
    const id = getValues(`directorsInterestedCompanies.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/startupDirectorsIntCompanies/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletedirectorsInterestedCompanies(index);
          }
        }
      });
    } else {
      indexBaseddeletedirectorsInterestedCompanies(index);
    }
  };
  const indexBaseddeletedirectorsInterestedCompanies = (index) => {
    var startupTeams = getValues("directorsInterestedCompanies");
    if (startupTeams.length === 0 && index === 0) {
      var directors = getValues();
      directors.directorsInterestedCompanies = [
        {
          id: 0,
          name: "",
          address: "",
          din: "",
          interested: "",
        },
      ];
      setFormData(directors);
    } else {
      directorsInterestedCompaniesRemove(index);
      var directorsInter = getValues();
      setFormData(directorsInter);
    }
  };

  // remunerationPaidToDirectors

  const {
    fields: remunerationPaidToDirectorsFields,
    append: remunerationPaidToDirectorsAppend,
    remove: remunerationPaidToDirectorsRemove,
  } = useFieldArray({
    control,
    name: "remunerationPaidToDirectors",
  });

  const adddirectorsInterestedCompaniesAppendBtn = () => {
    remunerationPaidToDirectorsAppend({
      id: 0,
      name: "",
      fy: "",
      date: "",
      remuneration: "",
    });
  };

  const deleteRemunerationPaidToDirectors = async (index) => {
    const id = getValues(`remunerationPaidToDirectors.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/remunerationPaidToDirectors/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletedremunerationPaidToDirectors(index);
          }
        }
      });
    } else {
      indexBaseddeletedremunerationPaidToDirectors(index);
    }
  };

  const indexBaseddeletedremunerationPaidToDirectors = (index) => {
    var startupTeams = getValues("remunerationPaidToDirectors");
    if (startupTeams.length === 0 && index === 0) {
      var remuneration = getValues();
      remuneration.remunerationPaidToDirectors = [
        {
          id: 0,
          name: "",
          fy: "",
          date: "",
          remuneration: "",
        },
      ];
      setFormData(remuneration);
    } else {
      remunerationPaidToDirectorsRemove(index);
      var PaidToDirectors = getValues();
      setFormData(PaidToDirectors);
    }
  };

  // relatedPartyTransactions

  const {
    fields: relatedPartyTransactionsFields,
    append: relatedPartyTransactionsAppend,
    remove: relatedPartyTransactionsRemove,
  } = useFieldArray({
    control,
    name: "relatedPartyTransactions",
  });
  const addrelatedPartyTransactionsBtn = () => {
    relatedPartyTransactionsAppend({
      id: 0,
      name: "",
      nature: "",
      amount: "",
      fy: "",
      boardAuthDate: "",
      dpt3Filed: "",
      directorLoanDeclarationDoc: "",
      directorLoanDeclarationDocName: ""
    });
  };
  const deleteRelatedPartyTransactions = async (index) => {
    const id = getValues(`relatedPartyTransactions.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/relatedPartyTransactions/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteRelatedPartyTransactions(index);
          }
        }
      });
    } else {
      indexBaseddeleteRelatedPartyTransactions(index);
    }
  };
  const indexBaseddeleteRelatedPartyTransactions = (index) => {
    var startupTeams = getValues("relatedPartyTransactions");
    if (startupTeams.length === 0 && index === 0) {
      var relatedParty = getValues();
      relatedParty.relatedPartyTransactions = [
        {
          id: 0,
          name: "",
          nature: "",
          amount: "",
          fy: "",
          boardAuthDate: "",
          dpt3Filed: "",
          directorLoanDeclarationDoc: "",
          directorLoanDeclarationDocName: ""
        },
      ];
      setFormData(relatedParty);
    } else {
      relatedPartyTransactionsRemove(index);
      var Transactions = getValues();
      setFormData(Transactions);
    }
  };

  // detailsOfBorrowings

  const {
    fields: detailsOfBorrowingsFields,
    append: detailsOfBorrowingsAppend,
    remove: detailsOfBorrowingsRemove,
  } = useFieldArray({
    control,
    name: "detailsOfBorrowings",
  });
  const addDetailsOfBorrowingsBtn = () => {
    detailsOfBorrowingsAppend({
      id: 0,
      name: "",
      date: "",
      amount: "",
      secured: "",
      boardAuthDate: "",
      termsOrInterest: "",
      dpt3Filed: "",
    });
  };
  const deleteDetailsOfBorrowings = async (index) => {
    const id = getValues(`detailsOfBorrowings.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/detailsOfBorrowings/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteDetailsOfBorrowings(index);
          }
        }
      });
    } else {
      indexBaseddeleteDetailsOfBorrowings(index);
    }
  };
  const indexBaseddeleteDetailsOfBorrowings = (index) => {
    var startupTeams = getValues("detailsOfBorrowings");
    if (startupTeams.length === 0 && index === 0) {
      var detailsOf = getValues();
      detailsOf.detailsOfBorrowings = [
        {
          id: 0,
          name: "",
          date: "",
          amount: "",
          secured: "",
          termsOrInterest: "",
          dpt3Filed: "",
        },
      ];
      setFormData(detailsOf);
    } else {
      detailsOfBorrowingsRemove(index);
      var Borrowings = getValues();
      setFormData(Borrowings);
    }
  };


  // companyInvestmentsOrLoan

  const {
    fields: companyInvestmentsOrLoanFields,
    append: companyInvestmentsOrLoanAppend,
    remove: companyInvestmentsOrLoanRemove,
  } = useFieldArray({
    control,
    name: "companyInvestmentsOrLoan",
  });
  const addCompanyInvestmentsOrLoanBtn = () => {
    companyInvestmentsOrLoanAppend({
      id: 0,
      name: "",
      amount: "",
      nature: "",
      fy: "",
    });
  };
  const deleteCompanyInvestmentsOrLoan = async (index) => {
    const id = getValues(`companyInvestmentsOrLoan.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/companyInvestmentsOrLoan/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteCompanyInvestmentsOrLoan(index);
          }
        }
      });
    } else {
      indexBaseddeleteCompanyInvestmentsOrLoan(index);
    }
  };
  const indexBaseddeleteCompanyInvestmentsOrLoan = (index) => {
    var startupTeams = getValues("companyInvestmentsOrLoan");
    if (startupTeams.length === 0 && index === 0) {
      var company = getValues();
      company.companyInvestmentsOrLoan = [
        {
          id: 0,
          name: "",
          amount: "",
          nature: "",
          fy: "",
        },
      ];
      setFormData(company);
    } else {
      companyInvestmentsOrLoanRemove(index);
      var Investments = getValues();
      setFormData(Investments);
    }
  };


  // last 3 years 
  const {
    fields: annualFilingsLast3YearsFields,
    append: annualFilingsLast3YearsAppend,
    remove: annualFilingsLast3YearsRemove,
  } = useFieldArray({
    control,
    name: "annualFilingsLast3Years",
  });

  const addannualFilingsLast3YearsFieldsBtn = () => {
    annualFilingsLast3YearsAppend({
      id: 0,
      financialYear: "",
      formFiled: "",
      dateOfFiling: ""
    });
  };
  const deleteannualFilingsLast3YearsFields = async (index) => {
    const id = getValues(`annualFilingsLast3Years.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/annualfilings?id=${id}`, "", "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteannualFilingsLast3Years(index);
          }
        }
      });
    } else {
      indexBaseddeleteannualFilingsLast3Years(index);
    }
  };

  const indexBaseddeleteannualFilingsLast3Years = (index) => {
    var startupTeams = getValues("annualFilingsLast3Years");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.existingAuditorAppointments = [
        {
          id: 0,
          financialYear: "",
          formFiled: "",
          dateOfFiling: ""
        },
      ];
      setFormData(val);
    } else {
      annualFilingsLast3YearsRemove(index);
      var Teams = getValues();
      setFormData(Teams);
    }
  };


  // existingAuditorAppointments

  const {
    fields: existingAuditorAppointmentsFields,
    append: existingAuditorAppointmentsAppend,
    remove: existingAuditorAppointmentsRemove,
  } = useFieldArray({
    control,
    name: "existingAuditorAppointments",
  });

  const addexistingAuditorAppointmentsFieldsBtn = () => {
    existingAuditorAppointmentsAppend({
      id: 0,
      name: "",
      period: "",
      remuneration: "",
      date: "",
    });
  };
  const deleteexistingAuditorAppointments = async (index) => {
    const id = getValues(`existingAuditorAppointments.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/delete/existingauditor?id=${id}`, "", "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteexistingAuditorAppointments(index);
          }
        }
      });
    } else {
      indexBaseddeleteexistingAuditorAppointments(index);
    }
  };
  const indexBaseddeleteexistingAuditorAppointments = (index) => {
    var startupTeams = getValues("existingAuditorAppointments");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.existingAuditorAppointments = [
        {
          id: 0,
          name: "",
          period: "",
          remuneration: "",
          date: "",
        },
      ];
      setFormData(val);
    } else {
      existingAuditorAppointmentsRemove(index);
      var Teams = getValues();
      setFormData(Teams);
    }
  };


  // Yes or no Save and doc state make true

  const LicensedCheck = (val) => {
    // setLicensedSoftware(val);
    var req = {
      confirmOfKMPAppointment: val,
      id: fileUploadId,
    };
    saveData(req);
  };
  const WebsiteDisclosureCheck = (val) => {
    // setWebsiteShow(val);
    var req = {
      companyHaveWebsite: val,
      id: fileUploadId,
    };
    saveData(req);
  };

  const FemaissueCheck = (val) => {
    var req = {
      shareIssueTransferForms: val,
      id: fileUploadId,
    };
    saveData(req);
  }


  const FemaissueCheck1 = (val) => {
    setFirmissue1(val);
    var req = {
      annualReturns: val,
      id: fileUploadId,
    };
    saveData(req);
  };

  const FemaissueCheck2 = (val) => {
    var req = {
      firmsPortal: val,
      id: fileUploadId,
    };
    saveData(req);
  }
  const annretCheck = (val) => {
    var req = {
      annualReturnDisclosure: val,
      id: fileUploadId,
    };
    saveData(req);
  }
  const compDetCheck = (val) => {
    var req = {
      companyDetailsOnWebsite: val,
      id: fileUploadId,
    };
    saveData(req);
  }

  const partyTo = (val) => {
    setpartyToDoc(val);
    debugger
    var req = {
      companyGuarantee: val,
      id: fileUploadId,
    };
    saveData(req);
  };

  const ComplianceCheck = (val) => {
    setComplianceCheck(val);
    debugger
    var req = {
      section185186ComplianceCheck: val,
      id: fileUploadId,
    };
    saveData(req);
  };

  const handlepartyToDoc = (e) => {
    handleFileUpload(
      e,
      "companyGuaranteeDoc",
      "companyGuaranteeDocName",
      "companyGuaranteeDocURL",
      "file"
    );
  };

  const handleannualReturns = (e) => {
    handleFileUpload(
      e,
      "annualReturnsDoc",
      "annualReturnsDocName",
      "annualReturnsDocURL",
      "file"
    );
  };


  const RocDoc = (val, index) => {
    setFormFiled(val)
    debugger
    setValue(`companyRocFiled.${index}.formFiled`, val)
    clearErrors(`businessSpecificLicenses.${index}.obtained`);
    debugger
  }

  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };


  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src="/images/Fig-back.png" className="img-fluid" alt="" />
            </span>
            <h6 className="ml-2 mb-0">
              Certificate from a Practising Company Secretary{" "}
            </h6>
          </div>
          <hr />

          <form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="row doc-row">

              <div className="col-lg-12  col-12 mt-2">
                <FormLabel sx={{ color: "blue" }}>
                  Please provide a Certificate from a Practising Company Secretary
                  / Chartered Accountant covering the following declarations given below:
                </FormLabel>
              </div>

              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Confirmation that Company has appointed MD/WTD/CFO/Co Secy/KMP as required:
                  </FormLabel>
                  <Controller
                    name="confirmOfKMPAppointment"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            LicensedCheck(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            LicensedCheck(e.target.value);
                            setValue("confirmOfKMPAppointment", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.confirmOfKMPAppointment && errors.confirmOfKMPAppointment.message}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Confirm that there are no guarantees that the Company is a party to. If so, to provide details of the same
                  </FormLabel>
                  <Controller
                    name="companyGuarantee"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            partyTo(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            partyTo(e.target.value);
                            setValue("companyGuaranteeDoc", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.companyGuarantee &&
                      errors.companyGuarantee.message}
                  </FormHelperText>
                </div>
              </div>
              {(partyToDoc === "true" ||
                partyToDoc === true) && (
                  <>
                    <div className="col-lg-4  col-12 mt-2">
                      <div className="d-flex align-items-center">
                        <div className="form-group my-1">
                          <Controller
                            name="companyGuaranteeDoc"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <FileUploader
                                {...field}
                                handleChange={handlepartyToDoc}
                                value={getValues("companyGuaranteeDoc")}
                                name="file"
                                types={fileTypesAll}
                                children={
                                  <div
                                    className={
                                      getValues("companyGuaranteeDoc") !==
                                        ""
                                        ? "fileupload-pdf-dpiit-bg1-active"
                                        : "fileupload-pdf-dpiit-bg1"
                                    }
                                  >
                                    <center>
                                      <p
                                        className={
                                          getValues(
                                            "companyGuaranteeDoc"
                                          ) !== ""
                                            ? "fileupload-pdf-active"
                                            : "fileupload-pdf"
                                        }
                                      >
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues(
                                            "companyGuaranteeDoc"
                                          ) !== ""
                                            ? "Uploaded (Click to Change)"
                                            : "Company Guarantee Document"}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            )}
                          />
                          <FormHelperText className="ml-2">
                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                          </FormHelperText>
                          <FormHelperText
                            sx={{ marginLeft: "5px" }}
                            className="text-danger"
                          >
                            {errors.companyGuaranteeDoc &&
                              errors.companyGuaranteeDoc.message}
                          </FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"
                          onClick={() =>
                            handleClickOpens(
                              getValues("companyGuaranteeDocURL")
                            )
                          }
                        >
                          <VisibilityIcon className="icon-size mr-1" />
                          <Link
                          
                            style={{ color: "#80809e" }}
                          >
                            {" "}
                            Download
                          </Link>
                        </span>
                      </div>
                    </div>
                  </>
                )}

              {/* <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Confirm that there are no guarantees that the Company is a party to. If so, to provide details of the same
                  </FormLabel>
                  <Controller
                    name="companyGuarantee"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            partyTo(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            partyTo(e.target.value);
                            setValue("companyGuarantee", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.companyGuarantee &&
                      errors.companyGuarantee.message}
                  </FormHelperText>
                </div>
              </div> */}


              {/* Section 185/186 field*/}

              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Please confirm whether Section 185 / Section 186 of Companies Act has been attracted to any of the transactions undertaken by the Company and if so what steps the Company has taken in this regard.
                  </FormLabel>
                  <Controller
                    name="section185186ComplianceCheck"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            ComplianceCheck(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            ComplianceCheck(e.target.value);
                            setValue("section185186Compliance", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.section185186ComplianceCheck &&
                      errors.section185186ComplianceCheck.message}
                  </FormHelperText>
                </div>
              </div>
              {(getValues(`section185186ComplianceCheck`) === "true" ||
                getValues(`section185186ComplianceCheck`) === true) && (
                  <>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="form-group my-1">
                        <Controller
                          name="section185186Compliance"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Compliance is Required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              label="Compliance *"
                              placeholder="Enter Compliance"
                              fullWidth
                              multiline
                              rows={3}
                              disabled={readyOnlyValue}
                              // inputProps={{
                              //   readOnly: readyOnlyValue,
                              // }}
                              onBlur={() => saveData({ section185186Compliance: field.value, id: fileUploadId })}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.section185186Compliance && errors.section185186Compliance.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </>
                )}

              {/* Website Declaration */}
              <div className="col-lg-12 col-12">
                <FormLabel sx={{ color: "blue" }}>Website Disclosure: </FormLabel>
                <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="form-group">
                      <FormLabel>
                        Does Company have a Website
                      </FormLabel>
                      <Controller
                        name="companyHaveWebsite"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <RadioGroup row name="position" {...field}>
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                WebsiteDisclosureCheck(e.target.value);
                              }}
                              defaultValue="null"
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                WebsiteDisclosureCheck(e.target.value);
                                setValue("companyHaveWebsite", "");
                              }}
                              defaultValue="null"
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.companyHaveWebsite && errors.companyHaveWebsite.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  {(getValues('companyHaveWebsite') === "true" || getValues('companyHaveWebsite') === true) && (
                    <>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="form-group">
                          <FormLabel>
                            If so, has it disclosed AGM Notice & Annual Return on Website </FormLabel>
                          <Controller
                            name="annualReturnDisclosure"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <RadioGroup row name="position" {...field}>
                                <FormControlLabel
                                  disabled={readyOnlyValue}
                                  onChange={(e) => {
                                    annretCheck(e.target.value);
                                  }}
                                  defaultValue="null"
                                  value="true"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  disabled={readyOnlyValue}
                                  onChange={(e) => {
                                    annretCheck(e.target.value);
                                    setValue("annualReturnDisclosure", "");
                                  }}
                                  defaultValue="null"
                                  value="false"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.annualReturnDisclosure && errors.annualReturnDisclosure.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="form-group">
                          <FormLabel>
                            Does the Website contain details of Registered Office, CIN etc. of the Company
                          </FormLabel>
                          <Controller
                            name="companyDetailsOnWebsite"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <RadioGroup row name="position" {...field}>
                                <FormControlLabel
                                  disabled={readyOnlyValue}
                                  onChange={(e) => {
                                    compDetCheck(e.target.value);
                                  }}
                                  defaultValue="null"
                                  value="true"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  disabled={readyOnlyValue}
                                  onChange={(e) => {
                                    compDetCheck(e.target.value);
                                    setValue("companyDetailsOnWebsite", "");
                                  }}
                                  defaultValue="null"
                                  value="false"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.companyDetailsOnWebsite && errors.companyDetailsOnWebsite.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                    </>
                  )}

                </Grid>
              </div>

              {/* FEMA Compliances */}

              <div className="col-lg-12 col-12">
                <FormLabel sx={{ color: "blue" }}>FEMA Compliances: </FormLabel>
                <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="form-group">
                      <FormLabel>
                        Details of all forms filed with for Issue /Transfer of shares
                      </FormLabel>
                      {/* <Controller
                        name="shareIssueTransferForms"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <RadioGroup row name="position" {...field}>
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                FemaissueCheck(e.target.value);
                              }}
                              defaultValue="null"
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                FemaissueCheck(e.target.value);
                                setValue("shareIssueTransferForms", "");
                              }}
                              defaultValue="null"
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      /> */}
                      <div className="form-group mt-2">
                        <Controller
                          name="shareIssueTransferForms"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Details"
                              placeholder="Enter Details"
                              fullWidth
                              multiline
                              rows={3}
                              {...field}
                              // onBlur={handleBlur}
                              onBlur={() => saveData({ shareIssueTransferForms: field.value, id: fileUploadId })}
                              disabled={readyOnlyValue}
                              inputProps={{ maxLength: 10000 }}
                            />
                          )}
                        />
                        <FormHelperText sx={{ float: 'right' }}>
                          Max 10000 Characters
                        </FormHelperText>
                      </div>
                      <FormHelperText className="text-danger">
                        {errors.shareIssueTransferForms && errors.shareIssueTransferForms.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="form-group mt-2">
                      <FormLabel>
                        Annual Returns / FLA/ APR duly filed
                      </FormLabel>
                      <Controller
                        name="annualReturns"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <RadioGroup row name="position" {...field}>
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                FemaissueCheck1(e.target.value);
                              }}
                              defaultValue="null"
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                FemaissueCheck1(e.target.value);
                                setValue("annualReturnsDoc", "");
                              }}
                              defaultValue="null"
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.annualReturns && errors.annualReturns.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  {(firmissue1 === "true" ||
                    firmissue1 === true) && (
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="col-lg-4  col-12 mt-2">
                          <div className="d-flex align-items-center">
                            <div className="form-group my-1">
                              <Controller
                                name="annualReturnsDoc"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Annual Returns Document Required" }}
                                render={({ field }) => (
                                  <FileUploader
                                    {...field}
                                    handleChange={handleannualReturns}
                                    value={getValues("annualReturnsDoc")}
                                    name="file"
                                    types={fileTypesAll}
                                    children={
                                      <div
                                        className={
                                          getValues("annualReturnsDoc") !==
                                            ""
                                            ? "fileupload-pdf-dpiit-bg1-active"
                                            : "fileupload-pdf-dpiit-bg1"
                                        }
                                      >
                                        <center>
                                          <p
                                            className={
                                              getValues(
                                                "annualReturnsDoc"
                                              ) !== ""
                                                ? "fileupload-pdf-active"
                                                : "fileupload-pdf"
                                            }
                                          >
                                            <span>
                                              <AttachFileIcon className="file-icon mr-2" />
                                              {getValues(
                                                "annualReturnsDoc"
                                              ) !== ""
                                                ? "Uploaded (Click to Change)"
                                                : "Annual Returns Document"}
                                            </span>
                                          </p>
                                        </center>
                                      </div>
                                    }
                                    disabled={readyOnlyValue}
                                  />
                                )}
                              />
                              <FormHelperText className="ml-2">
                                Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                              </FormHelperText>
                              <FormHelperText
                                sx={{ marginLeft: "5px" }}
                                className="text-danger"
                              >
                                {errors.annualReturnsDoc &&
                                  errors.annualReturnsDoc.message}
                              </FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit"
                             onClick={() =>
                              handleClickOpens(
                                getValues("annualReturnsDocURL")
                              )
                            }
                            >
                              <VisibilityIcon className="icon-size mr-1" />
                              <Link
                               
                                style={{ color: "#80809e" }}
                              >
                                {" "}
                                Download
                              </Link>
                            </span>
                          </div>
                        </div>
                      </Grid>
                    )}
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="form-group mt-2">
                      <FormLabel>
                        Whether FIRMS portal duly updated
                      </FormLabel>
                      <Controller
                        name="firmsPortal"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <RadioGroup row name="position" {...field}>
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                FemaissueCheck2(e.target.value);
                              }}
                              defaultValue="null"
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              disabled={readyOnlyValue}
                              onChange={(e) => {
                                FemaissueCheck2(e.target.value);
                                setValue("firmsPortal", "");
                              }}
                              defaultValue="null"
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.firmsPortal && errors.firmsPortal.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {/* statutory registers */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen7}
                  onChange={() => setIsAccordionOpen7(!isAccordionOpen7)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      We hereby confirm that the Company has maintained the following statutory registers as required under the Companies Act, 2013:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companyStatutoryRegistersFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {companyStatutoryRegistersFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "25%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyStatutoryRegisters.${index}.details`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Details of Statutory is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Details of Statutory Registers  *"
                                                  placeholder="Enter Details of Statutory"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.companyStatutoryRegisters?.[
                                                index
                                              ]?.details &&
                                                errors.companyStatutoryRegisters?.[
                                                  index
                                                ]?.details.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "25%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyStatutoryRegisters.${index}.remarks`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Remarks is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Remarks *"
                                                  placeholder="Enter Remarks"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.companyStatutoryRegisters?.[
                                                index
                                              ]?.remarks &&
                                                errors.companyStatutoryRegisters?.[
                                                  index
                                                ]?.remarks.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "30%",
                                            borderBottom: "none",
                                            padding: "5px",
                                            marginTop: '20px'
                                          }}
                                        >
                                          {
                                            getValues(`companyStatutoryRegisters.${index}.statutoryRegisterDocName`) !== '' && <span>Upload Statutory Register Document <span className='text-danger'>*</span></span>
                                          }
                                          <div className="d-flex align-items-center">
                                            <div className='form-group my-1'>
                                              <Controller name={`companyStatutoryRegisters.${index}.statutoryRegisterDoc`} control={control} defaultValue="" rules={{ required: "Document is Required" }}
                                                render={({ field }) =>

                                                  <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                      `companyStatutoryRegisters.${index}.statutoryRegisterDoc`,
                                                      `companyStatutoryRegisters.${index}.statutoryRegisterDocName`,
                                                      `companyStatutoryRegisters.${index}.statutoryRegisterDocURL`,
                                                      'file',
                                                      'nosave'
                                                    )}
                                                    value={getValues(`companyStatutoryRegisters.${index}.statutoryRegisterDoc`)}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={

                                                      <div className={getValues(`companyStatutoryRegisters.${index}.statutoryRegisterDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                        <center>
                                                          <p className={getValues(`companyStatutoryRegisters.${index}.statutoryRegisterDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                          // style={{
                                                          //   backgroundColor: nonEditAble
                                                          //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                          //     : ''
                                                          // }}
                                                          >
                                                            <span>
                                                              <AttachFileIcon className="file-icon mr-2" />
                                                              {getValues(`companyStatutoryRegisters.${index}.statutoryRegisterDoc`) !== '' ? 'Uploaded (Click to Change)' : ' Statutory Register Document'}
                                                            </span>
                                                          </p>
                                                        </center>
                                                      </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                  // disabled={nonEditAble}
                                                  />
                                                } />
                                              <FormHelperText className="ml-2">
                                                Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                              </FormHelperText>
                                              <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.companyStatutoryRegisters?.[index]?.statutoryRegisterDoc && errors.companyStatutoryRegisters?.[index]?.statutoryRegisterDoc.message}</FormHelperText>

                                            </div>
                                            <span className="pre-btn-dpiit"
                                            onClick={() => handleClickOpens(getValues(`companyStatutoryRegisters.${index}.statutoryRegisterDocURL`))}
                                            ><VisibilityIcon className='icon-size mr-1' /><Link
                                              
                                              style={{ color: "#80809e" }}
                                            > Download
                                            </Link>
                                            </span>
                                          </div>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deletecompanyRegister(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addcompanyRegisterFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* ROC FILED */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen9}
                  onChange={() => setIsAccordionOpen9(!isAccordionOpen9)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      We hereby confirm that the Company has filed the following forms with ROC for
                      the period specified below and also confirm that other than the above mentioned forms,
                      there are no forms which are required to be filed with the ROC
                      during the period under review:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companyRocFiledFields.length >= 0 && (
                            <>
                              {companyRocFiledFields.map(
                                (field, index) => (
                                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index} spacing={3}>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                      sx={{ borderBottom: "none", }}>
                                      {index + 1}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%", paddingTop: '9px !important' }}>
                                        <Controller
                                          name={`companyRocFiled.${index}.financialYear`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Financial year is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Financial Year *"
                                              placeholder="Enter Financial Year"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors
                                            .companyRocFiled?.[
                                            index
                                          ]?.financialYear &&
                                            errors
                                              .companyRocFiled?.[
                                              index
                                            ]?.financialYear.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={formFiled === "Yes" ? 3 : 4} lg={formFiled !== "Yes" ? 3 : 4} xl={formFiled !== "Yes" ? 3 : 4}>
                                      <FormControl sx={{ width: "100%", paddingTop: '5px !important' }}>
                                        <Controller
                                          name={`companyRocFiled.${index}.remarks`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Remarks is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Remarks *"
                                              placeholder="Enter Remarks"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.companyRocFiled?.[
                                            index
                                          ]?.remarks &&
                                            errors.companyRocFiled?.[
                                              index
                                            ]?.remarks.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <FormLabel>
                                          Form Filed *
                                        </FormLabel>
                                        <Controller
                                          name={`companyRocFiled.${index}.formFiled`}
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: "Form Filed is Required" }}
                                          render={({ field }) => (
                                            <RadioGroup
                                              row
                                              name="position"
                                              {...field}
                                              onChange={(e) => {
                                                RocDoc(e.target.value, index);
                                              }}
                                            >
                                              <FormControlLabel
                                                disabled={readyOnlyValue}
                                                // onClick={(e) => {
                                                //   setValue(`companyRocFiled.${index}.formFiled`,e.target.value)
                                                // }}
                                                defaultValue="null"
                                                value="true"
                                                control={<Radio />}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                disabled={readyOnlyValue}
                                                // onClick={(e) => {
                                                //   setValue(`companyRocFiled.${index}.formFiled`,e.target.value)
                                                //   setValue(`companyRocFiled.${index}.filedRocDocName`, "")
                                                // }}
                                                defaultValue="null"
                                                value="false"
                                                control={<Radio />}
                                                label="No"
                                              />
                                            </RadioGroup>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.companyRocFiled?.[
                                            index
                                          ]?.formFiled &&
                                            errors.companyRocFiled?.[
                                              index
                                            ]?.formFiled.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>


                                    {(getValues(`companyRocFiled.${index}.formFiled`) === "true" || getValues(`companyRocFiled.${index}.formFiled`) === true) ? (
                                      <>
                                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                          {
                                            getValues(`companyRocFiled.${index}.filedRocDocName`) !== '' && <span>Upload ROC Document <span className='text-danger'>*</span></span>
                                          }
                                          <div className="d-flex align-items-center">
                                            <div className='form-group my-1'>
                                              <Controller name={`companyRocFiled.${index}.filedRocDoc`} control={control} defaultValue="" rules={{ required: "Document is Required" }}
                                                render={({ field }) =>

                                                  <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                      `companyRocFiled.${index}.filedRocDoc`,
                                                      `companyRocFiled.${index}.filedRocDocName`,
                                                      `companyRocFiled.${index}.filedRocDocURL`,
                                                      'file',
                                                      'nosave'
                                                    )}
                                                    value={getValues(`companyRocFiled.${index}.filedRocDoc`)}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={

                                                      <div className={getValues(`companyRocFiled.${index}.filedRocDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                        <center>
                                                          <p className={getValues(`companyRocFiled.${index}.filedRocDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                          // style={{
                                                          //   backgroundColor: nonEditAble
                                                          //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                          //     : ''
                                                          // }}
                                                          >
                                                            <span>
                                                              <AttachFileIcon className="file-icon mr-2" />
                                                              {getValues(`companyRocFiled.${index}.filedRocDoc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                            </span>
                                                          </p>
                                                        </center>
                                                      </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                  // disabled={nonEditAble}
                                                  />
                                                } />
                                              <FormHelperText className="ml-2">
                                                Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                              </FormHelperText>
                                              <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.companyRocFiled?.[index]?.filedRocDoc && errors.companyRocFiled?.[index]?.filedRocDoc.message}</FormHelperText>

                                            </div>
                                            <span className="pre-btn-dpiit"
                                            onClick={() => handleClickOpens(getValues(`companyRocFiled.${index}.filedRocDocURL`))}
                                            ><VisibilityIcon className='icon-size mr-1' /><Link
                                              
                                              style={{ color: "#80809e" }}
                                            > Download
                                            </Link>
                                            </span>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                      </>
                                    ) : (<></>)}

                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                      <DeleteIcon
                                        sx={{
                                          backgroudColor: "#F4F4F4",
                                          fontSize: "24px",
                                          fill: "#808080",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          readyOnlyValue === false ? (
                                            deletecompanyRocFiled(index)
                                          ) : (
                                            <></>
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addcompanyRocFiledFieldsbtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* Board Meeting */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen8}
                  onChange={() => setIsAccordionOpen8(!isAccordionOpen8)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      The following are the details of Board meetings held for the period
                      given below and we hereby confirm that the Company has maintained all
                      Board Meeting Minutes, notices, attendance registers and related documents
                      as per the requirements of the Companies Act, 2013 and the Secretarial
                      Standards.
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companyBoardMeetingsFields.length >= 0 && (
                            <>
                              {companyBoardMeetingsFields.map(
                                (field, index) => (
                                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index} spacing={3}>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                      sx={{
                                        borderBottom: "none",

                                      }}
                                    >
                                      {index + 1}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%", paddingTop: '9px !important' }}>
                                        <Controller
                                          name={`companyBoardMeetings.${index}.financialYear`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Financial Year is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Financial Year *"
                                              placeholder="Enter Financial Year"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors
                                            .companyBoardMeetings?.[
                                            index
                                          ]?.financialYear &&
                                            errors
                                              .companyBoardMeetings?.[
                                              index
                                            ]?.financialYear.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`companyBoardMeetings.${index}.date`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Date of Board Meeting is required",
                                          }}
                                          render={({ field }) => (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                                fullWidth
                                              >
                                                <DatePicker
                                                  label="Date of Board Meeting *"
                                                  format="DD/MM/YYYY"
                                                  value={
                                                    field.value || null
                                                  }
                                                  sx={{ width: "100%" }}
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) =>
                                                    handleDateFormat4(
                                                      newValue,
                                                      index
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                    />
                                                  )}
                                                  disabled={readyOnlyValue}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.companyBoardMeetings?.[
                                            index
                                          ]?.date &&
                                            errors.companyBoardMeetings?.[
                                              index
                                            ]?.date.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%", paddingTop: '5px !important' }}>
                                        <Controller
                                          name={`companyBoardMeetings.${index}.remarks`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Remarks is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Remarks *"
                                              placeholder="Enter Remarks"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.companyBoardMeetings?.[
                                            index
                                          ]?.remarks &&
                                            errors.companyBoardMeetings?.[
                                              index
                                            ]?.remarks.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                          <Controller name={`companyBoardMeetings.${index}.maintainedBoardDoc`} control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                              <FileUploader
                                                {...field}
                                                handleChange={(e) => handleFileUpload(e,
                                                  `companyBoardMeetings.${index}.maintainedBoardDoc`,
                                                  `companyBoardMeetings.${index}.maintainedBoardDocName`,
                                                  `companyBoardMeetings.${index}.maintainedBoardDocURL`,
                                                  'file',
                                                  'nosave'
                                                )}
                                                value={getValues(`companyBoardMeetings.${index}.maintainedBoardDoc`)}
                                                name="file"
                                                types={fileTypesAll}
                                                children={

                                                  <div className={getValues(`companyBoardMeetings.${index}.maintainedBoardDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                      <p className={getValues(`companyBoardMeetings.${index}.maintainedBoardDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                      // style={{
                                                      //   backgroundColor: nonEditAble
                                                      //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                      //     : ''
                                                      // }}
                                                      >
                                                        <span>
                                                          <AttachFileIcon className="file-icon mr-2" />
                                                          {getValues(`companyBoardMeetings.${index}.maintainedBoardDoc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                        </span>
                                                      </p>
                                                    </center>
                                                  </div>
                                                }
                                                disabled={readyOnlyValue}
                                              // disabled={nonEditAble}
                                              />
                                            } />
                                          <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                          </FormHelperText>
                                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.companyBoardMeetings?.[index]?.maintainedBoardDoc && errors.companyStatutoryRegisters?.[index]?.maintainedBoardDoc.message}</FormHelperText>

                                        </div>
                                        <span className="pre-btn-dpiit"
                                        onClick={() => handleClickOpens(getValues(`companyBoardMeetings.${index}.maintainedBoardDocURL`))}
                                        ><VisibilityIcon className='icon-size mr-1' /><Link
                                          
                                          style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                      <DeleteIcon
                                        sx={{
                                          backgroudColor: "#F4F4F4",
                                          fontSize: "24px",
                                          fill: "#808080",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          readyOnlyValue === false ? (
                                            deletecompanyBoardMeetings(index)
                                          ) : (
                                            <></>
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addcompanyBoardMeetingsFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* General Meeting */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen10}
                  onChange={() => setIsAccordionOpen10(!isAccordionOpen10)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      The following are the details of General meetings held for the period given below
                      and we hereby confirm that the Company has maintained all General Meeting Minutes,
                      notices, attendance registers and related documents as per the requirements of the
                      Companies Act, 2013 and the Secretarial Standards.
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companyGeneralMeetingsFields.length >= 0 && (
                            <>
                              {companyGeneralMeetingsFields.map(
                                (field, index) => (
                                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index} spacing={3}>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                      sx={{
                                        borderBottom: "none",

                                      }}
                                    >
                                      {index + 1}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%", paddingTop: '9px !important' }}>
                                        <Controller
                                          name={`companyGeneralMeetings.${index}.financialYear`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Financial Year is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Financial Year *"
                                              placeholder="Enter Financial Year"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors
                                            .companyGeneralMeetings?.[
                                            index
                                          ]?.financialYear &&
                                            errors
                                              .companyGeneralMeetings?.[
                                              index
                                            ]?.financialYear.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`companyGeneralMeetings.${index}.date`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Date of General Meeting is required",
                                          }}
                                          render={({ field }) => (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                                fullWidth
                                              >
                                                <DatePicker
                                                  label="Date of General Meeting *"
                                                  format="DD/MM/YYYY"
                                                  value={
                                                    field.value || null
                                                  }
                                                  sx={{ width: "100%" }}
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) =>
                                                    handleDateFormat5(
                                                      newValue,
                                                      index
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                    />
                                                  )}
                                                  disabled={readyOnlyValue}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.companyGeneralMeetings?.[
                                            index
                                          ]?.date &&
                                            errors.companyGeneralMeetings?.[
                                              index
                                            ]?.date.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%", paddingTop: '5px !important' }}>
                                        <Controller
                                          name={`companyGeneralMeetings.${index}.remarks`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Remarks is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Remarks *"
                                              placeholder="Enter Remarks"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.companyGeneralMeetings?.[
                                            index
                                          ]?.remarks &&
                                            errors.companyGeneralMeetings?.[
                                              index
                                            ]?.remarks.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                          <Controller name={`companyGeneralMeetings.${index}.maintainedBoardDoc`} control={control} defaultValue="" rules={{ required: "Document is Required" }}
                                            render={({ field }) =>

                                              <FileUploader
                                                {...field}
                                                handleChange={(e) => handleFileUpload(e,
                                                  `companyGeneralMeetings.${index}.maintainedBoardDoc`,
                                                  `companyGeneralMeetings.${index}.maintainedBoardDocName`,
                                                  `companyGeneralMeetings.${index}.maintainedBoardDocURL`,
                                                  'file',
                                                  'nosave'
                                                )}
                                                value={getValues(`companyGeneralMeetings.${index}.maintainedBoardDoc`)}
                                                name="file"
                                                types={fileTypesAll}
                                                children={

                                                  <div className={getValues(`companyGeneralMeetings.${index}.maintainedBoardDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                      <p className={getValues(`companyGeneralMeetings.${index}.maintainedBoardDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                      // style={{
                                                      //   backgroundColor: nonEditAble
                                                      //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                      //     : ''
                                                      // }}
                                                      >
                                                        <span>
                                                          <AttachFileIcon className="file-icon mr-2" />
                                                          {getValues(`companyGeneralMeetings.${index}.maintainedBoardDoc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                        </span>
                                                      </p>
                                                    </center>
                                                  </div>
                                                }
                                                disabled={readyOnlyValue}
                                              // disabled={nonEditAble}
                                              />
                                            } />
                                          <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                          </FormHelperText>
                                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.companyGeneralMeetings?.[index]?.maintainedBoardDoc && errors.companyGeneralMeetings?.[index]?.maintainedBoardDoc.message}</FormHelperText>

                                        </div>
                                        <span className="pre-btn-dpiit"
                                        onClick={() => handleClickOpens(getValues(`companyGeneralMeetings.${index}.maintainedBoardDocURL`))}
                                        ><VisibilityIcon className='icon-size mr-1' /><Link
                                          
                                          style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{ paddingTop: '0px !important' }}>
                                      <DeleteIcon
                                        sx={{
                                          backgroudColor: "#F4F4F4",
                                          fontSize: "24px",
                                          fill: "#808080",
                                          cursor: "pointer",

                                        }}
                                        onClick={() => {
                                          readyOnlyValue === false ? (
                                            deletecompanyGeneralMeetings(index)
                                          ) : (
                                            <></>
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addcompanyGeneralMeetingsFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>


              {/* Shareholding Partner */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen1}
                  onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      The shareholding pattern of the Company as on date along with copies of all share certificates for the present shareholders:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        {/* {shareHoldingPatternsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {shareHoldingPatternsFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Name of Shareholder is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Name of Shareholder *"
                                                  placeholder="Enter Name of Shareholder"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.name &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.date`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Date of allotment / Transfer is required",
                                              }}
                                              render={({ field }) => (
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                    fullWidth
                                                  >
                                                    <DatePicker
                                                      label="Date of allotment / Transfer"
                                                      format="DD/MM/YYYY"
                                                      value={
                                                        field.value || null
                                                      }
                                                      sx={{ width: "100%" }}
                                                      inputFormat="DD/MM/YYYY"
                                                      onChange={(newValue) =>
                                                        handleDateFormat(
                                                          newValue,
                                                          index
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                      disabled={readyOnlyValue}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.date &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.date.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.shares`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Number of shares is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="Number of shares *"
                                                  placeholder="Enter Number of shares"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                    maxLength: 3,
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateNumberonly(e)
                                                  }
                                                // value={valuePincode}
                                                // onInput={(e) => {
                                                //     const { value } = e.target;
                                                //     if (value === "" || (/^\d{1,6}$/.test(value))) {
                                                //         setValuePincode(value);
                                                //     }
                                                // }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.shares &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.shares.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.certfNo`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Name of Shareholder is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Share Certificate Number *"
                                                  placeholder="Enter Share Certificate Number"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.certfNo &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.certfNo.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <FormLabel>
                                              Whether the share certificate is
                                              duly stamped/ Form SH4 (for
                                              transfer) is duly stamped
                                            </FormLabel>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.dulyStamped`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <RadioGroup
                                                  row
                                                  name="position"
                                                  {...field}
                                                >
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    defaultValue="null"
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Yes"
                                                  />
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    defaultValue="null"
                                                    value="false"
                                                    control={<Radio />}
                                                    label="No"
                                                  />
                                                </RadioGroup>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.dulyStamped &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.dulyStamped.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteDirectorsInterested(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => adddirectorsFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )} */}
                        {shareHoldingPatternsFields.length >= 0 && (
                          <>
                            {shareHoldingPatternsFields.map(
                              (field, index) => (
                                <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                    {index + 1}
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`shareHoldingPatterns.${index}.name`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Name of Shareholder is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Name of Shareholder *"
                                            placeholder="Enter Name of Shareholder"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.shareHoldingPatterns?.[
                                          index
                                        ]?.name &&
                                          errors.shareHoldingPatterns?.[
                                            index
                                          ]?.name.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`shareHoldingPatterns.${index}.date`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Date of allotment / Transfer is required",
                                        }}
                                        render={({ field }) => (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["DatePicker"]}
                                              fullWidth
                                            >
                                              <DatePicker
                                                label="Date of allotment / Transfer"
                                                format="DD/MM/YYYY"
                                                value={
                                                  field.value || null
                                                }
                                                sx={{ width: "100%" }}
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(newValue) =>
                                                  handleDateFormat(
                                                    newValue,
                                                    index
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                  />
                                                )}
                                                disabled={readyOnlyValue}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.shareHoldingPatterns?.[
                                          index
                                        ]?.date &&
                                          errors.shareHoldingPatterns?.[
                                            index
                                          ]?.date.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`shareHoldingPatterns.${index}.shares`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Number of shares is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="Number of shares *"
                                            placeholder="Enter Number of shares"
                                            fullWidth
                                            multiline
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                              maxLength: 3,
                                            }}
                                            {...field}
                                            onKeyDown={(e) =>
                                              validateNumberonly(e)
                                            }
                                          // value={valuePincode}
                                          // onInput={(e) => {
                                          //     const { value } = e.target;
                                          //     if (value === "" || (/^\d{1,6}$/.test(value))) {
                                          //         setValuePincode(value);
                                          //     }
                                          // }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.shareHoldingPatterns?.[
                                          index
                                        ]?.shares &&
                                          errors.shareHoldingPatterns?.[
                                            index
                                          ]?.shares.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`shareHoldingPatterns.${index}.certfNo`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Name of Shareholder is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Share Certificate Number *"
                                            placeholder="Enter Share Certificate Number"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.shareHoldingPatterns?.[
                                          index
                                        ]?.certfNo &&
                                          errors.shareHoldingPatterns?.[
                                            index
                                          ]?.certfNo.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <FormLabel>
                                        Whether the share certificate is
                                        duly stamped/ Form SH4 (for
                                        transfer) is duly stamped *
                                      </FormLabel>
                                      <Controller
                                        name={`shareHoldingPatterns.${index}.dulyStamped`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Duly Stamped is Required" }}
                                        render={({ field }) => (
                                          <RadioGroup
                                            row
                                            name="position"
                                            {...field}
                                          >
                                            <FormControlLabel
                                              disabled={readyOnlyValue}
                                              defaultValue="null"
                                              value="true"
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              disabled={readyOnlyValue}
                                              defaultValue="null"
                                              value="false"
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.shareHoldingPatterns?.[
                                          index
                                        ]?.dulyStamped &&
                                          errors.shareHoldingPatterns?.[
                                            index
                                          ]?.dulyStamped.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                    <DeleteIcon
                                      sx={{
                                        backgroudColor: "#F4F4F4",
                                        fontSize: "24px",
                                        fill: "#808080",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        readyOnlyValue === false ? (
                                          deleteDirectorsInterested(index)
                                        ) : (
                                          <></>
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>))}
                            <center>
                              <Button
                                className="add-more-btn"
                                disabled={readyOnlyValue}
                                onClick={() => adddirectorsFieldsBtn("")}
                              >
                                <AddCircleIcon className="mr-2" />
                                Add More
                              </Button>
                            </center>
                          </>
                        )}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* Details of Directors */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen2}
                  onChange={() => setIsAccordionOpen2(!isAccordionOpen2)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of Directors (as on date) as per the format below:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {directorsInterestedCompaniesFields.length >= 0 && (
                      <>
                        {directorsInterestedCompaniesFields.map(
                          (field, index) => (<>
                            <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                {index + 1}
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`directorsInterestedCompanies.${index}.name`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Name is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Name *"
                                        placeholder="Enter Name"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors
                                      .directorsInterestedCompanies?.[
                                      index
                                    ]?.name &&
                                      errors
                                        .directorsInterestedCompanies?.[
                                        index
                                      ]?.name.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`directorsInterestedCompanies.${index}.address`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Address is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Address *"
                                        placeholder="Enter Address"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors
                                      .directorsInterestedCompanies?.[
                                      index
                                    ]?.address &&
                                      errors
                                        .directorsInterestedCompanies?.[
                                        index
                                      ]?.address.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`directorsInterestedCompanies.${index}.din`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required:
                                        "DIN Number is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="DIN *"
                                        placeholder="Enter DIN"
                                        fullWidth
                                        multiline
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                        {...field}
                                      // onKeyDown={(e) =>
                                      //   validateNumberonly(e)
                                      // }
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors
                                      .directorsInterestedCompanies?.[
                                      index
                                    ]?.din &&
                                      errors
                                        .directorsInterestedCompanies?.[
                                        index
                                      ]?.din.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={0} sm={0} md={1} lg={1} xl={1}></Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`directorsInterestedCompanies.${index}.dateOfFiling`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required:
                                        "Date of filing is required",
                                    }}
                                    render={({ field }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                          fullWidth
                                        >
                                          <DatePicker
                                            label="Date of filing form with ROC for the appointment / regularisation / resignation *"
                                            format="DD/MM/YYYY"
                                            value={
                                              field.value || null
                                            }
                                            sx={{ width: "100%" }}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={(newValue) =>
                                              handleDateFormat9(
                                                newValue,
                                                index
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                              />
                                            )}
                                            disabled={readyOnlyValue}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors
                                      .directorsInterestedCompanies?.[
                                      index
                                    ]?.dateOfFiling &&
                                      errors
                                        .directorsInterestedCompanies?.[
                                        index
                                      ]?.dateOfFiling.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormControl sx={{ width: "100%" }}>
                                  <FormLabel>
                                    Interest in other entities (as per Form MBP-1) and Board authorisation date
                                  </FormLabel>
                                  <Controller
                                    name={`directorsInterestedCompanies.${index}.interested`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Interest in other entities is Required" }}
                                    render={({ field }) => (
                                      <RadioGroup
                                        row
                                        name="position"
                                        {...field}
                                      >
                                        <FormControlLabel
                                          disabled={readyOnlyValue}
                                          defaultValue="null"
                                          value="true"
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          disabled={readyOnlyValue}
                                          defaultValue="null"
                                          value="false"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors
                                      .directorsInterestedCompanies?.[
                                      index
                                    ]?.interested &&
                                      errors
                                        .directorsInterestedCompanies?.[
                                        index
                                      ]?.interested.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                <DeleteIcon
                                  sx={{
                                    backgroudColor: "#F4F4F4",
                                    fontSize: "24px",
                                    fill: "#808080",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      deleteDirectorsInterestedCompanies(
                                        index
                                      )
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </>))}
                        <center>
                          <Button
                            className="add-more-btn"
                            disabled={readyOnlyValue}
                            onClick={() =>
                              addRemunerationPaidToDirectorsBtn("")
                            }
                          >
                            <AddCircleIcon className="mr-2" />
                            Add More
                          </Button>
                        </center>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* Details of remuneration */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen3}
                  onChange={() => setIsAccordionOpen3(!isAccordionOpen3)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of remuneration paid to Directors (for the last 3 financial years):
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        {remunerationPaidToDirectorsFields.length >= 0 && (
                          <>
                            {remunerationPaidToDirectorsFields.map(
                              (field, index) => (
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} >
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                  >
                                    {index + 1}
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`remunerationPaidToDirectors.${index}.fy`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Financial Year is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Financial Year *"
                                            placeholder="Enter Financial Year"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors
                                          .remunerationPaidToDirectors?.[
                                          index
                                        ]?.fy &&
                                          errors
                                            .remunerationPaidToDirectors?.[
                                            index
                                          ]?.fy.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`remunerationPaidToDirectors.${index}.name`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Name of Director is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Name of Director *"
                                            placeholder="Enter Name of Director"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors
                                          .remunerationPaidToDirectors?.[
                                          index
                                        ]?.name &&
                                          errors
                                            .remunerationPaidToDirectors?.[
                                            index
                                          ]?.name.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`remunerationPaidToDirectors.${index}.remuneration`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Remuneration is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="Remuneration *"
                                            placeholder="Enter Remuneration"
                                            fullWidth
                                            multiline
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                            {...field}
                                            onKeyDown={(e) =>
                                              validateNumberonly(e)
                                            }
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors
                                          .remunerationPaidToDirectors?.[
                                          index
                                        ]?.remuneration &&
                                          errors
                                            .remunerationPaidToDirectors?.[
                                            index
                                          ]?.remuneration.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`remunerationPaidToDirectors.${index}.date`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Date of Board is required",
                                        }}
                                        render={({ field }) => (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["DatePicker"]}
                                              fullWidth
                                            >
                                              <DatePicker
                                                label="Date of Board resolution approving the same & filing with ROC *"
                                                format="DD/MM/YYYY"
                                                value={
                                                  field.value || null
                                                }
                                                sx={{ width: "100%" }}
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(newValue) =>
                                                  handleDateFormat2(
                                                    newValue,
                                                    index
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                  />
                                                )}
                                                disabled={readyOnlyValue}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors
                                          .remunerationPaidToDirectors?.[
                                          index
                                        ]?.date &&
                                          errors
                                            .remunerationPaidToDirectors?.[
                                            index
                                          ]?.date.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                    <DeleteIcon
                                      sx={{
                                        backgroudColor: "#F4F4F4",
                                        fontSize: "24px",
                                        fill: "#808080",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        readyOnlyValue === false ? (
                                          deleteRemunerationPaidToDirectors(
                                            index
                                          )
                                        ) : (
                                          <></>
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                            <center>
                              <Button
                                className="add-more-btn"
                                disabled={readyOnlyValue}
                                onClick={() =>
                                  adddirectorsInterestedCompaniesAppendBtn("")
                                }
                              >
                                <AddCircleIcon className="mr-2" />
                                Add More
                              </Button>
                            </center>
                          </>

                        )}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* Details of all related party transactions */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen4}
                  onChange={() => setIsAccordionOpen4(!isAccordionOpen4)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of all related party transactions as per the following format for the last 3 financial years:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        {relatedPartyTransactionsFields.length >= 0 && (<>
                          {relatedPartyTransactionsFields.map(
                            (field, index) => (
                              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  {index + 1}
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name={`relatedPartyTransactions.${index}.fy`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required:
                                          "Financial Year is required",
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Financial Year *"
                                          placeholder="Enter Financial Year"
                                          fullwidth
                                          {...field}
                                          inputProps={{
                                            readOnly: readyOnlyValue,
                                          }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors
                                        .relatedPartyTransactions?.[
                                        index
                                      ]?.fy &&
                                        errors
                                          .relatedPartyTransactions?.[
                                          index
                                        ]?.fy.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name={`relatedPartyTransactions.${index}.name`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required:
                                          "Name of related party is required",
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Name of related party *"
                                          placeholder="Enter Name of related party"
                                          fullwidth
                                          {...field}
                                          inputProps={{
                                            readOnly: readyOnlyValue,
                                          }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors
                                        .relatedPartyTransactions?.[
                                        index
                                      ]?.name &&
                                        errors
                                          .relatedPartyTransactions?.[
                                          index
                                        ]?.name.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name={`relatedPartyTransactions.${index}.nature`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required:
                                          "Nature of transaction is required",
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Nature of transaction *"
                                          placeholder="Enter Nature of transaction"
                                          fullwidth
                                          {...field}
                                          inputProps={{
                                            readOnly: readyOnlyValue,
                                          }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors
                                        .relatedPartyTransactions?.[
                                        index
                                      ]?.nature &&
                                        errors
                                          .relatedPartyTransactions?.[
                                          index
                                        ]?.nature.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name={`relatedPartyTransactions.${index}.amount`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required: "Amount is required",
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          variant="outlined"
                                          label="Amount *"
                                          placeholder="Enter Amount"
                                          fullWidth
                                          multiline
                                          inputProps={{
                                            readOnly: readyOnlyValue,
                                            maxLength: 13
                                          }}
                                          {...field}
                                          onKeyDown={(e) =>
                                            validateDecimalNumberonly(e)
                                          }
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors
                                        .relatedPartyTransactions?.[
                                        index
                                      ]?.amount &&
                                        errors
                                          .relatedPartyTransactions?.[
                                          index
                                        ]?.amount.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name={`relatedPartyTransactions.${index}.boardAuthDate`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required:
                                          "Board Authorisation date is required",
                                      }}
                                      render={({ field }) => (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DemoContainer
                                            components={["DatePicker"]}
                                            fullWidth
                                          >
                                            <DatePicker
                                              label="Board Authorisation date *"
                                              format="DD/MM/YYYY"
                                              value={
                                                field.value || null
                                              }
                                              sx={{ width: "100%" }}
                                              inputFormat="DD/MM/YYYY"
                                              onChange={(newValue) =>
                                                handleDateFormat6(
                                                  newValue,
                                                  index
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                />
                                              )}
                                              disabled={readyOnlyValue}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.relatedPartyTransactions?.[
                                        index
                                      ]?.boardAuthDate &&
                                        errors.relatedPartyTransactions?.[
                                          index
                                        ]?.boardAuthDate.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid> */}
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <FormLabel>
                                      Whether DPT-3 filed with date of filing *
                                    </FormLabel>
                                    <Controller
                                      name={`relatedPartyTransactions.${index}.dpt3Filed`}
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: "DPT3 Filed is required" }}
                                      render={({ field }) => (
                                        <RadioGroup
                                          row
                                          name="position"
                                          {...field}
                                        >
                                          <FormControlLabel
                                            disabled={readyOnlyValue}
                                            defaultValue="null"
                                            value="true"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            disabled={readyOnlyValue}
                                            defaultValue="null"
                                            value="false"
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors
                                        .relatedPartyTransactions?.[
                                        index
                                      ]?.dpt3Filed &&
                                        errors
                                          .relatedPartyTransactions?.[
                                          index
                                        ]?.dpt3Filed.message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <FormLabel>Declaration Document given by directors in case of loan from directors</FormLabel>
                                  <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                      <Controller name={`relatedPartyTransactions.${index}.directorLoanDeclarationDoc`} control={control} defaultValue="" rules={{ required: "Document is Required" }}
                                        render={({ field }) =>

                                          <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                              `relatedPartyTransactions.${index}.directorLoanDeclarationDoc`,
                                              `relatedPartyTransactions.${index}.directorLoanDeclarationDocName`,
                                              `relatedPartyTransactions.${index}.directorLoanDeclarationDocURL`,
                                              'file',
                                              'nosave'
                                            )}
                                            value={getValues(`relatedPartyTransactions.${index}.directorLoanDeclarationDoc`)}
                                            name="file"
                                            types={fileTypesAll}
                                            children={

                                              <div className={getValues(`relatedPartyTransactions.${index}.directorLoanDeclarationDoc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                <center>
                                                  <p className={getValues(`relatedPartyTransactions.${index}.directorLoanDeclarationDoc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                  // style={{
                                                  //   backgroundColor: nonEditAble
                                                  //     ? (getValues(`companyInsurancePolicies.${index}.insuranceDoc`) !== '' ? 'gray' : 'gray')
                                                  //     : ''
                                                  // }}
                                                  >
                                                    <span>
                                                      <AttachFileIcon className="file-icon mr-2" />
                                                      {getValues(`relatedPartyTransactions.${index}.directorLoanDeclarationDoc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                    </span>
                                                  </p>
                                                </center>
                                              </div>
                                            }
                                            disabled={readyOnlyValue}
                                          // disabled={nonEditAble}
                                          />
                                        } />
                                      <FormHelperText className="ml-2">
                                        Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                      </FormHelperText>
                                      <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.relatedPartyTransactions?.[index]?.directorLoanDeclarationDoc && errors.relatedPartyTransactions?.[index]?.directorLoanDeclarationDoc.message}</FormHelperText>

                                    </div>
                                    <span className="pre-btn-dpiit"
                                     onClick={() => handleClickOpens(getValues(`relatedPartyTransactions.${index}.directorLoanDeclarationDocURL`))}
                                    ><VisibilityIcon className='icon-size mr-1' /><Link
                                     
                                      style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                  <DeleteIcon
                                    sx={{
                                      backgroudColor: "#F4F4F4",
                                      fontSize: "24px",
                                      fill: "#808080",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      readyOnlyValue === false ? (
                                        deleteRelatedPartyTransactions(
                                          index
                                        )
                                      ) : (
                                        <></>
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          <center>
                            <Button
                              className="add-more-btn"
                              disabled={readyOnlyValue}
                              onClick={() =>
                                addrelatedPartyTransactionsBtn("")
                              }
                            >
                              <AddCircleIcon className="mr-2" />
                              Add More
                            </Button>
                          </center>
                        </>)}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* borrowings */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen5}
                  onChange={() => setIsAccordionOpen5(!isAccordionOpen5)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of all borrowings (both secured & unsecured) made by the Company
                      as per the following format  along with copies of the
                      loan agreement, forms filed, if any:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {detailsOfBorrowingsFields.length >= 0 && (
                            <>
                              {detailsOfBorrowingsFields.map(
                                (field, index) => (
                                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                      sx={{
                                        borderBottom: "none",
                                      }}
                                    >
                                      {index + 1}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`detailsOfBorrowings.${index}.date`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Date of borrowing is required",
                                          }}
                                          render={({ field }) => (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                                fullWidth
                                              >
                                                <DatePicker
                                                  label="Date of borrowing *"
                                                  format="DD/MM/YYYY"
                                                  value={
                                                    field.value || null
                                                  }
                                                  sx={{ width: "100%" }}
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) =>
                                                    handleDateFormat3(
                                                      newValue,
                                                      index
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                    />
                                                  )}
                                                  disabled={readyOnlyValue}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.detailsOfBorrowings?.[
                                            index
                                          ]?.date &&
                                            errors.detailsOfBorrowings?.[
                                              index
                                            ]?.date.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`detailsOfBorrowings.${index}.name`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Name of Lender is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Name of Lender *"
                                              placeholder="Enter Name of Lender"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.detailsOfBorrowings?.[
                                            index
                                          ]?.name &&
                                            errors.detailsOfBorrowings?.[
                                              index
                                            ]?.name.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`detailsOfBorrowings.${index}.amount`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Amount is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              variant="outlined"
                                              label="Amount *"
                                              placeholder="Enter Amount"
                                              fullWidth
                                              multiline
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                                maxLength: 13
                                              }}
                                              {...field}
                                              onKeyDown={(e) =>
                                                validateDecimalNumberonly(e)
                                              }
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.detailsOfBorrowings?.[
                                            index
                                          ]?.amount &&
                                            errors.detailsOfBorrowings?.[
                                              index
                                            ]?.amount.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`detailsOfBorrowings.${index}.secured`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Secured / unsecured is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Secured / unsecured If secured whether charge filed with RoC *"
                                              placeholder="Enter Secured / unsecured"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.detailsOfBorrowings?.[
                                            index
                                          ]?.secured &&
                                            errors.detailsOfBorrowings?.[
                                              index
                                            ]?.secured.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`detailsOfBorrowings.${index}.termsOrInterest`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Repayment terms, interest is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Repayment terms, interest *"
                                              placeholder="Enter Repayment terms, interest"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.detailsOfBorrowings?.[
                                            index
                                          ]?.termsOrInterest &&
                                            errors.detailsOfBorrowings?.[
                                              index
                                            ]?.termsOrInterest.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`detailsOfBorrowings.${index}.boardAuthDate`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Board Authorisation date is required",
                                          }}
                                          render={({ field }) => (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                                fullWidth
                                              >
                                                <DatePicker
                                                  label="Board Authorisation date *"
                                                  format="DD/MM/YYYY"
                                                  value={
                                                    field.value || null
                                                  }
                                                  sx={{ width: "100%" }}
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) =>
                                                    handleDateFormat7(
                                                      newValue,
                                                      index
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                    />
                                                  )}
                                                  disabled={readyOnlyValue}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.detailsOfBorrowings?.[
                                            index
                                          ]?.boardAuthDate &&
                                            errors.detailsOfBorrowings?.[
                                              index
                                            ]?.boardAuthDate.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`detailsOfBorrowings.${index}.dpt3Filed`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Whether DPT-3 filed with RoC & date of filing is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Whether DPT-3 filed with RoC & date of filing *"
                                              placeholder="Enter Whether DPT-3 filed with RoC & date of filing"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.detailsOfBorrowings?.[
                                            index
                                          ]?.dpt3Filed &&
                                            errors.detailsOfBorrowings?.[
                                              index
                                            ]?.dpt3Filed.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                      <DeleteIcon
                                        sx={{
                                          backgroudColor: "#F4F4F4",
                                          fontSize: "24px",
                                          fill: "#808080",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          readyOnlyValue === false ? (
                                            deleteDetailsOfBorrowings(index)
                                          ) : (
                                            <></>
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addDetailsOfBorrowingsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* Company Secretary setting */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen6}
                  onChange={() => setIsAccordionOpen6(!isAccordionOpen6)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of investments made by the Company / loans granted by the Company:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companyInvestmentsOrLoanFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {companyInvestmentsOrLoanFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Party Name is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Party Name *"
                                                  placeholder="Enter Party Name"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.name &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.amount`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Amount is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="Amount *"
                                                  placeholder="Enter Amount"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                    maxLength: 13
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateDecimalNumberonly(e)
                                                  }
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.amount &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.amount.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.nature`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Nature (whether loan / investment) is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Nature (whether loan / investment) *"
                                                  placeholder="Enter Nature (whether loan / investment)"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.nature &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.nature.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.fy`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Financial Year is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Financial Year *"
                                                  placeholder="Enter Financial Year"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.fy &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.fy.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteCompanyInvestmentsOrLoan(
                                                  index
                                                )
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() =>
                                    addCompanyInvestmentsOrLoanBtn("")
                                  }
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* forms with RoC */}

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen11}
                  onChange={() => setIsAccordionOpen11(!isAccordionOpen11)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Date of appointment of existing auditor & filing of forms with RoC
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {existingAuditorAppointmentsFields.length >= 0 && (
                            <>
                              {existingAuditorAppointmentsFields.map(
                                (field, index) => (
                                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index} spacing={3}>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                      sx={{
                                        borderBottom: "none",

                                      }}
                                    >
                                      {index + 1}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%", paddingTop: '9px !important' }}>
                                        <Controller
                                          name={`existingAuditorAppointments.${index}.name`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Name of Auditor is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Name of Auditor *"
                                              placeholder="Enter Name of Auditor"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors
                                            .existingAuditorAppointments?.[
                                            index
                                          ]?.name &&
                                            errors
                                              .existingAuditorAppointments?.[
                                              index
                                            ]?.name.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%", paddingTop: '5px !important' }}>
                                        <Controller
                                          name={`existingAuditorAppointments.${index}.period`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Period of appointment is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Period of appointment *"
                                              placeholder="Enter Period of appointment"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.existingAuditorAppointments?.[
                                            index
                                          ]?.period &&
                                            errors.existingAuditorAppointments?.[
                                              index
                                            ]?.period.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%", paddingTop: '5px !important' }}>
                                        <Controller
                                          name={`existingAuditorAppointments.${index}.remuneration`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Remuneration is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Remuneration *"
                                              placeholder="Enter Remuneration"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                              onKeyDown={(e) =>
                                                validateNumberonly(e)
                                              }
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.existingAuditorAppointments?.[
                                            index
                                          ]?.remuneration &&
                                            errors.existingAuditorAppointments?.[
                                              index
                                            ]?.remuneration.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`existingAuditorAppointments.${index}.date`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Date of Board resolution approving the same & filing with RoC is required",
                                          }}
                                          render={({ field }) => (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                                fullWidth
                                              >
                                                <DatePicker
                                                  label="Date of Board resolution approving the same & filing with RoC*"
                                                  format="DD/MM/YYYY"
                                                  value={
                                                    field.value || null
                                                  }
                                                  sx={{ width: "100%" }}
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) =>
                                                    handleDateFormat8(
                                                      newValue,
                                                      index
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                    />
                                                  )}
                                                  disabled={readyOnlyValue}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.existingAuditorAppointments?.[
                                            index
                                          ]?.date &&
                                            errors.existingAuditorAppointments?.[
                                              index
                                            ]?.date.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                      <DeleteIcon
                                        sx={{
                                          backgroudColor: "#F4F4F4",
                                          fontSize: "24px",
                                          fill: "#808080",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          readyOnlyValue === false ? (
                                            deleteexistingAuditorAppointments(index)
                                          ) : (
                                            <></>
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addexistingAuditorAppointmentsFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>


              {/* Last 3 years Annual Returns */}
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen12}
                  onChange={() => setIsAccordionOpen12(!isAccordionOpen12)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Details of filing Annual Accounts & Annual Returns for the last 3 years:
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {annualFilingsLast3YearsFields.length >= 0 && (
                            <>
                              {annualFilingsLast3YearsFields.map(
                                (field, index) => (
                                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index} spacing={3}>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                                      sx={{
                                        borderBottom: "none",

                                      }}
                                    >
                                      {index + 1}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%", paddingTop: '9px !important' }}>
                                        <Controller
                                          name={`annualFilingsLast3Years.${index}.financialYear`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Financial year is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Financial Year *"
                                              placeholder="Enter Financial Year"
                                              fullwidth
                                              {...field}
                                              inputProps={{
                                                readOnly: readyOnlyValue,
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors
                                            .annualFilingsLast3Years?.[
                                            index
                                          ]?.financialYear &&
                                            errors
                                              .annualFilingsLast3Years?.[
                                              index
                                            ]?.financialYear.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <FormLabel>
                                          Form Filed *
                                        </FormLabel>
                                        <Controller
                                          name={`annualFilingsLast3Years.${index}.formFiled`}
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: "Form Filed is Required" }}
                                          render={({ field }) => (
                                            <RadioGroup
                                              row
                                              name="position"
                                              {...field}
                                            >
                                              <FormControlLabel
                                                disabled={readyOnlyValue}
                                                onChange={(e) => {
                                                  setValue(`annualFilingsLast3Years.${index}.formFiled`, e.target.value)
                                                }}
                                                defaultValue="null"
                                                value="Yes"
                                                control={<Radio />}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                disabled={readyOnlyValue}
                                                onChange={(e) => {
                                                  setValue(`annualFilingsLast3Years.${index}.formFiled`, "")
                                                }}
                                                defaultValue="null"
                                                value="No"
                                                control={<Radio />}
                                                label="No"
                                              />
                                            </RadioGroup>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.annualFilingsLast3Years?.[
                                            index
                                          ]?.formFiled &&
                                            errors.annualFilingsLast3Years?.[
                                              index
                                            ]?.formFiled.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                      <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                          name={`annualFilingsLast3Years.${index}.dateOfFiling`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required:
                                              "Date of filing is required",
                                          }}
                                          render={({ field }) => (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                                fullWidth
                                              >
                                                <DatePicker
                                                  label="Date of filing *"
                                                  format="DD/MM/YYYY"
                                                  value={
                                                    field.value || null
                                                  }
                                                  sx={{ width: "100%" }}
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) =>
                                                    handleDateFormat10(
                                                      newValue,
                                                      index
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                    />
                                                  )}
                                                  disabled={readyOnlyValue}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.annualFilingsLast3Years?.[
                                            index
                                          ]?.dateOfFiling &&
                                            errors.annualFilingsLast3Years?.[
                                              index
                                            ]?.dateOfFiling.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                      <DeleteIcon
                                        sx={{
                                          backgroudColor: "#F4F4F4",
                                          fontSize: "24px",
                                          fill: "#808080",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          readyOnlyValue === false ? (
                                            deleteannualFilingsLast3YearsFields(index)
                                          ) : (
                                            <></>
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addannualFilingsLast3YearsFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            {/* <PreliminaryTextPart2 {...{ control, getValues, errors, setValue, clearErrors }} setFormData={setFormData} formData={formData} readyOnlyValue={readyOnlyValue} /> */}
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button
                variant="contained"
                onClick={handleBackapp}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
        {/* <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        /> */}
      </section>
    </>
  );
};

export default PreliminaryDocumentPart2;
