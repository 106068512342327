import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext'
import notifyService from '../../../../api/notifySerivce';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormHelperText, FormLabel, Grid } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { Link } from 'react-router-dom';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import apiService from '../../../../api/apiService';
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];

const CPDocumentScst = () => {
    const { handleBackapp, navigator, setPageLoading } = useContext(DataContext)
    const [formData, setFormData] = useState({})
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [getId, setGetId] = useState(0)
    const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors }, } = useForm({ values: formData });

    const handleClickOpens = (url) => {
        if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    useDidMountEffect(() => {
        getApi();
    }, []);

    const getApi = async () => {
        setPageLoading(true);
        await apiService(`startup/invdoc/cp/get`, "", "get")
            .then((res) => {
                setPageLoading(false);
                if (res && res.data) {
                    if (res.data.editForm === false && res.data.superEdit === false) {
                        setReadyOnlyValue(true);
                    }
                    if (res.data.editForm === false && res.data.superEdit === true) {
                        setReadyOnlyValue(false);
                    }
                    if (res.data.editForm === true && res.data.superEdit === false) {
                        setReadyOnlyValue(false);
                    }
                    if (res.data.id) {
                        setGetId(res.data.id);
                    }
                    setFormData(res.data)
                }

            }).catch((err) => {
                console.log(err);

            });
    }

    // saveApi
    const onSubmit = (data) => {
        setPageLoading(true);
        debugger;
        apiService("startup/invdoc/cp/save", data, "post").then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService("success", "Success", "Saved successfully !!");
                    handleBackapp()
                    // navigator("/startup/ldd-document1/scst");
                }
            }
        });
    };
    const saveData = (req) => {
        debugger
        setPageLoading(true);
        apiService("startup/invdoc/cp/save", req, "post").then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    getApi();
                }
            }
        });
    };

    // FileUpload
    const handleFileUpload = (event, filekey, fileName, fileURL, format, val) => {
        debugger
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event;
            } else {
                logoselecetdFile = event.target.files[0];
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type;
                var imagedatatype = imagetype.split("/");
                var img_crt_type = imagedatatype[1];

                if (
                    (format === "file" && img_crt_type === "pdf") ||
                    img_crt_type === "vnd.ms-excel" ||
                    img_crt_type ===
                    "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    img_crt_type ===
                    "vnd.openxmlformats-officedocument.wordprocessingml.document"
                ) {
                    setValue(filekey, "");
                    setValue(fileName, "");

                    var fileValue = logoselecetdFile;
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(",");
                        var ImageValue = spl[1];
                        var img_name = fileValue.name;
                        setValue(filekey, ImageValue);
                        setValue(fileName, img_name);
                        setValue(fileURL, logourl1);
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        if (val !== 'nosave') {
                            var req = {
                                [filekey]: ImageValue,
                                [fileName]: img_name,
                                id: getId,
                            };
                            console.log(req);

                            saveData(req);
                        }
                    };
                } else {
                    notifyService(
                        "danger",
                        "File Format Invalided",
                        "Please check your file format"
                    );
                }
            }
        }
    };

    // Bank Document
    const handleBankDetailsDoc = (e) => {
        handleFileUpload(
            e,
            "bankAccountDetailsDoc",
            "bankAccountDetailsDocName",
            "bankAccountDetailsDocURL",
            "file"
        );
    };

    // Fund sanction letter  Document
    const handlefundsancDoc = (e) => {
        handleFileUpload(
            e,
            "fundSanctionLetterDoc",
            "fundSanctionLetterDocName",
            "fundSanctionLetterDocURL",
            "file"
        );
    };

    // Fund Sanction Acceptance Letter

    const handlefundsacceptDoc = (e) => {
        handleFileUpload(
            e,
            "fundSanctionAccLetterDoc",
            "fundSanctionAccLetterDocName",
            "fundSanctionAccLetterDocURL",
            "file"
        );
    };

    // Tansim with seal
    const handleTansimDoc = (e) => {
        handleFileUpload(
            e,
            "pas4AndPas5CpDoc",
            "pas4AndPas5CpDocName",
            "pas4AndPas5CpDocURL",
            "file"
        );
    };

    // MGT-14 Doc
    const handleMg14Doc = (e) => {
        handleFileUpload(
            e,
            "mgt14FiledDoc",
            "mgt14FiledDocName",
            "mgt14FiledDocURL",
            "file"
        );
    };

    // Indemnity bonds
    const handleindemnityBondsDoc = (e) => {
        handleFileUpload(
            e,
            "indemnityBondsDoc",
            "indemnityBondsDocName",
            "indemnityBondsDocURL",
            "file"
        );
    }

    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };

    return (
        <>
            <section className="container-fluid">
                <div className="form_div">
                    <div className="d-flex align-items-center">
                        <span className="back_span" onClick={handleBackapp}>
                            <img src="/images/Fig-back.png" className="img-fluid" alt="" />
                        </span>
                        <h6 className="ml-2 mb-0">Information from the Company:</h6>
                    </div>
                    <hr />
                    <form
                        className="signin-form"
                        onSubmit={handleSubmit(onSubmit, onError)}
                    >
                        <div className='my-4'>
                            <h6 style={{ color: "blue" }}>Investment Documents for Tranche 1:</h6>
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>

                            {/* Bank Details */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Details of Bank Account Opened for Receipt of Money (Cancelled Cheque / Bank Welcome Letter / Passbook Front Page) - Original
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="bankAccountDetailsDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleBankDetailsDoc}
                                                    value={getValues("bankAccountDetailsDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("bankAccountDetailsDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "bankAccountDetailsDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "bankAccountDetailsDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.bankAccountDetailsDoc &&
                                                errors.bankAccountDetailsDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("bankAccountDetailsDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* Fund Sanction Letter */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Fund Sanction Letter - Photocopy
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="fundSanctionLetterDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handlefundsancDoc}
                                                    value={getValues("fundSanctionLetterDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("fundSanctionLetterDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "fundSanctionLetterDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "fundSanctionLetterDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.fundSanctionLetterDoc &&
                                                errors.fundSanctionLetterDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("fundSanctionLetterDocURL")
                                            )
                                        }>
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* Fund Sanction Acceptance Letter */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Fund Sanction Acceptance Letter - Original
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="fundSanctionAccLetterDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handlefundsacceptDoc}
                                                    value={getValues("fundSanctionAccLetterDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("fundSanctionAccLetterDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "fundSanctionAccLetterDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "fundSanctionAccLetterDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.fundSanctionAccLetterDoc &&
                                                errors.fundSanctionAccLetterDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("fundSanctionAccLetterDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* Tansim with seal */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    PAS-4, PAS -5 form for offering Shares to TANSIM with company seal
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="pas4AndPas5CpDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleTansimDoc}
                                                    value={getValues("pas4AndPas5CpDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("pas4AndPas5CpDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "pas4AndPas5CpDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "pas4AndPas5CpDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.pas4AndPas5CpDoc &&
                                                errors.pas4AndPas5CpDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("pas4AndPas5CpDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* MGT-14 Doc*/}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Copy of MGT-14 Filed  for CCD offering with ROC with Payment Receipt with company seal
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="mgt14FiledDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleMg14Doc}
                                                    value={getValues("mgt14FiledDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("mgt14FiledDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "mgt14FiledDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "mgt14FiledDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.mgt14FiledDoc &&
                                                errors.mgt14FiledDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("mgt14FiledDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                            {/* Startup Founder	Indemnity bonds (if applicable) with company seal & Sign */}
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>
                                    Indemnity bonds (if applicable) with company seal & Sign
                                </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className="form-group my-1">
                                        <Controller
                                            name="indemnityBondsDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleindemnityBondsDoc}
                                                    value={getValues("indemnityBondsDoc")}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    disabled={readyOnlyValue}
                                                    children={
                                                        <div
                                                            className={
                                                                getValues("indemnityBondsDoc") !==
                                                                    ""
                                                                    ? "fileupload-pdf-dpiit-bg1-active"
                                                                    : "fileupload-pdf-dpiit-bg1"
                                                            }
                                                        >
                                                            <center>
                                                                <p
                                                                    className={
                                                                        getValues(
                                                                            "indemnityBondsDoc"
                                                                        ) !== ""
                                                                            ? "fileupload-pdf-active"
                                                                            : "fileupload-pdf"
                                                                    }
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(
                                                                            "indemnityBondsDoc"
                                                                        ) !== ""
                                                                            ? "Uploaded (Click to Change)"
                                                                            : "Document"}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />
                                        <FormHelperText className="ml-2">
                                            Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                                        </FormHelperText>
                                        <FormHelperText
                                            sx={{ marginLeft: "5px" }}
                                            className="text-danger"
                                        >
                                            {errors.indemnityBondsDoc &&
                                                errors.indemnityBondsDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"
                                        onClick={() =>
                                            handleClickOpens(
                                                getValues("indemnityBondsDocURL")
                                            )
                                        }
                                    >
                                        <VisibilityIcon className="icon-size mr-1" />
                                        <Link

                                            style={{ color: "#80809e" }}
                                        >
                                            {" "}
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </Grid>

                        </Grid>
                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button
                                variant="contained"
                                onClick={handleBackapp}
                                className="btn btn-cancel submit px-3"
                            >
                                Cancel
                            </Button>
                            {/* {readyOnlyValue === false ? ( */}
                            {/* <Button
                                type="submit"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3 ml-3"
                            >
                                <span>Save</span>
                            </Button> */}
                            {/* ) : (
                                        <></>
                                      )} */}
                        </div>
                    </form>
                </div>

            </section>
        </>
    )
}

export default CPDocumentScst
