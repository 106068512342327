import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import ClarificationAll from "../../../scstBdd-page/scstBdd-details/ClarificationAll";
import Scsthubavpremarks from "../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks";
import Viewimage from "../../../../api/Viewimage";
import DataContext from "../../../../context/DataContext";

const LDDPart2 = ({
  idNumber,
  path,
  startupCheckList,
  startupValue,
  viewStartupDetails,
  showStartupValue,
}) => {
  const { setPageLoading, getBDDStatus } = useContext(DataContext);
  const [formData, setFormData] = useState();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const { fields: identifyingInformationFields } = useFieldArray({
    control,
    name: "identifyingInformation",
  });
  useDidMountEffect(() => {
    getApi();
  }, []);
  const getApi = () => {
    setPageLoading(true);
    var req = {
      startupId: idNumber,
      round: "ldd",
      category: "section2",
    };
    apiService(`partner/startup/clarification/list`, req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          result.data.identifyingInformation.forEach((element) => {
            if (element.status === 3) {
              element.status = 2;
            }
            if (element.fileUrl) {
              var val = element.fileUrl;
              var data = val.split("?");
              if (data) {
                var formet = data[0].split(".");
                element.formet = formet[4];
              }
            }
          });

          setFormData(result.data);
          // console.log(result.data);
        }
      })
      .catch((err) => { });
  };
  const handelVerifiedStatus = (value, i) => {
    // console.log(value);
    setValue(`identifyingInformation.${i}.status`, Number(value));
    var getData = getValues(`identifyingInformation.${i}`);
    if (value === "1") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        status: 1,
        round: "ldd",
      };
      apiService(`partner/startups/clarification/ldd/status/save`, req, "post")
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.responseStatus === "Success"
          ) {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => { });
    }
    setFormData(getValues());
  };
  const handleRequestValue = (value, i) => {
    setValue(`identifyingInformation.${i}.clarification`, value);
    clearErrors(`identifyingInformation.${i}.clarification`);
  };
  const onSubmit = (data) => { };
  const handleRequestSubmit = (i) => {
    var getData = getValues(`identifyingInformation.${i}`);
    if (getData.clarification !== "") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        clarification: getData.clarification,
        status: 2,
        round: "ldd",
      };
      apiService(`partner/startup/clarification/ldd/save`, req, "post")
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.responseStatus === "Success"
          ) {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => { });
    } else {
      setError(`identifyingInformation.${i}.clarification`, {
        message: "Request Clarification is required",
      });
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value, formet) => {
    setViewImage(value);
    if (formet === "xlsx") {
      const link = document.createElement('a');
      link.href = value;
      // link.download = 'excelfile.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else if (formet === "docx") {
      const link = document.createElement('a');
      link.href = value;
      // link.download = 'excelfile.docs';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ p: 3 }}>
      <div>
        <div className="table_main_div">
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            {identifyingInformationFields &&
              identifyingInformationFields.length ? (
              <>
                {identifyingInformationFields.map((data, index) => (
                  <div className="mb-3">
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="mb-0">
                          {index + 1}. {data.attribute}
                        </p>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        {data.value && (
                          <div className="ans-div">{data.value}</div>
                        )}
                        {data.fileUrl && (
                          <div className="my-2">
                            View Document :{" "}
                            <span
                              className="view-doc"
                              onClick={() =>
                                handleClickOpen(data.fileUrl, data.formet)
                              }
                            >
                              {data.formet === 'pdf' ? 'View' : 'Download'}
                            </span>
                          </div>
                        )}
                        {data.fileUrl && data.formet &&
                          <>
                            {data.fileUrl && data.formet === 'xlsx' &&
                              (<>
                                <span>(File Format : Excel)</span>
                              </>)
                            }
                            {data.fileUrl && data.formet === 'docx' &&
                              (<>
                                <span>(File Format : DOC)</span></>)}
                            {data.fileUrl && data.formet === 'pdf' &&
                              (<>
                                <span>(File Format : PDF)</span></>)}
                          </>
                        }
                        {data.companyInvestmentOrLoans && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Party Name</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Nature</TableCell>
                                    <TableCell>Financial Year</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.companyInvestmentOrLoans.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.name ? row.name : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.amount ? row.amount : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.nature ? row.nature : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.fy ? row.fy : `-`}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.shareHoldingPatterns && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Number of Shares</TableCell>
                                    <TableCell>
                                      Share Certificate Number
                                    </TableCell>
                                    <TableCell>Form SH4</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.shareHoldingPatterns.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.name ? row.name : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.date ? row.date : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.shares ? row.shares : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.certfNo ? row.certfNo : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {/* {row.dulyStamped
                                            ? row.dulyStamped === true
                                              ? "Yes"
                                              : `No`
                                            : "-"} */}
                                            {row.dulyStamped !== undefined ? (row.dulyStamped ? "Yes" : "No") : "NA"}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.directorsInterestedCompanies && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>DIN Number</TableCell>
                                    <TableCell>Form MBP-1</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.directorsInterestedCompanies.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.name ? row.name : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.address ? row.address : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.din ? row.din : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {/* {row.interested
                                            ? row.interested === true
                                              ? "Yes"
                                              : `No`
                                            : "-"} */}
                                            {row.interested !== undefined ? (row.interested ? "Yes" : "No") : "NA"}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.remunerationPaidToDirectors && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Financial Year</TableCell>
                                    <TableCell>Name of Director</TableCell>
                                    
                                    <TableCell>Remuneration</TableCell>
                                    <TableCell>
                                      Board resolution approving
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.remunerationPaidToDirectors.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.fy ? row.fy : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.name ? row.name : `-`}
                                        </TableCell>
                                      
                                        <TableCell>
                                          {row.remuneration
                                            ? row.remuneration
                                            : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.date ? row.date : `-`}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.detailsOfBorrowings && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Date of borrowing</TableCell>
                                    <TableCell>Name of Lender</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Secured / unsecured</TableCell>
                                    <TableCell>Repayment terms</TableCell>
                                    <TableCell>Authorisation date</TableCell>
                                    <TableCell>DPT-3 filed</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.detailsOfBorrowings.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.date ? row.date : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.name ? row.name : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.amount ? row.amount : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.secured ? row.secured : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.termsOrInterest
                                            ? row.termsOrInterest
                                            : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.boardAuthDate ? row.boardAuthDate : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.dpt3Filed ? row.dpt3Filed : `-`}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.relatedPartyTransactions && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Financial Year</TableCell>
                                    <TableCell>Related party</TableCell>
                                    <TableCell>Nature of transaction</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Board Authorisation date</TableCell>
                                    <TableCell>Date of filing</TableCell>
                                    <TableCell>File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.relatedPartyTransactions.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.fy ? row.fy : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.name ? row.name : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.nature ? row.nature : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.amount ? row.amount : `-`}
                                        </TableCell>
                                     
                                        <TableCell>
                                          {row.boardAuthDate ? row.boardAuthDate : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {/* {row.dpt3Filed ? row.dpt3Filed : `-`} */}
                                          {row.dpt3Filed !== undefined ? (row.dpt3Filed ? "Yes" : "No") : "NA"}
                                        </TableCell>
                                        <TableCell sx={{ padding: "0px" }}>
                                          {row.docname ? (
                                            <span
                                              className="view-doc"
                                              onClick={() =>
                                                window.open(row.docname, "_blank")
                                              }
                                            >
                                              View
                                            </span>
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}

                        {data.companyStatutoryRegisters && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Details of Statutory Registers</TableCell>
                                    <TableCell>Remarks</TableCell>
                                    <TableCell>File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.companyStatutoryRegisters.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.details ? row.details : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.remarks ? row.remarks : `-`}
                                      </TableCell>
                                      <TableCell sx={{ padding: "0px" }}>
                                        {row.docname ? (
                                          <span
                                            className="view-doc"
                                            onClick={() =>
                                              window.open(row.docname, "_blank")
                                            }
                                          >
                                            View
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.companyRocFiled && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Financial Year</TableCell>
                                    <TableCell>Remarks</TableCell>
                                    <TableCell>Form Filed</TableCell>
                                   
                                    <TableCell>File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.companyRocFiled.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.financialYear ? row.financialYear : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.remarks ? row.remarks : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {/* {row.formFiled ? row.formFiled : `-`} */}
                                        {row.formFiled !== undefined ? (row.formFiled ? "Yes" : "No") : "NA"}
                                      </TableCell>
                                   
                                      <TableCell sx={{ padding: "0px" }}>
                                        {row.docname ? (
                                          <span
                                            className="view-doc"
                                            onClick={() =>
                                              window.open(row.docname, "_blank")
                                            }
                                          >
                                            View
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.companyBoardMeetings && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Financial Year</TableCell>
                                    <TableCell>Date of Board Meeting</TableCell>
                                    <TableCell>Remarks</TableCell>
                                    <TableCell>File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.companyBoardMeetings.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.financialYear ? row.financialYear : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.date ? row.date : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.remarks ? row.remarks : `-`}
                                      </TableCell>
                                      <TableCell sx={{ padding: "0px" }}>
                                        {row.docname ? (
                                          <span
                                            className="view-doc"
                                            onClick={() =>
                                              window.open(row.docname, "_blank")
                                            }
                                          >
                                            View
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.companyGeneralMeetings && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Financial Year</TableCell>
                                    <TableCell>Date of General Meeting</TableCell>
                                    <TableCell>Remarks</TableCell>
                                    <TableCell>File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.companyGeneralMeetings.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.financialYear ? row.financialYear : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.date ? row.date : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.remarks ? row.remarks : `-`}
                                      </TableCell>
                                      <TableCell sx={{ padding: "0px" }}>
                                        {row.docname ? (
                                          <span
                                            className="view-doc"
                                            onClick={() =>
                                              window.open(row.docname, "_blank")
                                            }
                                          >
                                            View
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                         {data.existingAuditorAppointments && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name of Auditor</TableCell>
                                    <TableCell>Period of appointment</TableCell>
                                    <TableCell>Remuneration</TableCell>
                                    <TableCell>Date of Board</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.existingAuditorAppointments.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.name ? row.name : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.period ? row.period : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.remuneration ? row.remuneration : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.date ? row.date : `-`}
                                      </TableCell>
                                    
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                         {data.annualFilingsLast3Years && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Financial Year</TableCell>
                                    <TableCell>Form Filed</TableCell>
                                    <TableCell>Date of filing</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.annualFilingsLast3Years.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.financialYear ? row.financialYear : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.formFiled !== undefined ? (row.formFiled === "Yes" ? "Yes" : "No") : "NA"}
                                      </TableCell>
                                      <TableCell>
                                        {row.dateOfFiling ? row.dateOfFiling : `-`}
                                      </TableCell>
                                    
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {!data.value &&
                          !data.fileUrl &&
                          !data.companyInvestmentOrLoans &&
                          !data.shareHoldingPatterns &&
                          !data.directorsInterestedCompanies &&
                          !data.remunerationPaidToDirectors &&
                          !data.detailsOfBorrowings &&
                          !data.relatedPartyTransactions &&
                          !data.companyStatutoryRegisters &&
                          !data.companyRocFiled &&
                          !data.companyBoardMeetings &&
                          !data.companyGeneralMeetings && 
                          !data.existingAuditorAppointments && 
                          !data.annualFilingsLast3Years && (
                            <>
                              <b>Not Provided</b>
                            </>
                          )}
                      </Grid>
                      {path === "scstldd" &&
                        startupCheckList.status === 17 &&
                        startupCheckList.showlddVerified === false ? (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <div>
                              <FormControl>
                                <Controller
                                  name={`identifyingInformation.${index}.status`}
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      defaultValue="Innovation"
                                      name="radio-buttons-group"
                                      {...field}
                                      onChange={(e) =>
                                        handelVerifiedStatus(
                                          e.target.value,
                                          index
                                        )
                                      }
                                    >
                                      <span className="d-flex">
                                        {/* {(data.fileUrl ||
                                          data.value ||
                                          data.shareHoldingPatterns ||
                                          data.companyInvestmentOrLoans ||
                                          data.remunerationPaidToDirectors ||
                                          data.directorsInterestedCompanies ||
                                          data.detailsOfBorrowings ||
                                          data.relatedPartyTransactions ||
                                          data.value) && (
                                          <FormControlLabel
                                            disabled={
                                              getValues(
                                                `identifyingInformation.${index}.status`
                                              ) === 2 && data.clarification
                                                ? true
                                                : false
                                            }
                                            value={1}
                                            control={<Radio />}
                                            label="Verified"
                                          />
                                        )} */}
                                        <FormControlLabel
                                          disabled={
                                            getValues(
                                              `identifyingInformation.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false
                                          }
                                          value={1}
                                          control={<Radio />}
                                          label="Reviewed"
                                        />
                                        <FormControlLabel
                                          disabled={
                                            getValues(
                                              `identifyingInformation.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false
                                          }
                                          value={2}
                                          control={<Radio />}
                                          label="More clarification"
                                        />
                                      </span>
                                    </RadioGroup>
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.identifyingInformation?.[index]
                                    ?.status &&
                                    errors.identifyingInformation?.[index]
                                      ?.status.message}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            {getValues(
                              `identifyingInformation.${index}.status`
                            ) === 2 && (
                                <div className="form-group">
                                  <Controller
                                    name={`identifyingInformation.${index}.clarification`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required:
                                        "Request Clarification is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Request Clarification"
                                        placeholder="Enter Message"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        onInput={(e) =>
                                          handleRequestValue(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        inputProps={{
                                          readOnly:
                                            getValues(
                                              `identifyingInformation.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false,
                                        }}
                                        {...field}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Button
                                                disabled={
                                                  getValues(
                                                    `identifyingInformation.${index}.status`
                                                  ) === 2 && data.clarification
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  handleRequestSubmit(index)
                                                }
                                              >
                                                <SendIcon />
                                              </Button>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.identifyingInformation?.[index]
                                      ?.clarification &&
                                      errors.identifyingInformation?.[index]
                                        ?.clarification.message}
                                  </FormHelperText>
                                </div>
                              )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <p className="mb-1">
                              Status : <b>{getBDDStatus(data.status)}</b>
                            </p>
                            {data.clarification && (
                              <p className="mb-1">
                                Clarification :{" "}
                                {data.clarificationAll ? (
                                  <ClarificationAll
                                    remarks={data.clarificationAll}
                                    lblName="Clarifications"
                                    btnName="View"
                                  />
                                ) : (
                                  <Scsthubavpremarks
                                    remarks={data.clarification}
                                    lblName="View"
                                  />
                                )}{" "}
                              </p>
                            )}
                            {data.response && (
                              <p className="mb-1">
                                Startup Response :{" "}
                                <Scsthubavpremarks
                                  remarks={data.response}
                                  lblName="View"
                                />
                              </p>
                            )}
                            {data.fileUrl && (
                              <p className="mb-1">
                                Startup Attached Document :{" "}
                                <Button
                                  onClick={() =>
                                    handleClickOpen(data.fileUrl, data.formet)
                                  }
                                  className="text-cap"
                                >
                                  View
                                </Button>
                              </p>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <h4>LDD Document details is empty</h4>
                </div>
              </>
            )}
          </form>
        </div>
        <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        />
      </div>
    </Box>
  );
};

export default LDDPart2;
