import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import apiService from "../../../api/apiService";
import downloadExcel from "../../../hooks/downloadExcel";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import notifyService from "../../../api/notifySerivce";
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Modal, TextField } from "@mui/material";
import SendEmail from "../startup-page/SendEmail";
import { Controller, useForm } from "react-hook-form";
import CustomLink from "../../common-page/CustomLink";
import { IconButton } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "beige",
  "&:hover": {
    backgroundColor: "beige",
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "23ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const CurrentTanseed = () => {
  const { open, modalstyle, setPageLoading, tableIndexValue } =
    useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, watch, reset, clearErrors, getValues, formState: { errors } } = useForm(
    { values: formData, }
  );
  const [partnerListData, setPartnerListData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeStartup, setActiveStartup] = useState(1);
  const [acceptTanseed, setAcceptTanseed] = useState(true);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [sectorValue, setSectorValue] = useState("All");
  const [cityName, setCityName] = useState("All");
  useDidMountEffect(() => {
    dropdownValueApi();
    getCityApi();
  }, []);
  const [sectorData, setSectorData] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=startup_min`,
      "",
      "get"
    ).then((res) => {
      if (res?.data?.dropDownValues) {
        let data = res.data.dropDownValues.sectors;
        // console.log(data);
        var val = res.data.dropDownValues.sectors.unshift("All");
        // console.log(val);
        setSectorData(data);
      }
    });
  };
  const [cityList, setCityList] = useState([]);
  const getCityApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=district`, "", "get").then(
      (res) => {
        let data = res.data.dropDownValues.districts;
        var val = res.data.dropDownValues.districts.unshift("All");
        setCityList(data);
      }
    );
  };
  useDidMountEffect(() => {
    userListItemTanseed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    searchInputValue,
    activeStartup,
    acceptTanseed,
    sectorValue,
    cityName,
  ]);
  const [searchName, setSearchName] = useState("");
  const handleSearch = (searchValue) => {
    if (searchValue.length > 2) {
      setSearchInputValue(searchValue);
      setPage(0);
    } else {
      setSearchInputValue("");
      setPage(0);
    }
    setSearchName(searchValue);
  };

  const handleSelectSector = (value) => {
    setSectorValue(value);
  };

  const handleSelectCityName = (value) => {
    setCityName(value);
  };
  const userListItemTanseed = async () => {
    setPageLoading(true);
    setPartnerListData([]);
    var reqTanseed = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      acceptTanseedTerms: acceptTanseed,
      sector: sectorValue,
      city: cityName,
    };
    await apiService("user/tanseedstartupslist", reqTanseed, "post").then(
      (res) => {
        setPageLoading(false);
        if (res) {
          setCount(res.data.count);
          if (res.data.responseModelList) {
            setPartnerListData(res.data.responseModelList);
          } else {
            setPartnerListData([]);
          }
        }
      }
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [open2, setOpen2] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen2(!open2);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  // const [loading, setLoading] = useState(false);
  const exportExcelStartup = (download, emails) => {
    setPageLoading(true);
    var req;
    if (download === "All") {
      req = {
        download: download,
        roleId: 4,
        searchString: searchInputValue,
        acceptTanseedTerms: acceptTanseed,
        emails: emails,
        listSize: rowsPerPage,
        pageNumber: page + 1,
      };
    } else {
      req = {
        emails: emails,
        download: download,
        roleId: 4,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        searchString: searchInputValue,
        acceptTanseedTerms: acceptTanseed,
      };
    }
   
      // req = {
      //   email: emails,
      //   download: download,
      //   roleId: 4,
      //   listSize: rowsPerPage,
      //   pageNumber: page + 1,
      //   searchString: searchInputValue,
      //   acceptTanseedTerms: acceptTanseed,
      // };
    // user/list
    apiService("user/tanseedstartupslist", req, "downloadpost").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.byteLength === 169) {
            notifyService("danger", "TANSEED list is empty", "");
          } else {
            if (download === "All") {
              notifyService(
                "success",
                "Success",
                "An Excel file has been sent to your email address."
              );
              handleExportAllClose();
            } else {
              downloadExcel(res.data, "tanseed_");
              // setDownloadValueStartup('')
            }
          }
        }
      }
    });
  };
  // const exportExcel = () => {
  //     setLoading(true);
  //     apiService('export/users?roleid=4', '', 'download').then((res) => {
  //         setLoading(false);
  //         downloadExcel(res.data, 'startup_')
  //     })
  // }

  const handleFilterStartup = (value) => {
    setAcceptTanseed(value);
  };
  // const handleActiveApi = (path, id) => {
  //     apiService(`user/${path}?id=${id}`, '', 'get').then((res) => {
  //         if (res) {
  //             if (res.data) {
  //                 userListItem(4)
  //             }
  //         }
  //     })
  // }

  const [openAllExport, setopenAllExport] = useState(false);
  const handleExportAllClick = () => {
    setopenAllExport(true);
  };
  const handleExportAllClose = () => {
    setopenAllExport(false);
  };
  const handleClear = () => {
    setCount(0);
    setPage(0);
    setRowsPerPage(10);
    setSearchName("");
    setSearchInputValue("");
    setSectorValue("All");
    setCityName("All");
  };

  return (
    <div className="table_tag">
      <div className="table_header_tag set-res-flex">
        <SearchinputDiv
          sx={{ display: { xs: "none", sm: "block" } }}
          onChange={(e) => handleSearch(e.target.value)}
        >
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            value={searchName}
            type="search"
            placeholder="Search by Startup Name"
            inputProps={{ "aria-label": "search" }}
          />
        </SearchinputDiv>
        <div className="ml-auto">
          <div className="d-flex align-item-center">
            <div className="mr-3">
              <Autocomplete
                size="small"
                disableClearable
                disablePortal
                options={sectorData}
                value={sectorValue}
                onChange={(e, selectedOptions) =>
                  handleSelectSector(selectedOptions)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Sector" />
                )}
                sx={{ width: "250px" }}
              />
            </div>
            <div className="mr-3">
              <Autocomplete
                size="small"
                disableClearable
                disablePortal
                value={cityName}
                options={cityList}
                onChange={(e, selectedOptions) =>
                  handleSelectCityName(selectedOptions)
                }
                renderInput={(params) => <TextField {...params} label="City" />}
                sx={{ width: "150px" }}
              />
            </div>
            {(searchInputValue !== "" ||
              sectorValue !== "All" ||
              cityName !== "All") && (
                <div className="mr-3">
                  {/* <Button variant="outlined" onClick={() => handleClear()}>
                    Clear
                  </Button> */}
                  <Button
                    variant="contained"
                    // sx={{ marginRight: "10px" }}
                    onClick={() => handleClear()}
                    data-toggle="collapse"
                    data-target="#collapseWidthExample"
                  >
                    Clear
                  </Button>
                </div>
              )}

            <div className="filiterinvestor">
              <span
                className={
                  acceptTanseed === true
                    ? "common_span act_span"
                    : "common_span"
                }
                onClick={() => {
                  handleFilterStartup(true);
                }}
              >
                Submitted
              </span>
              <span
                className={
                  acceptTanseed === false
                    ? "common_span act_span"
                    : "common_span"
                }
                onClick={() => {
                  handleFilterStartup(false);
                }}
              >
                Not Submitted
              </span>
            </div>
            {/* unused code */}
            {/* <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="outlined"
                            onClick={exportExcel}
                        >
                            <span>EXPORT STARTUP</span>
                        </LoadingButton> */}
            {/* hided code  */}
            {/* {partnerListData.length ? (
              <div className="exp-btn">
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="outlined"
                  disableElevation
                  onClick={handleClick}
                  startIcon={<CloudDownloadIcon />}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Export Startups
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  anchorEl={anchorEl}
                  open={open2}
                  onClose={handleClose2}
                >
                  <MenuItem disableRipple onClick={handleExportAllClick}>
                    Export All
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      handleClose2();
                      exportExcelStartup("This Page Only");
                    }}
                    disableRipple
                  >
                    This Page Only
                  </MenuItem>
                </StyledMenu>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
      <div>
        <div></div>
        {partnerListData.length ? (
          <TableContainer component={Paper}>
            <div className="d-flex align-items-center justify-content-between">
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ float: "left" }}
                ActionsComponent={(paginationProps) => (
                  <CustomTablePaginationActions {...paginationProps} />
                )}
              />
              <div className="exp-btn mr-2">
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="outlined"
                  disableElevation
                  onClick={handleExportAllClick}
                  startIcon={<CloudDownloadIcon />}
                >
                  Export Startups
                </Button>

              </div>
            </div>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Startup Name</TableCell>

                  <TableCell>Email Id</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Sector</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Applied Date</TableCell>
                  {/* <TableCell align='center'>TANSEED Applied</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerListData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {tableIndexValue(rowsPerPage, page, index)}
                    </TableCell>
                    <TableCell>
                      {/* {row.name} */}
                      <CustomLink
                        to={`/admin/tanseed/current/details/${btoa(row.id)}`}
                      >
                        {row.name}
                      </CustomLink>
                    </TableCell>

                    <TableCell>
                      {row.email}
                      {/* {row.accountVerified === true ? (
                        <span className="verify_span">
                          (<i className="fas fa-check-circle"></i> Verified)
                        </span>
                      ) : (
                        <span className="inprogress_span">(Not verified)</span>
                      )} */}
                    </TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.sector ? row.sector : `-`}</TableCell>
                    <TableCell>{row.city ? row.city : `-`}</TableCell>
                    <TableCell>{row.appliedDate}</TableCell>
                    {/* <TableCell align='center'>
                                        {
                                            (row.active === false) && (<span className='no_span'>No</span>)
                                        }
                                        {
                                            (row.active === true) && (<span className='yes_span'>Yes</span>)
                                        }
                                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
              <caption>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ float: "left" }}
                  ActionsComponent={(paginationProps) => (
                    <CustomTablePaginationActions {...paginationProps} />
                  )}
                />
              </caption>
            </Table>
          </TableContainer>
        ) : (
          <div className="nodata_tag">
            <img
              src="/images/Tanfundlogo-black-1.png"
              className="no_data_logo"
              alt=""
            />
            <h4>No Records Found</h4>
          </div>
        )}
      </div>
      <Modal open={openAllExport}>
        <Box sx={modalstyle} className="form_tag">
          <SendEmail
            handleExportAllClose={handleExportAllClose}
            exportExcelStartup={exportExcelStartup}
          />
          {/* <Bulkupload handleExportAllClose={handleExportAllClose} title={'Investor'} api={'investor'} roleid={5} /> */}
        </Box>
      </Modal>
    </div>
  );
};

export default CurrentTanseed;
function CustomTablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "1rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}
