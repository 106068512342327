import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../../../api/apiService';
import { FormHelperText, TextField, Tooltip } from '@mui/material';
import notifyService from '../../../../../api/notifySerivce';
import DataContext from '../../../../../context/DataContext';

const Cpresubmit = ({ id, idNumber, historyList, statusValue, name, viewStartupDetails, showStartupValue, cpBtn, proposeAction }) => {
    debugger
    const { setPageLoading, fullScreen } = useContext(DataContext)
    const { handleSubmit, getValues, control, formState: { errors } } = useForm({});
    const [open, setOpen] = useState(false);
    const [api, setApi] = useState();

    const handleClickOpen = (val) => {
        setOpen(true);
        setApi(val)
    };

    const onSubmit = (data) => {
        setPageLoading(true)
        var getform = data
        let url
        if (name === 'bdd') {
            getform.startupId = idNumber
            getform.bcId = id
            getform.status = 2
            getform.clarification = data.comments
            url = 'partner/startup/clarification/save'
        }
        else if (name === 'ldd') {
            getform.startupId = idNumber
            getform.bcId = id
            getform.status = 2
            getform.clarification = data.comments
            url = 'partner/startup/clarification/ldd/save'
        }
        else if (name === 'fdd') {
            getform.startupId = idNumber
            getform.bcId = id
            getform.status = 2
            getform.clarification = data.comments
            url = 'partner/startup/clarification/fdd/save'
        }
        else if (name === 'cp' && statusValue === 1) {
            getform.startupId = idNumber
            getform.id = id
            getform.status = 4
            getform.proposeAction = data.proposeAction
            // getform.clarification = getValues('comments')
            // delete getform.comments
            url = 'cp/partner/propose/action/save'
            debugger
        }
        else if (name === 'cp' && statusValue === 3 || statusValue === 4) {
            getform.startupId = idNumber
            getform.id = id
            getform.status = 0
            getform.clarification = data.comments
            // getform.clarification = getValues('comments')
            // delete getform.comments
            url = 'cp/partner/startups/additionalinfo/resubmit'
        }
        else {
            getform.id = id
            getform.status = 0
            url = 'partner/startups/additionalinfo/resubmit'
        }
        apiService(url, getform, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success" || res.data.responseStatus === "Saved Successfully") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    historyList()
                    showStartupValue()
                    handleClose()
                }
            }
        })
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {/* {name === 'cp' && statusValue === '1' ? (<>
                <button className='resubmit_btn' onClick={handleClickOpen}>Request Clarification</button>
            </>) : (
                <>
                    <button className='resubmit_btn' onClick={handleClickOpen}>Request More Details</button>
                </>
            )} */}
            {name === 'cp' ? (
                <>
                    {statusValue === 1 ? (<>
                        <Tooltip title="Propose Action">
                            {/* <i
                                className="fas fa-pencil-alt"
                                onClick={handleClickOpen}
                                style={{ color: 'green', cursor: 'pointer', fontSize: '16px' }}
                            ></i> */}
                             <img src="/images/drafts.png" alt="" style={{ cursor: 'pointer', width: '25px' }}   onClick={handleClickOpen} />
                        </Tooltip>
                        {/* <i class="fas fa-pencil-alt" onClick={handleClickOpen} style={{ color: 'green', cursor: 'pointer', fontSize: '18px' }}></i> */}
                    </>
                    )
                        : (<>
                        <Tooltip title="Request Clarification">
                            <i
                                className="fas fa-pencil-alt"
                                onClick={handleClickOpen}
                                style={{ color: 'green', cursor: 'pointer', fontSize: '16px' }}
                            ></i>
                        </Tooltip>
                            {/* <button className='resubmit_btn' onClick={handleClickOpen}>Request Clarification</button> */}
                            </>
                        )}
                </>
            ) : (<>  <button className='resubmit_btn' onClick={handleClickOpen}>Request More Details</button></>)}

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            {proposeAction === 'proposeAction' ? (<>
                                <div className='col-lg-12 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="proposeAction" control={control} defaultValue="" rules={{ required: 'Propose Action is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    variant='outlined'
                                                    label="Enter Propose Action *"
                                                    placeholder='Enter Propose Action'
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.proposeAction && errors.proposeAction.message}</FormHelperText>
                                    </div>
                                </div>
                            </>) : (<>
                                <div className='col-lg-12 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="comments" control={control} defaultValue="" rules={{ required: 'Comments is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    variant='outlined'
                                                    label="Enter Your Additional Comment *"
                                                    placeholder='Enter Your Additional Comment'
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                                    </div>
                                </div></>)}

                        </div>
                        <div className='text-center'>
                            <Button className='mr-3' onClick={handleClose} variant="outlined">
                                Cancel
                            </Button>
                            {cpBtn === 'cpBtn' ? (
                                <>
                                    {statusValue === 1 ? (
                                        <Button type="submit" variant="contained">
                                            Save
                                        </Button>
                                    ) : (<>
                                        <Button type="submit" variant="contained">
                                            Request
                                        </Button>
                                    </>)}
                                </>

                            ) : (<>
                                <Button type="submit" variant="contained">
                                    Resubmit
                                </Button>
                            </>)}

                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </>
    )
}
export default Cpresubmit