import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import PriorityDocuments from "./fdd-verification-details/PriorityDocuments";
import AccountsAndAudit from "./fdd-verification-details/AccountsAndAudit";
import FDDClarifications from "./FDDClarifications";
import FDDpart1 from "./fdd-verification-details/FDDpart1";
import FDDpart2 from "./fdd-verification-details/FDDpart2";
import FDDPriorDocuments1 from "./fdd-verification-details/FDDPriorDocuments1";
import FDDPriorDocuments2 from "./fdd-verification-details/FDDPriorDocuments2";
import FDDPriorDocuments3 from "./fdd-verification-details/FDDPriorDocuments3";
import FDDPriorDocuments4 from "./fdd-verification-details/FDDPriorDocuments4";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const FDDLayout = ({
  idNumber,
  path,
  viewStartupDetails,
  showStartupValue,
  startupCheckList,
  startupValue,
}) => {
  debugger
  // console.log(startupCheckList);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue1("");
    setValue(newValue);
  };
  const [value1, setValue1] = useState();
  const handleChange1 = (event, newValue) => {
    setValue("");
    setValue1(newValue);
  };

  return (
    <div className="overallTab">
      {/* <Tabs
        className="verticalTabCSS"
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab className="verticalLable" label="Priority" {...a11yProps(0)} />
        <Tab
          className="verticalLable"
          label="Additional Documents Part 1"
          {...a11yProps(1)}
        />
        <Tab
          className="verticalLable"
          label="Additional Documents Part 2"
          {...a11yProps(2)}
        />
        {path === "scstfdd" && (
          <Tab
            className="verticalLable"
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Clarification
                {startupCheckList.fddClarificationRequested === true ? (
                  <span className="ml-2">
                    <img
                      src="/images/giphy.gif"
                      alt="gif"
                      className="gif-bell img-fluid"
                    />
                  </span>
                ) : (
                  <></>
                )}
              </div>
            }
            {...a11yProps(3)}
          />
        )}
      </Tabs> */}
      <Tabs
        className="verticalTabCSS"
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab
          className="verticalLable"
          label="Prior Documents 1"
          {...a11yProps(0)}
        />
        <Tab
          className="verticalLable"
          label="Prior Documents 2"
          {...a11yProps(1)}
        />
        <Tab
          className="verticalLable"
          label="Prior Documents 3"
          {...a11yProps(2)}
        />
        <Tab
          className="verticalLable"
          label="Prior Documents 4"
          {...a11yProps(3)}
        />
        <Tab className="verticalLable" label="Priority" {...a11yProps(4)} />
      </Tabs>
      <Tabs
        className="verticalTabCSS"
        orientation="horizontal"
        variant="scrollable"
        value={value1}
        onChange={handleChange1}
        aria-label="Vertical tabs example"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab
          className="verticalLable"
          label="Additional Documents Part 1"
          {...a11yProps(0)}
        />
        <Tab
          className="verticalLable"
          label="Additional Documents Part 2"
          {...a11yProps(1)}
        />
        {path === "scstfdd" && (
          <Tab
            className="verticalLable"
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Clarification
                {startupCheckList.fddClarificationRequested === true ? (
                  <span className="ml-2">
                    <img
                      src="/images/giphy.gif"
                      alt="gif"
                      className="gif-bell img-fluid"
                    />
                  </span>
                ) : (
                  <></>
                )}
              </div>
            }
            {...a11yProps(2)}
          />
        )}
      </Tabs>
      <Box sx={{ p: 3 }}>
        <TabPanel value={value} index={0}>
          <FDDPriorDocuments1
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FDDPriorDocuments2
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FDDPriorDocuments3
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <FDDPriorDocuments4
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PriorityDocuments
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value1} index={0}>
          <FDDpart1
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value1} index={1}>
          <FDDpart2
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={1}><AccountsAndAudit idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails} /></TabPanel>*/}
        <TabPanel value={value1} index={2}>
          <FDDClarifications
            idNumber={idNumber}
            path={path}
            viewStartupDetails={viewStartupDetails}
            showStartupValue={showStartupValue}
            startupCheckList={startupCheckList}
            startupValue={startupValue}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default FDDLayout;
