import { Grid, Pagination, Stack, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Viewimage from '../../../api/Viewimage';

const DocumentRoomAdminAll = ({ status, startupId, searchInputValue }) => {
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [listData, setListData] = useState([])

    const { setPageLoading } = useContext(DataContext)


    useDidMountEffect(() => {
        listApi(status, startupId);
    }, [page, searchInputValue]);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const listApi = (status, startupId) => {
        setPageLoading(true)
        setListData([])
        var req = {
            "listSize": 12,
            "pageNumber": page,
            "searchString": searchInputValue,
            "startupId": startupId,
            "status": status
        }
        debugger
        apiService('document/room/details/list', req, 'post')
            .then((res) => {
                setPageLoading(false)
                if (res && res.data) {
                    setTotalPage(res.data.totalPages)

                    if (res.data.documentRoomDetailsList) {


                        res.data.documentRoomDetailsList.forEach((element) => {

                            if (element.docUrl) {
                                var val = element.docUrl;
                                var data = val.split("?");
                                if (data) {
                                    var formet = data[0].split(".");
                                    element.formet = formet[4];
                                }
                            }
                        });
                        setListData(res.data.documentRoomDetailsList)
                    }
                }

            }).catch((err) => {
                console.log(err);

            });

    }

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value, formet) => {
        debugger
        setViewImage(value);
        if (formet === "xlsx" || formet === "docx" || formet === "zip" || formet === "txt" || formet === "csv") {
            const link = document.createElement('a');
            link.href = value;
            // link.download = 'excelfile.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>

            {listData.length > 0 ? (<>
                <Grid
                    container
                    direction="row"
                    // alignItems="flex-start"
                    justifyContent={'flex-start'}
                    spacing={3}
                >
                    {listData.map((item, i) => (
                        <>
                            <Tooltip title={item.docName}>
                                <Grid item sm={12} md={2} lg={2} xl={2} key={i}>

                                    <div className='report_view_div' onClick={() => handleClickOpen(item.docUrl, item.formet)}>

                                        {(item.formet === 'xlsx' || item.formet === 'csv') && (
                                            <>
                                                <img src='/images/excelpng.png' className='pdf_img' alt='Excel_icon' />
                                            </>)}
                                        {item.formet === 'pdf' && (
                                            <>
                                                <img src='/images/pdf.png' className='pdf_img' alt='pdf_icon' />
                                            </>)}
                                        {item.formet === 'zip' && (
                                            <>
                                                <img src='/images/img/zip.png' className='pdf_img' alt='pdf_icon' />
                                            </>)}
                                        {(item.formet === 'docx' || item.formet === "txt") && (
                                            <>
                                                <img src='/images/img/docx.png' className='pdf_img' alt='pdf_icon' />
                                            </>)}
                                        {(item.formet === 'jpg' || item.formet === 'jpeg' || item.formet === 'png' || item.formet === 'svg') && (
                                            <>
                                                <img src='/images/img/image.png' className='pdf_img' alt='pdf_icon' />
                                            </>)}
                                        <span style={{
                                            fontSize: ' 13px',
                                            fontWeight: '600',
                                            marginTop: '10px'
                                        }}>{item.docName}</span>
                                    </div>
                                </Grid>
                            </Tooltip></>))}
                </Grid>
                {totalPage > 1 && (<div className="d-flex justify-content-center mt-3">
                    <Stack spacing={2}>
                        <Pagination
                            count={totalPage}
                            page={page}
                            onChange={handleChange}
                            color="primary"
                        />
                    </Stack>
                </div>)}
            </>) : (<>
                <div className="nodata_tag">
                    <img
                        src="/images/Tanfundlogo-black-1.png"
                        className="no_data_logo"
                        alt="Tanfund_logo"
                    />
                    <h4>No Documents Submitted</h4>
                </div></>)}




            <Viewimage
                viewImage={viewImage}
                open={open}
                handleClose={handleClose}
            />
        </div>
    )
}

export default DocumentRoomAdminAll
