import React from 'react'
import { useState } from 'react';
import MentorStartupsOnboarded from './Mentorship-details/MentorStartupsOnboarded';
import ScstoldMentorship from './ScstoldMentorship';

const Scstmentorshiplist = ({role,stage}) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue)
  };
  return (
    <div className="scsthub-div form_div chart">
      <MentorStartupsOnboarded role={role} stage={stage}/>
    </div>
  )
}

export default Scstmentorshiplist