import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import DataContext from '../../../context/DataContext';
// import Viewimage from '../../api/Viewimage';
// import { Link } from 'react-router-dom';
// import PreviewIcon from '@mui/icons-material/Preview';
import { Controller, useForm } from 'react-hook-form';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { MuiFileInput } from 'mui-file-input';

const IcPscApprovel = ({ className, btnName, idNumber, viewStartupDetails, showStartupValue, lbl, status, mentorShip }) => {
    debugger
    const [openReport, setOpenReport] = useState(false);
    const handleClickReportOpen = () => {
        setOpenReport(true);
        setAdditionalDocValue(null)
        clearErrors('additionalDoc')
    };
    const handleReportClose = () => {
        setOpenReport(false);
        setAdditionalDocValue('');
        setValue('additionalDoc', '');
    };
    // console.log(statusValue)
    // const { id } = data
    const { VisuallyHiddenInput, setPageLoading } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, getValues, control, clearErrors, setValue, formState: { errors } } = useForm(
        { values: formData }
    );
    const [additionalDocURL, setAdditionalDocURL] = useState(null)
    const [additionalDocValue, setAdditionalDocValue] = useState(null)
    const [open, setOpen] = useState(false);
    const [checkMethod, setCheckMethod] = useState(false)

    // const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
    //     if (event) {
    //         if (event.target === undefined) {
    //             logoselecetdFile = event
    //         } else {
    //             logoselecetdFile = event.target.files[0]
    //         }
    //         if (logoselecetdFile) {
    //             var reader = new FileReader();
    //             var imagetype = logoselecetdFile.type
    //             var imagedatatype = imagetype.split("/")
    //             var img_crt_type = imagedatatype[1]
    //             if (img_crt_type === "pdf") {
    //                 setValue(filekey, "")
    //                 setValue(fileName, "")
    //                 setdata('')
    //                 setUrl('')
    //                 if (event.target === undefined) {
    //                     setdata(event)
    //                 } else {
    //                     setdata(event.target.files[0])
    //                 }
    //                 var fileValue = logoselecetdFile
    //                 reader.readAsDataURL(logoselecetdFile);
    //                 reader.onload = () => {
    //                     var logourl1 = reader.result;
    //                     var spl = logourl1.split(',')
    //                     var ImageValue = spl[1]
    //                     var img_name = fileValue.name
    //                     setUrl(logourl1)
    //                     setValue(filekey, ImageValue)
    //                     setValue(fileName, img_name)
    //                     clearErrors(filekey);
    //                     clearErrors(fileName);
    //                     debugger
    //                 }
    //             } else {
    //                 notifyService(
    //                     "danger",
    //                     "File Format Invalid",
    //                     "Please check your file format."
    //                 );
    //             }
    //         }
    //     }
    // }
    let logoselecetdFile
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        debugger
        if (event) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 10 * 1024 * 1024;
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type
                    var imagedatatype = imagetype.split("/")
                    var img_crt_type = imagedatatype[1]
                    if (img_crt_type === "pdf") {
                        setValue(filekey, "")
                        setValue(fileName, "")
                        setdata('')
                        setUrl('')
                        if (event.target === undefined) {
                            setdata(event)
                        } else {
                            setdata(event.target.files[0])
                        }
                        var fileValue = logoselecetdFile
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(',')
                            var ImageValue = spl[1]
                            var img_name = fileValue.name
                            setUrl(logourl1)
                            setValue(filekey, ImageValue)
                            setValue(fileName, img_name)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            debugger
                        }

                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File size exceeds 10MB",
                        "Please check your file size"
                    );
                }
            }
        }
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        var getform = data
        getform.status = status
        getform.startupId = idNumber
        debugger
        apiService('partner/scst/status/save', getform, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    showStartupValue()
                    handleReportClose()
                }
            }
        })
    };
 

    const [openReportAdmin, setOpeReportAdmin] = useState(false);
    const handleReportCloseAdmin = () => {
        setOpeReportAdmin(false);
    };
    const handleViewOpenAdmin = (status) => {
        setOpeReportAdmin(true);
    };
    const onSave = (data) => {
        setPageLoading(true)
        var getform = data
        getform.status = status
        getform.startupId = idNumber
        debugger
        apiService('partner/scst/status/save', getform, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    showStartupValue()
                    setOpeReportAdmin()
                }
            }
        })
    };
    return (
        <>
            {mentorShip === 'mentorShip' ? (

                <>
                    <button
                        className={className}
                        onClick={() => {
                            handleViewOpenAdmin();
                        }}
                    >
                        {btnName}
                    </button>
                </>
            ) : (<>
                <button
                    className={className}
                    onClick={() => {
                        handleClickReportOpen();
                    }}
                >
                    {btnName}
                </button>
            </>)}

            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={openReport}
                onClose={handleReportClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {lbl} Report
                </DialogTitle>
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="additionalDoc" control={control} defaultValue="" rules={{ required: `${lbl} Report File is required` }}
                                        render={({ field: { value, onChange, ...field } }) =>
                                            <MuiFileInput
                                                {...field}
                                                // onChange={(e) => handleFileUpload(e, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL)}
                                                onChange={(file) => {
                                                    if (file) {
                                                        handleFileUpload(file, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL);
                                                    } else {
                                                        setAdditionalDocValue('');
                                                        setValue('additionalDoc', '');
                                                    }
                                                    // onChange(file);
                                                }}
                                                value={additionalDocValue}
                                                variant='outlined'
                                                fullWidth={true}
                                                label={`Upload ${lbl} Report *`}
                                                placeholder='Upload Report' />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.additionalDoc && errors.additionalDoc.message}</FormHelperText>
                                    <span className='font-sm'>Maximum 10 mb allowed doc (pdf) <span className='text-danger'>*</span></span>
                                </div>

                                <div className="form-group mt-2">
                                    <Controller name="comments" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                multiline
                                                label="Enter comments"
                                                placeholder='Enter comments'
                                                fullWidth
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className='text-center p-3'>
                            <Button className='mr-3' onClick={handleReportClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth={"sm"}
                fullWidth={true}
                open={openReportAdmin}
                onClose={handleReportCloseAdmin}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">Mentorship</DialogTitle>
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSave)}>
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="form-group mt-2">
                                    <Controller
                                        name="comments"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Please Enter Remarks" }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                multiline
                                                label="Remarks *"
                                                placeholder="Enter Remarks"
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.comments && errors.comments.message}
                                    </FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="text-center p-3">
                            <Button
                                className="mr-3"
                                onClick={handleReportCloseAdmin}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default IcPscApprovel