import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormHelperText } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../context/DataContext';
import { Controller, useForm } from 'react-hook-form';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Jurysave = ({ handleClose, path, name }) => {
  const { readyOnlyEmail, validatePhoneNumber, validateEmail, setLoading, userId, loading, handleCancelModal, fullScreen } = useContext(DataContext)
  const [lableName, setLableName] = useState('Add')
  const [registerData, setRegisterData] = useState({});
  const { handleSubmit, control, setValue, reset, setError, clearErrors, formState: { errors } } = useForm(
    { values: registerData }
  );
  const [paData, setPartnerListData] = useState([])
  const [expertiseName, setExpertiseName] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('');
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    dropdownValueApi()
    ExpertisedropdownValueApi()
    if (userId !== '') {
      (async () => await getRegisterItem())()
      setLableName('Update')
      setReadyOnlyValue(true)
    } else {
      setReadyOnlyValue(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyOnlyValue, setReadyOnlyValue]);
  const [sectorData, setSectorData] = useState([])
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=investor`, '', 'get').then((res) => {
      var val = res.data.dropDownValues
      setSectorData(val.sectors)
    })
  }
  // get user api   
  const getRegisterItem = async () => {
    apiService(`user/get?id=${userId}`, '', 'get').then((res) => {
      if (res) {
        setReadyOnlyValue(true)
        setRegisterData(res.data)
        setExpertiseName(res.data.expertise)
        if (res.data.preferredSectors) {
          setPreferredSectors(res.data.preferredSectors)
        }
      }
    })
  }
  const [preferredSectors, setPreferredSectors] = useState([]);
  const handleSelectSector = (value) => {
    setPreferredSectors([])
    if (value.length === 0) {
      setError('preferredSectors', { message: 'Sector is required' });
      return;
    }
    if (value[value.length - 1] === 'Select All') {
      var dataa = sectorData.filter((item) => item !== 'Remove All' && item !== 'Select All')
      setPreferredSectors(dataa);
      setValue('preferredSectors', dataa)
      clearErrors('preferredSectors');
    } else if (value[value.length - 1] === 'Remove All') {
      setPreferredSectors([]);
      setValue('preferredSectors', [])
    } else {
      setPreferredSectors(value);
      setValue('preferredSectors', value)
      clearErrors('preferredSectors');
    }
  };

  // Expertise dropdown api

  const [expertiseData,setExpertiseData] = useState([])
  const ExpertisedropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=expertise`,
      "",
      "get"
    ).then((res) => {
      if (res?.data?.dropDownValues) {
        let data = res.data.dropDownValues.expertise;
        setExpertiseData(data);
      }
    });
  };

 
  const handleExpertise = (value) => {
      setValue('expertise', value);
      clearErrors('expertise')
      setExpertiseName(value)
  };

  // const onSubmit = (data, event) => {
  //   var getform = data
  //   
  //   apiService('jury/save', getform, 'post').then((res) => {
  //     setLoading(false);
  //     if (res) {
  //       notifyService('success', 'Success', 'Your registration details added successfully !!')
  //       event.target.reset();
  //       handleCancelModal()
  //       handleSuccessOpen()
  //     }
  //   })
  // }
  const [email, setEmail] = useState('')
  const onSubmit = (data, event) => {
    var getform = data;
    let url
    if (lableName === 'Add') {
      url = 'jury/save'
    } else {
      url = 'jury/update'
    }
    apiService(url, getform, 'post').then((res) => {
      if (res && res.data) {
        setEmail(res.data.email)
        setLoading(false);
        if (res && res.data.userAlreadyExist === true) {
          if (lableName === 'Add') {
            handleSuccessOpen();
          } else {
            notifyService('success', 'Success', 'Your registration details updated successfully !!');
            event.target.reset();
            handleClose()
          }

        } else {
          notifyService('success', 'Success', 'Your registration details added successfully !!');
          event.target.reset();
          handleClose()
        }
      }

    });
  };

  const handleSave = () => {
    var req = {
      "associate": true,
      "email": email
    }
    console.log(req);

    apiService('jury/save', req, 'post').then((res) => {
      if (res) {
        notifyService('success', 'Success', 'Your registration details added successfully !!')
        handleSuccessClose()
        handleClose()
      }
    });
  }

  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSuccessOpen = () => {
    setOpenSuccess(true)
  };
  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  return (
    <section className=''>
      <Container>
        <div className='header_tag'>
          <h5 className='c-black'>{lableName} {name}</h5>
        </div>
        <div className=''>
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='d-none'>
                <div className="form-group mb-3">
                  <Controller name="id" control={control} defaultValue="0" rules={{ required: false }}
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>

              <div className='col-lg-6 col-12 mb-3'>
                <div className="form-group mb-3">
                  <Controller name="name" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Name *"
                        placeholder='Enter Name'

                        fullWidth
                        {...field}
                      // inputProps={
                      //   { readOnly: readyOnlyValue }
                      // }
                      />} />
                  <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-6 col-12 mb-3'>
                <div className="form-group mb-3">
                  <Controller name="phone" control={control} defaultValue="" rules={{ required: 'Phone Number is required', validate: validatePhoneNumber }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Phone Number *"
                        placeholder='Enter Phone Number'
                        fullWidth
                        {...field}
                        inputProps={
                          { maxLength: 10 }
                        }
                      />} />
                  <FormHelperText className='text-danger'>{errors.phone && errors.phone.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-6 col-12 mb-3'>
                <div className="form-group mb-3">
                  {/* userId */}
                  <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email Id is required', validate: validateEmail }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Email Id *"
                        placeholder='Enter Email Id'
                        fullWidth
                        inputProps={
                          { readOnly: readyOnlyValue }
                        }
                        {...field}
                      />} />
                  <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                </div>
              </div>

              <div className='col-lg-6 col-12 mb-3'>
                <div className="form-group mb-3">
                  <Controller name="linkedIn" control={control} defaultValue="" rules={{ required: 'LinkedIn is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="LinkedIn *"
                        placeholder='Enter LinkedIn'
                        fullWidth
                        {...field}
                      // inputProps={
                      //   { readOnly: readyOnlyValue }
                      // }
                      />} />
                  <FormHelperText className='text-danger'>{errors.linkedIn && errors.linkedIn.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-6 col-12 mb-3'>
                <div className="form-group mb-3">
                  {/* <Controller name="expertise" control={control} defaultValue="" rules={{ required: 'Expertise is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Expertise *"
                        placeholder='Enter Expertise'
                        fullWidth
                        {...field}
                      />} />
                  <FormHelperText className='text-danger'>{errors.expertise && errors.expertise.message}</FormHelperText> */}
                  <Controller name="expertise" control={control} defaultValue="" rules={{ required: 'Expertise is requried' }}
                    render={({ field }) =>
                      <Autocomplete
                        fullWidth
                        {...field}
                        disableClearable
                        disablePortal
                        value={expertiseName}
                        autoFocus
                        options={expertiseData}
                        onChange={(e, selectedOptions) => handleExpertise(selectedOptions)}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Expertise *" />}
                      />} />
                  <FormHelperText className='text-danger'>{errors.expertise && errors.expertise.message}</FormHelperText>
                </div>
              </div>                
              {
                (path === 'investor' || path === 'admin') && (
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mb-3">
                      <Controller name="preferredSectors" control={control} defaultValue="" rules={{ required: 'Sector is required' }}
                        render={({ field }) =>
                          <Autocomplete
                            disableClearable
                            disablePortal
                            multiple
                            {...field}
                            limitTags={2}
                            value={preferredSectors}
                            options={sectorData}
                            onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => <TextField {...params} label="Sectors *" />}
                          />
                        } />
                      <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                      <FormHelperText className='text-danger'>{errors.preferredSectors && errors.preferredSectors.message}</FormHelperText>

                    </div>
                  </div>
                )
              }

            </div>
            <div className="form-group text-center">
              <Button variant="contained" onClick={handleClose} className="btn btn-cancel submit px-3 mr-3">Cancel</Button>
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3"
              >
                {
                  (lableName === 'Add') ? (<span>Save</span>) : (<span>Update</span>)
                }
              </LoadingButton>
            </div>
          </form>
        </div>
      </Container>
      <Dialog
        open={openSuccess}
        // onClose={handleSuccessClose}
        fullWidth={fullScreen}
        maxWidth={'sm'}
      >
        <DialogTitle id="alert-dialog-title">
          <center><h4 className='my-3'>{name} Already Exists</h4></center>
        </DialogTitle>
        <DialogContent>
          <center><p>{name} Already Exists in our Record. Do you like to add this {name === 'Jury' ? ('jury') : ('evaluator')} ?</p></center>
        </DialogContent>
        <DialogActions className='jury-modal my-3'>
          <Button variant='outlined' onClick={handleSuccessClose}>No</Button>
          <Button variant='outlined' onClick={handleSave} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </section>
  )
}

export default Jurysave