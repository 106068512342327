import React, { useContext, useState } from "react";
import {
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";

const AlertPanel = () => {
    const role = localStorage.getItem("role");
    useDidMountEffect(() => {
      if (
        role === "SCSTADMIN" ||
        role === "SCSTHUBAVP" ||
        role === "SCSTHUB" ||
        role === "JURY" ||
        role === "BDD" ||
        role === "LDD" ||
        role === "FDD" ||
        role === "IC" ||
        role === "PSC" ||
        role === "MENTORSHIP"
      ) {
        pendinglistApi();
      }
    }, []);
    const { navigator, setNotificationChange, scstBDDRemoveFiters, scstRemoveFiters, adminStartupRemoveFilter, tanseedJuryRemoveFilter, tanseedPartnerRemoveFilter } = useContext(DataContext);
  
    const [anchorEl, setAnchorEl] = useState(null);
    const [badgeCount, setBadgeCount] = useState();
    const [notificationList, setNotificationList] = useState([]);
  
    const handleClickOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
  
    const pendinglistApi = () => {
      apiService("notification/scst/admin/threshold/alert/list", "", "get")
        .then((res) => {
          if (res?.data) {
            if (res.data.count !== 0) {
              setBadgeCount(res.data.count);
              localStorage.setItem('thresholdCount',res.data.count)
            }
            if (res.data.thresholdAlert) {
              setNotificationList(res.data.thresholdAlert);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    const getPhaseColor = (phase) => {
      switch (phase) {
        case "All":
          return "#FFC107";
        case "Screening":
          return "#2196F3";
        case "Evaluation":
          return "#8BC34A";
        case "BDD First Connect":
          return "#FF5722";
        case "BDD Deep Dive":
          return "#9C27B0";
        case "IC":
          return "#3F51B5";
        case "PSC":
          return "#009688";
        case "LDD & FDD":
          return "#E91E63";
        case "Fund Sanctioned":
          return "#673AB7";
        case "CP":
          return "#802557";
        default:
          return "#f0f0f0";
      }
    };
    const handleselectStatus = (status, phase) => {
      debugger
      setNotificationChange(false);
      scstRemoveFiters()
      scstBDDRemoveFiters()
      adminStartupRemoveFilter()
      tanseedJuryRemoveFilter()
      tanseedPartnerRemoveFilter()
      
    
      if (role === "SCSTADMIN") {
        localStorage.setItem("statusValueScst", status);
        localStorage.setItem("phaseValueScst", phase);
        localStorage.setItem("hubScst", 'All');
        localStorage.setItem("districtNameScst", 'All');
        setTimeout(() => setNotificationChange(true), 0);
        navigator(`/scstadmin/alert-pending/list`);
       
      } 
      // else if (role === "SCSTHUBAVP") {
      //   debugger
      //   localStorage.setItem("statusValueScst", status);
      //   localStorage.setItem("phaseValueScst", phase);
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/scsthubavp/approval-pending/list`);
      //   debugger
      // } else if (role === "SCSTHUB") {
      //   debugger
      //   localStorage.setItem("statusValueScst", status);
      //   localStorage.setItem("phaseValueScst", phase);
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/scst/startups/validation`);
      //   debugger
      // } else if (role === "JURY") {
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/jury/scst/approval-pending/list`);
      // } else if (role === "BDD") {
        
      //   localStorage.setItem("statusValueBDDStartup", status);
      //   localStorage.setItem("phaseValueBDDStartup", phase);
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/scstbdd/startups/list`);
      // } else if (role === "LDD") {
      //   localStorage.setItem("statusValueBDDStartup", status);
      //   localStorage.setItem("phaseValueBDDStartup", "All");
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/scstldd/startups/list`);
      // } else if (role === "FDD") {
      //   localStorage.setItem("statusValueBDDStartup", status);
      //   localStorage.setItem("phaseValueBDDStartup", "All");
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/scstfdd/startups/list`);
      // } else if (role === "IC") {
      //   localStorage.setItem("statusValueBDDStartup", status);
      //   localStorage.setItem("phaseValueBDDStartup", "All");
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/ic/startups/list`);
      // } else if (role === "PSC") {
      //   localStorage.setItem("statusValueBDDStartup", status);
      //   localStorage.setItem("phaseValueBDDStartup", "All");
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/psc/startups/list`);
      // } else if (role === "MENTORSHIP") {
      //   setTimeout(() => setNotificationChange(true), 0);
      //   navigator(`/scstmentorship/startups/list/current`);
      // }
      handleClose();
    };
    return (
      <div>
        {(role === "SCSTADMIN" ||
          role === "SCSTHUBAVP" ||
          role === "SCSTHUB" ||
          role === "JURY" ||
          role === "BDD" ||
          role === "LDD" ||
          role === "FDD" ||
          role === "IC" ||
          role === "MENTORSHIP" ||
          role === "PSC") && (
            <IconButton onClick={handleClickOpen}>
              <Badge badgeContent={badgeCount} color="error">
                {/* <NotificationsIcon sx={{ color: "#fff" }} /> */}
                <img src="/images/warning.png" alt="" style={{width:'22px'}}/>
              </Badge>
            </IconButton>
          )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          PaperProps={{
            className: "notification-list",
            sx: {
              width: "480px",
              height: "auto",
              maxHeight: "550px",
              overflow: "auto",
              // top: "80px !important",
              // left: "1000px !important",
            },
          }}
        >
          <List>
            {notificationList.length > 0 ? (
              notificationList.map((notification, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={(e) =>
                    handleselectStatus(notification.status, notification.phase)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      backgroundColor: getPhaseColor(notification.phase),
                      marginRight: "10px",
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {notification.phase
                        ? notification.phase.charAt(0).toUpperCase()
                        : ""}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <ListItemText
                      primary={`${notification.message}`}
                      secondary={
                        (role === "SCSTADMIN" ||
                          role === "SCSTHUBAVP" ||
                          role === "BDD" ||
                          role === "SCSTHUB") &&
                        `Current Phase : ${notification.phase}`
                      }
                    />
                  </Box>
                </ListItem>
              ))
            ) : (
              <>
                <p className="text-center p-5">You're all caught up!</p>
              </>
            )}
          </List>
        </Popover>
      </div>
    );
  };

export default AlertPanel