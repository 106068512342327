import React, { useContext } from 'react'
import DataContext from '../../../context/DataContext'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../api/apiService';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
const Viewjurynamelist = ({ viewJuryData, id, handleCloseJury }) => {
    const { setPageLoading } = useContext(DataContext)
    const [openJury, setOpenJury] = React.useState(false);
    const [juryID, setJuryID] = React.useState(false);
    const deletEvalator = (id) => {
        setOpenJury(true);
        setJuryID(id)
    }
    const handleUnassign = () => {
        setPageLoading(true)
        var req = {
            "partnerOrInvestorId": id,
            // "assign": false,
            "jury": juryID
        }
        debugger
        apiService('partner/scst/jury/unassign', req, 'post').then((result) => {
            setPageLoading(false)
            if (result) {
                if (result.data.responseStatus === 'Success') {
                    // setJuryIds([])
                    // reset()
                    handleCloseJury()
                }
            }
        }).catch((err) => {

        });

    }
    return (
        <>
            <DialogTitle>
                <div className='d-flex align-item-center'> View Evaluator
                    <CancelIcon className='c-red ml-auto c-pointer' onClick={handleCloseJury} />
                </div>
            </DialogTitle>
            <DialogContent>
                {
                    (viewJuryData.length) ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell className='text-center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {viewJuryData.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{list.name}</TableCell>
                                            <TableCell className='text-center'>
                                                {/* <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => handleUnassign(list.id)} /> */}
                                                <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => deletEvalator(list.id)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <div className='nodata_tag'>
                            <p>Evaluator Details is empty</p>
                        </div>
                    )
                }
            </DialogContent>

            <Dialog
                maxWidth={'sm'}
                fullWidth
                open={openJury}
                onClose={handleCloseJury}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention -man.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h5 className='remove-api-modal text-center'>Are you sure ?</h5>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='d-flex justify-content-center mb-2'>
                    <Button className='remove-api-modal-cancel' onClick={handleCloseJury}>Cancel</Button>
                    <Button className='remove-api-modal-confirm' onClick={handleUnassign}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Viewjurynamelist