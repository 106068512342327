import React, { useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import apiService from '../../../../api/apiService';
import { Link } from 'react-router-dom';
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TablePagination, TextField } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Startround from './Startround';
import CancelIcon from '@mui/icons-material/Cancel';

const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const Tanseedassociatedlist = ({ name, path, endpoint, lable, url,selectedRound,setSelectedRound,roundListData,setRoundListData }) => {
    const { setPageLoading,  tableIndexValue } = useContext(DataContext)
    const [panelRoundId, setPanelRoundId] = useState(56)
    const [roundStatus, setRoundStatus] = useState(false)
    const [checkedList, setCheckedList] = useState([]);
    const [itemsChecked, setItemsChecked] = useState(false);
    const [indexValue, setIndexValue] = useState([])
    const [count, setCount] = useState(0);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortRank, setSortRank] = useState()


    const [openJury, setOpenJury] = React.useState(false);


    const handleJuryClickOpen = () => {
        setOpenJury(true);
    };

    const handleJuryClose = () => {
        setOpenJury(false);
    };

    useDidMountEffect(() => {
        localStorage.setItem('point', lable)
        if (roundListData.length > 0) {
            setSelectedRound(roundListData[0].name)
            setPanelRoundId(roundListData[0].id)
        }
        // roundlistItem()
        setRoundStatus(false)
        setCheckedList([])
        setItemsChecked(false)
        debugger
    }, [])
    const [enablePromoteRound, setEnablePromoteRound] = useState();
    useDidMountEffect(() => {
        listApi()
        // eslint-disable-next-line
        debugger
    }, [page, rowsPerPage, searchInputValue, panelRoundId, roundStatus, sortRank])
    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // const [selectedRound, setSelectedRound] = useState()
    const handleSelectRound = (value) => {
        setSelectedRound(value)
        debugger
        const roundname = roundListData.filter((item) => item.name === value)
        setPanelRoundId(roundname[0].id)
        setItemsChecked(false);
        setCheckedList([])
    }
    const [lableValue, setLableValue] = useState([])
    const [startupListData, setStartupListData] = useState([])
    const [showRank, setShowRank] = useState()
    const [keyName, setKeyName] = useState('')
    const [panelId, setPanelId] = useState()
    const [startupId, setStartupId] = useState()
    const [category, setCategory] = useState([])

    const [openWeightedScore, setOpenWeightedScore] = React.useState(false);
    const handleWeightedscore = (id) => {
        console.log(id, "ids")
        setOpenWeightedScore(true);
        setPageLoading(true)
        var req = {
            "panelroundId": panelId,
            "startupId": id,
        }
        apiService('partner/jury/weightedscore', req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.category) {
                    setCategory(res.data.category)
                }
            }
        })
    };
    const handleWeightedscoreClose = () => {
        setOpenWeightedScore(false);
    };
const [inProgressStatus,setInProgressStatus]=useState(false)
const [juryBtn, setJuryBtn] = useState(false)
    const listApi = async () => {
        setLableValue([])
        setStartupListData([])
        setPageLoading(true)
        setEnablePromoteRound(false)
        var req = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "searchString": searchInputValue,
            "panelRoundId": panelRoundId,
            "roundCompleted": roundStatus,
            "sort": sortRank
        }
        apiService(`${url}`, req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                setPanelId(res.data.panelRoundId)
                setShowRank(res.data.showRank)
                setKeyName(res.data.key)
                debugger
                setCount(res.data.count)
                setJuryBtn(res.data.firstPanelRound)
                if (res.data.startups) {
                    setStartupListData(res.data.startups)
                    // setStartupId(res.data.startups.startupId)
                }
                if(res.data.roundCompleted)
                setInProgressStatus(res.data.roundCompleted)
                if (res.data.juryNames) {
                    setLableValue(res.data.juryNames)
                }
                if (res.data.enablePromoteRound) {
                    setEnablePromoteRound(res.data.enablePromoteRound)
                }
                // 

            }
        })
    }
    const handleClear = () => {
        setSortRank('')
        setSelectedRound('')
        setRoundStatus(false)
        setCheckedList([])
    }
    const [openView, setOpenView] = useState(false);
    const [getData, setSetGetData] = useState(0);
    const handleViewOpen = (id, selected, remarks) => {
        var req = {
            "id": id,
            "selected": selected,
            "remarks": remarks
        }
        setSetGetData(req)
        setOpenView(true)
    }
      const RoundStartup =() =>{
        setRoundStatus(false)        
        setCheckedList([])
        setItemsChecked(false)
    }
    const RoundStartup2 =() =>{
        // setRoundStatus(true)
        setRoundStatus(true)
        setCheckedList([])
        setItemsChecked(false)
    }
    const handleViewClose = () => {
        setOpenView(false);
        // startuplistItem(name, endpoint)
        listApi()
    }
    // const promoteRound = (id) => {
    //     const collection = [];
    //     collection.push(id);
    //     var val = collection
    //     startRoundsApi(val)
    // }
    const [openStartRound, setOpenStartRound] = useState(false)

    const selectStartRound1 = () => {
        setOpenStartRound(true)
    }
    const selectStartRound = () => {
        var val = checkedList
        let urlName
        if (lable === 'start') {
            setOpenStartRound(true)
            // urlName = 'partner/startups/startround'
        } else {
            urlName = 'partner/startups/promoteround'
            startRoundsApi(val, urlName)
        }

    }
    const handleStartRoundClose = () => {
        setOpenStartRound(false)
        listApi()
    }
    const startRoundsApi = (val, urlName) => {
        setPageLoading(true)
        apiService(urlName, val, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    if (res.data.responseStatus === "Success") {
                        setCheckedList([]);
                        setItemsChecked(false);
                        // startuplistItem(name, endpoint)
                        listApi()
                    }
                }
            }

        })
    }


    const handleCheckboxClick = (e, index) => {
        const { value, checked } = e.target;
        var num = Number(value)
        if (checked) {
            setCheckedList([...checkedList, value * 1]);
            setIndexValue([indexValue, index])
            sectFgs()
        } else {
            // indexValue
            setIndexValue(indexValue.filter((item) => item !== index))
            setCheckedList(checkedList.filter((item) => item !== num));

            if (checkedList.length === 1) {
               
                setCheckedList([])

            }
            setItemsChecked(false);
        }
    };
    const sectFgs = () => {
        console.log(indexValue.length)
        if (startupListData.length === indexValue.length) {
            setItemsChecked(true);
        } else {
            setItemsChecked(false);
        }
    }
    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];
        if (checked) {
            for (const category of startupListData) {
                collection.push(category.startupId);
            }
        }
        setCheckedList(collection);
        setItemsChecked(checked);

    };


    const handleSortRank = (value) => {
        console.log(value, "rank")
        setSortRank(value)
    }

    return (
        <section className=''>
            <div className='table_header_tag d-flex'>
                <div>
                    <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            value={searchInputValue}
                            placeholder="Search…"
                            type="search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </SearchinputDiv>
                </div>

                <div className='ml-auto d-flex align-items-center'>
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={roundStatus === false
                            ? "roundStatus_css roundActive"
                            : "roundStatus_css"} onClick={() => {
                                RoundStartup();
                            }}>
                            {
                                roundStatus === false && (<DoneAllIcon className='mr-2' />)
                            }
                            Inprogress
                        </p>
                        <p className={roundStatus === true
                            ? "roundStatus_css roundActive"
                            : "roundStatus_css"} onClick={() => {
                                RoundStartup2();
                            }}>
                            {
                                roundStatus === true && (<DoneAllIcon className='mr-2' />)
                            }
                            Completed
                        </p>
                    </div>
                    {/* <div d-flex>
                        <div>Inprogress</div>
                        <div>Completed</div>
                    </div> */}
                    <div>
                        <Autocomplete
                            sx={{ width: '200px' }}
                            disableClearable
                            disablePortal
                            value={selectedRound}
                            options={roundListData.map(
                                (list) => list.name ? list.name : []
                            )}
                            onChange={(e, selectedOptions) => handleSelectRound(selectedOptions)}
                            renderInput={(params) => <TextField {...params} label="Round Name" />}
                        />
                    </div>
                    <div>
                        {
                            selectedRound && (
                                <Button
                                    variant="contained"
                                    sx={{ marginLeft: "10px" }}
                                    onClick={() => handleClear()}
                                >
                                    Clear
                                </Button>
                            )
                        }
                    </div>

                </div>
            </div>
            <div className='d-flex'>
                <div>
                    {
                        (keyName !== undefined && startupListData.length > 0) && (<Button variant='outlined' onClick={handleJuryClickOpen} className='m-3' >View Individual Jury Score</Button>
                        )
                    }
                </div>
                {
                    (keyName !== undefined) && (
                        <div className='ml-auto d-flex align-items-center m-3'>
                            <FormControl >
                                <InputLabel id="demo-simple-select-filled-label">Sort</InputLabel>
                                <Select
                                    sx={{ width: '280px' }}
                                    onChange={(e) => handleSortRank(e.target.value)}
                                    value={sortRank}
                                >
                                    <MenuItem value={0}>Sort by Weighted Rank</MenuItem>
                                    <MenuItem value={1}>Sort by Absolute Rank</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )
                }
            </div>
            <div className='my-3 ml-3'>
                {
                    (checkedList.length > 0 && (
                        <>
                            {( roundStatus == false && juryBtn == true) ? (<Button className='ml-auto' variant="outlined" onClick={selectStartRound1} sx={{ marginRight: '5px' }}>
                                Assign to Jury
                            </Button>):(<></>)}
                            <Button className='' variant="outlined" onClick={selectStartRound}>Promote Round</Button>
                        </>
                    ))
                }
            </div>
            {
                (startupListData.length && lableValue.length) ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {
                                        ( roundStatus == false && lable === 'inprogress' && enablePromoteRound === true) && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={itemsChecked} onClick={(e) => selectItem(e)}
                                                />
                                            </TableCell>
                                        )
                                    }

                                    <TableCell align='center'>No</TableCell>
                                    <TableCell>Startup Name</TableCell>
                                    {
                                        (keyName === undefined) && (
                                            <>
                                                {lableValue.map((list) => (
                                                    <TableCell align='center'>{list}</TableCell>
                                                ))}
                                            </>
                                        )
                                    }
                                    {
                                        (keyName !== undefined) && (
                                            <>
                                                <TableCell align='center'>
                                                    Weighted Average
                                                    {/* Absolute Score */}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Weighted Rank
                                                </TableCell>
                                                <TableCell align='center'>Absolute Average</TableCell>
                                                <TableCell align='center'>Absolute Rank</TableCell>
                                            </>
                                        )
                                    }


                                    {/* {
                                        (keyName !== undefined) && (
                                            <TableCell align='center'>Weighted Mean</TableCell>
                                        )
                                    }
                                    <TableCell align='center'>{keyName}</TableCell> */}



                                    {
                                        showRank === true && (
                                            <TableCell align='center'>Rank </TableCell>
                                        )
                                    }
                                    {/* {
                                        (keyName !== undefined) && (
                                            <TableCell align='center'><Button variant='outlined' onClick={handleJuryClickOpen} >View Individual Jury Score</Button></TableCell>
                                        )
                                    } */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {startupListData.map((list, index) => (
                                    <TableRow key={index}>
                                        {
                                            (roundStatus == false && lable === 'inprogress' && enablePromoteRound === true) && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        item={list}
                                                        value={list.startupId}
                                                        checked={checkedList.includes(list.startupId)}
                                                        onChange={e => handleCheckboxClick(e, index)}
                                                    />
                                                </TableCell>
                                            )
                                        }
                                        <TableCell align='center'> {
                                            tableIndexValue(rowsPerPage, page, index)
                                        }</TableCell>
                                        <TableCell>
                                            <Link className="c-blue" to={`/${path}/associated-startups/details/${btoa(list.startupId)}/${btoa(list.partnerOrInvestorId)}`}>{list.startupName}</Link>
                                        </TableCell>
                                        {
                                            (keyName === undefined) && (
                                                <>
                                                    {
                                                        lableValue.map((res, index) => (
                                                            <TableCell className='tanseed-data' align='center'>{list.juryScore[index] ? (
                                                                <>
                                                                    {
                                                                        list.juryScore[index] === 'Not Submitted' && <span className="NotSubmitted_span">{list.juryScore[index]}</span>
                                                                    }
                                                                    {
                                                                        list.juryScore[index] === 'Recommended' && <span className="Recommended_span">{list.juryScore[index]}</span>
                                                                    }
                                                                    {
                                                                        list.juryScore[index] === 'Not Recommended' && <span className="NotRecommended_span">{list.juryScore[index]}</span>
                                                                    }
                                                                    {
                                                                        (list.juryScore[index] !== 'Not Recommended' && list.juryScore[index] !== 'Recommended' && list.juryScore[index] !== 'Not Submitted') && <span>{list.juryScore[index]}</span>
                                                                    }
                                                                </>
                                                            ) : '0'}</TableCell>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            (keyName !== undefined) && (
                                                <>
                                                    <TableCell align='center' >{list.score}</TableCell>
                                                    <TableCell align='center' >{list.rank}</TableCell>
                                                    <TableCell align='center' onClick={() => handleWeightedscore(list.startupId)} >
                                                        <Link className="c-blue"><b>{list.weightedScore}</b></Link>
                                                        <span style={{ color: list.categoryJuries === 6 ? '#388e3c' : '#F55A00' }}>
                                                            {/* {list.categoryJuries > 0 && ` (${list.categoryJuries} Category Jury Filled)`} */}
                                                            {
                                                                list.categoryJuries > 0 && (
                                                                    <>
                                                                        {' '}
                                                                        (<strong>{list.categoryJuries}</strong> Category Jury Filled)
                                                                    </>
                                                                )
                                                            }
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align='center' >{list.weightedRank}</TableCell>
                                                </>
                                            )
                                        }


                                        {/* {
                                            (keyName !== undefined) && (
                                                <TableCell align='center'>{list.weightedMean}</TableCell>
                                            )
                                        }
                                        <TableCell align='center'>{list.score}</TableCell> */}




                                        {
                                            showRank === true && (
                                                <TableCell align='center'>{list.rank !== 0 ? (list.rank) : ('NA')}</TableCell>
                                            )
                                        }
                                        {/* {
                                            (keyName !== undefined) && (
                                                <TableCell></TableCell>
                                            )
                                        } */}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <caption >
                                <TablePagination
                                    component="div"
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    style={{ float: 'left' }}
                                /></caption>
                        </Table>

                    </TableContainer>
                ) : (
                    <div className='nodata_tag'>
                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                        <h4>No Data Found</h4>
                    </div>
                )
            }
            <Dialog
                open={openStartRound}
                // onClose={handleStartRoundClose}
                fullWidth
               maxWidth="md"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <DialogContent className="form_tag p-3">
                    <Startround handleStartRoundClose={handleStartRoundClose} checkedList={checkedList} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openWeightedScore}
                onClose={handleWeightedscoreClose}
                minWidth={'lg'}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {
                    category.length ? (
                        <>
                            <div className='mt-3 close-icon'><span onClick={handleWeightedscoreClose}><CancelIcon /></span></div>
                            <TableContainer component={Paper} className='p-3'>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Juries</TableCell>
                                            <TableCell>Absolute Score</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            category && category.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{list.name}</TableCell>
                                                    <TableCell>{list.juries}</TableCell>
                                                    <TableCell>{list.weightedScore}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            <div className='my-3 close-icon'><span onClick={handleWeightedscoreClose}><CancelIcon /></span></div>
                            <div className='nodata_tag'>
                                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                <h4>No Data Found</h4>
                            </div>
                        </>
                    )
                }
            </Dialog>


            <Dialog
                open={openJury}
                onClose={handleJuryClose}
                maxWidth={'xl'}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='my-3 close-icon'><span onClick={handleJuryClose}><CancelIcon /></span></div>
                {
                    (startupListData.length && lableValue.length) ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Startup Name</TableCell>
                                        {lableValue.map((list) => (
                                            <TableCell align='center'>{list}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {startupListData.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {list.startupName}
                                            </TableCell>
                                            {
                                                lableValue.map((res, index) => (
                                                    <TableCell className='tanseed-data' align='center'>{list.juryScore[index] ? (
                                                        <>
                                                            {
                                                                list.juryScore[index] === 'Not Submitted' && <span className="NotSubmitted_span">{list.juryScore[index]}</span>
                                                            }
                                                            {
                                                                list.juryScore[index] === 'Recommended' && <span className="Recommended_span">{list.juryScore[index]}</span>
                                                            }
                                                            {
                                                                list.juryScore[index] === 'Not Recommended' && <span className="NotRecommended_span">{list.juryScore[index]}</span>
                                                            }
                                                            {
                                                                // (list.juryScore[index] !== 'Not Recommended' && list.juryScore[index] !== 'Recommended' && list.juryScore[index] !== 'Not Submitted') && <span>{list.juryScore[index]}</span>
                                                                (
                                                                    list.juryScore[index] !== 'Not Recommended' && list.juryScore[index] !== 'Recommended' && list.juryScore[index] !== 'Not Submitted') && (
                                                                    <span style={{ color: parseInt(list.juryScore[index]) > 0 ? 'blue' : '#000' }}>
                                                                        {list.juryScore[index]}
                                                                    </span>
                                                                )
                                                            }
                                                        </>
                                                    ) : '0'}</TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <center><Button variant='contained' color='error' onClick={handleJuryClose} className='my-3'>Close</Button></center>
                        </TableContainer>
                    ) : (
                        <>
                        </>
                    )}
            </Dialog>
        </section >
    )
}

export default Tanseedassociatedlist
