import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../context/DataContext";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  TextField,
  Tooltip,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import apiService from "../../../api/apiService";
const AddComments = ({ id, comments, userListItem }) => {
  const [formData, setFormData] = useState({});
  const { setPageLoading } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    values: formData,
  });
  useEffect(() => {
    setFormData({
      comments: comments,
    });
  }, [comments]);
  const [commentsDialog, setCommentsDialog] = useState(false);
  const handleAddComment = () => {
    setCommentsDialog(true);
  };
  const handleCommentsClose = () => {
    setCommentsDialog(false);
    reset();
  };
  const onSubmit = (data) => {
    setPageLoading(true);
    let value = data.comments;

    apiService(`user/comments/save?id=${id}&type=TANFUND`, value, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data?.responseStatus === "Success") {
          userListItem();
          handleCommentsClose();
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Tooltip title="Comments" placement="top">
        <ChatIcon
          className="ml-3 c-pointer chat-i"
          onClick={() => handleAddComment()}
        />
      </Tooltip>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={commentsDialog}
        onClose={handleCommentsClose}
      >
        <DialogTitle id="responsive-dialog-title">
          Add Your Comments
          <Divider />
        </DialogTitle>
        <DialogContent>
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-2">
              <Controller
                name="comments"
                control={control}
                defaultValue=""
                rules={{ required: "Comments is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Your Comment *"
                    placeholder="Enter Your Comment"
                    fullWidth
                    multiline
                    rows={3}
                  />
                )}
              />
              <FormHelperText>
                Current comments will overwrite your previous comments !
              </FormHelperText>
              <FormHelperText className="text-danger">
                {errors.comments && errors.comments.message}
              </FormHelperText>
            </div>
            <center>
              <Button
                className="m-3"
                variant="outlined"
                onClick={handleCommentsClose}
              >
                Close
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </center>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddComments;
