import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import Paper from '@mui/material/Paper';
import React, { useContext, useState } from 'react'
import apiService from '../../../api/apiService'
import Viewimage from '../../../api/Viewimage'
import DataContext from '../../../context/DataContext'
import notifyService from '../../../api/notifySerivce'
import Resubmit from '../../investor-module/associated-startups-page/associated-details-page/pages/Resubmit'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import ClarificationAll from '../scstBdd-details/ClarificationAll'
import Scsthubavpremarks from '../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks'
import BddComents from './pages/BddComents';

const BddClarification = ({ idNumber, path, viewStartupDetails, startupCheckList, startupValue, showStartupValue }) => {
    const { setPageLoading } = useContext(DataContext)
    const [addInfoValue, setAddInfoValue] = React.useState('1');
    const handleChangeInfo = (event, newValue) => {
        setAddInfoValue(newValue);
    };
    const [historyTabValue, setHistoryTabValue] = useState('1');

    const handleHistoryChangeInfo = (event, newValue) => {
        setHistoryTabValue(newValue);
        setAddInfoValue('1')
    };
    const [statusValue, setStatusValue] = useState(2)
    const handleStatus = (value) => {
        setStatusValue(value)
    }
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useDidMountEffect(() => {
        historyList()
    }, [statusValue, page, rowsPerPage,])
    const [historyListValue, setHistoryListValue] = useState([])
    const historyList = () => {
        setHistoryListValue([])
        setPageLoading(true)
        var id = Number(idNumber)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            startupId: id,
            // userId: localStorage.getItem('userId'),
            status: statusValue,
            round: "firstconnect",
            type: 'Field'
        }
        apiService('partner/startup/clarification/history', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setCount(res.data.count)
                if (res.data.clarifications) {
                    res.data.clarifications.forEach((element) => {
                        if (element.documentName) {
                          var val = element.documentName;
                          var data = val.split("?");
                          if (data) {
                            var formet = data[0].split(".");
                            element.formet = formet[4];
                          }
                        }
                      });
                    setHistoryListValue(res.data.clarifications)
                }
            }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value,formet) => {
        setViewImage(value)
        if (formet === "xlsx") {
            const link = document.createElement('a');
            link.href = value;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          else if(formet === "docx"){
            const link = document.createElement('a');
            link.href = value;
            // link.download = 'excelfile.docs';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          else {
            setOpen(true);
          }
    };
    const handleClose = () => {
        setOpen(false);
    };
   const [openModal, setOpenModal] = useState(false);
    const [bcId,setbcid] = useState()
    const handleOpenModal = (id) => {
        setOpenModal(true);
        setbcid(id)
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handelMarkVerifed = () => {
        setPageLoading(true)
        var req = {
            bcId: bcId,
            startupId: idNumber,
            status: 1
        }
        apiService(`partner/startups/clarification/status/save`, req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success" || res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Updated successfully !!')
                    setStatusValue(3)
                    historyList()
                    showStartupValue()
                    viewStartupDetails()
                    setOpenModal(false);
                }
            }
        })
    }
    const [isFullTextVisible2, setIsFullTextVisible2] = useState({})
    const toggleText2 = (index) => {
        setIsFullTextVisible2(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const truncateTexts = (text, charLimit) => {
        if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
        return text.length > charLimit ? text.substring(0, charLimit) + ".." : text;
    };
    return (
        <main>
            <section>
                <div className="table_main_div">
                    <div className='row'>
                        <div className='col-lg-12 col-12 my-3'>
                            <TabContext value={addInfoValue}>
                                <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                                    <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                                        <Tab label="Requested" value="1" onClick={() => handleStatus(2)} />
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Submitted by startup
                                                    {startupCheckList?.bddClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            value="2" onClick={() => handleStatus(3)} />
                                        <Tab label="Verified" value="3" onClick={() => handleStatus(1)} />
                                    </TabList>
                                </Box>
                                <Divider />
                                <div className="mt-3">
                                    {historyListValue.length > 0 &&
                                        (historyListValue.length) ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>No</TableCell>
                                                        <TableCell>Question</TableCell>
                                                        <TableCell>Requested Clarifications</TableCell>
                                                        {/* <TableCell align='center'>Comments</TableCell> */}
                                                        {
                                                            statusValue === 2 && <TableCell align='center'>Requested Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 3 && <TableCell align='center'>Submitted Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 1 && <TableCell align='center'>Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 3 && <TableCell align='center'>Attached Document</TableCell>
                                                        }
                                                        {
                                                            statusValue === 1 && <TableCell align='center'>Document Details</TableCell>
                                                        }

                                                        {
                                                            statusValue === 3 && (
                                                                <TableCell align='center'>Action</TableCell>
                                                            )
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {historyListValue.map((list, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>
                                                            <TableCell>{list.attribute}</TableCell>
                                                            <TableCell>
                                                                <>
                                                                    {list.clarification ? (
                                                                        <>
                                                                            {isFullTextVisible2[index] ? list.clarification : truncateTexts(list.clarification || '', 15)}
                                                                            {list.clarificationAll && (<ClarificationAll remarks={list.clarificationAll} lblName="Clarifications" btnName="Show All Clarifications" />)}
                                                                            {list.clarification.length > 15 && (
                                                                                <h6
                                                                                    onClick={() => toggleText2(index)}
                                                                                    style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                                >
                                                                                    {isFullTextVisible2[index] ? " Read Less" : " Read More"}
                                                                                </h6>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>-</>
                                                                    )}
                                                                </>
                                                            </TableCell>
                                                            {/* <TableCell >{list.clarification ? list.clarification : '-'}  {list.clarificationAll && (<ClarificationAll remarks={list.clarificationAll} lblName="Clarifications" btnName="Show All Clarifications" />)}</TableCell> */}
                                                            <TableCell align='center'>{list.time}</TableCell>
                                                            {
                                                                statusValue === 3 && <TableCell align='center'>
                                                                    {list.documentName && (
                                                                        <>
                                                                           <Tooltip title="View Latest Document" arrow>
                                                                        <img
                                                                            src="/images/docs.png"
                                                                            alt="Document Icon"
                                                                            style={{ cursor: 'pointer', width: '25px' }}
                                                                            onClick={() => handleClickOpen(list.documentName,list.formet)}
                                                                        />
                                                                    </Tooltip>
                                                                        </>)}
                                                                    {/* {list.documentName && <button className='viewdetails' onClick={() => { handleClickOpen(list.documentName) }}>View Document</button>} */}
                                                                 
                                                                    {list.responseAll && <BddComents remarks={list.response} lblName="View Comments" />}
                                                                </TableCell>
                                                            }
                                                            {
                                                                statusValue === 1 && <TableCell align='center'>
                                                                    {list.documentName && (
                                                                        <>
                                                                         <Tooltip title="View Latest Document" arrow>
                                                                        <img
                                                                            src="/images/docs.png"
                                                                            alt="Document Icon"
                                                                            style={{ cursor: 'pointer', width: '25px' }}
                                                                            onClick={() => handleClickOpen(list.documentName,list.formet)}
                                                                        />
                                                                    </Tooltip>
                                                                        </>)}
                                                                    {/* {list.documentName && <button className='viewdetails' onClick={() => { handleClickOpen(list.documentName) }}>View Document</button>} */}
                                                                   
                                                                    {list.responseAll && <BddComents remarks={list.response} lblName="View Comments" />}
                                                                </TableCell>
                                                            }
                                                            {
                                                                statusValue === 3 && (
                                                                    <TableCell align='center'>
                                                                        <Resubmit id={list.id} idNumber={idNumber} historyList={historyList} name="bdd" viewStartupDetails={viewStartupDetails} />
                                                                        <button className='verified_btn' onClick={() => handleOpenModal(list.id)}>Mark as Verified</button>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <caption>
                                                    <TablePagination
                                                        component="div"
                                                        count={count}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        style={{ float: 'left' }}
                                                    />
                                                </caption>
                                            </Table>

                                        </TableContainer>
                                    ) : (
                                        <div className='nodata_tag'>
                                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                            <p>No Records Found</p>
                                        </div>
                                    )
                                    }
                                </div>
                            </TabContext>
                        </div>
                    </div>
                    <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
                </div>
            </section>
            <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    maxWidth={"sm"}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <center>
                            <img src="/images/img/attention.gif" alt="" className="alert-image" />
                        </center>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <h4 className="text-center p-3">Are you sure ?</h4>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="d-flex justify-content-center mb-2">
                        <Button
                            className="remove-api-modal-cancel"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button className="remove-api-modal-confirm" onClick={handelMarkVerifed}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
        </main>
    )
}

export default BddClarification