import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
// import BddClarification from './BddClarification';
import BDDDeepDive from "./BDDDeepDive";
import BDDDeepDiveClarification from "./BDDDeepDiveClarification";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import BddAdditionalInfo from "../../scstBdd-page/scst-verification-details/pages/BddAdditionalInfo";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const BDDDeepDiveLayout = ({
  idNumber,
  path,
  viewStartupDetails,
  showStartupValue,
  startupCheckList,
  startupValue,
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // useDidMountEffect(() => {

  //     showStartupValue()
  // }, [])
  // const [startupCheckList,setStartupCheckList]= useState([])
  // const showStartupValue = async () =>{
  //     apiService(`startup/clarifications/status/get?id=${idNumber}`, '', 'get').then((res) => {

  //         if (res) {
  //             if (res.data) {
  //                 debugger
  //                 setStartupCheckList(res.data)
  //             }
  //         }
  //     })
  // }
  const roles = localStorage.getItem('role')
  return (
    <div className="overallTab">
      {/* {path === "scstbdd" && ( */}
        <Tabs
          className="verticalTabCSS"
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab className="verticalLable" label="Documents" {...a11yProps(0)} />
          {path === "scstbdd" && (
          <Tab
            className="verticalLable"
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Field Clarification
                {startupCheckList?.bddDDClarificationRequested === true ? (
                  <span className="ml-2">
                    <img
                      src="/images/giphy.gif"
                      alt="gif"
                      className="gif-bell img-fluid"
                    />
                  </span>
                ) : (
                  <></>
                )}
              </div>
            }
            {...a11yProps(1)}
          />
          )}
          {(path === "scstbdd" || roles === 'SCSTADMIN') && (
            <Tab
              className="verticalLable"
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  General Clarification
                  {startupCheckList?.bddClarificationRequested === true ? (
                    <span className="ml-2">
                      <img
                        src="/images/giphy.gif"
                        alt="gif"
                        className="gif-bell img-fluid"
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                      { startupCheckList?.generalBddDDadditionalInfoRequested === true  ? (
                    <span className="ml-2">
                      <img
                        src="/images/giphy.gif"
                        alt="gif"
                        className="gif-bell img-fluid"
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              }
              {...a11yProps(2)}
            />
          )}
        </Tabs>
     
      <Box sx={{ p: 3 }}>
        <TabPanel value={value} index={0}>
          <BDDDeepDive
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            showStartupValue={showStartupValue}
            startupCheckList={startupCheckList}
          />
        </TabPanel>
        <TabPanel value={value} index={roles === 'SCSTADMIN' ? 2 : 1}>
          <BDDDeepDiveClarification
            idNumber={idNumber}
            path={path}
            viewStartupDetails={viewStartupDetails}
            showStartupValue={showStartupValue}
            startupCheckList={startupCheckList}
            startupValue={startupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={roles === 'SCSTADMIN' ? 1 : 2}>
          <BddAdditionalInfo
            idNumber={idNumber}
            path={''}
            viewStartupDetails={viewStartupDetails}
            startupValue={startupValue}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
            showData={'bddDeepDive'}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default BDDDeepDiveLayout;
