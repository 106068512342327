import React, { useEffect, useState } from "react";
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CurrentTanseed from "./CurrentTanseed";
import PreviousTanseed from "./PreviousTanseed";
import TanseedSettings from "./TanseedSettings";

const Admintanseed = () => {

    const [value, setValue] = useState(localStorage.getItem('tanseedTab') || "1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('tanseedTab', newValue)
    };

    return (
        <div className="scsthub-div form_div chart">
            {/* <div className='header_tag'>
                <h5>TANSEED List</h5>
            </div> */}
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Current" value="1" />
                            <Tab label="Previous" value="2" />
                            <Tab label="Settings" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <CurrentTanseed />
                    </TabPanel>
                    <TabPanel value="2" >
                        <PreviousTanseed />
                    </TabPanel>
                    <TabPanel value="3" >
                        <TanseedSettings />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default Admintanseed;
