import { Autocomplete, Button, Checkbox, Chip, FormControl, FormHelperText, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../../context/DataContext';
import apiService from '../../../../../api/apiService';
import notifyService from '../../../../../api/notifySerivce';
import Panelquestionadd from './Panelquestionadd';
import Paneljuryadd from './Paneljuryadd';
import DeleteIcon from '@mui/icons-material/Delete';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Roundadd = ({ name, path }) => {
  const { navigator, Accordion, AccordionSummary, AccordionDetails, setPageLoading } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
    { values: formData, }
  );
  const [juryList, setJuryList] = useState([])
  useDidMountEffect(() => {
    userListItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useDidMountEffect(() => {
    // eslint-disable-next-line    
    // getCondition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [title, setTitle] = useState('Add')
  const getCondition = () => {
    if (window.location.hash === '/partner/round/update') {
      setTitle('Update')
    } else {
      setTitle('Add')
    }
    listApi()
  }
  const {
    fields: panelRoundsFields,
    remove: panelRoundsRemove
  } = useFieldArray({
    control,
    name: "panelRounds"
  })
const [juryval,setJuryval] = useState()
  const userListItem = () => {
    var req = {
      "active": 1,
      "roleId": 3,
    }
    apiService('investor/jury/list', req, 'post').then((res) => {
      if (res) {
        if (res.data.responseModelList) {

          setJuryList(res.data.responseModelList)
          if (window.location.hash === '/partner/round/update') {
            setTitle('Update')
          } else {
            setTitle('Add')
          }
        
          listApi(res.data.responseModelList)
          setJuryval(res.data.responseModelList)
          // 
        } else {
          setJuryList([])
        }
      }
    })
  }

  const addRoundBtn = () => {
    var getform = {
      panelRounds: [{
        name: "",
        panelRoundJury: [{}]
      }],
    }
    apiService(`partner/panelround/save`, getform, 'post').then((res) => {
      if (res) {
        if (res.data.responseStatus === "Success") {
          listApi(juryList)
        }
      }
    })
  }
  const [enable, setEnable] = useState()
  const listApi = (juryValue) => {
    debugger
    setPageLoading(true)
    apiService('partner/panelround/list', '', 'get').then((res) => {
      setPageLoading(false)
      if (res) {
        if (res.data.panelRounds) {
          res.data.panelRounds.forEach((rounds, index) => {

            if (rounds.juries) {
              let ids = []
              rounds.juries.forEach(jury => {
                console.log(juryValue)
                const juryname = juryValue.filter((item) => item.id === jury)
                if (juryname.length > 0) {
                  ids.push(juryname[0].name)
                }
                // handleSelectJury(juryname[0].name,index)
              })
              rounds.juries = ids

            } else {
              rounds.juries = []

            }
            // rounds.readOnly = !rounds.enableJuryEdit

            // 

            // setSelectedJurys(ids)
          });
          setFormData(res.data)
          setEnable(res.data.panelRounds.enableJuryEdit)
          console.log(formData)
          // 
        } else {
          setFormData([])
        }
      }
    })
  }
  const [expanded, setExpanded] = useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // const [selectedJurys, setSelectedJurys] = useState([])
  const handleSelectJury = (value, index) => {
    setValue(`panelRounds.${index}.juries`, value)
    clearErrors(`panelRounds.${index}.juries`)
  }
  
  const deletChipJury = (name) => {
    deletJury(name)
  }
  const deletJury = (name) => {
    const juryname = juryList.filter((item) => item.name === name)
    var req = {
      "juryId": juryname[0].id
    }

    apiService('partner/panelround/jury/remove', req, 'post')
      .then((result) => { }).catch((err) => { })
  }
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setPageLoading(true);
    var getform = data

    getform.panelRounds.forEach((rounds, index) => {
      let ids = []
      rounds.juries.forEach((name, k) => {
        const juryname = juryList.filter((item) => item.name === name)

        ids.push(juryname[0].id)
        // if (juryname.length > 0) {
        //   getform.panelRounds[index].panelRoundJury[k].juryName = juryname[0].name
        //   
        // } else {
        //   
        //   return notifyService('danger', 'Jury', 'Jury details is required !!')
        // }
      });
      rounds.juries = ids
    });

    apiService(`partner/panelround/save`, getform, 'post').then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          handleCancel()
        }
      }
    })
  }
  // const handleBlur = () => {

  //   const data = getValues();
  //   debugger
  //   saveRoundData(data);
  
  // };
  const handleBlur = (index) => {
    const data = getValues();
    
    // data.panelRounds.forEach((round ,index)=> {
      var name = getValues(`panelRounds.${index}.name`)
      var juries = getValues(`panelRounds.${index}.juries`)
      if (name && Array.isArray(juries) && juries.length > 0) {
        debugger
        saveRoundData(data);  // Call API to save data if valid
      } else {      
      
      }
    // });
  };
  
  // const saveRoundData = (data) => {
  //   setPageLoading(true);
  //   var getform = data;
  //   getform.panelRounds.forEach((rounds, index) => {
  //     let ids = [];
  //     rounds.juries.forEach((name, k) => {
  //       const juryname = juryList.filter((item) => item.name === name);
  //       ids.push(juryname[0].id);
  //     });
  //     rounds.juries = ids;
  //   });

  //   apiService('partner/panelround/save', getform, 'post').then((res) => {
  //     setPageLoading(false);
  //     if (res && res.data.responseStatus === "Success") {
  //       notifyService('success', 'Success', 'Saved successfully!');       
  //       // window.location.reload()
  //     }
  //   });
  // };
  let juryname
  const saveRoundData = (data) => {
    setPageLoading(true);
    var getform = data;
  
    getform.panelRounds.forEach((rounds, index) => {
      let ids = [];
      // Convert jury names to IDs
      rounds.juries.forEach((name, k) => {
        juryname = juryList.find((item) => item.name === name);
        if (juryname) {
          ids.push(juryname.id); // Save the ID
        } else {
          // If no match, log or handle the error
          console.error("Jury not found for name: ", name);
        }
      });
      rounds.juries = ids; // Replace names with IDs
    });
  
    apiService('partner/panelround/save', getform, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data.responseStatus === "Success") {
        notifyService('success', 'Success', 'Saved successfully!');
        listApi(juryval)
      }
    });
  };
  
  

  const handleCancel = () => {
    navigator(`/${path}/round/list`)

  }
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  return (
    <section className=''>
      <div className='container-fluid'>
        <div className='form_div chart'>
          <div className='header_tag'>
            <div className=''>
              <h5>{title} Round Details</h5>
              {
                panelRoundsFields.length > 0 && (
                  <Button variant="outlined" className='ml-auto' onClick={() => addRoundBtn()}>+ Add Round</Button>
                )
              }

            </div>
          </div>
          {
            (panelRoundsFields.length > 0 ? (
              <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                <div className='row'>
                  <div className='d-none'>
                    <div className="form-group">
                      <Controller name="id" control={control} defaultValue="0"
                        render={({ field }) =>
                          <TextField
                            {...field}
                          />} />
                    </div>
                  </div>
                </div>
                {panelRoundsFields.map((value, index) => (
                  <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary aria-controls="panel1d-content" id={`panel${index}d-header`}>
                      <Typography>Round {index + 1}</Typography>
                      {
                        (getValues(`panelRounds.${index}.id`) === undefined) ? (<><DeleteIcon className="ml-auto" style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { panelRoundsRemove(index) }} /></>) : (<></>)
                      }
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='row'>
                        <div className='col-lg-6 col-12'>
                          <FormControl sx={{ width: '100%' }} >
                            <Controller name={`panelRounds.${index}.name`} control={control} defaultValue="" rules={{ required: 'Round Name is required' }}
                              render={({ field }) =>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Round Name *"
                                  placeholder='Enter Round Name'
                                  fullwidth
                                  {...field}
                                  onBlur={()=>handleBlur(index)} 
                                  inputProps={{
                                    readOnly: enable
                                  }}
                                />} />
                            <FormHelperText className='text-danger'>
                              {errors.panelRounds?.[index]?.name && errors.panelRounds?.[index]?.name.message}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <div className='col-lg-6 col-12'>
                          <FormControl sx={{ width: '100%' }} >
                            <Controller name={`panelRounds.${index}.juries`} control={control} defaultValue="" rules={{ required: 'Round Name is required' }}
                              render={({ field }) =>
                                <Autocomplete
                                  disableClearable
                                  disablePortal
                                  multiple
                                  {...field}
                                  limitTags={2}
                                  value={field.value}
                                  onBlur={()=>handleBlur(index)} 
                                  options={juryList.map(
                                    (list) => list.name
                                  )}
                                  onChange={(e, selectedOptions) => handleSelectJury(selectedOptions, index)}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      // onClick={() => deletJury(option)}
                                      />
                                      {option}
                                    </li>
                                  )}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                      // onClick={() => deletChipJury(option)}
                                      />
                                    ))
                                  }
                                  disabled={enable}
                                  renderInput={(params) => <TextField {...params} label="Jury *" />}
                                />} />
                            <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                            <FormHelperText className='text-danger'>
                              {errors.panelRounds?.[index]?.juries && errors.panelRounds?.[index]?.juries.message}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <hr />
                        <div className='col-12'>
                          <div>
                            <Panelquestionadd listApi={listApi} nestIndex={index} {...{ control, getValues, errors }} juryList={juryList} enableJuryEdit={enable} />
                          </div>
                        </div>
                        {/* <div className='col-12'>
                            <div>
                              <Paneljuryadd nestIndex={index} {...{ control, getValues, setValue, errors }} juryList={juryList} />
                            </div>
                          </div> */}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
                }
                <div>
                  <div className="bt-1 form-group text-center pt-4">

                    <LoadingButton
                      type="submit"
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      className="btn btn-primary submit px-3"
                    >
                      <span>Save</span>
                    </LoadingButton>
                    <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
                  </div>
                </div>
              </form>
            ) : (
              <>
                {/* <p className='text-center'>Round details is empty</p> */}
                <div className='text-center'>
                  <Button variant="outlined" onClick={() => addRoundBtn()}>+ Add Round</Button>
                </div>
              </>

            )
            )
          }
        </div>
      </div>
    </section>
  )
}

export default Roundadd