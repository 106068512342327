import React, { useContext, useState } from 'react'
import { Autocomplete, FormControl, Button, TextField, Grid, } from "@mui/material";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
import { FaFileExport } from "react-icons/fa6";

const NewReportFilters = ({ setOpenExportModal, setOpenExportModal2, handleClear, handleClear2, scsthub, setScsthub, yearNameSelected, setYearNameSelected, yearName, setYearName, report, setReport, scsthub2, setScsthub2, yearNameSelected2, setYearNameSelected2, yearName2, setYearName2, report2, setReport2 }) => {
    const { setPageLoading } = useContext(DataContext)
    const [hubList, setHubList] = useState([""]);
    const [hubList2, setHubList2] = useState([""]);
    useDidMountEffect(() => {
        getScstHub()
        yearApi(2021);
    }, []);
    useDidMountEffect(() => {
        getReport()
    }, []);
    const [filteredreports, setFilteredReports] = useState([]);
    const [filteredreports2, setFilteredReports2] = useState([]);
    const getReport = () => {
        apiService(`admin/dropdownvalues/get?key=reports`, '', 'get').then(
            (res) => {
                debugger
                setPageLoading(false);
                if (res && res.data && res.data.dropDownValues.reports) {
                    const report = res.data.dropDownValues.reports
                    const criteriaReport = report.filter(item => item === "Criteria Report");
                    const criteriaReport2 = report.filter(item => item === "Checklist For Selection");

                    setFilteredReports(criteriaReport);
                    setFilteredReports2(criteriaReport2);
                    // setFilteredReports(report)
                    if (criteriaReport.length > 0) {
                        setReport("Criteria Report");
                    }
                    if (criteriaReport2.length > 0) {
                        setReport2("Checklist For Selection");
                    }

                }
            }
        );
    }

    const getScstHub = async () => {
        await apiService(`partner/hub/filter`, "", "get")
            .then((res) => {
                if (res.data) {
                    var hub = res.data
                    hub.unshift('All')
                    setHubList(hub);
                    setHubList2(hub);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleScstHub = (value) => {
        setScsthub(value);
    };
    const handleScstHub2 = (value) => {
        setScsthub2(value);
    };
    const [filteredYears, setFilteredYears] = useState([]);
    const [filteredYears2, setFilteredYears2] = useState([]);
    const yearApi = () => {
        apiService(`common/dashboard/listyears?startyear=2021`, "", "get").then(
            (res) => {
                setPageLoading(false);
                if (res && res.data && res.data.yearMap) {
                    var yearMap = res.data.yearMap;

                    var allYears = Object.keys(yearMap).map((year) => ({
                        label: year,
                        value: yearMap[year]
                    }));

                    allYears.unshift({ label: "All", value: 0 });

                    setFilteredYears(allYears);
                    setFilteredYears2(allYears);
                }
            }
        );
    };
    // const handleSelectYear = (event, selectedOption) => {
    //     setYearNameSelected(selectedOption);

    //     if (selectedOption && selectedOption !== 'All') {
    //         var selectedYear = filteredYears.find(year => year.label === selectedOption);

    //         if (selectedYear) {
    //             setYearName(selectedYear.label);
    //             yearApi(selectedYear.value);
    //         }
    //     } else {
    //         setYearName('0');
    //         yearApi(2021);

    //     }
    // };
    const handleSelectYear = (event, selectedOption) => {
        setYearName(selectedOption);
        setYearNameSelected(selectedOption)
        if (selectedOption) {
            var name = selectedOption.split(' - ')
            setYearName(name[0]);
        } else {
            setYearName('2024');

        }
        debugger
    };
    const handleSelectYear2 = (event, selectedOption) => {
        setYearName2(selectedOption);
        setYearNameSelected2(selectedOption)
        if (selectedOption) {
            var name = selectedOption.split(' - ')
            setYearName2(name[0]);
        } else {
            setYearName2('2024');

        }
        debugger
    };
    const handleSelectReport = (event, selectedOption) => {
        setReport(selectedOption)
    }
    const handleSelectReport2 = (event, selectedOption) => {
        setReport2(selectedOption)
    }
    //    const [openExportModal, setOpenExportModal] = useState(false)
    const handleExportCloseModal = () => { setOpenExportModal(false) }
    const handleExportOpenModal = () => { setOpenExportModal(true) }
    const handleExportOpenModal2 = () => { setOpenExportModal2(true) }
    return (
        <div>
            {/* <h4 className='mb-3'>Criteria Report : </h4> */}
            <div className="scsthub-div form_div chart">
                <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center">
                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                        {/* <FormControl fullWidth>
                        <Autocomplete
                            disableClearable
                            disablePortal
                            value={report}
                            options={filteredreports}
                            onChange={handleSelectReport}
                            renderInput={(params) => (
                                <TextField {...params} label="Report Type" fullWidth />
                            )}
                        />
                    </FormControl> */}
                        <h5 className='mb-3'>Criteria Report</h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disableClearable
                                disablePortal
                                value={scsthub}
                                options={hubList}
                                onChange={(e, selectedOptions) =>
                                    handleScstHub(selectedOptions)
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Hub" fullWidth />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disableClearable
                                disablePortal
                                value={yearNameSelected}
                                options={filteredYears.map((year) => year.label)}
                                onChange={handleSelectYear}
                                renderInput={(params) => (
                                    <TextField {...params} label="Financial Year" fullWidth />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>
                        <div>
                            <Button
                                variant="contained"
                                sx={{ marginRight: "10px" }}
                                onClick={() => handleClear()}
                            >
                                Clear
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                        <div>
                            <div>
                                <div className=''>
                                    <Button className="down-pdf-btn2" startIcon={<FaFileExport />} onClick={() => handleExportOpenModal()} >Export</Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="scsthub-div form_div chart">
                <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center">
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                
                        <h5 className='mb-3'>Evaluation Report</h5>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disableClearable
                                disablePortal
                                value={scsthub2}
                                options={hubList2}
                                onChange={(e, selectedOptions) =>
                                    handleScstHub2(selectedOptions)
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Hub" fullWidth />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disableClearable
                                disablePortal
                                value={yearNameSelected2}
                                options={filteredYears2.map((year) => year.label)}
                                onChange={handleSelectYear2}
                                renderInput={(params) => (
                                    <TextField {...params} label="Financial Year" fullWidth />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>
                        <div>
                            <Button
                                variant="contained"
                                sx={{ marginRight: "10px" }}
                                onClick={() => handleClear2()}
                            >
                                Clear
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>

                        <div className=''>
                            <Button className="down-pdf-btn2" startIcon={<FaFileExport />} onClick={() => handleExportOpenModal2()} >Export</Button>
                        </div>

                    </Grid>
                </Grid>
            </div>
        </div >
    )
}


export default NewReportFilters

