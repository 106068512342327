import React, { useContext, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Chronology = ({ startupIds }) => {
    const { setPageLoading, tableIndexValue } = useContext(DataContext);
    const [tableData, setTableData] = useState({});

    useDidMountEffect(() => {
        startupStatus();
    }, []);

    const startupStatus = () => {
        setPageLoading(true);
        var req = {
            startupId: Number(startupIds),
        };
        apiService('partner/scst/startup/chronology/list', req, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res && res.data && res.data.startupChronologyList) {
                    setTableData(res.data.startupChronologyList);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div style={{ placeItems: 'center' }}>
            {tableData.length > 0 ? (
                <TableContainer component={Paper} className="table-container">
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                            <TableRow sx={{ fontWeight: 600 }}>
                                <TableCell align="center" className="table-header">
                                    S.No
                                </TableCell>
                                <TableCell className="table-header">
                                    Phase
                                </TableCell>
                                <TableCell className="table-header" align='center'>
                                    Promoted Date
                                </TableCell>
                                <TableCell className="table-header" align='center'>
                                    Completion Date
                                </TableCell>
                                <TableCell className="table-header" align='center'>
                                    No of Days
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData.map((data, i) => (
                                <TableRow className="table-row">
                                    <TableCell align="center" className="table-cell">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell className="table-cell" sx={{ fontSize: '14px' }}>
                                        {data.phase ?? "-"}
                                    </TableCell>
                                    <TableCell className="table-cell" align='center'>
                                        {data.startDate ?? "-"}
                                    </TableCell>
                                    <TableCell className="table-cell" align='center'>
                                        {data.endDate ?? "-"}
                                    </TableCell>
                                    <TableCell className="table-cell" align="center">
                                        {data.days === 0 ? "-" : data.days ?? "-"}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div className="no-data">
                    <img src="/images/Tanfundlogo-black-1.png" alt="No Data" className='no_data_logo' />
                    <h4>No Records Found</h4>
                </div>
            )}
        </div>
    );
};

export default Chronology;
