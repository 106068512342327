import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Viewquestion from '../modal-form/Viewquestion';
import DataContext from '../../../../context/DataContext';
import Viewjury from '../modal-form/Viewjury';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../../api/apiService';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Tooltip } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddJuryList from '../modal-form/AddJuryList';
const Roundlist = ({ name, path }) => {
  const { navigator, modalstyle, searchInputValue, roundListData, page, rowsPerPage, roundlistItem, setPageLoading } = useContext(DataContext)
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await roundlistItem())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchInputValue])
  const addRound = () => {
    navigator(`/${path}/round/add`)
  }

  const EditRoundBtn = () => {
    navigator(`/${path}/round/update`)
  }
  const removeRount = () => {
    if (deleteId !== 0) {
      setPageLoading(true)
      apiService(`${name}/panelround/remove?id=${deleteId}`, '', 'get').then((res) => {
        setPageLoading(false)
        if (res) {
          if (res.data.responseStatus === 'Success') {
            roundlistItem()
            setOpenModal(false);
          }
        }
      })
    }

  }

  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const handleClickOpen = (id) => {
    setDeleteId(id)
    setOpenModal(true);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [panalRoundId, setPanalRoundId] = useState(0)
  const [openJury, setOpenJury] = useState(false);
  const viewJuryModal = (id) => {
    setPanalRoundId(id)
    setOpenJury(true);
  }
  const handleJuryClose = () => {
    setOpenJury(false)
    roundlistItem()
  };
  const [openAddJury, setOpenAddJury] = useState(false);
  const handleAddJury = (id) => {
    setPanalRoundId(id)
    setOpenAddJury(true);
  }
  const handleAddJuryClose = () => {
    setOpenAddJury(false)
    roundlistItem()
  };
  const [viewQusData, setViewQusData] = useState([])
  const [openQues, setOpenQues] = useState(false);
  const viewQues = (value) => {
    setViewQusData(value)
    setOpenQues(true)
  }
  const handleQuesClose = () => {
    setOpenQues(false)
  }
  return (
    <section className=''>
      <div className='container-fluid'>
        <div className='form_div chart'>
          <div className='header_tag'>
            <h5>Round List</h5>
          </div>
          <div className='table_tag'>
            <div className='table_header_tag d-flex'>

              <Button variant="outlined" onClick={addRound}>+ Add Round</Button>
            </div>
            {
              (roundListData.length) ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align='center'>Question</TableCell>
                        <TableCell align='center'>Jury</TableCell>
                        <TableCell align='center'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {roundListData.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{list.name}</TableCell>
                          <TableCell align='center'>
                            {
                              list.panelRoundQuestions ? (
                                <Button onClick={() => viewQues(list.panelRoundQuestions)}>View Question</Button>
                              ) : (`-`)
                            }

                          </TableCell>
                          <TableCell align='center'>
                           
                                {
                                  list.panelRoundJury && (<Button onClick={() => viewJuryModal(list.id)}>View / Remove</Button>)
                                }
                                <Tooltip title="Add Jury" placement="right">
                                  <GroupAddIcon sx={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleAddJury(list.id, index)} />
                                </Tooltip>

                          </TableCell>
                          <TableCell align='center'>
                            {/* <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => { EditRoundBtn() }} /> */}
                            <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => handleClickOpen(list.id)} />
                            {/* onClick={() => { removeRound(list.id) }} */}

                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              ) : (
                <div className='nodata_tag'>
                  <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                  <p>Round list is empty</p>
                </div>
              )
            }

          </div>
          <Dialog open={openQues} onClose={handleQuesClose} maxWidth="md">
            <Viewquestion viewQusData={viewQusData} handleQuesClose={handleQuesClose} />
          </Dialog>
          <Dialog open={openJury} onClose={handleJuryClose} maxWidth="md">
            <Viewjury panalRoundId={panalRoundId} handleJuryClose={handleJuryClose} />
          </Dialog>
          <Dialog open={openAddJury} fullWidth maxWidth="sm">
            <AddJuryList panalRoundId={panalRoundId} handleAddJuryClose={handleAddJuryClose} />
          </Dialog>
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
          >
            <DialogTitle id="alert-dialog-title">
              <center><img src='/images/alert.png' alt='' className='alert-image' /></center>
              <h4 className='text-center p-3'>Confirm</h4>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p className='remove-api-modal'>Deleting Round will remove all the score, remarks provided by jury / investor for the startups associated.</p>
                <p className='remove-api-modal text-center'>Are you sure ?</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions className='d-flex justify-content-center mb-2'>
              <Button className='remove-api-modal-cancel' onClick={handleCloseModal}>Cancel</Button>
              <Button className='remove-api-modal-confirm' onClick={() => { removeRount() }}>Confirm</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </section >
  )
}

export default Roundlist