import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import apiService from '../../../api/apiService';
// import Additionalinfo from '../additional-info-page/Additionalinfo';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { Button, DialogContent, DialogTitle } from '@mui/material';

const Startuphistroy = ({ partnerorInvestorId, handleClose }) => {
    useDidMountEffect(() => {
        (async () => await historyList())()
    }, [])

    const [historyData, setHistoryData] = useState([])
    const historyList = () => {
        var req = {
            partnerid: partnerorInvestorId,
        }
        apiService(`startup/additionalinfo/history`, req, 'post').then((res) => {
            if (res) {
                if (res.data.additionalInfo) {
                    setHistoryData(res.data.additionalInfo)
                } else {
                    setHistoryData([])
                }
            }
        })
    }

    // const sendRequest = (id) => {
    //   apiService(`startup/partner/feedback?id=${id}`, '', 'get').then((res) => {
    //     if (res) {
    //       if (res.data.responseStatus === 'Success') {
    //         // roundlistItem()
    //       }
    //     }
    //   })
    // }
    return (
        // <div className='p-3'>
        //     <div className='table_tags'>
        //         <h5 className='font-weight-bold mb-3'>History</h5>


        //     </div>
        //     <div className='text-center mt-3'>
        //         <Button className='' onClick={handleClose} variant="outlined">
        //             OK
        //         </Button>               
        //     </div>
        // </div>
        <>
            <DialogTitle id="responsive-dialog-title">
                History
            </DialogTitle>
            <DialogContent>
                {
                    (historyData.length) ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>By</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell align='center'>Time</TableCell>
                                        <TableCell align='center'>Document Uploaded</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {historyData.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                {list.by}
                                            </TableCell>
                                            <TableCell>
                                                {list.remarks}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {list.time}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {list.documentUploaded ? ('Yes') : ('No')}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    ) : (
                        <div className='nodata_tag'>
                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                            <p>No data found</p>
                        </div>
                    )
                    // : (
                    //     <div className='nodata_tag'>
                    //         <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                    //         <p>A partner will be assigned as soon as the criteria matches</p>
                    //     </div>
                    // )
                }
                <div className='text-center p-3'>
                    <Button className='mr-3' onClick={handleClose} variant="outlined">
                        OK
                    </Button>
                </div>
            </DialogContent>
        </>
    )
}

export default Startuphistroy