
import React, { useContext, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';

const StartupApplicationStatus = ({ startupIds }) => {
    const { setPageLoading } = useContext(DataContext);


    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'Days',
                data: []
            }
        ]
    });

    const [clarificationData, setClarificationData] = useState({
        series: [
            {
                name: 'Days',
                data: []
            }
        ]
    });
    useEffect(() => {
        startupStatus();
        startupclarificationStatus()
    }, []);

    const startupStatus = () => {
        setPageLoading(true);
        const req = {
            startupId: Number(startupIds),
        };
        apiService('admin/startup/application/status', req, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res.data) {
                    const durations = [
                        res.data.screening ?? 0, res.data.evaluation ?? 0, res.data.bdd ?? 0,
                        res.data.ic ?? 0, res.data.psc ?? 0, res.data.ldd ?? 0,
                        res.data.cp ?? 0, res.data.fs ?? 0,
                        res.data.fd ?? 0, res.data.cs ?? 0
                    ];
                    setChartData({
                        series: [{
                            name: 'Days',
                            data: durations
                        }]
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setPageLoading(false);
            });
    };
    const startupclarificationStatus = () => {
        setPageLoading(true);
        const req = {
            startupId: Number(startupIds),
        };
        apiService('admin/startup/clarification/status', req, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res.data) {
                    // const clarification = [
                    //     res.data.screening ?? 0, res.data.evaluation ?? 0, res.data.bdd ?? 0,
                    //     res.data.ic ?? 0, res.data.psc ?? 0, res.data.ldd ?? 0,
                    //     res.data.fdd ?? 0, res.data.cp ?? 0, res.data.fs ?? 0,
                    //     res.data.fd ?? 0
                    // ];
                    const clarification = [
                        res.data.screening ?? 0, res.data.evaluation ?? 0, res.data.bdd ?? 0,
                        res.data.ldd ?? 0,
                        res.data.fdd ?? 0, res.data.cp ?? 0,
                    ];
                    setClarificationData({
                        series: [{
                            name: 'Days',
                            data: clarification
                        }]
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setPageLoading(false);
            });
    };
    const chartOptions = {
        chart: {
            height: 400,
            type: 'bar',
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                horizontal: false,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        colors: [
            '#FF5733', // Screening
            '#33FF57', // Evaluation
            '#3357FF', // BDD
            '#FF33A5', // IC
            '#A533FF', // PSC
            '#FF9C33', // LDD
            '#33FFC5', // FDD
            '#FFC133', // CP
            '#57FF33', // FS
            '#5733FF', // FD
        ],
        dataLabels: {
            enabled: true,
            formatter: (val) => val > 0 ? `${val} day(s)` : '',
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        xaxis: {
            categories: [
                'Screening', 'Evaluation', 'BDD', 'IC', 'PSC', 'LDD & FDD', 'CP', 'FS', 'FD', 'CS',
            ],
            position: 'bottom',
            axisBorder: { show: false },
            axisTicks: { show: false },
            tooltip: { enabled: true },
            labels: {
                style: {
                    fontWeight: 'bold',
                    colors: ['#304758'],
                    fontSize: '14px',
                }
            }
        },
        // yaxis: {
        //     title: { text: 'Day(s)' },
        //     axisBorder: { show: false },
        //     axisTicks: { show: false },
        //     labels: {
        //         // formatter: (val) => Math.round(val),
        //         formatter: (val) => val.toFixed(1), // Ensure decimals are shown
        //     },
        //     tickAmount: 5,
        //     min: 0,
        //     max: chartData.series[0].data.length > 0 ? Math.max(...chartData.series[0].data) + 1 : 5,
        // },
        yaxis: {
            title: { text: 'Day(s)' },
            axisBorder: { show: false },
            axisTicks: { show: false },
            labels: {
                formatter: (val) => (Number.isInteger(val) ? val : ''), // Show only whole numbers
            },
            tickAmount: 4, // Controls the number of ticks (ensuring spacing like 0, 2, 4, 6)
            min: 0,
            max: chartData.series[0].data.length > 0 ? Math.ceil(Math.max(...chartData.series[0].data) / 2) * 2 + 2 : 6,
        },

        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
            labels: {
                colors: ['#304758'],
            },
            itemMargin: {
                vertical: 10,
            },
            containerMargin: {
                left: 10,
                right: 10,
            },
            maxHeight: undefined,
            floating: false,
            itemWidth: 20,
        },
    };
    const clarificationOptions = {
        chart: {
            height: 400,
            width: '100%', // Ensures full width
            type: 'bar',
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '40%',
                barHeight: '100%',
                horizontal: false,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        colors: [
            '#FF5733', // Screening
            '#33FF57', // Evaluation
            '#3357FF', // BDD
            '#FF9C33', // LDD
            '#33FFC5', // FDD
            '#FFC133', // CP
        ],
        dataLabels: {
            enabled: true,
            formatter: (val) => val > 0 ? `${val} day(s)` : '',
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        xaxis: {
            categories: [
                'Screening', 'Evaluation', 'BDD', 'LDD', 'FDD', 'CP',
            ],
            position: 'bottom',
            axisBorder: { show: false },
            axisTicks: { show: false },
            tooltip: { enabled: true },
            labels: {
                style: {
                    fontWeight: 'bold',
                    colors: ['#304758'],
                    fontSize: '14px',
                }
            }
        },
        // yaxis: {
        //     title: { text: 'Day(s)' },
        //     axisBorder: { show: false },
        //     axisTicks: { show: false },
        //     labels: {
        //         // formatter: (val) => Math.round(val),
        //         formatter: (val) => val.toFixed(1), // Ensure decimals are shown
        //     },
        //     tickAmount: 5,
        //     min: 0,
        //     max: clarificationData.series[0].data.length > 0 ? Math.max(...clarificationData.series[0].data) + 1 : 5,
        // },
        yaxis: {
            title: { text: 'Day(s)' },
            axisBorder: { show: false },
            axisTicks: { show: false },
            labels: {
                formatter: (val) => Math.round(val), // Show only whole numbers
            },
            tickAmount: 4, // Adjusts ticks to show even spacing (0, 2, 4, 6)
            min: 0,
            max: clarificationData.series[0].data.length > 0
                ? Math.ceil(Math.max(...clarificationData.series[0].data) / 2) * 2 + 2
                : 6, // Ensures max is rounded to the next even number
        },

        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
            labels: {
                colors: ['#304758'],
            },
            itemMargin: {
                vertical: 10,
            },
            containerMargin: {
                left: 10,
                right: 10,
            },
            maxHeight: undefined,
            floating: false,
            itemWidth: 20,
        },
    };
    return (
        <div>
            {chartData.series && (
                <>
                    <div className='text-center my-3'><h4>Process Aging</h4></div>
                    <div id="chart">
                        <ReactApexChart
                            options={chartOptions}
                            series={chartData.series}
                            type="bar"
                            height={350}
                        />
                    </div>
                </>
            )}
            {clarificationData.series && (
                <>
                    <div className='text-center my-3'><h4>Clarification Aging</h4></div>
                    <div id="chart">
                        <ReactApexChart
                            options={clarificationOptions}
                            series={clarificationData.series}
                            type="bar"
                            height={350}
                        />
                    </div>
                </>)}
        </div>
    );
};

export default StartupApplicationStatus;



