import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';
import { Dialog, Tooltip } from '@mui/material';
const Scsthubavpremarks = ({ remarks, lblName, bddAdditionalInfo }) => {
    const { modalstyle } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            {
                remarks ? (
                    <>
                        {bddAdditionalInfo === 'bddAdditionalInfo' ? (
                            <>
                            <Tooltip title="View Comments" arrow>
                                <img src="/images/chat.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => setOpenModal(true)} className="mr-2 ml-2" />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Button onClick={() => setOpenModal(true)} className='text-cap'>{lblName}</Button>
                            </>
                        )}

                    </>
                ) : (<></>)
            }
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(true)}
                maxWidth="sm"
                fullWidth
            >
                <Box className="form_tag">
                    <div className='px-5 py-3'>
                        {bddAdditionalInfo === 'bddAdditionalInfo' ? (<>
                            {remarks && (
                                <>
                                    <div className='div_header'>
                                        <h6>View All Comments :</h6>
                                    </div>
                                    <ol>
                                        {
                                            remarks.length > 0 && remarks.map((list) => (
                                                <li><p>{list}</p></li>
                                            ))
                                        }

                                    </ol>
                                </>
                            )}

                        </>) : (<>
                            <p>{remarks}</p>
                        </>)}


                        <div className='text-center'>
                            <Button onClick={() => setOpenModal(false)} variant="outlined">Close</Button>
                        </div>
                    </div>
                </Box>
            </Dialog>
        </>
    )
}

export default Scsthubavpremarks