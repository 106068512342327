import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import apiService from "../../../api/apiService";
import EditIcon from "@mui/icons-material/Edit";
import downloadExcel from "../../../hooks/downloadExcel";
import Adminstartupupdate from "./Adminstartupupdate";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import notifyService from "../../../api/notifySerivce";
import CustomLink from "../../common-page/CustomLink";
import { Controller, useForm } from "react-hook-form";
import SendEmail from "./SendEmail";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TableChartIcon from "@mui/icons-material/TableChart";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { IoMdClose } from "react-icons/io";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { PiSortDescendingBold } from "react-icons/pi";
// import { PiSortAscendingBold } from "react-icons/pi";

import "./AdminStartup.css";
import { Link } from "react-router-dom";
import AddComments from "./AddComments";
import FavMark from "./FavMark";
const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "23ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const Adminstartuplist = () => {
  const {
    open,
    handleClose,
    modalstyle,
    editValue,
    setPageLoading,
    icon,
    checkedIcon,
    tableIndexValue,
    adminStartupRemoveFilter,
  } = useContext(DataContext);
  const {
    getValues,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useDidMountEffect(() => {
    dropdownValueApi();
    dropdownDistrictApi();
  }, []);
  const [sectorData, setSectorData] = useState([]);
  const [revenueStagesData, setRevenueStagesData] = useState([]);
  const [startupStageData, setStartupStageData] = useState([]);
  const [districtData, setDistrictData] = useState([]);

  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=startup_min`,
      "",
      "get"
    ).then((res) => {
      var val = res.data.dropDownValues;
      setRevenueStagesData(val.revenueStages);
      setStartupStageData(val.startupStages);
      setSectorData(val.sectors);
    });
  };
  const dropdownDistrictApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=district`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setDistrictData(val.districts);
      }
    );
  };

  var pageNumberPartners = Number(
    localStorage.getItem("pageNumberAdminStartup")
  );
  const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

  var listSizePartners = Number(localStorage.getItem("listSizeAdminStartup"));
  const [rowsPerPage, setRowsPerPage] = useState(
    listSizePartners ? listSizePartners : 10
  );

  var applicationStatusAdminStartup = localStorage.getItem(
    "applicationStatusAdminStartup"
  );
  const [submitted, setSubmitted] = useState(
    applicationStatusAdminStartup === "0"
      ? 0
      : applicationStatusAdminStartup === "2"
      ? 2
      : 1
  );

  var searchInputValuePartners = localStorage.getItem(
    "searchStringAdminStartup"
  );
  const [searchInputValue, setSearchInputValue] = useState(
    searchInputValuePartners ? searchInputValuePartners : ""
  );
  const [searchName, setSearchName] = useState(
    searchInputValuePartners ? searchInputValuePartners : ""
  );
  let columnsDataAdminStartup = JSON.parse(
    localStorage.getItem("columnsDataAdminStartup")
  );
  const [checkBoxColumnsData, setCheckBoxColumnsData] = useState(
    columnsDataAdminStartup !== null
      ? columnsDataAdminStartup
      : [
          { key: "email", lblName: "Email", value: false },
          { key: "phone", lblName: "Phone", value: false },
          { key: "stage", lblName: "Stage", value: false },
          { key: "sector", lblName: "Sector", value: false },
          { key: "city", lblName: "City", value: false },
          { key: "revenueStage", lblName: "Revenue Stage", value: false },
          { key: "annualRevenue", lblName: "Annual Revenue", value: false },
          {
            key: "totalFundRaised",
            lblName: "Total Fund Raised",
            value: false,
          },
          { key: "incubationName", lblName: "Incubation Name", value: false },
          { key: "about", lblName: "About", value: false },
          { key: "customers", lblName: "Customers", value: false },
          { key: "category", lblName: "Category", value: false },
          { key: "pitchdeck", lblName: "Pitchdeck", value: false },
          {
            key: "founderList",
            lblName: "Founders",
            value: false,
          },
          // { key: "comments", lblName: "Comments", value: false },
        ]
  );

  const [checkBoxColumnsValues, setCheckBoxColumnsValues] = useState({});
  let annualRevenueSortAdminStartup = localStorage.getItem(
    "annualRevenueSortAdminStartup"
  );
  const [annualRevenueSort, setAnnualRevenueSort] = useState(
    annualRevenueSortAdminStartup ? annualRevenueSortAdminStartup : "asc"
  );
  let totalFundRaisedSortAdminStartup = localStorage.getItem(
    "totalFundRaisedSortAdminStartup"
  );
  const [totalFundRaisedSort, setTotalFundRaisedSort] = useState(
    totalFundRaisedSortAdminStartup ? totalFundRaisedSortAdminStartup : "asc"
  );
  let favoriteSortAdminStartup = localStorage.getItem(
    "favoriteSortAdminStartup"
  );

  const [favoriteSort, setFavoriteSort] = useState(
    favoriteSortAdminStartup === "true" ? true : false
  );
  const [sortDirectionName, setSortDirection] = useState("");
  useDidMountEffect(() => {
    var startupStageAdminStartup = localStorage.getItem(
      "startupStageAdminStartup"
    );
    setValue("startupStage", startupStageAdminStartup);

    var cityAdminStartup = localStorage.getItem("cityAdminStartup");
    setValue("city", cityAdminStartup ? cityAdminStartup : "");

    var revenuStageAdminStartup = localStorage.getItem(
      "revenuStageAdminStartup"
    );
    setValue(
      "revenuStage",
      revenuStageAdminStartup ? revenuStageAdminStartup : ""
    );

    var sectors = JSON.parse(localStorage.getItem("sectorsAdminStartup"));
    setValue("sectors", sectors ? sectors : []);
    if (columnsDataAdminStartup !== null) {
      columnsDataAdminStartup.map((list) =>
        handleColumns("get", list.value, list.key)
      );
    } else {
      setCheckBoxColumnsValues({
        email: false,
        phone: false,
        stage: false,
        sector: false,
        city: false,
        revenueStage: false,
        annualRevenue: false,
        totalFundRaised: false,
        incubationName: false,
        about: false,
        customers: false,
        category: false,
        // comments: false,
        pitchdeck: false,
        founderList: false,
      });
    }
  }, []);
  const [partnerListData, setPartnerListData] = useState([]);
  const [count, setCount] = useState(0);
  useDidMountEffect(() => {
    (async () => await userListItem())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    searchInputValue,
    submitted,
    annualRevenueSort,
    totalFundRaisedSort,
    favoriteSort,
  ]);
  const userListItem = async () => {
    setPartnerListData([]);
    var data = getValues();
    var reqData = {
      roleId: 4,
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      active: submitted === 2 ? 0 : 1,
      applicationStatus: submitted === 1 ? true : false,
      startupStage: data.startupStage,
      city: data.city,
      revenuStage: data.revenuStage,
      sectors: data.sectors ? data.sectors : [],
      fav: favoriteSort,
      // sort: sortDirectionName,
      // sortDirection:
      //   sortDirectionName === "annualrevenue"
      //     ? annualRevenueSort
      //     : sortDirectionName === "totalfundsraised"
      //     ? totalFundRaisedSort
      //     : "",
    };
    setPageLoading(true);
    await apiService("user/startup/admin/listv2", reqData, "post").then(
      (res) => {
        setPageLoading(false);
        if (res) {
          setCount(res.data.count);
          if (res.data.responseModelList) {
            setPartnerListData(res.data.responseModelList);
          } else {
            setPartnerListData([]);
          }
        }
      }
    );
  };
  const handleSearch = (searchValue) => {
    if (searchValue.length > 3) {
      setSearchInputValue(searchValue);
      localStorage.setItem("searchStringAdminStartup", searchValue);
      localStorage.setItem("pageScst", 0);
      setPage(0);
    } else {
      setSearchInputValue("");
      localStorage.setItem("searchStringAdminStartup", "");
      localStorage.setItem("pageScst", 0);
      setPage(0);
    }
    setSearchName(searchValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("pageNumberAdminStartup", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem(
      "listSizeAdminStartup",
      parseInt(event.target.value, 10)
    );
    localStorage.setItem("pageNumberAdminStartup", 0);
    setPage(0);
  };

  const exportExcelStartup = (download, emails) => {
    let data = getValues();
    let req = {
      download: "yes",
      email: emails,
      roleId: 4,
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      active: submitted === 2 ? 0 : 1,
      applicationStatus: submitted === 1 ? true : false,
      startupStage: data.startupStage,
      city: data.city,
      revenuStage: data.revenuStage,
      sectors: data.sectors ? data.sectors : [],
      fav: favoriteSort,
      // sort: sortDirectionName,
      // sortDirection:
      //   sortDirectionName === "annualrevenue"
      //     ? annualRevenueSort
      //     : sortDirectionName === "totalfundsraised"
      //     ? totalFundRaisedSort
      //     : "",
    };
    setPageLoading(true);
    apiService("user/startup/admin/listv2", req, "post").then((res) => {
      setPageLoading(false);
      if (res?.data?.response?.responseStatus === "Success") {
        notifyService(
          "success",
          "Success",
          "An Excel file has been sent to your email address."
        );
        handleExportAllClose();
      }
    });
  };
  // const exportExcel = () => {
  //     setLoading(true);
  //     apiService('export/users?roleid=4', '', 'download').then((res) => {
  //         setLoading(false);
  //         downloadExcel(res.data, 'startup_')
  //     })
  // }

  // const handleFilterStartup = (value) => {
  //   setActiveStartup(value);
  //   localStorage.setItem("activeAdminStartup", value);
  //   setPage(0);
  // };

  const handleFilterApplication = (value) => {
    localStorage.setItem("applicationStatusAdminStartup", value);
    setSubmitted(value);
    localStorage.setItem("pageNumberAdminStartup", 0);
    setPage(0);
    localStorage.setItem("listSizeAdminStartup", 10);
    setRowsPerPage(10);
    localStorage.setItem("favoriteSortAdminStartup", false);
    setFavoriteSort(false);
  };

  const handleActiveApi = (path, id) => {
    apiService(`user/${path}?id=${id}`, "", "get").then((res) => {
      if (res) {
        if (res.data) {
          userListItem(4);
        }
      }
    });
  };

  const [openAllExport, setopenAllExport] = useState(false);
  const handleExportAllClick = () => {
    setopenAllExport(true);
    // notifyService('success', 'Success', 'An Excel file has been sent to your email address.')
  };
  const handleExportAllClose = () => {
    reset();
    setopenAllExport(false);
  };
  const handleFilter = () => {
    var data = getValues();
    localStorage.setItem("startupStageAdminStartup", data.startupStage);
    localStorage.setItem("cityAdminStartup", data.city);
    localStorage.setItem("revenuStageAdminStartup", data.revenuStage);
    localStorage.setItem("sectorsAdminStartup", JSON.stringify(data.sectors));
    localStorage.setItem("pageNumberAdminStartup", 0);
    setPage(0);
    userListItem();
  };
  const handleClear = () => {
    reset();
    userListItem();
    adminStartupRemoveFilter();
  };

  const [checkBoxColumns, setCheckBoxColumns] = React.useState(null);
  const openColumns = Boolean(checkBoxColumns);
  const handleClick = (event) => {
    setCheckBoxColumns(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setCheckBoxColumns(null);
  };
  const handleColumns = (way, checked, key) => {
    // console.log(checked, key);

    setCheckBoxColumnsData((prevData) => {
      const checkedCount = prevData.filter((item) => item.value).length;
      if (way === "select" && checked && checkedCount >= 7) {
        notifyService(
          "info",
          "Alert",
          "You can select a maximum of 7 options."
        );
        return prevData;
      }
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, value: checked } : item
      );
      setCheckBoxColumnsValues((prevValues) => ({
        ...prevValues,
        [key]: checked,
      }));
      localStorage.setItem(
        "columnsDataAdminStartup",
        JSON.stringify(updatedData)
      );
      return updatedData;
    });
  };
  const [aboutDialog, setAboutDialog] = useState(false);
  const [aboutData, setAboutData] = useState("");
  const [aboutLable, setAboutLable] = useState("");
  const handleAboutOpen = (label, value) => {
    setAboutData(value);
    setAboutLable(label);
    setAboutDialog(true);
  };
  const handleAboutClose = () => {
    setAboutData("");
    setAboutDialog(false);
  };
  const [founderDialog, setFounderDialog] = useState(false);
  const [founderData, setFounderData] = useState([]);
  const handleFounderOpen = (value) => {
    setFounderData(value);
    setFounderDialog(true);
  };
  const handleFounderClose = () => {
    setFounderData([]);
    setFounderDialog(false);
  };
  const handleAnnualRevenueSort = (value) => {
    setAnnualRevenueSort(value === "asc" ? "desc" : "asc");
    localStorage.setItem(
      "annualRevenueSortAdminStartup",
      value === "asc" ? "desc" : "asc"
    );
    setSortDirection("annualrevenue");
  };
  const handleTotalFundRaisedSort = (value) => {
    setTotalFundRaisedSort(value === "asc" ? "desc" : "asc");
    localStorage.setItem(
      "totalFundRaisedSortAdminStartup",
      value === "asc" ? "desc" : "asc"
    );
    setSortDirection("totalfundsraised");
  };
  const handleClickDownload = (result) => {
    const link = document.createElement("a");
    link.href = result;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.setAttribute("download", "Pitchdeck");
    document.body.appendChild(link);
    link.click();
  };
  const handleFavoriteSort = () => {
    setFavoriteSort(!favoriteSort);
    localStorage.setItem("favoriteSortAdminStartup", !favoriteSort);
  };

  return (
    <section className="">
      <div className="container-fluid">
        <div className="form_div chart">
          <div>
            <div className="filiterstartup">
              <button
                className={submitted === 1 ? "applyBtn act_span" : "applyBtn"}
                onClick={() => {
                  handleFilterApplication(1);
                }}
              >
                Application Submitted
              </button>
              <button
                className={submitted === 0 ? "applyBtn act_span" : "applyBtn"}
                onClick={() => {
                  handleFilterApplication(0);
                }}
              >
                Application In Progress
              </button>
              <button
                className={submitted === 2 ? "applyBtn act_span" : "applyBtn"}
                onClick={() => {
                  handleFilterApplication(2);
                }}
              >
                Disabled Account
              </button>
            </div>
          </div>
          <div className="table_tag">
            <div className="table_header_tag set-res-flex align-item-center">
              <div className="mb-3">
                <SearchinputDiv
                  sx={{ display: { xs: "none", sm: "block" } }}
                  onChange={(e) => handleSearch(e.target.value)}
                >
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    value={searchName}
                    type="search"
                    placeholder="Search by Startup Name"
                    inputProps={{ "aria-label": "search" }}
                  />
                </SearchinputDiv>
              </div>
              <div className="ml-auto">
                <Grid className="d-flex align-items-center">
                  <Grid>
                    <ButtonGroup variant="contained" aria-label="button group">
                      {submitted === 1 && (
                        <>
                          <Button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseWidthExample"
                          >
                            <Tooltip title="Filter" placement="top">
                              <FilterAltIcon />
                            </Tooltip>
                          </Button>

                          <Button
                            onClick={(e) => handleClick(e)}
                            aria-controls={
                              openColumns ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openColumns ? "true" : undefined}
                          >
                            <Tooltip title="Manage Columns" placement="top">
                              <TableChartIcon />
                            </Tooltip>
                          </Button>
                        </>
                      )}
                      {submitted !== 2 && (
                        <Button onClick={() => handleFavoriteSort()}>
                          <Tooltip title="Favorite" placement="top">
                            {favoriteSort ? (
                              <StarIcon className="c-yellow" />
                            ) : (
                              <StarBorderIcon />
                            )}
                          </Tooltip>
                        </Button>
                      )}
                    </ButtonGroup>
                  </Grid>
                  {submitted !== 2 && (
                    <Button
                      className="ml-md-3"
                      variant="outlined"
                      onClick={handleExportAllClick}
                      startIcon={<CloudDownloadIcon />}
                    >
                      Export
                    </Button>
                  )}
                </Grid>
                <Menu
                  anchorEl={checkBoxColumns}
                  id="account-menu"
                  open={openColumns}
                  onClose={handleCloseMenu}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        py: 1,
                        px: 2,
                        width: 250,
                        maxHeight: 250,
                        overflowY: "auto",
                        "& .MuiAvatar-root": {
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Grid className="d-flex align-items-center pb-2">
                    Manage Columns
                    <IoMdClose
                      className="ml-auto c-pointer"
                      onClick={handleCloseMenu}
                    />
                  </Grid>
                  <Divider />
                  <FormGroup>
                    {checkBoxColumnsData.map(({ key, lblName, value }) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(e) =>
                              handleColumns("select", e.target.checked, key)
                            }
                          />
                        }
                        label={lblName}
                      />
                    ))}
                  </FormGroup>
                </Menu>
              </div>
            </div>
            <div className="collapse my-3" id="collapseWidthExample">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}

                      <Controller
                        name="sectors"
                        control={control}
                        defaultValue={[]} // Ensure default is an array
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            multiple
                            limitTags={2}
                            value={Array.isArray(value) ? value : []} // Ensure value is an array
                            options={sectorData}
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option?.name || ""
                            } // Ensure it always returns a string
                            onChange={(e, newValue) => {
                              onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Sectors" />
                            )}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option || ""}
                              </li>
                            )}
                          />
                        )}
                      />

                      <FormHelperText className="fnt-sm c-blue">
                        Select one or more
                      </FormHelperText>
                      {/* </FormControl> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}
                      <Controller
                        name="startupStage"
                        control={control}
                        defaultValue={""} // Ensure it's null for single selection
                        rules={{ required: false }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            fullWidth
                            disableClearable
                            disablePortal
                            value={value || ""} // Ensure value is either an object or null
                            options={startupStageData}
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option?.name || ""
                            } // Ensure it always returns a string
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Startup Stage"
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        )}
                      />

                      {/* </FormControl> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}
                      <Controller
                        name="revenuStage"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            // {...field}
                            fullWidth
                            disableClearable
                            disablePortal
                            value={value || null}
                            options={revenueStagesData}
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option?.name || ""
                            } // Ensure it always returns a string
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Revenu Stage"
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        )}
                      />
                      {/* </FormControl> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            // {...field}
                            fullWidth
                            disableClearable
                            disablePortal
                            value={value || null}
                            options={districtData}
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option?.name || ""
                            } // Ensure it always returns a string
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="City"
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        )}
                      />
                      {/* </FormControl> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-3">
                <center>
                  <Button
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    onClick={() => handleClear()}
                    data-toggle="collapse"
                    data-target="#collapseWidthExample"
                  >
                    Clear
                  </Button>
                  <Button variant="contained" onClick={handleFilter}>
                    Filter
                  </Button>
                </center>
              </div>
            </div>
            <div>
              {partnerListData.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "15px" }}>No</TableCell>
                        {submitted !== 2 && (
                          <TableCell sx={{ width: "100px" }}></TableCell>
                        )}
                        <TableCell>Organization Name</TableCell>
                        {submitted === 1 && checkBoxColumnsValues.email && (
                          <TableCell>Email Id</TableCell>
                        )}
                        {submitted !== 1 && <TableCell>Email Id</TableCell>}
                        {submitted === 1 && checkBoxColumnsValues.phone && (
                          <TableCell>Phone Number</TableCell>
                        )}
                        {submitted !== 1 && <TableCell>Phone Number</TableCell>}
                        {submitted === 1 && checkBoxColumnsValues.city && (
                          <TableCell>City</TableCell>
                        )}
                        {submitted === 1 && checkBoxColumnsValues.sector && (
                          <TableCell>Sector</TableCell>
                        )}
                        {submitted === 1 && checkBoxColumnsValues.stage && (
                          <TableCell>Stage</TableCell>
                        )}
                        {submitted === 1 &&
                          checkBoxColumnsValues.revenueStage && (
                            <TableCell>Revenue Stage</TableCell>
                          )}
                        {submitted === 1 &&
                          checkBoxColumnsValues.annualRevenue && (
                            <TableCell>
                              Annual Revenue
                              {/* {" "}
                              <span
                                onClick={() =>
                                  handleAnnualRevenueSort(annualRevenueSort)
                                }
                                className="c-pointer"
                              >
                                {annualRevenueSort === "asc" ? (
                                  <PiSortAscendingBold className="font-16" />
                                ) : (
                                  <PiSortDescendingBold className="font-16" />
                                )}
                              </span> */}
                            </TableCell>
                          )}
                        {submitted === 1 &&
                          checkBoxColumnsValues.totalFundRaised && (
                            <TableCell>
                              Total Fund Raised{" "}
                              {/* <span
                                onClick={() =>
                                  handleTotalFundRaisedSort(totalFundRaisedSort)
                                }
                                className="c-pointer"
                              >
                                {totalFundRaisedSort === "asc" ? (
                                  <PiSortAscendingBold className="font-16" />
                                ) : (
                                  <PiSortDescendingBold className="font-16" />
                                )}
                              </span> */}
                            </TableCell>
                          )}
                        {submitted === 1 &&
                          checkBoxColumnsValues.incubationName && (
                            <TableCell>Incubation Name</TableCell>
                          )}
                        {submitted === 1 && checkBoxColumnsValues.about && (
                          <TableCell align="center">About</TableCell>
                        )}
                        {submitted === 1 && checkBoxColumnsValues.customers && (
                          <TableCell>Customers</TableCell>
                        )}
                        {submitted === 1 && checkBoxColumnsValues.category && (
                          <TableCell>Category</TableCell>
                        )}
                        {/* {submitted === 1 && checkBoxColumnsValues.comments && (
                          <TableCell align="center">Comments</TableCell>
                        )} */}
                        {submitted === 1 && checkBoxColumnsValues.pitchdeck && (
                          <TableCell align="center">Pitchdeck</TableCell>
                        )}
                        {submitted === 1 &&
                          checkBoxColumnsValues.founderList && (
                            <TableCell align="center">Founders</TableCell>
                          )}
                        {submitted !== 2 && (
                          <TableCell align="center">Action</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {partnerListData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ width: "15px" }}>
                            {tableIndexValue(rowsPerPage, page, index)}
                          </TableCell>
                          {submitted !== 2 && (
                            <TableCell sx={{ width: "100px" }}>
                              <FavMark
                                id={row.id}
                                fav={row.fav}
                                userListItem={userListItem}
                              />
                              <AddComments
                                id={row.id}
                                comments={row.comments}
                                userListItem={userListItem}
                              />
                            </TableCell>
                          )}
                          <TableCell>
                            {submitted !== 2 ? (
                              <>
                                <CustomLink
                                  to={`/admin/startup/details/${btoa(row.id)}`}
                                >
                                  {row.name}
                                </CustomLink>
                                <Tooltip title="Edit" placement="top">
                                  <EditIcon
                                    className="c-green c-pointer ml-2"
                                    onClick={() => editValue(row.id)}
                                  />
                                </Tooltip>
                              </>
                            ) : (
                              <>{row.name}</>
                            )}
                          </TableCell>
                          {submitted === 1 && checkBoxColumnsValues.email && (
                            <TableCell>{row.email}</TableCell>
                          )}
                          {submitted !== 1 && (
                            <TableCell>{row.email}</TableCell>
                          )}
                          {submitted === 1 && checkBoxColumnsValues.phone && (
                            <TableCell>
                              {row.phone}
                              <Tooltip title="Edit" placement="top">
                                <EditIcon
                                  className="c-green c-pointer ml-2"
                                  onClick={() => editValue(row.id)}
                                />
                              </Tooltip>
                            </TableCell>
                          )}
                          {submitted !== 1 && (
                            <TableCell>
                              {row.phone}
                              {submitted !== 2 && (
                                <Tooltip title="Edit" placement="top">
                                  <EditIcon
                                    className="c-green c-pointer ml-2"
                                    onClick={() => editValue(row.id)}
                                  />
                                </Tooltip>
                              )}
                            </TableCell>
                          )}
                          {submitted === 1 && checkBoxColumnsValues.city && (
                            <TableCell>{row.city ? row.city : "-"}</TableCell>
                          )}
                          {submitted === 1 && checkBoxColumnsValues.sector && (
                            <TableCell>
                              {row.sector ? row.sector : `-`}
                            </TableCell>
                          )}
                          {submitted === 1 && checkBoxColumnsValues.stage && (
                            <TableCell>
                              {" "}
                              {row.startupStage ? row.startupStage : `-`}
                            </TableCell>
                          )}
                          {submitted === 1 &&
                            checkBoxColumnsValues.revenueStage && (
                              <TableCell>
                                {row.startupRevenueStage
                                  ? row.startupRevenueStage
                                  : `-`}
                              </TableCell>
                            )}
                          {submitted === 1 &&
                            checkBoxColumnsValues.annualRevenue && (
                              <TableCell>
                                {row.annualRevenue ? row.annualRevenue : `-`}
                              </TableCell>
                            )}
                          {submitted === 1 &&
                            checkBoxColumnsValues.totalFundRaised && (
                              <TableCell>
                                {row.totalFundRaised
                                  ? row.totalFundRaised
                                  : `-`}
                              </TableCell>
                            )}
                          {submitted === 1 &&
                            checkBoxColumnsValues.incubationName && (
                              <TableCell>
                                {row.incubationName ? row.incubationName : `-`}
                              </TableCell>
                            )}
                          {submitted === 1 && checkBoxColumnsValues.about && (
                            <TableCell align="center">
                              {row.about ? (
                                <VisibilityIcon
                                  className="c-blue c-pointer"
                                  onClick={() =>
                                    handleAboutOpen("About Details", row.about)
                                  }
                                />
                              ) : (
                                `-`
                              )}
                            </TableCell>
                          )}
                          {submitted === 1 &&
                            checkBoxColumnsValues.customers && (
                              <TableCell>
                                {row.customers ? row.customers : `-`}
                              </TableCell>
                            )}
                          {submitted === 1 &&
                            checkBoxColumnsValues.category && (
                              <TableCell>
                                {row.category ? row.category : `-`}
                              </TableCell>
                            )}
                          {/* {submitted === 1 &&
                            checkBoxColumnsValues.comments && (
                              <TableCell align="center">
                                {row.comments ? (
                                  <VisibilityIcon
                                    className="c-blue c-pointer"
                                    onClick={() =>
                                      handleAboutOpen("Comments", row.comments)
                                    }
                                  />
                                ) : (
                                  `-`
                                )}
                              </TableCell>
                            )} */}
                          {submitted === 1 &&
                            checkBoxColumnsValues.pitchdeck && (
                              <TableCell align="center">
                                {row.pitchDeckName ? (
                                  <PictureAsPdfIcon
                                    className="c-red c-pointer"
                                    onClick={() =>
                                      handleClickDownload(row.pitchDeckName)
                                    }
                                  />
                                ) : (
                                  `-`
                                  // <PictureAsPdfIcon
                                  //   className="c-red c-pointer"
                                  //   onClick={() =>
                                  //     handleClickDownload(row.pitchdeck)
                                  //   }
                                  // />
                                )}
                              </TableCell>
                            )}
                          {submitted === 1 &&
                            checkBoxColumnsValues.founderList && (
                              <TableCell align="center">
                                {row.founders ? (
                                  <VisibilityIcon
                                    className="c-blue c-pointer"
                                    onClick={() =>
                                      handleFounderOpen(row.founders)
                                    }
                                  />
                                ) : (
                                  `-`
                                )}
                              </TableCell>
                            )}
                          {submitted !== 2 && (
                            <TableCell align="center">
                              <Tooltip title="Disable" placement="top">
                                <DeleteSweepIcon
                                  className="c-red c-pointer"
                                  onClick={() => {
                                    handleActiveApi("remove", row.id);
                                  }}
                                />
                              </Tooltip>
                              {/* {row.active === false && (
                                <span
                                  className="yes_span c-pointer ml-2"
                                  onClick={() => {
                                    handleActiveApi("activate", row.id);
                                  }}
                                >
                                  Enable
                                </span>
                              )} */}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                    <caption>
                      <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{ float: "left" }}
                      />
                    </caption>
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <h4>Startup list is empty</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag">
          <Adminstartupupdate handleClose={handleClose} />
        </Box>
      </Modal>
      <Modal open={openAllExport}>
        <Box sx={modalstyle} className="form_tag">
          <SendEmail
            handleExportAllClose={handleExportAllClose}
            exportExcelStartup={exportExcelStartup}
          />
        </Box>
      </Modal>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={aboutDialog}
        onClose={handleAboutClose}
      >
        <DialogTitle>
          {aboutLable} <Divider />
        </DialogTitle>

        <DialogContent>{aboutData}</DialogContent>
        <DialogActions>
          <Button className="m-3" variant="outlined" onClick={handleAboutClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={founderDialog}
        onClose={handleFounderClose}
      >
        <DialogTitle>
          Founder Details <Divider />
        </DialogTitle>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">
                    <LinkedInIcon className="c-blue" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {founderData.length &&
                  founderData.map((list, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{list.name}</TableCell>
                      <TableCell align="center">
                        {list.linkedIn ? (
                          <>
                            <Link to={list.linkedIn} target="_blank">
                              {list.linkedIn}
                            </Link>
                          </>
                        ) : (
                          `-`
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="m-3"
            onClick={handleFounderClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Adminstartuplist;
