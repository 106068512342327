import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import apiService from '../../../api/apiService';
// import Additionalinfo from '../additional-info-page/Additionalinfo';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Dialog from '@mui/material/Dialog';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import Startuphistroy from '../additional-info-page/Startuphistroy';
import { Divider, TablePagination } from '@mui/material';
import DataContext from '../../../context/DataContext';
// import ScstBddStartups from '../../scstBdd-page/scstBdd-details/ScstBddStartups';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect } from 'react';
// import Resubmit from '../../investor-module/associated-startups-page/associated-details-page/pages/Resubmit';
import StartupAdditionalinfo from '../../startup-module/progress-page/StartupAdditionalinfo';
import ScstBDDAdditionalinfo from './ScstBDDAdditionalinfo';
import Scsthubavpremarks from '../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import ClarificationAll from './ClarificationAll';
// import StartupAdditionalinfo from './StartupAdditionalinfo';

const ScstBddStartups = ({ partnerId, name, historyNotifyBDD, progressList, round }) => {
    const { setPageLoading, setHasNotification, hasNotification } = useContext(DataContext)
    const [statusValue, setStatusValue] = useState(2)
    const [historyListValue, setHistoryListValue] = useState([])
    const [countHistory, setCountHistory] = useState(0);
    const [pageHistory, setPageHistory] = useState(0);
    const [rowsPerPageHistory, setRowsPerPageHistory] = useState(10);

    useDidMountEffect(() => {
        if (partnerId) {
            historyList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusValue, round, partnerId, pageHistory, rowsPerPageHistory]);

    const handleStatus = (value) => {
        setStatusValue(value)
    }

    const historyList = () => {
        setHistoryListValue([])
        setPageLoading(true)
        var req = {
            listSize: rowsPerPageHistory,
            pageNumber: pageHistory + 1,
            userId: partnerId,
            status: statusValue,
            round: round
        }
        apiService('startup/clarification/history', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setCountHistory(res.data.count)
                // setHistoryNotify(res.data.additionalInfoRequested)
                if (res.data.clarifications) {
                    res.data.clarifications.forEach((element) => {
                    if (element.documentName) {
                        var val = element.documentName;
                        var data = val.split("?");
                        if (data) {
                          var formet = data[0].split(".");
                          element.formet = formet[4];
                        }
                      }
                    });
                    setHistoryListValue(res.data.clarifications)
                }
            }
        })
    }
    const handleHistoryChangePage = (event, newPage) => {
        setPageHistory(newPage);
    };
    const handleChangeHistoryRowsPerPage = (event) => {
        setRowsPerPageHistory(parseInt(event.target.value, 10));
        setPageHistory(0);
    };

    const [addInfoValue, setAddInfoValue] = React.useState('1');
    const handleChangeInfo = (event, newValue) => {
        setAddInfoValue(newValue);
    };


    const [open, setOpen] = React.useState(false);
    const [partnerorInvestorId, setPartnerorInvestorId] = useState()
    const handleClickOpen = (id) => {
        setOpen(true);
        setPartnerorInvestorId(id)
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [isFullTextVisible, setIsFullTextVisible] = useState({})
    const toggleText = (index) => {
        debugger
        setIsFullTextVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
        debugger
    };
    const truncateTexts = (text, charLimit) => {
        if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
        return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
    };
    return (
        <>
            <div className='mt-3'>
                {/* <div className='div_header'>
                    <h6 className='mt-5' style={{ color: '#313131' }}>Additional Information History</h6>
                </div> */}
                <TabContext value={addInfoValue}>
                    <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                        <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                            <Tab
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Pending
                                        {(historyNotifyBDD === true) ? (
                                            <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                        ) : (<></>)}
                                    </div>
                                }
                                value="1" onClick={() => handleStatus(2)} />
                            <Tab label="Submitted" value="2" onClick={() => handleStatus(3)} />
                            <Tab label="Verified" value="3" onClick={() => handleStatus(1)} />
                        </TabList>
                    </Box>
                    <Divider />
                    <div className="mt-3">
                        {historyListValue.length > 0 &&
                            (historyListValue.length) ? (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>No</TableCell>
                                            <TableCell>Question</TableCell>
                                            <TableCell>Requested Clarification</TableCell>


                                            {
                                                statusValue === 2 && <TableCell align='center'>Requested Time</TableCell>
                                            }
                                            {
                                                statusValue === 3 && <TableCell align='center'>Submitted Time</TableCell>
                                            }
                                            {
                                                statusValue === 1 && <TableCell align='center'>Time</TableCell>
                                            }
                                            {/* <TableCell align='center'>Requested By</TableCell> */}
                                            <TableCell align='center'>
                                                Your Clarification Response
                                            </TableCell>

                                            {/* {
                                        statusValue === 1 && (
                                          <TableCell align='center'>Action</TableCell>
                                        )
                                      } */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            historyListValue.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align='center'>{pageHistory * rowsPerPageHistory + index + 1}</TableCell>
                                                    <TableCell sx={{ maxWidth: '200px' }}>{list.attribute ?? 'General'}</TableCell>

                                                    <TableCell >
                                                        {list.clarification ? (
                                                            <>
                                                                {isFullTextVisible[index]
                                                                    ? list.clarification
                                                                    : truncateTexts(list.clarification || '', 20)}
                                                                {list.clarificationAll && (<ClarificationAll remarks={list.clarificationAll} lblName="Clarifications" btnName="Show All Clarifications" />)}
                                                                {list.clarification && list.clarification.length > 20 && (
                                                                    <h6
                                                                        onClick={() => toggleText(index)}
                                                                        style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                    >
                                                                        {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                                                    </h6>
                                                                )}
                                                            </>) : ((`General`))}

                                                    </TableCell>
                                                    {/* <TableCell >{list.clarification} 
                                                        {list.clarificationAll && (<ClarificationAll remarks={list.clarificationAll} lblName="Clarifications" btnName="Show All Clarifications" />)}</TableCell> */}
                                                    {/* <TableCell align='center'>
                                                        {list.remarks ? list.remarks : (`-`)}
                                                    </TableCell> */}
                                                    <TableCell align='center'>{list.time}</TableCell>

                                                    <TableCell align='center'>
                                                        {/* <span className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</span> */}
                                                        <p className="nav-link mb-0">
                                                            <ScstBDDAdditionalinfo data={list} clarificationId={list.clarificationId} statusValue={statusValue} partnerId={partnerId} historyList={historyList} progressList={progressList} round={round} />
                                                            {(statusValue !== 2 && list.response) && <Scsthubavpremarks remarks={list.response} lblName="View Comments" />}
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <caption>
                                        <TablePagination
                                            component="div"
                                            count={countHistory}
                                            page={pageHistory}
                                            onPageChange={handleHistoryChangePage}
                                            rowsPerPage={rowsPerPageHistory}
                                            onRowsPerPageChange={handleChangeHistoryRowsPerPage}
                                            style={{ float: 'left' }}
                                        />
                                    </caption>
                                </Table>

                            </TableContainer>
                        ) : (
                            <div className='nodata_tag'>
                                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                <p>No Records Found</p>
                            </div>
                        )
                        }
                    </div>
                </TabContext>
            </div>
        </>
    )
}

export default ScstBddStartups