import React, { useContext, useState } from 'react';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Associatedlist from './Associatedlist';
import Tanseedassociatedlist from './Tanseedassociatedlist';
import DataContext from '../../../../context/DataContext';
import apiService from '../../../../api/apiService';

const Associatedlisttab = ({ name, path, endpoint }) => {
    useDidMountEffect(() => {
    }, [name, path, endpoint])
    const [navTabValue, setNavTabValue] = useState('1');
    const handleNavTabChange = (event, newValue) => {
        setNavTabValue(newValue);
    };
    useDidMountEffect(() => {
        roundlistItem()
        // eslint-disable-next-line
        debugger
    }, [])
    const { icon, checkedIcon, tableIndexValue, setPageLoading, searchInputValue, setSearchInputValue } = useContext(DataContext);
    const [selectedRound, setSelectedRound] = useState()
      // round list api
      const [roundListData, setRoundListData] = useState([]);
    
      const roundlistItem = async () => {
        setPageLoading(true);
        // var req = {
        // "roleId": localStorage.getItem('regRoleId'),
        // "listSize": rowsPerPage,
        // "pageNumber": page + 1,
        // "searchString": searchInputValue,
        // }
        await apiService("partner/panelround/list", "", "get").then((res) => {
          setPageLoading(false);
          if (res) {
            if (res.data.panelRounds) {
              // setCount(res.data)
              setRoundListData(res.data.panelRounds);
              setSelectedRound(res.data.panelRounds[0].name)
            } else {
              setRoundListData([]);
            }
          }
        });
      };
    // let fund = localStorage.getItem('fund')
    let tanseedlist = localStorage.getItem('tanseed')
    return (
        <section className=''>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div className='header_tag'>
                        <h5>Associated Startups</h5>
                    </div>
                    <div className='table_tag'>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={navTabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleNavTabChange} aria-label="lab API tabs example">
                                        <Tab label="Rounds Not Started" value="1" />
                                        <Tab label="Rounds Started" value="2" />
                                        {/* <Tab label="Rounds Completed" value="3" /> */}
                                        {/* <Tab label="Startups Without Rounds" value="4" /> */}
                                    </TabList>
                                </Box>
                                <TabPanel value="1" sx={{ padding: '10px 0px' }}>
                                    <Associatedlist name={name} path={path} endpoint={endpoint} lable={`start`} url={`partner/startups/round/notstarted/list`} />
                                </TabPanel>
                                <TabPanel value="2" sx={{ padding: '10px 0px' }}>
                                    {/* {
                                        tanseedlist === 'true' ? (
                                            <Tanseedassociatedlist name={name} path={path} endpoint={endpoint} lable={`inprogress`} url={`partner/startups/round/started/list`} />
                                        ) : (
                                            <Associatedlist name={name} path={path} endpoint={endpoint} lable={`inprogress`} url={`partner/startups/round/started/list`} />                                   
                                        )
                                    } */}
                                    <Tanseedassociatedlist selectedRound={selectedRound} setSelectedRound={setSelectedRound} roundListData={roundListData} setRoundListData={setRoundListData} name={name} path={path} endpoint={endpoint} lable={`inprogress`} url={`partner/startups/round/started/list`} />
                                   
                                </TabPanel>
                                {/* <TabPanel value="3" sx={{ padding: '10px 0px' }}>
                                    {
                                        tanseedlist === 'true' ? (
                                            <Tanseedassociatedlist name={name} path={path} endpoint={endpoint} lable={`completed`} url={`partner/startups/round/completed/list`} />
                                        ) : (
                                            <Associatedlist name={name} path={path} endpoint={endpoint} lable={`completed`} url={`partner/startups/round/completed/list`} />
                                        )
                                    }


                                </TabPanel> */}
                                {/* <TabPanel value="4" sx={{ padding: '10px 0px' }}>
                                    <Associatedlist name={name} path={path} endpoint={endpoint} lable={`rounds`} url={`partner/startups/without/rounds/list`} />
                                </TabPanel> */}
                            </TabContext>
                        </Box>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Associatedlisttab