import React, { useEffect, useContext, useState } from "react";
import { Container, FormHelperText } from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../context/DataContext";
import { Controller } from "react-hook-form";

const Adminstartupupdate = () => {
  const {
    readyOnlyEmail,
    userId,
    registerData,
    getRegisterItem,
    handleSubmit,
    control,
    errors,
    loading,
    onSubmit,
    handleCancelModal,
  } = useContext(DataContext);
  const [lableName, setLableName] = useState("Add");
  useEffect(() => {
    if (userId !== "") {
      (async () => await getRegisterItem())();
      setLableName("Update");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, registerData]);

  return (
    <section className="">
      <Container>
        <div className="header_tag">
          <h5 className="c-black">{lableName} Startup</h5>
        </div>
        <div className="">
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group mb-3">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    rules={{ required: false }}
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="d-none">
                <div className="form-group mb-3">
                  <Controller
                    name="roleId"
                    control={control}
                    defaultValue="5"
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Name of Organization *"
                        placeholder="Enter Organization Name"
                        autoFocus
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-none">
                <div className="form-group mb-3">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Email Id is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Email Id *"
                        placeholder="Enter Email Id"
                        autoFocus
                        fullWidth
                        inputProps={{ readOnly: readyOnlyEmail }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.email && errors.email.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-12 col-12 mb-3">
                <div className="form-group mb-3">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Organization name is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Name of Organization *"
                        placeholder="Enter Organization Name"
                        autoFocus
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-12 col-12 mb-3">
                <div className="form-group mb-3">
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Phone Number is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Phone Number *"
                        placeholder="Enter Phone Number"
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.phone && errors.phone.message}
                  </FormHelperText>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3"
              >
                {lableName === "Add" ? <span>Save</span> : <span>Update</span>}
              </LoadingButton>
              <Button
                variant="contained"
                onClick={handleCancelModal}
                className="btn btn-cancel submit px-3 ml-3"
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default Adminstartupupdate;
