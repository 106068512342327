import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import ClarificationAll from "../../../scstBdd-page/scstBdd-details/ClarificationAll";
import Scsthubavpremarks from "../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks";
import Viewimage from "../../../../api/Viewimage";
import DataContext from "../../../../context/DataContext";

const FDDPriorDocuments2 = ({
  idNumber,
  path,
  startupCheckList,
  startupValue,
  viewStartupDetails,
  showStartupValue,
}) => {
  const { setPageLoading, getBDDStatus } = useContext(DataContext);
  const [formData, setFormData] = useState();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const { fields: prior2Fields } = useFieldArray({
    control,
    name: "prior2",
  });

  useDidMountEffect(() => {
    getApi();
  }, []);
  const getApi = () => {
    setPageLoading(true);
    var req = {
      startupId: idNumber,
      round: "fdd",
      category: "prior2",
    };
    apiService(`partner/startup/clarification/list`, req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          result.data.prior2.forEach((element) => {
            if (element.status === 3) {
              element.status = 2;
            }
            if (element.fileUrl) {
              var val = element.fileUrl;
              var data = val.split("?");
              if (data) {
                var formet = data[0].split(".");
                element.formet = formet[4];
              }
            }
          });

          setFormData(result.data);
          // console.log(result.data);
        }
      })
      .catch((err) => {});
  };
  const handelVerifiedStatus = (value, i) => {
    // console.log(value);
    setValue(`prior2.${i}.status`, Number(value));
    var getData = getValues(`prior2.${i}`);
    if (value === "1") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        status: 1,
        round: "fdd",
      };
      apiService(`partner/startups/clarification/fdd/status/save`, req, "post")
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.responseStatus === "Success"
          ) {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => {});
    }
    setFormData(getValues());
  };
  const handleRequestValue = (value, i) => {
    setValue(`prior2.${i}.clarification`, value);
    clearErrors(`prior2.${i}.clarification`);
  };
  const onSubmit = (data) => {};
  const handleRequestSubmit = (i) => {
    var getData = getValues(`prior2.${i}`);
    if (getData.clarification !== "") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        clarification: getData.clarification,
        status: 2,
        round: "fdd",
      };
      apiService(`partner/startup/clarification/fdd/save`, req, "post")
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.responseStatus === "Success"
          ) {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => {});
    } else {
      setError(`prior2.${i}.clarification`, {
        message: "Request Clarification is required",
      });
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value, formet) => {
    setViewImage(value);
    if (formet === "xlsx") {
      const link = document.createElement('a');
      link.href = value;
      // link.download = 'excelfile.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else if(formet === "docx"){
      const link = document.createElement('a');
      link.href = value;
      // link.download = 'excelfile.docs';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ p: 3 }}>
      <div>
        <div className="table_main_div">
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            {prior2Fields && prior2Fields.length ? (
              <>
                {prior2Fields.map((data, index) => (
                  <div className="mb-3">
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="mb-0">
                          {index + 1}. {data.attribute}
                        </p>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        {data.value && (
                          <div className="ans-div">{data.value}</div>
                        )}
                        {data.fileUrl && (
                          <div className="my-2">
                            Attached Document :{" "}
                            <span
                              className="view-doc"
                              onClick={() =>
                                handleClickOpen(data.fileUrl, data.formet)
                              }
                            >
                              {data.formet === 'pdf' ? 'View & Download' : 'Download'}
                            </span>
                          </div>
                        )}
                        {data.fileUrl && data.formet && (
                          <>
                            {data.fileUrl && data.formet === "xlsx" ? (
                              <>
                                <span>(File Format : Excel)</span>
                              </>
                            ) : (
                              <>
                                <span>(File Format : PDF)</span>
                              </>
                            )}
                          </>
                        )}
                        {!data.patents &&
                          !data.competitors &&
                          !data.founders &&
                          !data.employee &&
                          !data.awards &&
                          !data.value &&
                          !data.value &&
                          !data.fileUrl && (
                            <>
                              <b>Not Provided</b>
                            </>
                          )}
                        {/* !data.value &&
                          data.patents &&
                          data.patents.length === 0 && */}
                        {data.patents && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Choose</TableCell>
                                    <TableCell>Stage</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.patents.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.name ? row.name : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.stage ? row.stage : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.number ? row.number : `-`}
                                      </TableCell>
                                      <TableCell sx={{ padding: "0px" }}>
                                        {row.docname ? (
                                          <span
                                            className="view-doc"
                                            onClick={() =>
                                              window.open(row.docname, "_blank")
                                            }
                                          >
                                            View
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.competitors && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>advantage</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.competitors.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.name ? row.name : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.advantage ? row.advantage : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.founders && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Education</TableCell>
                                    <TableCell>Experience</TableCell>
                                    <TableCell>companies Owned</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.founders.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.name ? row.name : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.education ? row.education : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.experience ? row.experience : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.companiesOwned
                                          ? row.companiesOwned
                                          : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                         {data.awards && (
                          <>
                          {data.awards && data.awards.map((award,i)=>(
                            <div key={i}>
                            <li>{award}</li>
                            </div>
                          ))}
                          </>
                         )}
                        {data.employee && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Responsibility</TableCell>
                                    <TableCell>Education</TableCell>
                                    <TableCell>
                                      Professional Experience
                                    </TableCell>
                                    <TableCell>Relevant Experience</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.employee.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.name ? row.name : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.role ? row.role : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.responsibility
                                          ? row.responsibility
                                          : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.education ? row.education : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.professionalExperience
                                          ? row.professionalExperience
                                          : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.relevantExperience
                                          ? row.relevantExperience
                                          : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                      </Grid>
                      {path === "scstfdd" &&
                      startupCheckList.status === 17 &&
                      startupCheckList.showfddVerified === false ? (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <div>
                              <FormControl>
                                <Controller
                                  name={`prior2.${index}.status`}
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      defaultValue="Innovation"
                                      name="radio-buttons-group"
                                      {...field}
                                      onChange={(e) =>
                                        handelVerifiedStatus(
                                          e.target.value,
                                          index
                                        )
                                      }
                                    >
                                      <span className="d-flex">
                                        {/* <FormControlLabel disabled={(getValues(`prior2.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" /> */}
                                        {/* {(
                                                  (data.fileUrl ) && (
                                                <FormControlLabel disabled={(getValues(`prior2.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" />
                                                  ))} */}
                                        <FormControlLabel
                                          disabled={
                                            getValues(
                                              `prior2.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false
                                          }
                                          value={2}
                                          control={<Radio />}
                                          label="More clarification"
                                        />
                                      </span>
                                    </RadioGroup>
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.prior2?.[index]?.status &&
                                    errors.prior2?.[index]?.status.message}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            {getValues(`prior2.${index}.status`) === 2 && (
                              <div className="form-group">
                                <Controller
                                  name={`prior2.${index}.clarification`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required:
                                      "Request Clarification is required",
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Request Clarification"
                                      placeholder="Enter Message"
                                      fullWidth
                                      multiline
                                      rows={3}
                                      onInput={(e) =>
                                        handleRequestValue(
                                          e.target.value,
                                          index
                                        )
                                      }
                                      inputProps={{
                                        readOnly:
                                          getValues(
                                            `prior2.${index}.status`
                                          ) === 2 && data.clarification
                                            ? true
                                            : false,
                                      }}
                                      {...field}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Button
                                              disabled={
                                                getValues(
                                                  `prior2.${index}.status`
                                                ) === 2 && data.clarification
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                handleRequestSubmit(index)
                                              }
                                            >
                                              <SendIcon />
                                            </Button>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.prior2?.[index]?.clarification &&
                                    errors.prior2?.[index]?.clarification
                                      .message}
                                </FormHelperText>
                              </div>
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <p className="mb-1">
                              Status : <b>{getBDDStatus(data.status)}</b>
                            </p>
                            {data.clarification && (
                              <p className="mb-1">
                                Clarification :{" "}
                                {data.clarificationAll ? (
                                  <ClarificationAll
                                    remarks={data.clarificationAll}
                                    lblName="Clarifications"
                                    btnName="View"
                                  />
                                ) : (
                                  <Scsthubavpremarks
                                    remarks={data.clarification}
                                    lblName="View"
                                  />
                                )}{" "}
                              </p>
                            )}
                            {data.response && (
                              <p className="mb-1">
                                Startup Response :{" "}
                                <Scsthubavpremarks
                                  remarks={data.response}
                                  lblName="View"
                                />
                              </p>
                            )}
                            {data.documentName && (
                              <p className="mb-1">
                                Startup Attached Document :{" "}
                                <Button
                                  onClick={() =>
                                    handleClickOpen(data.documentName)
                                  }
                                  className="text-cap"
                                >
                                  View
                                </Button>
                              </p>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <h4>FDD Document details is empty</h4>
                </div>
              </>
            )}
          </form>
        </div>
        <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        />
      </div>
    </Box>
  );
};

export default FDDPriorDocuments2;
