import React, { useContext, useState } from "react";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from '@mui/icons-material/Delete';

import { useForm, Controller } from "react-hook-form";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

import CustomLink from "../../common-page/CustomLink";
import OnBoradFilter from "./OnBoradFilter";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import UpdateStatus from "./UpdateStatus";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";
import notifyService from "../../../api/notifySerivce";
import { FaFileExport } from "react-icons/fa6";

const StartupOnboraded = ({ role }) => {


    const { setPageLoading, tableIndexValue, notificationChange } = useContext(DataContext);
    const [count, setCount] = useState(0);
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);

    const [scstHubList, setScstHubList] = useState([]);
    // const [districtName, setDistrictName] = useState("");
    // const [scsthub, setScsthub] = useState("");
    // const [yearName, setYearName] = useState(2022);
    // const [monthName, setMonthName] = useState(0);
    // const [manualName, setManualName] = useState(0)
    // const [searchInputValue, setSearchInputValue] = useState('');
    // const [statusValue, setStatusValue] = useState(0)
    var pageNumberPartners = Number(localStorage.getItem('pageScst'))
    const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

    var listSizePartners = Number(localStorage.getItem('rowsPerPageScst'))
    const [rowsPerPage, setRowsPerPage] = useState(listSizePartners ? listSizePartners : 10);



    var districtNameScst = localStorage.getItem('districtNameScst')
    const [districtName, setDistrictName] = useState(districtNameScst ? districtNameScst : 'All')

    var hubScst = localStorage.getItem('hubScst')
    const [scsthub, setScsthub] = useState(hubScst ? hubScst : "All");
    const d = new Date();
    let year = d.getFullYear();
    var yearNameScst = Number(localStorage.getItem('yearNameScst'))
    const [yearName, setYearName] = useState(yearNameScst ? yearNameScst : "2022");

    var toYearNameScst = Number(localStorage.getItem('toYearNameScst'))
    const [toYearName, setToYearName] = useState(toYearNameScst ? toYearNameScst : '2022');

    var monthNameScst = Number(localStorage.getItem('monthNameScst'))
    const [monthName, setMonthName] = useState(monthNameScst ? monthNameScst : 0);

    var toMonthNameScst = Number(localStorage.getItem('toMonthNameScst'))
    const [toMonthName, setToMonthName] = useState(toMonthNameScst ? toMonthNameScst : '');

    var manualNameScst = Number(localStorage.getItem('manualNameScst'))
    const [manualName, setManualName] = useState(manualNameScst ? manualNameScst : 0)

    var searchInputValueScst = localStorage.getItem('searchInputValueScst')
    const [searchInputValue, setSearchInputValue] = useState(searchInputValueScst ? searchInputValueScst : '');
    var searchInputApplicationValueScst = localStorage.getItem('searchInputApplicationValueScst')
    const [searchInputNumberValue, setSearchInputNumberValue] = useState(searchInputApplicationValueScst ? searchInputApplicationValueScst : '');

    var phaseValueScst = localStorage.getItem('phaseValueScst')
    const [phaseValue, setPhaseValue] = useState(phaseValueScst ? phaseValueScst : 'All')
    // console.log(phaseValueScst);

    var statusValueScst = Number(localStorage.getItem('statusValueScst'))
    const [statusValue, setStatusValue] = useState(statusValueScst ? statusValueScst : 100)
    const [searchName, setSearchName] = useState("");
    const [searchApplication, setSearchApplication] = useState("");
    useDidMountEffect(() => {
        var phaseValueScst = localStorage.getItem("phaseValueScst");
        setPhaseValue(phaseValueScst);
        var statusValueScst = Number(localStorage.getItem("statusValueScst"));
        setStatusValue(statusValueScst);
    }, [notificationChange]);

    useDidMountEffect(() => {
        (async () => await scstHublist())();
        // localStorage.removeItem('phaseValueScst')
        // eslint-disable-next-line
    }, [page, rowsPerPage, districtName, phaseValue, scsthub, yearName, toYearName, monthName, toMonthName, manualName, searchInputValue,searchInputNumberValue, phaseValue, statusValue]);

    const scstHublist = () => {
        setPageLoading(true)
        setScstHubList([]);
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            district: districtName,
            hub: scsthub,
            fromYear: yearName === 'All' ? 0 : yearName,
            toYear: toYearName === 'All' ? 0 : toYearName,
            fromMonth: monthName,
            toMonthName: toMonthName,
            phaseValue: phaseValue,
            onboardType: manualName,
            searchString: searchName !== "" ? searchInputValue : "",
            appNoSearchString:searchApplication !== "" ? searchInputNumberValue :"",
            status: statusValue
        }
        apiService("partner/scst/admin/startups/list", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                setCount(res.data.count);
                if (res.data.startups) {
                    setScstHubList(res.data.startups);
                } else {
                    setScstHubList([]);
                }
            }
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setCheckedList([])
        setItemsChecked(false);
        localStorage.setItem('pageScst', newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem('rowsPerPageScst', parseInt(event.target.value, 10))
        localStorage.setItem('pageScst', 0)
        setPage(0);
        setCheckedList([])
        setItemsChecked(false);
    };
    const [checkedList, setCheckedList] = useState([]);
    const [itemsChecked, setItemsChecked] = useState(false);
    const [indexValue, setIndexValue] = useState([])
    const handleCheckboxClick = (e, index) => {
        const { value, checked } = e.target;
        var num = Number(value)
        if (checked) {
            setCheckedList([...checkedList, value * 1]);
            setIndexValue([...indexValue, index])
            sectFgs()
        } else {
            setIndexValue(indexValue => indexValue.filter((item) => item !== index))
            setCheckedList(checkedList => checkedList.filter((item) => item !== num));
            if (checkedList.length === 1) {
                setCheckedList([])
            }
            setItemsChecked(false);
        }
    };
    const sectFgs = () => {
        if ((scstHubList.length - 1) === indexValue.length) {
            setItemsChecked(true);
        } else {
            setItemsChecked(false);
        }
    }
    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];
        if (checked) {
            for (const category of scstHubList) {
                collection.push(category.startupId);
            }
        }
        setCheckedList(collection);
        setItemsChecked(checked);
    };
    const resetCheckbox = () => {
        setItemsChecked(false);
        setCheckedList([])
    }
    // const updateStatus = () => {
    //     var data = {}
    //     var val = typeof checkedList
    //     debugger
    //     if (val === 'string') {
    //         data.startupId = [checkedList]
    //     } else {
    //         data.startupId = checkedList
    //     }
    //     resetCheckbox()
    // }
    const [openModal, setOpenModal] = useState(false);
    const [userId, setUserId] = useState([]);
    const handleOpenModal = (id) => {
        setOpenModal(true);
        setUserId(id);
    };
    const deleteUser = () => {
        //  userId;
        var data = [userId]
        apiService(`scst/user/remove`, data, "post")
            .then((result) => {
                if (result) {
                    if (result.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Success Deleted !!')
                        handleCloseModal();
                        scstHublist();
                    }
                }
            })
            .catch((err) => { });
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    useDidMountEffect(() => {
        setSearchInputValue('')
        setSearchInputNumberValue('')
    }, [])
    const [openExportModal, setOpenExportModal] = useState(false)
    const handleExportCloseModal = () => { setOpenExportModal(false) }
    const handleExportOpenModal = () => { setOpenExportModal(true) }
    const handleExport = () => {
        setPageLoading(true)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            district: districtName,
            hub: scsthub,
            fromYear: yearName === 'All' ? 0 : yearName,
            toYear: toYearName === 'All' ? 0 : toYearName,
            fromMonth: monthName,
            toMonthName: toMonthName,
            phaseValue: phaseValue,
            onboardType: manualName,
            searchString: searchInputValue,
            status: statusValue
        }
        apiService("admin/export/excel/mail", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                notifyService('success', 'Success', 'Export successfully !!')
                handleExportCloseModal()
            }
        });
    }

    return (
        <div>
            <OnBoradFilter
                setPage={setPage}
                resetCheckbox={resetCheckbox}
                searchName={searchName}
                searchApplication={searchApplication}
                setSearchName={setSearchName}
                setSearchApplication={setSearchApplication}
                districtName={districtName} setDistrictName={setDistrictName}
                scsthub={scsthub} setScsthub={setScsthub}
                yearName={yearName} setYearName={setYearName}
                toYearName={toYearName} setToYearName={setToYearName}
                monthName={monthName} setMonthName={setMonthName}
                toMonthName={toMonthName} setToMonthName={setToMonthName}
                manualName={manualName} setManualName={setManualName} path={role}
                searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
                searchInputNumberValue={searchInputNumberValue} setSearchInputNumberValue={setSearchInputNumberValue}
                phaseValue={phaseValue} setPhaseValue={setPhaseValue}
                statusValue={statusValue} setStatusValue={setStatusValue}
                scstHublist={scstHublist}
                lblName='SC/ST Startups'
            />
            {/* {
                (checkedList.length > 0 && (
                    <UpdateStatus startupId={checkedList} scstHublist={scstHublist} userListItemTanseed={resetCheckbox} />
                    // <Button className='mb-3' variant="outlined" onClick={() => updateStatus()}>Status Update</Button>
                ))
            } */}
            <div className="d-flex justify-content-start align-Items-start">
                <UpdateStatus startupId={checkedList} setItemsChecked={setItemsChecked} setCheckedList={setCheckedList} scstHublist={scstHublist} userListItemTanseed={resetCheckbox} />
                {scstHubList.length > 0 && (
                    <>
                {
                    role === 'scstadmin' && (<div className=" ml-auto my-2">

                        <button className="down-pdf-btn" onClick={() => handleExportOpenModal()} ><FaFileExport />Export</button>
                    </div>)}
                    </>
                )}
            </div>
            <div className="table_tag">

                {scstHubList.length ? (
                    <TableContainer component={Paper}>
                        {/* <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ float: 'left' }}
                            ActionsComponent={(paginationProps) => (
                                <CustomTablePaginationActions {...paginationProps} />
                            )}
                        />*/}
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {
                                        role === 'scstadmin' && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={itemsChecked} onClick={(e) => selectItem(e)}
                                                />
                                            </TableCell>
                                        )
                                    }
                                    <TableCell>#</TableCell>
                                    <TableCell>Startup Name</TableCell>
                                    <TableCell>Application Number</TableCell>
                                    <TableCell>District</TableCell>
                                    <TableCell>Hub</TableCell>
                                    <TableCell>Entity Type</TableCell>
                                    <TableCell>Onboarded Type</TableCell>
                                    <TableCell>Onboarded Date</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                    {
                                        role === 'scstadmin' && (
                                            <TableCell align="center">Action</TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scstHubList.map((row, index) => (
                                    <TableRow key={index}>
                                        {
                                            role === 'scstadmin' && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        item={row}
                                                        value={row.startupId}
                                                        checked={checkedList.includes(row.startupId)}
                                                        onChange={e => handleCheckboxClick(e, index)}
                                                    />
                                                </TableCell>
                                            )
                                        }
                                        <TableCell>
                                            {
                                                tableIndexValue(rowsPerPage, page, index)
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <CustomLink to={`/${role}/startups/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink>
                                        </TableCell>
                                        <TableCell>{row.applicationNo?? `-`}</TableCell>
                                        <TableCell>{row.district}</TableCell>
                                        <TableCell>{row.investorName}</TableCell>
                                        <TableCell>{row.entityType}</TableCell>
                                        <TableCell>
                                            {
                                                row.onboardType === 1 ? 'Manual' : 'Portal'
                                            }
                                        </TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell align='center'>{row.status}</TableCell>
                                        {
                                            role === 'scstadmin' && (
                                                <TableCell align="center">
                                                    <DeleteIcon
                                                        style={{ fill: "#D32F2F", cursor: "pointer" }}
                                                        onClick={() => handleOpenModal(row.startupId)}
                                                    />
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                        <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ float: 'left' }}
                            ActionsComponent={(paginationProps) => (
                                <CustomTablePaginationActions {...paginationProps} />
                            )}

                        />
                    </TableContainer>
                ) : (
                    <div className="nodata_tag">
                        <img
                            src="/images/Tanfundlogo-black-1.png"
                            className="no_data_logo"
                            alt=""
                        />
                        <h4>No Records Found</h4>
                    </div>
                )}
            </div>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/alert.png" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={deleteUser}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openExportModal}
                // onClose={handleExportCloseModal} 
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention -man.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleExportCloseModal}
                    >
                        Back
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={() => handleExport()}>
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default StartupOnboraded

function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPage />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPage />
            </IconButton>
        </div>
    );
}
