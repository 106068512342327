import { Button, FormHelperText, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import apiService from '../../../../../api/apiService';
import DataContext from '../../../../../context/DataContext';
import notifyService from '../../../../../api/notifySerivce';

const ProposedAction = ({reasonAll,idNumber,proposeID,handleCloseModal,historyList,tabVal}) => {
    debugger
    const [formData, setFormData] = useState({});
    const { setPageLoading, fullScreen } = useContext(DataContext)
    const { handleSubmit, getValues, setValue, clearErrors, reset, control, formState: { errors } } = useForm(
        { values: formData, }
    );
    const onSubmit =  (data) => {
        var getform = { ...data };  // Ensure a new object is created

        // Assign values correctly
        getform.startupId = idNumber;
        getform.id = proposeID;
        getform.status = 4;
        setPageLoading(true)
        apiService('cp/partner/propose/action/save', getform, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success" || res.data.responseStatus === "Saved Successfully") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    handleCloseModal()
                    historyList()
                }
            }
        })

    }
    const isWhitespace = (value) => {
        return /^\s*$/.test(value);
    };
    return (
        <>
        {tabVal === 'tabval4' ? (<>
            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-3">
                <Controller
                    name="proposeAction"
                    control={control}
                    defaultValue={reasonAll} 
                    rules={{ required: "Propose Action is required",
                        validate: {
                            noWhitespace: (value) =>
                                !isWhitespace(value) || "Whitespace not allowed",
                        },
                     }}
                    render={({ field }) => (
                        <TextField
                            variant="outlined"
                            label="Enter Propose Action *"
                            placeholder="Enter Propose Action"
                            {...field}
                            type="text"
                            fullWidth
                            multiline
                            rows={3}
                        />
                    )}
                />
                <FormHelperText className='text-danger'>{errors.proposeAction && errors.proposeAction.message}</FormHelperText>
            </div>
            <div className='text-center'>
            <Button
                variant="contained"
              onClick={handleCloseModal}
            >
                <span>cancel</span>
            </Button>
            <Button
            className='ml-2'
                variant="contained"
                type='submit'
            >
                <span>Submit</span>
            </Button>
            </div>
        </form>
        </>):(<>
        <h6>{reasonAll}</h6>
        </>)}
        </>
        
    )
}

export default ProposedAction