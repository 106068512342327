import React, { useContext, useEffect, useState } from 'react'
import apiService from '../../../../../api/apiService'
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../../../api/notifySerivce';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormHelperText, Tab, TablePagination, TextField, Tooltip } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';
import Viewimage from '../../../../../api/Viewimage';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import Additionalinfo from '../../../../startup-module/additional-info-page/Additionalinfo';
import DataContext from '../../../../../context/DataContext';
import Resubmit from './Resubmit';
import ClarificationAll from '../../../../scstBdd-page/scstBdd-details/ClarificationAll';
import { FaEye } from "react-icons/fa";
import Cpresubmit from './Cpresubmit';
import ProposedAction from './ProposedAction';
import CpDocuments from '../../../../scst-page/cp-cs-documents/CpDocuments';
const CpMoreinfoSave = ({ startupCheckList, idNumber, detail, viewStartupDetails, path, showStartupValue }) => {
    debugger
    const { setPageLoading } = useContext(DataContext)
    const [historyTabValue, setHistoryTabValue] = React.useState('1');
    const handleHistoryChangeInfo = (event, newValue) => {
        setHistoryTabValue(newValue);
        setAddInfoValue('1')
    };
    const [addInfoValue, setAddInfoValue] = React.useState('1');
    const handleChangeInfo = (event, newValue) => {
        setAddInfoValue(newValue);
        debugger
    };
    const [formData, setFormData] = useState({});

    const { handleSubmit, getValues, setValue, clearErrors, reset, control, formState: { errors } } = useForm(
        { values: formData, }
    );
    const [statusValue, setStatusValue] = useState(5)
    const [page, setPage] = useState(0);
    useDidMountEffect(() => {
        historyList()
        showStartupValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusValue, page]);

    useDidMountEffect(() => {
        // infoGetInfo()
        if ((path === 'scstldd' && startupCheckList.lddCpCompleted === false && startupCheckList.status === 20 || startupCheckList.status === 31)  || (path === 'scstfdd' && startupCheckList.fddCpCompleted === false && startupCheckList.status === 20 || startupCheckList.status === 31)) {
          
            // setStatusValue(5)
            handleStatus(5)
            setAddInfoValue('1')
        } else {
            // setStatusValue(0)
            handleStatus(0)
            setAddInfoValue('2')
        }
    }, []);
    const [viewFile, setViewFile] = useState(undefined)
    const infoGetInfo = () => {
        var id = Number(idNumber)
        apiService(`partner/additionalinfo/get?id=${id}`, '', 'get').then((res) => {
            if (res) {
                setFormData(res.data)
                setViewFile(res.data.additionalDocName)
            }
        })
    }
    const reasonList = [
        'Application Details',
        'Funding & Financial Details',
        'Founder Informations',
        'Incubation/Acceleration Details'
    ]
    const [reasonName, setReasonName] = useState('')
    const handleReason = (value) => {
        setValue('reason', value);
        clearErrors('reason')
        setReasonName(value)
    };
    const handleStatus = (value) => {
        setStatusValue(value)
    }
    const [count, setCount] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [historyListValue, setHistoryListValue] = useState([])
    const historyList = () => {
        setHistoryListValue([])
        setPageLoading(true)
        var id = Number(idNumber)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            startupId: id,
            userId: localStorage.getItem('userId'),
            status: statusValue
        }
        apiService('cp/partner/startups/additionalinfo/history', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setCount(res.data.count)
                if (res.data.additionalInfo) {
                    setHistoryListValue(res.data.additionalInfo)
                }
            }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [startupCheckLists, setStartupCheckLists] = useState([])
    const showStartupValues = async () => {
        apiService(`startup/clarifications/status/get?id=${idNumber}`, '', 'get').then((res) => {

            if (res) {
                if (res.data) {
                    debugger
                    setStartupCheckLists(res.data)
                }
            }
        })
    }


    const [loading, setLoading] = useState(false);
    // const onSubmit = (data) => {
    //     debugger

    //     var getform = data
    //     getform.startupId = Number(idNumber)
    //     getform.status = 0
    //     getform.userId = localStorage.getItem('userId')
    //     setLoading(true)
    //     apiService('cp/partner/additionalinfo/save', getform, 'post').then((res) => {

    //         if (res && res.data) {
    //             if (res.data.responseStatus === "Success") {
    //                 debugger
    //                 setLoading(false);
    //                 notifyService('success', 'Success', 'Updated successfully !!')
    //                 reset()
    //                 setAddInfoValue('1')
    //                 setRish([])
    //                 // setValue('risk',)
    //                 setReasonName('')
    //                 showStartupValue()
    //                 viewStartupDetails()
    //                 setStatusValue(0)
    //                 historyList()
    //                 debugger
    //             }
    //         }
    //     })
    // }
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data) => {
        if (isSubmitting) return;  // Prevent multiple calls
        setIsSubmitting(true);

        try {
            setLoading(true);

            let getform = { ...data };
            getform.startupId = Number(idNumber);
            getform.status = 0;
            getform.userId = localStorage.getItem('userId');

            const res = await apiService('cp/partner/additionalinfo/save', getform, 'post');

            if (res?.data?.responseStatus === "Success") {
                notifyService('success', 'Success', 'Updated successfully !!');
                reset();
                setAddInfoValue('1');
                setRish([]);
                setReasonName('');
                showStartupValue();
                viewStartupDetails();
                setStatusValue(5);
                historyList();
            }
        } catch (error) {
            console.error("Error submitting form", error);
        } finally {
            setLoading(false);
            setIsSubmitting(false);  // Reset after API call
        }
    };

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handelMarkVerifed = (id) => {
        setLoading(true)
        var req = {
            id: id,
            status: 2
        }
        debugger
        apiService(`cp/partner/startups/additionalinfo/status/save`, req, 'post').then((res) => {
            setLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Updated successfully !!')
                    viewStartupDetails()
                    showStartupValue()
                    // setStatusValue(0)
                    historyList()
                }
            }
        })
    }
    const Round = localStorage.getItem("startupRoundStatus")
    const roles = localStorage.getItem('role')
    const Risk = ["Low", "Medium", "High"]
    const [rish, setRish] = useState([])
    const handleSelectRisk = (event, selectedOption) => {

        setRish(selectedOption)
        setValue("risk", selectedOption);
        clearErrors('risk')
    }
    const isWhitespace = (value) => {
        return /^\s*$/.test(value);
    };
    const [isFullTextVisible, setIsFullTextVisible] = useState({})
    const toggleText = (index) => {
        debugger
        setIsFullTextVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
        debugger
    };
    const [isFullTextVisible2, setIsFullTextVisible2] = useState({})
    const toggleText2 = (index) => {
        setIsFullTextVisible2(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const [isFullTextVisible3, setIsFullTextVisible3] = useState({})
    const toggleText3 = (index) => {
        setIsFullTextVisible3(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const [isFullTextVisible4, setIsFullTextVisible4] = useState({})
    const toggleText4 = (index) => {
        setIsFullTextVisible4(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const truncateTexts = (text, charLimit) => {
        if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
        return text.length > charLimit ? text.substring(0, charLimit) + ".." : text;
    };
    const [openModal, setOpenModal] = useState(false)
    const [reasonAll, setReasonAll] = useState()
    const [proposeID, setProposeID] = useState()
    const [tabVal, setTabVal] = useState()
    const handlereasonModal = (value, id, tabVal) => {
        debugger
        setReasonAll(value)
        setOpenModal(true)
        setProposeID(id)
        setTabVal(tabVal)
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const [openModal2, setOpenModal2] = useState(false);
    const [clarificationList, setClarificationList] = useState();
    const handleOpenModal = (val) => {
        setOpenModal2(true);
        setClarificationList(val)
    }
    const handleCloseModal2 = () => {
        setOpenModal2(false);
    };
    const onSave = (data) => {
        debugger
        console.log(data);

    }
    return (
        <main>
            <section>


                <div>
                   
                    <TabContext value={addInfoValue}>
                        <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                            <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                                {((path === 'scstldd' && startupCheckList.lddCpCompleted === false && startupCheckList.status === 20 || startupCheckList.status === 31) || (path === 'scstfdd' && startupCheckList.fddCpCompleted === false && startupCheckList.status === 20 || startupCheckList.status === 31)) && (
                                    <Tab className="verticalLable" label="Raise Clarification" value="1" onClick={() => handleStatus(5)} />
                                )}


                                <Tab className="verticalLable" label="Clarification Requested" value="2" onClick={() => handleStatus(0)} />
                                {roles === 'LDD' && (
                                    <Tab
                                        className="verticalLable"
                                        label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                Management Responded
                                                {startupCheckList.additionalInfoLddCpRequested === true ? (
                                                    <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                ) : (<></>)}
                                            </div>
                                        }
                                        value="3" onClick={() => handleStatus(1)} />
                                )}
                                {roles === 'FDD' && (
                                    <Tab className="verticalLable"
                                        label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                Management Responded
                                                {startupCheckList.additionalInfoFddCpRequested === true ? (
                                                    <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                ) : (<></>)}
                                            </div>
                                        }
                                        value="3" onClick={() => handleStatus(1)} />
                                )}

                                <Tab className="verticalLable" label="Proposed Action" value="4" onClick={() => handleStatus(4)} />
                                <Tab className="verticalLable" label="Clarified - Closed" value="5" onClick={() => handleStatus(3)} />
                                <Tab className="verticalLable" label="CP Documents" value="6"  />
                            </TabList>
                         
                        </Box>
                        <Divider />
                        <div className="mt-3">
                            {(statusValue === 5 && addInfoValue !== "6" )&&
                                <>
                                   
                                    {
                                         ((path === 'scstldd' && startupCheckList.lddCpCompleted === false) && (startupCheckList.status === 20 || startupCheckList.status === 31) || (path === 'scstfdd' && startupCheckList.fddCpCompleted === false) && (startupCheckList.status === 20 || startupCheckList.status === 31)) && (
                                            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                                                <div className='row align-item-center p-3'>

                                                    <div className='col-lg-6 col-md-6 col-12 mt-3'>
                                                        <Controller name="diligenceArea" control={control} defaultValue="" rules={{
                                                            required: 'Area of Diligence is requried',
                                                            validate: {
                                                                noWhitespace: (value) =>
                                                                    !isWhitespace(value) || "Whitespace not allowed",
                                                            },
                                                        }}
                                                            render={({ field }) =>
                                                                <TextField
                                                                    variant='outlined'
                                                                    label="Area of Diligence *"
                                                                    placeholder='Enter Area of Diligence'
                                                                    fullWidth
                                                                    {...field}
                                                                />} />
                                                        <FormHelperText className='text-danger'>{errors.diligenceArea && errors.diligenceArea.message}</FormHelperText>

                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-12 mt-3'>
                                                        <Controller name="observations" control={control} defaultValue="" rules={{
                                                            required: 'Observations is requried',
                                                            validate: {
                                                                noWhitespace: (value) =>
                                                                    !isWhitespace(value) || "Whitespace not allowed",
                                                            },
                                                        }}
                                                            render={({ field }) =>
                                                                <TextField
                                                                    variant='outlined'
                                                                    label="Observations *"
                                                                    placeholder='Enter Observations'
                                                                    fullWidth
                                                                    {...field}
                                                                />} />
                                                        <FormHelperText className='text-danger'>{errors.observations && errors.observations.message}</FormHelperText>

                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-12 mt-3'>
                                                        <Controller name="impact" control={control} defaultValue="" rules={{
                                                            required: 'Impact is requried',
                                                            validate: {
                                                                noWhitespace: (value) =>
                                                                    !isWhitespace(value) || "Whitespace not allowed",
                                                            },
                                                        }}
                                                            render={({ field }) =>
                                                                <TextField
                                                                    variant='outlined'
                                                                    label="Impact *"
                                                                    placeholder='Enter Impact'
                                                                    fullWidth
                                                                    {...field}
                                                                />} />
                                                        <FormHelperText className='text-danger'>{errors.impact && errors.impact.message}</FormHelperText>

                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-12 mt-3'>
                                                        <Controller
                                                            name="risk"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: "Risk is required" }}
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    disableClearable
                                                                    disablePortal
                                                                    {...field}
                                                                    value={rish}
                                                                    options={Risk}
                                                                    onChange={handleSelectRisk}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label="Risk *" fullWidth />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText className='text-danger'>{errors.risk && errors.risk.message}</FormHelperText>

                                                    </div>
                                                    <div className='col-lg-12 col-md-6 col-12 mt-3'>

                                                        <Controller name="clarification" control={control} defaultValue="" rules={{
                                                            required: 'Clarification is requried',
                                                            validate: {
                                                                noWhitespace: (value) =>
                                                                    !isWhitespace(value) || "Whitespace not allowed",
                                                            },
                                                        }}
                                                            render={({ field }) =>
                                                                <TextField
                                                                    variant='outlined'
                                                                    label="Clarification to be requested from Startup (Only this field will be visible to Startup) *"
                                                                    placeholder='Enter Clarification to be requested'
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    {...field}
                                                                />} />
                                                        <FormHelperText className='text-danger'>{errors.clarification && errors.clarification.message}</FormHelperText>

                                                    </div>
                                                    <div className='col-lg-12 col-md-6 col-12 text-center mt-2'>
                                                        <Button
                                                            type="submit"
                                                            loading={loading}
                                                            loadingPosition="start"
                                                            startIcon={<SendIcon />}
                                                            variant="contained"
                                                        >
                                                            <span>Request</span>
                                                        </Button>
                                                    </div>

                                                    {/* {
                                    getValues(`startupComments`) && (
                                        <div className='col-lg-12 col-12 mt-3'>
                                            <p>Comments from Startups : <b>{getValues(`startupComments`)}</b></p>
                                        </div>
                                    )
                                } */}
                                                </div>
                                            </form>
                                        )
                                    }
                                    {((path === 'scstldd' && startupCheckList.lddCpCompleted === true &&  startupCheckList.status === 31) || (path === 'scstfdd' && startupCheckList.fddCpCompleted === true &&  startupCheckList.status === 31)) &&(
                                        <>
                                       <div className='nodata_tag'>
                                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                            <p>No Clarification Requests Found</p>
                                        </div>
                                        </>
                                    )}
                                </>
                            }
                            {(statusValue !== 5 && addInfoValue !== "6" ) &&
                                <>
                                    {historyListValue.length > 0 &&
                                        (historyListValue.length) ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>No</TableCell>
                                                        {/* <TableCell>Reason</TableCell> */}

                                                        <TableCell align='center'>Area Diligence</TableCell>

                                                        {/* {
                                                    (statusValue === 1 || statusValue === 4) && <TableCell align='center'>Propose Action</TableCell>
                                                } */}
                                                        {
                                                            statusValue === 2 && <TableCell align='center'>Time</TableCell>
                                                        }
                                                        {/* {
                                                    statusValue === 1 && <TableCell align='center'>Attached Document</TableCell>
                                                } */}
                                                        {/* {
                                                    (statusValue === 2 || statusValue === 4) && <TableCell align='center'>View Document</TableCell>
                                                } */}
                                                        {/* {
                                                    (statusValue === 1 || statusValue === 4) && <TableCell align='center'>Action</TableCell>
                                                } */}




                                                        <TableCell align='center'>Observations</TableCell>
                                                        <TableCell align='center'>Impact</TableCell>
                                                        <TableCell align='center'>Risk</TableCell>
                                                        <TableCell align='center'>Clarification Requested</TableCell>
                                                        {
                                                            (statusValue === 1 || statusValue === 3 || statusValue === 4) &&
                                                            <TableCell align='center'>Proposed Action</TableCell>
                                                        }
                                                        {
                                                            (statusValue === 1 || statusValue === 4 || statusValue === 3) && <TableCell align='center'>Attached Document</TableCell>
                                                        }
                                                        {
                                                            (statusValue === 1 || statusValue === 4) && <TableCell align='center'>Action</TableCell>
                                                        }
                                                        {/* <TableCell align='center'>Action</TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {historyListValue.map((list, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>


                                                            <TableCell align="center">
                                                                {list.diligenceArea ? (
                                                                    <>
                                                                        {isFullTextVisible[index]
                                                                            ? list.diligenceArea
                                                                            : truncateTexts(list.diligenceArea || '', 8)}

                                                                        {list.diligenceArea && list.diligenceArea.length > 8 && (
                                                                            <h6
                                                                                onClick={() => toggleText(index)}
                                                                                style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                            >
                                                                                {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                                                            </h6>
                                                                        )}
                                                                    </>) : ((`-`))}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {list.observations ? (
                                                                    <>
                                                                        {isFullTextVisible2[index] ? list.observations : truncateTexts(list.observations || '', 8)}
                                                                        {list.observations && list.observations.length > 8 && (<h6
                                                                            onClick={() => toggleText2(index)}
                                                                            style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                        >
                                                                            {isFullTextVisible2[index] ? " Read Less" : " Read More"}
                                                                        </h6>)}
                                                                    </>) : ((`-`))}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {list.impact ? (
                                                                    <>
                                                                        {isFullTextVisible3[index] ? list.impact : truncateTexts(list.impact || '', 8)}
                                                                        {list.impact && list.impact.length > 8 && (<h6
                                                                            onClick={() => toggleText3(index)}
                                                                            style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                        >
                                                                            {isFullTextVisible3[index] ? " Read Less" : " Read More"}
                                                                        </h6>)}
                                                                    </>) : ((`-`))}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {list.risk ? (
                                                                    <>
                                                                        {list.risk}
                                                                    </>) : ((`-`))}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <img src="/images/chat.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleOpenModal(list.clarificationList)} className="mr-2" />
                                                                {/* {list.clarificationList ? (
                                                            <>
                                                                {list.clarificationList && (
                                                                    <>
                                                                        {isFullTextVisible4[index] ? list.clarificationList : truncateTexts(list.clarificationList || '', 10)}
                                                                        {list.clarificationList && list.clarificationList.length > 10 && (<span
                                                                            onClick={() => toggleText4(index)}
                                                                            style={{ cursor: "pointer", color: "#7F9AD2", }}
                                                                        >
                                                                            {isFullTextVisible4[index] ? " Read Less" : " Read More"}
                                                                        </span>)}
                                                                    </>
                                                                )}
                                                            </>) : ((`-`))} */}
                                                            </TableCell>


                                                            {
                                                                (statusValue === 3) &&
                                                                <TableCell align="center" className="c-pointer">
                                                                    {list.proposeAction ? (
                                                                        <img src="/images/drafts.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handlereasonModal(list.proposeAction, list.id, 'tabval3')} />
                                                                    ) : (`-`)}

                                                                </TableCell>
                                                            }
                                                            {
                                                                (statusValue === 4) &&
                                                                <TableCell align="center" className="c-pointer">
                                                                    {list.proposeAction ? (
                                                                        <img src="/images/drafts.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handlereasonModal(list.proposeAction, list.id, 'tabval4')} />
                                                                    ) : (`-`)}

                                                                </TableCell>
                                                            }

                                                            {
                                                                (statusValue === 1) && (
                                                                    <TableCell align='center'>
                                                                        <Cpresubmit id={list.id} idNumber={idNumber} historyList={historyList} name="cp" statusValue={statusValue} showStartupValue={showStartupValue} cpBtn='cpBtn' proposeAction={'proposeAction'} />
                                                                    </TableCell>
                                                                )
                                                            }

                                                            {
                                                                (statusValue === 2 || statusValue === 4 || statusValue === 3) && <TableCell align='center'>
                                                                    {/* <button className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</button> */}
                                                                    <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickOpen(list.document)} />
                                                                </TableCell>
                                                            }
                                                            {
                                                                statusValue === 1 && <TableCell align='center'>
                                                                    {/* <button className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</button> */}
                                                                    <img src="/images/pdf.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickOpen(list.document)} />
                                                                </TableCell>
                                                            }

                                                            {
                                                                (statusValue === 1 || statusValue === 4 || statusValue === 3) && (
                                                                    <>
                                                                        <TableCell align='center'>

                                                                            {
                                                                                (statusValue === 4) && (
                                                                                    <Cpresubmit id={list.id} idNumber={idNumber} historyList={historyList} name="cp" statusValue={statusValue} showStartupValue={showStartupValue} cpBtn='cpBtn' />
                                                                                )
                                                                            }
                                                                            {
                                                                                (statusValue === 1 || statusValue === 4) && (
                                                                                    <Tooltip title="Mark as Clarified & Closed">
                                                                                        <img src="/images/disk.png" alt="" style={{ cursor: 'pointer', width: '20px', marginLeft: '10px' }} onClick={() => handelMarkVerifed(list.id)} />
                                                                                        {/* <i
                                                                                    className="fas fa-pencil-alt"
                                                                                   
                                                                                    style={{ color: 'green', cursor: 'pointer', fontSize: '18px' }}
                                                                                ></i> */}
                                                                                    </Tooltip>
                                                                                    // <button className='verified_btn' onClick={() => handelMarkVerifed(list.id)}>Mark as Clarified & Closed</button>
                                                                                )}
                                                                        </TableCell>
                                                                    </>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <caption>
                                                    <TablePagination
                                                        component="div"
                                                        count={count}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        style={{ float: 'left' }}
                                                    />
                                                </caption>
                                            </Table>

                                        </TableContainer>
                                    ) : (
                                        <>
                                       {addInfoValue !== "6" &&(<div className='nodata_tag'>
                                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                            <p>No Records Found</p>
                                        </div>)}
                                        </>
                                    )
                                    }
                                </>
                            }
                        </div>
                        <TabPanel value="6"><CpDocuments idNumber={idNumber}/></TabPanel>
                    </TabContext>
                    {/* <div className='div_header'>
                        <h6 className='mt-5' style={{ color: '#313131' }}>History</h6>
                    </div> */}

                </div>
            </section>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth={"md"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                    <h4 className="">Proposed Action:</h4>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {/* <ol>
                            {reasonAll && reasonAll.map((reason, index) => (
                                <li key={index}>{reason ? reason : ""}</li>
                            ))}
                        </ol> */}
                        {/* <ol>
                            {reasonAll &&
                                [...reasonAll].reverse().map((reason, index) => (
                                    <li key={index}>{reason || ""}</li>
                                ))}
                        </ol> */}
                        {/* <h5 style={{color:'black'}}>{reasonAll}</h5> */}

                        {/* <form className="signin-form" onSubmit={handleSubmit(onSave)}>
                            <div className="form-group mt-3">
                                <Controller
                                    name="proposeAction"
                                    control={control}
                                    defaultValue={reasonAll}  // ✅ Default value from state/props
                                    rules={{ required: "Propose Action is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Enter Propose Action"
                                            placeholder="Enter Propose Action"
                                            {...field}
                                            type="text"
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                        </form>

                        <Button
                            variant="contained"
                            onClick={() => handleSubmit(onSave)({ proposeAction: reasonAll })} // ✅ Ensuring default value is included
                        >
                            <span>Submit</span>
                        </Button> */}
                        <ProposedAction reasonAll={reasonAll} idNumber={idNumber} proposeID={proposeID} handleCloseModal={handleCloseModal} historyList={historyList} tabVal={tabVal} />




                    </DialogContentText>
                </DialogContent>

            </Dialog>
            <Dialog
                open={openModal2}
                onClose={handleCloseModal2}
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3" style={{ color: 'black' }}>Clarification Requested</h4>

                        <ol>
                            {Array.isArray(clarificationList) && clarificationList.slice().reverse().map((reason, index) => (
                                <li style={{ color: 'black' }} key={index}>{reason || ""}</li>
                            ))}
                        </ol>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </main>

    )
}

export default CpMoreinfoSave