import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DataContext from "../../../context/DataContext";
import "./adminnavbar.css";

const Adminnavbar = () => {
  const {
    setActiveStartup,
    setActive,
    setInvestorProfileType,
    setSearchInputValue,
    adminStartupRemoveFilter,
  } = useContext(DataContext);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const investorHandle = () => {
    setActive(1);
    setInvestorProfileType(2);
    setSearchInputValue("");
  };

  const handleReset = () => {
    setSearchInputValue("");
  };

  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
  };

  const navItems = [
    { name: "Dashboard", path: "/admin/dashboard" },
    { name: "Investors", path: "/admin/investor", action: investorHandle },
    { name: "Jury", path: "/admin/jury" },
    {
      name: "Startups",
      path: "/admin/startup",
      action: () => setActiveStartup(1),
    },
    { name: "Associated Startups", path: "/admin/associated-startups" },
    { name: "TANSEED", path: "/admin/tanseed" },
    { name: "Reset Password", path: "/admin/resetpassword" },
  ];

  return (
    <>
      {/* Top AppBar for Navbar */}
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        {/* <Toolbar> */}
        {/* Hamburger Menu Button for Mobile */}
        <IconButton
          color="primary"
          edge="start"
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        {/* Desktop Navigation Links */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 1,
            marginLeft: "20px",
          }}
        >
          {navItems.map(({ name, path, action }) => (
            <NavLink
              key={name}
              className="nav-link"
              to={path}
              onClick={(e) => {
                handleClick(e);
                adminStartupRemoveFilter();
                action?.();
              }}
            >
              {name}
            </NavLink>
          ))}
        </Box>
        {/* </Toolbar> */}
      </AppBar>

      {/* Mobile Drawer (Sidebar) */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ "& .MuiDrawer-paper": { width: 250 } }}
      >
        <List>
          {navItems.map(({ name, path, action }) => (
            <ListItem
              button
              key={name}
              onClick={(e) => {
                handleClick(e);
                adminStartupRemoveFilter();
                action?.();
                setMobileOpen(false);
              }}
            >
              <NavLink className="nav-link" to={path} style={{ width: "100%" }}>
                <ListItemText primary={name} />
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Adminnavbar;
