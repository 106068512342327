import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import './scst-page/scst.css'
import { NavLink } from "react-router-dom";

const MentorshipNavbar = () => {
    const handleClick = (event) => {
      debugger
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
        }
        localStorage.setItem('mentorNav',event)
      };
      return (
        <nav>
          <div className="submenu_div">
            <div className="container-fluid">
              <Box
                sx={{
                  display: "flex",
                  paddingTop: "5px",
                  textAlign: "center",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
              >
                <Typography sx={{ minWidth: 100 }}>
                  <NavLink
                    className="nav-link"
                    to={`/scstmentorship/startups/list/current`}
                    onClick={() => handleClick('current')}
                  >
                    Currently Mentoring
                  </NavLink>
                </Typography>
                <Typography sx={{ minWidth: 100 }}>
                  <NavLink
                    className="nav-link"
                    to={`/scstmentorship/startups/list/previous`}
                    onClick={() => handleClick('previous')}
                  >
                   Previously Mentored
                  </NavLink>
                </Typography>
              </Box>
            </div>
          </div>
        </nav>
      );
    };

export default MentorshipNavbar