import React, { useContext, useState } from 'react'
import { alpha, Box, Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, InputBase, styled, Tab, Tabs, TextField } from '@mui/material'
import PropTypes from "prop-types";
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
import notifyService from '../../../api/notifySerivce';
import DocumentRoomAdminList from './DocumentRoomAdminList';
import DocumentRoomAdminAll from './DocumentRoomAdminAll';
import SearchIcon from "@mui/icons-material/Search";
import useDidMountEffect from '../../../hooks/useDidMountEffect';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const SearchinputDiv = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "23ch",
            background: '#2e55d14f',
            "&:focus": {
                width: "25ch",
            },
        },
    },
}));

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}


const DocumentRoomAdmin = ({ startupId }) => {
    const [tabvalue, setTabValue] = useState(0);
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({});
    const [page, setPage] = useState(0);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchName, setSearchName] = useState("");
    const { setPageLoading } = useContext(DataContext)
    const [listData, setListData] = useState([])
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [status, setStatus] = useState(5)
    const [startupname, setStartupname] = useState('')
    useDidMountEffect(() => {
        getApi()
    }, []);

    useDidMountEffect(() => {
        listApi();
    }, []);
    const { handleSubmit, getValues, control, clearErrors, setValue, reset, formState: { errors }, } = useForm({ values: formData });

    const getApi = () => {
        setPageLoading(true)
        var req = {
            "startupId": startupId,
        }
        apiService('document/room/email/startupname/get', req, "post")
            .then((res) => {
                if (res && res.data) {
                    setStartupname(res.data)
                }
            }).catch((err) => {
                console.log(err);

            });
    }
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setSearchInputValue("")
        setSearchName("")
        if (newValue === 0) {
            setStatus(5)
        } else if (newValue === 1) {
            setStatus(0)
        } else if (newValue === 2) {
            setStatus(4)
        }
        debugger
    };
    const handleOpenDialog = () => {
        setOpen(true)
        reset()
    }
    const handleCloseDialog = () => {
        setOpen(false)
        clearErrors('subject')
        clearErrors('body')
        reset()
    }

    const listApi = () => {
        setPageLoading(true)
        setListData([])
        var req = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "searchString": searchInputValue,
            "startupId": startupId,
            "status": status
        }
        debugger
        apiService('document/room/details/list', req, 'post')
            .then((res) => {
                setPageLoading(false)
                if (res && res.data) {
                    setCount(res.data.count)
                    if (res.data.documentRoomDetailsList) {

                        res.data.documentRoomDetailsList.forEach((element) => {

                            if (element.docUrl) {
                                var val = element.docUrl;
                                var data = val.split("?");
                                if (data) {
                                    var formet = data[0].split(".");
                                    element.formet = formet[4];
                                }
                            }
                        });
                        setListData(res.data.documentRoomDetailsList)
                    }
                }

            }).catch((err) => {
                console.log(err);

            });

    }
    const onSubmit = (data) => {
        console.log(data);
        setPageLoading(true)
        var req = {
            ...data,
            status: 0,
            startupId: startupId,
        };
        debugger
        apiService('document/room/request/reponse/save', req, 'post')
            .then((res) => {
                setPageLoading(false)
                if (res && res.data && res.data.responseStatus === "Success") {
                    notifyService("success", "Success", "Saved successfully !!");
                    handleCloseDialog()
                    reset()
                    setTabValue(1);
                    setStatus(0)
                    listApi()
                }
            }).catch((err) => {
                console.log(err);
            });
    }
    const handleSearch = (searchValue) => {
        if (searchValue.length > 3) {
            setSearchInputValue(searchValue);
            setPage(0);
        } else {
            setSearchInputValue("");
            setPage(0);
        }
        setSearchName(searchValue);
    };
    const isWhitespace = (value) => {
        return /^\s*$/.test(value);
    };
    return (
        <div>

            <div className="table_header_tag set-res-flex align-item-center">
                {/* {tabvalue !== 0 ? ( */}
                <div>
                    <SearchinputDiv
                        sx={{ display: { xs: "none", sm: "block" } }}
                        onChange={(e) => handleSearch(e.target.value)}
                    >
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            value={searchName}
                            type="search"
                            placeholder="Search by Subject / Body"
                            inputProps={{ "aria-label": "search" }}
                        />
                    </SearchinputDiv>
                </div>
                {/* ) : (<></>)} */}
                <div className="ml-auto">
                    <Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    background: 'linear-gradient(45deg, #2196F3, #0D47A1)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #1976D2, #0D47A1)',
                                    }
                                }}
                                onClick={() => handleOpenDialog()}
                            >
                                Request Document
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="overallTab mt-2">
                <Tabs
                    className="verticalTabCSS"
                    orientation="horizontal"
                    variant="scrollable"
                    value={tabvalue}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                >


                    <Tab
                        className="verticalLable"
                        label="All"
                        {...a11yProps(0)}
                    />
                    <Tab
                        className="verticalLable"
                        label="Pending"
                        {...a11yProps(1)}
                    />
                    <Tab
                        className="verticalLable"
                        label="Submitted"
                        {...a11yProps(2)}
                    />

                </Tabs>
                <Box sx={{ p: 3 }}>
                    <TabPanel value={tabvalue} index={0}>
                        <DocumentRoomAdminAll status={status} startupId={startupId} setSearchInputValue={setSearchInputValue} searchInputValue={searchInputValue} listData={listData} setListData={setListData} listApi={listApi} />
                    </TabPanel>
                    <TabPanel value={tabvalue} index={1}>
                        <DocumentRoomAdminList status={status} tab={"pending"} page={page} setPage={setPage} setSearchInputValue={setSearchInputValue} searchInputValue={searchInputValue} startupId={startupId} listData={listData} setListData={setListData} listApi={listApi} count={count} setCount={setCount} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                    </TabPanel>
                    <TabPanel value={tabvalue} index={2}>
                        <DocumentRoomAdminList status={status} tab={"submitted"} page={page} setPage={setPage} setSearchInputValue={setSearchInputValue} searchInputValue={searchInputValue} startupId={startupId} listData={listData} setListData={setListData} listApi={listApi} count={count} setCount={setCount} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                    </TabPanel>
                </Box>
            </div>

            <Dialog
                maxWidth={"sm"}
                fullWidth={true}
                open={open}
                // onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <h4>Request Via Mail</h4>
                </DialogTitle>
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="form-group mt-2">
                                    <Controller
                                        name="subject"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Mail Subject is required",  validate: {
                                            noWhitespace: (value) =>
                                                !isWhitespace(value) || "Whitespace not allowed",
                                        }, }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                multiline
                                                label="Subject *"
                                                placeholder="Enter Subject"
                                                fullWidth
                                                {...field}
                                                inputProps={{ maxLength: 250 }}
                                            />
                                        )}
                                    />
                                    <FormHelperText sx={{ textAlign: 'right' }}>
                                        Max 250 Characters
                                    </FormHelperText>
                                    <FormHelperText className="text-danger">
                                        {errors.subject && errors.subject.message}
                                    </FormHelperText>
                                </div>
                            </div>

                            <div className='col-lg-12 col-12'>
                                <p>Body</p>
                                <span style={{ fontSize: '13px', color: 'gray' }}>{startupname.startupName}</span>
                                <div className="form-group mt-2">
                                    <Controller
                                        name="body"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Mail Body is required" ,  validate: {
                                            noWhitespace: (value) =>
                                                !isWhitespace(value) || "Whitespace not allowed",
                                        },}}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                multiline
                                                // label="Body *"
                                                placeholder="Enter Your Content *"
                                                fullWidth
                                                {...field}
                                                rows={5}
                                                inputProps={{ maxLength: 5000 }}
                                            />
                                        )}
                                    />
                                    <FormHelperText sx={{ textAlign: 'right' }}>
                                        Max 5000 Characters
                                    </FormHelperText>
                                    <FormHelperText className="text-danger">
                                        {errors.body && errors.body.message}
                                    </FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="text-center p-3">
                            <Button
                                className="mr-3"
                                onClick={handleCloseDialog}
                                variant="outlined"
                                sx={{ textTransform: 'none' }}
                            >
                                Close
                            </Button>
                            <Button type="submit" variant="contained" sx={{ textTransform: 'none' }}>
                                Send Mail
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default DocumentRoomAdmin
