import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';

const ClarificationAll = ({ remarks, lblName,btnName }) => {
    const { modalstyle } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            {
                remarks.length > 0 && (
                    <Button onClick={() => setOpenModal(true)} className='text-cap'>{btnName}</Button>
                )
            }
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag p-4">
                    <div>
                        <div className='div_header'>
                            <h6>{lblName} Details</h6>
                        </div>
                        <ol>
                            {
                                remarks.length > 0 && remarks.map((list) => (
                                    <li><p>{list}</p></li>
                                ))
                            }

                        </ol>
                        <center>
                            <Button onClick={() => setOpenModal(false)} variant="outlined">Ok</Button>
                        </center>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ClarificationAll