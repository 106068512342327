import React, { useContext, useState } from 'react';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { Grid, Typography, Button } from '@mui/material';
import Viewimage from '../../../api/Viewimage';  // Adjust this import based on your file structure

const CsDocuments = ({ idNumber }) => {
    const { setPageLoading } = useContext(DataContext);
    const [formData, setFormData] = useState({});
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('');

    useDidMountEffect(() => {
        getApi();
    }, []);

    const getApi = () => {
        setPageLoading(true);
        const req = {
            startupId: idNumber,
            round: 'CS',
        };
        apiService('partner/startup/clarification/list', req, 'post')
            .then((result) => {
                setPageLoading(false);
                if (result && result.data) {
                    // Process each document and determine its format
                    result.data.csDocuments1.forEach((element) => {
                        if (element.fileUrl) {
                            // Extract the file format from the URL
                            const data = element.fileUrl.split('?');
                            if (data) {
                                const format = data[0].split('.').pop().toLowerCase();
                                element.format = format;  // Add 'format' field to each document
                            }
                        }
                    });
                    result.data.csDocuments2.forEach((element) => {
                        if (element.fileUrl) {
                            // Extract the file format from the URL
                            const data = element.fileUrl.split('?');
                            if (data) {
                                const format = data[0].split('.').pop().toLowerCase();
                                element.format = format;  // Add 'format' field to each document
                            }
                        }
                    });
                    result.data.csDocuments3.forEach((element) => {
                        if (element.fileUrl) {
                            // Extract the file format from the URL
                            const data = element.fileUrl.split('?');
                            if (data) {
                                const format = data[0].split('.').pop().toLowerCase();
                                element.format = format;  // Add 'format' field to each document
                            }
                        }
                    });

                    setFormData(result.data); // Set the processed data
                }
            })
            .catch((err) => {
                setPageLoading(false);
                console.error(err);
            });
    };

    const handleClickOpen = (value, format) => {
        setViewImage(value);  // Set the image URL or file path
        if (format === 'pdf') {
            setOpen(true);  // For PDF, open Viewimage dialog
        } else {
            // For other formats (like Excel or Word), trigger the download
            const link = document.createElement('a');
            link.href = value;
            link.download = value.split('/').pop();  // Use the filename for download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleClose = () => {
        setOpen(false);  // Close the Viewimage dialog
    };

    // Check if `cpDocuments` exists and is an array
    const cpDocumentsData = formData.csDocuments1 || [];
    const cpDocumentsData2 = formData.csDocuments2 || [];
    const cpDocumentsData3 = formData.csDocuments3 || [];
    debugger
    return (
        <div>
            <div class="my-4">
                <h6 style={{ color: 'blue' }}>Post Investment Tranche 1/3 Documents:</h6>
            </div>
            {cpDocumentsData.length > 0 && (
                cpDocumentsData.map((data, i) => (
                    <Grid container key={i} spacing={2}>
                        <Grid item sm={12} md={12} lg={12}>
                            <p className='mb-0'>{i + 1}. {data.displayname}</p>

                            {data.fileUrl ? (
                                <>
                                    <div className='my-3'>
                                        Attached Document :
                                        <span className='view-doc mx-1'
                                            onClick={() => handleClickOpen(data.fileUrl, data.format)}
                                        >
                                            {data.format === 'pdf' ? 'View & Download' : 'Download'}

                                        </span>

                                    </div>
                                    <span className='mb-5'>(File Format : {data.format})</span>
                                </>

                            ) : (
                                <div className="mt-3 mb-3">
                                    <span style={{ fontWeight: 600, margin: '5px 0px' }}>
                                        Not Provided
                                    </span>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                ))
            )}
            <div class="my-4">
                <h6 style={{ color: 'blue' }}>Post Investment Tranche 2/3 Documents:</h6>
            </div>
            {cpDocumentsData2.length > 0 && (
                cpDocumentsData2.map((data, i) => (
                    <Grid container key={i} spacing={2}>
                        <Grid item sm={12} md={12} lg={12}>
                            <p className='mb-0'>{i + 1}. {data.displayname}</p>

                            {data.fileUrl ? (
                                <>
                                    <div className='my-3'>
                                        Attached Document :
                                        <span className='view-doc mx-1'
                                            onClick={() => handleClickOpen(data.fileUrl, data.format)}
                                        >
                                            {data.format === 'pdf' ? 'View & Download' : 'Download'}

                                        </span>

                                    </div>
                                    <span className='mb-5'>(File Format : {data.format})</span>
                                </>

                            ) : (
                                <div className="mt-3 mb-3">
                                    <span style={{ fontWeight: 600, margin: '5px 0px' }}>
                                        Not Provided
                                    </span>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                ))
            )}
             <div class="my-4">
                <h6 style={{ color: 'blue' }}>Post Investment Tranche 3/3 Documents:</h6>
            </div>
            {cpDocumentsData3.length > 0 && (
                cpDocumentsData3.map((data, i) => (
                    <Grid container key={i} spacing={2}>
                        <Grid item sm={12} md={12} lg={12}>
                            <p className='mb-0'>{i + 1}. {data.displayname}</p>

                            {data.fileUrl ? (
                                <>
                                    <div className='my-3'>
                                        Attached Document :
                                        <span className='view-doc mx-1'
                                            onClick={() => handleClickOpen(data.fileUrl, data.format)}
                                        >
                                            {data.format === 'pdf' ? 'View & Download' : 'Download'}

                                        </span>

                                    </div>
                                    <span className='mb-5'>(File Format : {data.format})</span>
                                </>

                            ) : (
                                <div className="mt-3 mb-3">
                                    <span style={{ fontWeight: 600, margin: '5px 0px' }}>
                                        Not Provided
                                    </span>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                ))
            )}
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </div>
    );
};

export default CsDocuments;
