import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormHelperText, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import SendIcon from '@mui/icons-material/Send';
import { TabContext, TabList } from '@mui/lab';
import Resubmit from '../../../investor-module/associated-startups-page/associated-details-page/pages/Resubmit';
import Scsthubavpremarks from '../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import DataContext from '../../../../context/DataContext';
import apiService from '../../../../api/apiService';
import ClarificationAll from '../../scstBdd-details/ClarificationAll';
import Viewimage from '../../../../api/Viewimage';
import notifyService from '../../../../api/notifySerivce';
import BddComents from './BddComents';
import Bddresubmit from '../../../investor-module/associated-startups-page/associated-details-page/pages/Bddresubmit';

const BddAdditionalInfo = ({ idNumber, path, viewStartupDetails, startupCheckList, startupValue, showStartupValue,showData }) => {
    
    const [formData, setFormData] = useState({});
    const { setPageLoading } = useContext(DataContext)
    const { handleSubmit, getValues, setValue, clearErrors, reset, control, formState: { errors } } = useForm(
        { values: formData, }
    );
    const [addInfoValue, setAddInfoValue] = React.useState('1');
    const handleChangeInfo = (event, newValue) => {
        setAddInfoValue(newValue);
    };
    const [statusValue, setStatusValue] = useState(2)
    const handleStatus = (value) => {
        setStatusValue(value)
    }
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useDidMountEffect(() => {
        historyList()
    }, [statusValue, page, rowsPerPage,])
    const [historyListValue, setHistoryListValue] = useState([])
    const historyList = () => {
        setHistoryListValue([])
        setPageLoading(true)
        var id = Number(idNumber)
        
        if ((showData === 'bddFirstConnect')) {
            var req = {
                listSize: rowsPerPage,
                pageNumber: page + 1,
                startupId: id,
                status: statusValue,
                round: "firstconnect",
                type: 'General'
            }
            
        } else if ((showData === 'bddDeepDive')) {
            var req = {
                listSize: rowsPerPage,
                pageNumber: page + 1,
                startupId: id,
                status: statusValue,
                round: "deepdive",
                type: 'General'
            }
        } 
        // else {
        //     var req = {
        //         listSize: rowsPerPage,
        //         pageNumber: page + 1,
        //         startupId: id,
        //         status: statusValue,
        //         round: "firstconnect",
        //         type: 'General'
        //     }
        // }
        
        apiService('partner/startup/clarification/history', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setCount(res.data.count)
                if (res.data.clarifications) {
                    res.data.clarifications.forEach((element) => {
                        if (element.documentName) {
                          var val = element.documentName;
                          var data = val.split("?");
                          if (data) {
                            var formet = data[0].split(".");
                            element.formet = formet[4];
                          }
                        }
                      });
                    setHistoryListValue(res.data.clarifications)
                    debugger
                }
            }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value,formet) => {
        setViewImage(value)
        if (formet === "xlsx") {
            const link = document.createElement('a');
            link.href = value;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          else if(formet === "docx"){
            const link = document.createElement('a');
            link.href = value;
            // link.download = 'excelfile.docs';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          else {
            setOpen(true);
          }
      
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [openModal, setOpenModal] = useState(false);
    const [bcId,setbcid] = useState()
    const [clarificationIds,setClarificationIds] = useState()
    const handleOpenModal = (id, clarificationId) => {
        setOpenModal(true);
        setbcid(id)
        setClarificationIds(clarificationId)
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handelMarkVerifed = () => {
        setPageLoading(true)
        var req = {
            bcId: bcId,
            startupId: idNumber,
            status: 1,
            clarificationId: clarificationIds
        }
        apiService(`partner/startups/clarification/status/save`, req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success" || res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Updated successfully !!')
                    setStatusValue(3)
                    historyList()
                    showStartupValue()
                    viewStartupDetails()
                    handleCloseModal()
                }
            }
        })
    }
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const onSubmit = async (data) => {
        if (isSubmitting) return;  // Prevent multiple calls
        setIsSubmitting(true);

        try {
            setLoading(true);
            let getform = { ...data };
            if ((startupValue.status === 5 && showData === 'bddFirstConnect')) {
                getform.startupId = Number(idNumber);
                getform.status = 2;
                getform.bcId = 300
                getform.round = "firstconnect";
            } else if ((startupValue.status === 8 && showData === 'bddDeepDive')) {
                getform.startupId = Number(idNumber);
                getform.status = 2;
                getform.bcId = 400
                getform.round = "deepdive";
            }


            const res = await apiService('partner/startup/clarification/save', getform, 'post');

            if (res?.data?.responseStatus === "Success") {
                notifyService('success', 'Success', 'Updated successfully !!');
                reset();
                setStatusValue(2);
                setAddInfoValue('1');
                showStartupValue();
                viewStartupDetails();

                historyList();
                
            }
        } catch (error) {
            console.error("Error submitting form", error);
        } finally {
            setIsSubmitting(false);
        }
    };
    const isWhitespace = (value) => {
        return /^\s*$/.test(value);
    };
    const roles = localStorage.getItem('role')
    const [isFullTextVisible, setIsFullTextVisible] = useState({})
    const toggleText = (index) => {
        
        setIsFullTextVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
        
    };
    const truncateTexts = (text, charLimit) => {
        if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
        return text.length > charLimit ? text.substring(0, charLimit) + ".." : text;
    };
  
   
    return (
        <>
            {(roles === 'BDD') && ((startupValue.status === 5 && showData === 'bddFirstConnect') || (startupValue.status === 8 && showData === 'bddDeepDive')) && (
                <section>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row align-item-center p-3'>

                            <div className='col-lg-12 col-md-6 col-12 mt-3'>

                                <Controller name="clarification" control={control} defaultValue="" rules={{
                                    required: 'Raised additional query is requried',
                                    validate: {
                                        noWhitespace: (value) =>
                                            !isWhitespace(value) || "Whitespace not allowed",
                                    },
                                }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Raise Additional Query *"
                                            placeholder='Raise Additional Query'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.clarification && errors.clarification.message}</FormHelperText>

                            </div>
                            <div className='col-lg-12 col-md-6 col-12 text-center mt-2'>
                                <Button
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    // startIcon={<SendIcon />}
                                    variant="contained"
                                >
                                    <span>Request to Startup</span>
                                </Button>
                            </div>
                        </div>
                    </form>
                </section>

            )}

            <section>
                <div className="table_main_div">
                    <div className='row'>
                        <div className='col-lg-12 col-12 my-3'>
                            <TabContext value={addInfoValue}>
                                <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                                    <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                                        <Tab label="Requested" value="1" onClick={() => handleStatus(2)} />
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Submitted by startup
                                                    {roles === 'BDD' && (
                                                        <>
                                                            {startupCheckList.generalBddadditionalInfoRequested && startupCheckList.generalBddadditionalInfoRequested === true || startupCheckList.generalBddDDadditionalInfoRequested && startupCheckList.generalBddDDadditionalInfoRequested === true ? (
                                                                <span className="ml-2">
                                                                    <img
                                                                        src="/images/giphy.gif"
                                                                        alt="gif"
                                                                        className="gif-bell img-fluid"
                                                                    />
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )
                                                    }

                                                </div>
                                            }
                                            value="2" onClick={() => handleStatus(3)} />
                                        <Tab label="Verified" value="3" onClick={() => handleStatus(1)} />
                                    </TabList>
                                </Box>
                                <Divider />
                                <div className="mt-3">
                                    {historyListValue.length > 0 &&
                                        (historyListValue.length) ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>No</TableCell>
                                                        <TableCell>Requested Clarification</TableCell>
                                                        {
                                                            (statusValue === 2) &&
                                                            <TableCell align='center'>Last Requested Time</TableCell>

                                                        }
                                                        {
                                                            (statusValue === 3 || statusValue === 1) &&
                                                            <TableCell align='center'>Last Submission Time</TableCell>
                                                        }


                                                        {/* {
                                                            statusValue === 3 && <TableCell align='center'>Submitted Time</TableCell>
                                                        } */}

                                                        {
                                                            statusValue === 3 && <TableCell align='center'>Response</TableCell>
                                                        }
                                                        {
                                                            statusValue === 1 && <TableCell align='center'>Response</TableCell>
                                                        }
                                                        {roles === 'BDD' && (
                                                            <>
                                                                {
                                                                    statusValue === 3 && (
                                                                        <TableCell align='center'>Action</TableCell>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                        }

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {historyListValue.map((list, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>

                                                            {/* <TableCell >{list.clarification ? list.clarification : '-'}  {list.clarificationAll && (<ClarificationAll remarks={list.clarificationAll} lblName="Clarifications" btnName="Show All Clarifications" />)}</TableCell> */}

                                                            <TableCell>
                                                                <>
                                                                    {list.clarification ? (
                                                                        <>
                                                                            {isFullTextVisible[index] ? list.clarification : truncateTexts(list.clarification || '', 15)}
                                                                            {list.clarificationAll && (<ClarificationAll remarks={list.clarificationAll} lblName="Clarifications" btnName="Show All Clarifications" />)}
                                                                            {list.clarification.length > 15 && (
                                                                                <h6
                                                                                    onClick={() => toggleText(index)}
                                                                                    style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                                >
                                                                                    {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                                                                </h6>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>-</>
                                                                    )}
                                                                </>
                                                            </TableCell>

                                                            <TableCell align='center'>{list.time}</TableCell>
                                                            {
                                                                statusValue === 3 && <TableCell align='center'>
                                                                    {list.documentName &&
                                                                        // <button className='viewdetails' onClick={() => { handleClickOpen(list.documentName) }}>View Latest Document</button>
                                                                        // <img src="/images/docs.png" alt="" style={{ cursor: 'pointer', width: '25px' }} onClick={() => handleClickOpen(list.documentName)} />

                                                                        <Tooltip title="View Latest Document" arrow>
                                                                            <img
                                                                                src="/images/docs.png"
                                                                                alt="Document Icon"
                                                                                style={{ cursor: 'pointer', width: '25px' }}
                                                                                onClick={() => handleClickOpen(list.documentName,list.formet)}
                                                                            />
                                                                        </Tooltip>
                                                                    }
                                                                    {/* {list.responseAll && <Scsthubavpremarks remarks={list.responseAll} lblName="View Comments" bddAdditionalInfo={'bddAdditionalInfo'} />} */}
                                                                    {list.responseAll && <BddComents remarks={list.responseAll} lblName="View Comments" bddAdditionalInfo={'bddAdditionalInfo'} />}
                                                                </TableCell>
                                                            }
                                                            {
                                                                statusValue === 1 && <TableCell align='center'>
                                                                    {list.documentName &&
                                                                        // <button className='viewdetails' onClick={() => { handleClickOpen(list.documentName) }}>View Latest Document</button>
                                                                        <Tooltip title="View Latest Document" arrow>
                                                                            <img
                                                                                src="/images/docs.png"
                                                                                alt="Document Icon"
                                                                                style={{ cursor: 'pointer', width: '25px' }}
                                                                                onClick={() => handleClickOpen(list.documentName,list.formet)}
                                                                            />
                                                                        </Tooltip>
                                                                    }
                                                                    {list.responseAll && <BddComents remarks={list.responseAll} lblName="View Comments" bddAdditionalInfo={'bddAdditionalInfo'} />}
                                                                </TableCell>
                                                            }
                                                            {roles === 'BDD' && (
                                                                <>
                                                                    {
                                                                        statusValue === 3 && (
                                                                            <TableCell align='center'>
                                                                                <Bddresubmit id={list.id} idNumber={idNumber} historyList={historyList} name="bddAdditionalInfo" viewStartupDetails={viewStartupDetails} clarificationId={list.clarificationId} showStartupValue={showStartupValue} />
                                                                                <button className='verified_btn' onClick={() => handleOpenModal(list.id, list.clarificationId)}>Mark as Verified</button>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                </>)}

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <caption>
                                                    <TablePagination
                                                        component="div"
                                                        count={count}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        style={{ float: 'left' }}
                                                    />
                                                </caption>
                                            </Table>

                                        </TableContainer>
                                    ) : (
                                        <div className='nodata_tag'>
                                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                            <p>No Records Found</p>
                                        </div>
                                    )
                                    }
                                </div>
                            </TabContext>
                        </div>
                    </div>
                    <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
                </div>
                <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    maxWidth={"sm"}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <center>
                            <img src="/images/img/attention.gif" alt="" className="alert-image" />
                        </center>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <h4 className="text-center p-3">Are you sure ?</h4>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="d-flex justify-content-center mb-2">
                        <Button
                            className="remove-api-modal-cancel"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button className="remove-api-modal-confirm" onClick={handelMarkVerifed}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </section>
        </>
    )
}

export default BddAdditionalInfo