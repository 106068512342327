import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import apiService from "../../../api/apiService";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Additionalinfo from "../additional-info-page/Additionalinfo";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Dialog from "@mui/material/Dialog";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import Startuphistroy from "../additional-info-page/Startuphistroy";
import { Button, Divider, IconButton, TablePagination } from "@mui/material";
import DataContext from "../../../context/DataContext";
import ScstBddStartups from "../../scstBdd-page/scstBdd-details/ScstBddStartups";
import { TabContext, TabList } from "@mui/lab";
// import { useEffect } from "react";
// import Resubmit from "../../investor-module/associated-startups-page/associated-details-page/pages/Resubmit";
import StartupAdditionalinfo from "./StartupAdditionalinfo";
import ClarificationAll from "../../scstBdd-page/scstBdd-details/ClarificationAll";
import { FaEye } from "react-icons/fa";
import DocumentRoomStartup from "./DocumentRoomStartup";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Progresslist = ({ name }) => {
  const userId = localStorage.getItem("userId");
  const { setPageLoading, navigator, notificationBell, notificationGet } = useContext(DataContext);

  useDidMountEffect(() => {
    if (name === "tanseed") {
      startupdetailsGetApi();
      alldetailsGetApi();
    }
  }, [name]);
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const startupdetailsGetApi = () => {
    apiService(`startup/tanseed/startupdetails/get`, "", "get")
      .then((result) => {
        if (result?.data?.editForm) {
          setReadyOnlyValue(result.data.editForm);
        }
      })
      .catch((err) => { });
  };
  const [tanseedApplicationNo, settanseedApplicationNo] = useState(
    localStorage.getItem("tanseedApplicationNo")
  );
  const [status, setStatus] = useState("")
  const alldetailsGetApi = () => {
    apiService(`startup/tanseed/alldetails/get`, "", "get").then((res) => {
      if (res?.data) {
        localStorage.setItem("tanseedApplicationNo", res.data.applicationNo);
        settanseedApplicationNo(res.data.applicationNo);
        setStatus(res.data.startupStatus)
      }
    });
  };
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useDidMountEffect(() => {
    progressList();
  }, [name, page, rowsPerPage]);
  useDidMountEffect(() => {
    notificationGet();
  }, []);
  const [progressData, setProgressData] = useState([]);
  const [progressStatus, setProgressStatus] = useState("");
  const [partnerId, setPartnerId] = useState();
  const [historyNotifyBDD, setHistoryNotifyBDD] = useState(false);
  const [bddDDClarificationRequested, setbddDDClarificationRequested] =
    useState(false);
  const [lddClarificationRequested, setLddClarificationRequested] =
    useState(false);
  const [fddClarificationRequested, setFddClarificationRequested] = useState(false);
  const [cpLDDClarificationRequested, setCpLDDClarificationRequested] = useState(false);
  const [cpFDDClarificationRequested, setCpFDDClarificationRequested] = useState(false);
  const progressList = () => {
    setPageLoading(true);
    var reqData = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      fund: name,
    };
    apiService(`startup/partners/list`, reqData, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        setCount(res.data.count);
        debugger
        if (res.data.startupPartner) {
          if (res.data.startupPartner) {
            setProgressData(res.data.startupPartner);
          }
          if (res.data.startupPartner[0]) {
            setProgressStatus(res.data.startupPartner[0].selected);
          }

          setHistoryNotifyBDD(res.data.bddClarificationRequested);
          setbddDDClarificationRequested(res.data.bddDDClarificationRequested);
          setLddClarificationRequested(res.data.lddClarificationRequested);
          setFddClarificationRequested(res.data.fddClarificationRequested);
          setCpLDDClarificationRequested(res.data.lddCpClarificationRequested);
          setCpFDDClarificationRequested(res.data.fddCpClarificationRequested);
          // setPartnerId(res.data.startupPartner.partnerorInvestorId)
          setPartnerId(res.data.startupPartner[0].partnerorInvestorId);
          debugger
          if (res.data.startupPartner[0].partnerorInvestorId) {
            historyList(res.data.startupPartner[0].partnerorInvestorId);
            // historyList2(res.data.startupPartner[0].partnerorInvestorId);
            // historyList3(res.data.startupPartner[0].partnerorInvestorId);
          }
          // console.log(res.data.startupPartner[0].partnerorInvestorId, 'id')
        } else {
          setProgressData([]);
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [statusValue, setStatusValue] = useState(0);
  const [pageHistory, setPageHistory] = useState(0);
  const [rowsPerPageHistory, setRowsPerPageHistory] = useState(10);
  const [pageHistory2, setPageHistory2] = useState(0);
  const [rowsPerPageHistory2, setRowsPerPageHistory2] = useState(10);
  const [pageHistory3, setPageHistory3] = useState(0);
  const [rowsPerPageHistory3, setRowsPerPageHistory3] = useState(10);
  useDidMountEffect(() => {
    if (partnerId) {
      historyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusValue, pageHistory, rowsPerPageHistory, partnerId]);
  useDidMountEffect(() => {
    if (partnerId) {
      historyList2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusValue, rowsPerPageHistory2, pageHistory2, partnerId]);
  useDidMountEffect(() => {
    if (partnerId) {
      historyList3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusValue, rowsPerPageHistory3, pageHistory3, partnerId]);
  const handleCpApiCall = () => {
    setAddInfoValue("1")
    setStatusValue2(0)
    historyList2()
  }

  const handleCpFddApiCall = () => {
    setAddInfoValue("1")
    setStatusValue2(0)
    historyList3()
    debugger
  }
  const handleValitationApiCall = () => {
    historyList()
  }
  const handleNotificationBell = () => {
    notificationGet()
  }
  const [statusValue2, setStatusValue2] = useState(0);
  const [statusVal, setStatusVal] = useState();
  const handleStatus = (value, status) => {
    debugger
    if (status === 'lddcp') {
      setStatusValue2(value)
      historyList2(value)
      setStatusVal(value)
      setPageHistory2(0)
      setRowsPerPageHistory2(10)
    } else if (status === 'fddcp') {
      setStatusValue2(value)
      historyList3(value)
      setStatusVal(value)
      setPageHistory3(0)
      setRowsPerPageHistory3(10)
    } else {
      setStatusValue(value);
      setPageHistory(0)
    }
  };
  const [historyListValue, setHistoryListValue] = useState([]);
  const [historyListValue2, setHistoryListValue2] = useState([]);
  const [historyListValue3, setHistoryListValue3] = useState([]);
  const [countHistory, setCountHistory] = useState(0);
  const [countHistory2, setCountHistory2] = useState(0);
  const [countHistory3, setCountHistory3] = useState(0);
  const [historyNotify, setHistoryNotify] = useState(false);
  const [historyNotify2, setHistoryNotify2] = useState(false);
  const [historyNotify3, setHistoryNotify3] = useState(false);
  const [type, setType] = useState();
  const historyList = (id) => {
    setHistoryListValue([]);
    setPageLoading(true);
    var req = {
      listSize: rowsPerPageHistory,
      pageNumber: pageHistory + 1,
      userId: partnerId ? partnerId : id,
      status: statusValue,
    };
    apiService("startup/additionalinfo/history", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        setCountHistory(res.data.count);
        setHistoryNotify(res.data.additionalInfoRequested);
        if (res.data.additionalInfo) {
          setHistoryListValue(res.data.additionalInfo);
        }
      }
    });
  };
  const [cpReason, setCpReason] = useState(false)
  const historyList2 = (value) => {
    debugger
    setHistoryListValue2([]);
    setPageLoading(true);
    var req = {
      listSize: rowsPerPageHistory2,
      pageNumber: pageHistory2 + 1,
      userId: userId,
      status: value,
      type: 'LDD'
    };

    apiService("cp/startup/additionalinfo/history", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        setCountHistory2(res.data.count);
        setHistoryNotify2(res.data.additionalInfoRequested);
        debugger
        setType(res.data.type)
        if (res.data.additionalInfo) {
          setHistoryListValue2(res.data.additionalInfo);
        }

        setCpReason(true)
      }
    });
  };
  const [cpFDDReason, setCpFDDReason] = useState(false)
  const historyList3 = (value) => {
    setHistoryListValue3([]);
    setPageLoading(true);
    var req = {
      listSize: rowsPerPageHistory3,
      pageNumber: pageHistory3 + 1,
      userId: userId,
      status: value,
      type: 'FDD'
    };
    debugger
    apiService("cp/startup/additionalinfo/history", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        setCountHistory3(res.data.count);
        setHistoryNotify3(res.data.additionalInfoRequested);
        setType(res.data.type)
        if (res.data.additionalInfo) {
          setHistoryListValue3(res.data.additionalInfo);
        }
        setCpFDDReason(true)
      }
    });
  };
  const handleHistoryChangePage = (event, newPage) => {
    setPageHistory(newPage);
  };
  const handleChangeHistoryRowsPerPage = (event) => {
    setRowsPerPageHistory(parseInt(event.target.value, 10));
    setPageHistory(0);
  };
  const handleHistoryChangePage2 = (event, newPage) => {
    setPageHistory2(newPage);
  };
  const handleChangeHistoryRowsPerPage2 = (event) => {
    setRowsPerPageHistory2(parseInt(event.target.value, 10));
    setPageHistory2(0);
    historyList2(statusVal)
  };
  const handleHistoryChangePage3 = (event, newPage) => {
    debugger
    setPageHistory3(newPage);
  };
  const handleChangeHistoryRowsPerPage3 = (event) => {
    debugger
    setRowsPerPageHistory3(parseInt(event.target.value, 10));
    setPageHistory3(0);
    historyList3(statusVal)
  };

  const [addInfoValue, setAddInfoValue] = React.useState("1");
  const handleChangeInfo = (event, newValue) => {
    debugger
    setAddInfoValue(newValue);
  };
  useDidMountEffect(() => {
    historyList2()
  }, []);
  useDidMountEffect(() => {
    historyList3()
  }, []);
  const [open, setOpen] = React.useState(false);
  const [partnerorInvestorId, setPartnerorInvestorId] = useState();
  const handleClickOpen = (id) => {
    setOpen(true);
    setPartnerorInvestorId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue1("");
    setValue(newValue);
  };
  const [value1, setValue1] = useState();
  const handleChange1 = (event, newValue) => {
    setValue("");
    setValue1(newValue);
  };
  const handleTanseed7 = () => {
    navigator("/startup/tanseed/dashboard");
  };
  const tabsContainerRef = useRef(null);

  const scrollTabs = (direction) => {
    if (tabsContainerRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      tabsContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleLeftArrowClick = () => {
    setValue((prevValue) => (prevValue > 0 ? prevValue - 1 : prevValue));
  };

  const handleRightArrowClick = () => {
    setValue((prevValue) => (prevValue < tabsCount - 1 ? prevValue + 1 : prevValue));
  };
  const [isFullTextVisible, setIsFullTextVisible] = useState({})
  const toggleText = (index) => {
    debugger
    setIsFullTextVisible(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    debugger
  };
  const [isFullTextVisible2, setIsFullTextVisible2] = useState({})
  const toggleText2 = (index) => {
    debugger
    setIsFullTextVisible2(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    debugger
  };
  const [isFullTextVisible3, setIsFullTextVisible3] = useState({})
  const toggleText3 = (index) => {
    setIsFullTextVisible3(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const truncateTexts = (text, charLimit) => {
    if (typeof text !== 'string') return ''; // Handle undefined, null, or non-string values
    return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
  };
  const tabsCount = 7;
  return (
    <>
      {name === "scst" ? (
        <section className="container-fluid">
          <div className="form_div chart">
            <div className="table_tag mb-4">
              {progressData.length ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">No</TableCell>
                        <TableCell align="center">Application No</TableCell>
                        {name === "scst" ? (
                          <>
                            <TableCell align="center">HUB Name</TableCell>
                            {/* <TableCell align='center'>Comment</TableCell> */}
                          </>
                        ) : (
                          <>
                            <TableCell>Investor</TableCell>
                          </>
                        )}
                        <TableCell align="center">Application Status</TableCell>
                        <TableCell align="center">Submitted Date</TableCell>
                        {/* <TableCell align='center'>Additional Info</TableCell>
                                    <TableCell align='center'>Additional Info History</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {progressData.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {list.applicationNo}
                          </TableCell>
                          {name === "scst" ? (
                            <>
                              <TableCell align="center">
                                {list.partnerName}
                              </TableCell>
                              {/* <TableCell align='center'>
                                              {(list.partnerComments) ? (list.partnerComments) : (`NA`)}
                                            </TableCell> */}
                            </>
                          ) : (
                            <>
                              <TableCell align="center">
                                {list.partnerName}
                              </TableCell>
                            </>
                          )}
                          <TableCell align="center">{list.status}</TableCell>
                          <TableCell align="center">{list.date}</TableCell>
                          {/* <TableCell align='center'>
                                        <p className="nav-link mb-0"><Additionalinfo status={list.additionalInfo} id={list.partnerorInvestorId} listApi={progressList} /></p>
                                      </TableCell> */}
                          {/* <TableCell align='center'>
                                        <span className='reqSpan' onClick={() => handleClickOpen(list.partnerorInvestorId)}>
                                          View
                                        </span>
                                      </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* <caption>
                                  <TablePagination
                                    component="div"
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    style={{ float: 'left' }}
                                  />
                                </caption> */}
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <p>
                    A partner will be assigned as soon as the criteria matches
                  </p>
                </div>
              )}
            </div>
            <Box sx={{ width: "100%" }}>


              {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    {progressStatus >= 0 && (
                      <Tab label="Validation & Evaluation" {...a11yProps(0)} onClick={handleValitationApiCall} />
                    )}
                    {progressStatus >= 3 &&
                      progressStatus !== 4 &&
                      progressStatus !== 28 && (
                        <Tab
                          label={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              BDD First Connect
                              {historyNotifyBDD === true ? (
                                <span className="ml-2">
                                  <img
                                    src="/images/giphy.gif"
                                    alt="gif"
                                    className="gif-bell img-fluid"
                                  />
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          }
                          {...a11yProps(1)}

                        />
                      )}
                    {progressStatus >= 8 && progressStatus !== 28 && (
                      <Tab
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            BDD Deep Dive
                            {bddDDClarificationRequested === true ? (
                              <span className="ml-2">
                                <img
                                  src="/images/giphy.gif"
                                  alt="gif"
                                  className="gif-bell img-fluid"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        {...a11yProps(2)}
                      />
                    )}
                    {progressStatus >= 16 && progressStatus !== 28 && (
                      <Tab
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Legal Due Diligence
                            {lddClarificationRequested === true ? (
                              <span className="ml-2">
                                <img
                                  src="/images/giphy.gif"
                                  alt="gif"
                                  className="gif-bell img-fluid"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        {...a11yProps(3)}
                      />
                    )}
                    {progressStatus >= 16 && progressStatus !== 28 && (
                      <Tab
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Financial Due Diligence
                            {fddClarificationRequested === true ? (
                              <span className="ml-2">
                                <img
                                  src="/images/giphy.gif"
                                  alt="gif"
                                  className="gif-bell img-fluid"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        {...a11yProps(4)}
                      />
                    )}
                    {progressStatus >= 20 && progressStatus !== 28 && (
                      <Tab
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Legal Due Diligence CP
                            {cpLDDClarificationRequested === true ? (
                              <span className="ml-2">
                                <img
                                  src="/images/giphy.gif"
                                  alt="gif"
                                  className="gif-bell img-fluid"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        {...a11yProps(5)}
                        onClick={handleCpApiCall}
                      />
                    )}
                    {progressStatus >= 20 && progressStatus !== 28 && (
                      <Tab
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Financial Due Diligence CP
                            {cpFDDClarificationRequested === true ? (
                              <span className="ml-2">
                                <img
                                  src="/images/giphy.gif"
                                  alt="gif"
                                  className="gif-bell img-fluid"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        {...a11yProps(6)}
                        onClick={handleCpFddApiCall}
                      />
                    )}
                  </Tabs>
                </Box> */}
              <Box className="overallTab" >
                {/* {progressStatus >= 20 && progressStatus !== 28 && progressStatus !== 29 && (<IconButton onClick={handleLeftArrowClick}>
                  <KeyboardArrowLeft />
                </IconButton>)} */}
                {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  {progressStatus >= 0 && (
                    <Tab label="Validation & Evaluation" {...a11yProps(0)} onClick={handleValitationApiCall} />
                  )}
                  {progressStatus >= 3 && progressStatus !== 4 && progressStatus !== 28 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          BDD First Connect
                          {historyNotifyBDD === true ? (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          ) : null}
                        </div>
                      }
                      {...a11yProps(1)}
                    />
                  )}
                  {progressStatus >= 8 && progressStatus !== 28 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          BDD Deep Dive
                          {bddDDClarificationRequested === true ? (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          ) : null}
                        </div>
                      }
                      {...a11yProps(2)}
                    />
                  )}
                  {progressStatus >= 16 && progressStatus !== 28 && progressStatus !== 29 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Legal Due Diligence
                          {lddClarificationRequested === true ? (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          ) : null}
                        </div>
                      }
                      {...a11yProps(3)}
                    />
                  )}
                  {progressStatus >= 16 && progressStatus !== 28 && progressStatus !== 29 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Financial Due Diligence
                          {fddClarificationRequested === true ? (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          ) : null}
                        </div>
                      }
                      {...a11yProps(4)}
                    />
                  )}
                  {progressStatus >= 20 && progressStatus !== 28 && progressStatus !== 29 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Legal Due Diligence CP
                          {historyNotify2 === true ? (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          ) : null}
                        </div>
                      }
                      {...a11yProps(5)}
                      onClick={handleCpApiCall}
                    />
                  )}
                  {progressStatus >= 20 && progressStatus !== 28 && progressStatus !== 29 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Financial Due Diligence CP
                          {historyNotify3 === true ? (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          ) : null}
                        </div>
                      }
                      {...a11yProps(6)}
                      onClick={handleCpFddApiCall}
                    />
                  )}
                </Tabs> */}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                // style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                >
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Document Room
                        {notificationBell === true && (
                          <span className="ml-2">
                            <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                          </span>
                        )}
                      </div>
                    }
                    {...a11yProps(0)}
                    onClick={handleNotificationBell}
                  />
                  {progressStatus >= 0 && (
                    <Tab
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          Validation & Evaluation
                          {historyNotify === true && (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          )}
                        </div>
                      }
                      {...a11yProps(1)}
                      onClick={handleValitationApiCall}
                    />
                  )}
                  {progressStatus >= 3 && progressStatus !== 4 && progressStatus !== 28 && (
                    <Tab
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          BDD First Connect
                          {historyNotifyBDD && (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          )}
                        </div>
                      }
                      {...a11yProps(2)}

                    />
                  )}
                  {progressStatus >= 8 && progressStatus !== 28 && (
                    <Tab
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          BDD Deep Dive
                          {bddDDClarificationRequested && (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          )}
                        </div>
                      }
                      {...a11yProps(3)}

                    />
                  )}
                  {progressStatus >= 16 && progressStatus !== 28 && progressStatus !== 29 && (
                    <Tab
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          Legal Due Diligence
                          {lddClarificationRequested && (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          )}
                        </div>
                      }
                      {...a11yProps(4)}

                    />
                  )}
                  {progressStatus >= 16 && progressStatus !== 28 && progressStatus !== 29 && (
                    <Tab
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          Financial Due Diligence
                          {fddClarificationRequested && (
                            <span className="ml-2">
                              <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                            </span>
                          )}
                        </div>
                      }
                      {...a11yProps(5)}

                    />
                  )}
                </Tabs>
              </Box>
              {progressStatus >= 20 && progressStatus !== 28 && progressStatus !== 29 && (
                <Box className="overallTab">
                  <Tabs
                    value={value1}
                    onChange={handleChange1}
                    aria-label="basic tabs example">
                    {progressStatus >= 20 && progressStatus !== 28 && progressStatus !== 29 && (
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            Legal Due Diligence CP
                            {historyNotify2 && (
                              <span className="ml-2">
                                <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                              </span>
                            )}
                          </div>
                        }
                        {...a11yProps(0)}
                        onClick={handleCpApiCall}

                      />
                    )}
                    {progressStatus >= 20 && progressStatus !== 28 && progressStatus !== 29 && (
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            Financial Due Diligence CP
                            {historyNotify3 && (
                              <span className="ml-2">
                                <img src="/images/giphy.gif" alt="gif" className="gif-bell img-fluid" />
                              </span>
                            )}
                          </div>
                        }
                        {...a11yProps(1)}
                        onClick={handleCpFddApiCall}

                      />
                    )}
                  </Tabs>
                </Box>
              )}
              <Box >
                <TabPanel value={value} index={0}>
                  <Box sx={{ pt: 3 }}>
                    <DocumentRoomStartup />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box sx={{ pt: 3 }}>
                    <section className="container-fluid">
                      <div className="">
                        <div>
                          <div className="div_header">
                            <h6 className="mt-1" style={{ color: "#313131" }}>
                              Additional Information History
                            </h6>
                          </div>
                          <TabContext value={addInfoValue}>
                            <Box
                              sx={{ borderBottom: 0, borderColor: "transparent" }}
                            >
                              <TabList
                                onChange={handleChangeInfo}
                                aria-label="lab API tabs example"
                              >
                                <Tab
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Pending
                                      {historyNotify === true ? (
                                        <span className="ml-2">
                                          <img
                                            src="/images/giphy.gif"
                                            alt="gif"
                                            className="gif-bell img-fluid"
                                          />
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  }
                                  value="1"
                                  onClick={() => handleStatus(0)}
                                />
                                <Tab
                                  label="Submitted"
                                  value="2"
                                  onClick={() => handleStatus(1)}
                                />
                                <Tab
                                  label="Verified"
                                  value="3"
                                  onClick={() => handleStatus(2)}
                                />
                              </TabList>
                            </Box>
                            <Divider />
                            <div className="mt-3">
                              {historyListValue.length > 0 &&
                                historyListValue.length ? (
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="caption table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell>Reason</TableCell>
                                        <TableCell align="center">
                                          Comments
                                        </TableCell>

                                        {statusValue === 0 && (
                                          <TableCell align="center">
                                            Requested Time
                                          </TableCell>
                                        )}
                                        {statusValue === 1 && (
                                          <TableCell align="center">
                                            Submitted Time
                                          </TableCell>
                                        )}
                                        {statusValue === 2 && (
                                          <TableCell align="center">
                                            Time
                                          </TableCell>
                                        )}
                                        <TableCell align="center">
                                          Requested By
                                        </TableCell>
                                        <TableCell align="center">
                                          {statusValue === 1
                                            ? `Attached Document`
                                            : `Upload Document`}
                                        </TableCell>

                                        {/* {
                                        statusValue === 1 && (
                                          <TableCell align='center'>Action</TableCell>
                                        )
                                      } */}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {historyListValue.map((list, index) => (
                                        <TableRow key={index}>
                                          <TableCell align="center">
                                            {pageHistory * rowsPerPageHistory + index + 1}
                                          </TableCell>
                                          <TableCell>
                                            {list.reason}{" "}
                                            {list.reasonAll && (
                                              <ClarificationAll
                                                remarks={list.reasonAll}
                                                lblName="Reason"
                                                btnName="Show All Reason"
                                              />
                                            )}
                                          </TableCell>
                                          <TableCell align="center">
                                            {list.remarks ? list.remarks : `-`}
                                          </TableCell>
                                          <TableCell align="center">
                                            {list.time}
                                          </TableCell>
                                          <TableCell align="center">
                                            {list.by}
                                          </TableCell>
                                          <TableCell align="center">
                                            {/* <span className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</span> */}
                                            <p className="nav-link mb-0">
                                              <StartupAdditionalinfo
                                                data={list}
                                                statusValue={statusValue}
                                                partnerId={partnerId}
                                                historyList={historyList}
                                              />
                                            </p>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                    <caption>
                                      <TablePagination
                                        component="div"
                                        count={countHistory}
                                        page={pageHistory}
                                        onPageChange={handleHistoryChangePage}
                                        rowsPerPage={rowsPerPageHistory}
                                        onRowsPerPageChange={
                                          handleChangeHistoryRowsPerPage
                                        }
                                        style={{ float: "left" }}
                                      />
                                    </caption>
                                  </Table>
                                </TableContainer>
                              ) : (
                                <div className="nodata_tag">
                                  <img
                                    src="/images/Tanfundlogo-black-1.png"
                                    className="no_data_logo"
                                    alt=""
                                  />
                                  <p>No Records Found</p>
                                </div>
                              )}
                            </div>
                          </TabContext>
                        </div>
                      </div>
                      <Dialog
                        maxWidth={"lg"}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <Startuphistroy
                          partnerorInvestorId={partnerorInvestorId}
                          handleClose={handleClose}
                        />
                      </Dialog>
                    </section>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="div_header mt-3">
                    <h6 className="mt-1" style={{ color: "#313131" }}>
                      Clarification History
                    </h6>
                  </div>
                  <ScstBddStartups
                    partnerId={partnerId}
                    name={name}
                    historyNotifyBDD={historyNotifyBDD}
                    progressList={progressList}
                    round="firstconnect"
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <div className="div_header mt-3">
                    <h6 className="mt-1" style={{ color: "#313131" }}>
                      Clarification History
                    </h6>
                  </div>
                  <ScstBddStartups
                    partnerId={partnerId}
                    name={name}
                    historyNotifyBDD={bddDDClarificationRequested}
                    progressList={progressList}
                    round="deepdive"
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <div className="div_header mt-3">
                    <h6 className="mt-1" style={{ color: "#313131" }}>
                      Clarification History
                    </h6>
                  </div>
                  <ScstBddStartups
                    partnerId={partnerId}
                    name={name}
                    historyNotifyBDD={lddClarificationRequested}
                    progressList={progressList}
                    round="ldd"
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <div className="div_header mt-3">
                    <h6 className="mt-1" style={{ color: "#313131" }}>
                      Clarification History
                    </h6>
                  </div>
                  <ScstBddStartups
                    partnerId={partnerId}
                    name={name}
                    historyNotifyBDD={lddClarificationRequested}
                    progressList={progressList}
                    round="fdd"
                  />
                </TabPanel>
              </Box>
              <Box >
                <TabPanel value={value1} index={0}>
                  <Box sx={{ pt: 3 }}>
                    <section className="container-fluid">
                      <div className="">
                        <div>
                          {/* <div className="div_header">
                            <h6 className="mt-1" style={{ color: "#313131" }}>
                              Additional Information History
                            </h6>
                          </div> */}
                          <TabContext value={addInfoValue}>
                            <Box
                              sx={{ borderBottom: 0, borderColor: "transparent" }}
                            >
                              <TabList
                                onChange={handleChangeInfo}
                                aria-label="lab API tabs example"
                              >
                                <Tab
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Pending
                                      {historyNotify2 === true ? (
                                        <span className="ml-2">
                                          <img
                                            src="/images/giphy.gif"
                                            alt="gif"
                                            className="gif-bell img-fluid"
                                          />
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  }
                                  value="1"
                                  onClick={() => handleStatus(0, 'lddcp')}
                                />
                                <Tab
                                  label="Submitted"
                                  value="2"
                                  onClick={() => handleStatus(1, 'lddcp')}
                                />
                                <Tab
                                  label="Verified"
                                  value="3"
                                  onClick={() => handleStatus(2, 'lddcp')}
                                />
                              </TabList>
                            </Box>
                            <Divider />
                            <div className="mt-3">
                              {historyListValue2.length > 0 &&
                                historyListValue2.length ? (
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="caption table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell align="center">
                                          Clarification requested
                                        </TableCell>

                                        {statusValue2 === 0 && (
                                          <TableCell align="center">
                                            Requested Time
                                          </TableCell>
                                        )}
                                        {(statusValue2 === 1 || statusValue2 === 2) && (
                                          <TableCell align="center">
                                            Submitted Time
                                          </TableCell>
                                        )}
                                        {/* {statusValue2 === 2 && (
                                          <TableCell align="center">
                                            Time
                                          </TableCell>
                                        )} */}
                                        {/* {(statusValue2 === 1 || statusValue2 === 2) && (
                                          <TableCell align="center">
                                            Your Responce
                                          </TableCell>
                                        )} */}
                                        {/* <TableCell align="center">
                                          Requested By
                                        </TableCell> */}
                                        <TableCell align="center">
                                          {statusValue2 === 1 || statusValue2 === 2
                                            ? `Submitted Response`
                                            : `Submit Response`}
                                        </TableCell>

                                        {/* {
                                        statusValue === 1 && (
                                          <TableCell align='center'>Action</TableCell>
                                        )
                                      } */}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {historyListValue2.map((list, index) => (
                                        <TableRow key={index}>
                                          <TableCell align="center">
                                            {pageHistory2 * rowsPerPageHistory2 + index + 1}
                                          </TableCell>

                                          {/* <TableCell align="center">
                                            {list.reason ? (
                                              <>
                                                {isFullTextVisible[index]
                                                  ? list.reason
                                                  : truncateTexts(list.reason || '', 20)}

                                                {list.reason && list.reason.length > 20 && (
                                                  <span
                                                    onClick={() => toggleText(index)}
                                                    style={{ cursor: "pointer", color: "#7F9AD2" }}
                                                  >
                                                    {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                                  </span>
                                                )}
                                              </>) : ((`-`))}
                                          </TableCell> */}
                                          {/* {list.clarificationList && (
                                            <> */}
                                          {((statusValue2 === 0 || statusValue2 === 1 || statusValue2 === 2)) && (
                                            <TableCell align="center">
                                              {list.clarificationList && list.clarificationList[0] ? (
                                                <>
                                                  {isFullTextVisible3[index]
                                                    ? list.clarificationList[0]
                                                    : truncateTexts(list.clarificationList[0] || '', 20)}

                                                  {list.clarificationList[0] && list.clarificationList[0].length > 20 && (
                                                    <span
                                                      onClick={() => toggleText3(index)}
                                                      style={{ cursor: "pointer", color: "#7F9AD2" }}
                                                    >
                                                      {isFullTextVisible3[index] ? " Read Less" : " Read More"}
                                                    </span>
                                                  )}
                                                </>) : ((`-`))}
                                              {list.reasonAll && (
                                                <ClarificationAll
                                                  remarks={list.reasonAll}
                                                  lblName="Reason"
                                                  btnName="Show All Reason"
                                                />
                                              )}
                                            </TableCell>
                                          )}
                                          {/* </>
                                          )} */}

                                          <TableCell align="center">
                                            {list.time ?? '-'}
                                          </TableCell>

                                          {/* <TableCell align="center">
                                            {list.by}
                                          </TableCell> */}

                                          <TableCell align="center">
                                            {/* <span className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</span> */}
                                            <p className="nav-link mb-0">
                                              <StartupAdditionalinfo
                                                data={list}
                                                statusValue={statusValue2}
                                                partnerId={partnerId}
                                                progressList={progressList}
                                                handleCpApiCall={handleCpApiCall}
                                                cpLdd={'cpLdd'}
                                                historyList2={historyList2}
                                              />
                                            </p>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                    <caption>
                                      <TablePagination
                                        component="div"
                                        count={countHistory2}
                                        page={pageHistory2}
                                        onPageChange={handleHistoryChangePage2}
                                        rowsPerPage={rowsPerPageHistory2}
                                        onRowsPerPageChange={
                                          handleChangeHistoryRowsPerPage2
                                        }
                                        style={{ float: "left" }}
                                      />
                                    </caption>
                                  </Table>
                                </TableContainer>
                              ) : (
                                <div className="nodata_tag">
                                  <img
                                    src="/images/Tanfundlogo-black-1.png"
                                    className="no_data_logo"
                                    alt=""
                                  />
                                  <p>No Records Found</p>
                                </div>
                              )}
                            </div>
                          </TabContext>
                        </div>
                      </div>
                      <Dialog
                        maxWidth={"lg"}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <Startuphistroy
                          partnerorInvestorId={partnerorInvestorId}
                          handleClose={handleClose}
                        />
                      </Dialog>
                    </section>
                  </Box>
                </TabPanel>
                <TabPanel value={value1} index={1}>
                  <Box sx={{ pt: 3 }}>
                    <section className="container-fluid">
                      <div className="">
                        <div>
                          {/* <div className="div_header">
                            <h6 className="mt-1" style={{ color: "#313131" }}>
                              Additional Information History
                            </h6>
                          </div> */}
                          <TabContext value={addInfoValue}>
                            <Box
                              sx={{ borderBottom: 0, borderColor: "transparent" }}
                            >
                              <TabList
                                onChange={handleChangeInfo}
                                aria-label="lab API tabs example"
                              >
                                <Tab
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Pending
                                      {historyNotify3 === true ? (
                                        <span className="ml-2">
                                          <img
                                            src="/images/giphy.gif"
                                            alt="gif"
                                            className="gif-bell img-fluid"
                                          />
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  }
                                  value="1"
                                  onClick={() => handleStatus(0, 'fddcp')}
                                />
                                <Tab
                                  label="Submitted"
                                  value="2"
                                  onClick={() => handleStatus(1, 'fddcp')}
                                />
                                <Tab
                                  label="Verified"
                                  value="3"
                                  onClick={() => handleStatus(2, 'fddcp')}
                                />
                              </TabList>
                            </Box>
                            <Divider />
                            <div className="mt-3">
                              {historyListValue3.length > 0 &&
                                historyListValue3.length ? (
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="caption table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell align="center">
                                          Clarification requested
                                        </TableCell>

                                        {statusValue2 === 0 && (
                                          <TableCell align="center">
                                            Requested Time
                                          </TableCell>
                                        )}
                                        {(statusValue2 === 1 || statusValue2 === 2) && (
                                          <TableCell align="center">
                                            Submitted Time
                                          </TableCell>
                                        )}
                                        {/* {statusValue2 === 2 && (
                                          <TableCell align="center">
                                            Time
                                          </TableCell>
                                        )} */}
                                        {/* {(statusValue2 === 1 || statusValue2 === 2) && (
                                          <TableCell align="center">
                                            Your Responce
                                          </TableCell>
                                        )} */}
                                        {/* <TableCell align="center">
                                          Requested By
                                        </TableCell> */}
                                        <TableCell align="center">
                                          {statusValue2 === 1 || statusValue2 === 2
                                            ? `Submitted Response`
                                            : `Submit Response`}
                                        </TableCell>

                                        {/* {
                                        statusValue === 1 && (
                                          <TableCell align='center'>Action</TableCell>
                                        )
                                      } */}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {historyListValue3.map((list, index) => (
                                        // <TableRow key={index}>
                                        //   <TableCell align="center">
                                        //     {pageHistory3 * rowsPerPageHistory3 + index + 1}
                                        //   </TableCell>

                                        //   <TableCell align="center">
                                        //     {list.reason ? (
                                        //       <>
                                        //         {isFullTextVisible2[index]
                                        //           ? list.reason
                                        //           : truncateTexts(list.reason || '', 20)}

                                        //         {list.reason && list.reason.length > 20 && (
                                        //           <span
                                        //             onClick={() => toggleText2(index)}
                                        //             style={{ cursor: "pointer", color: "#7F9AD2" }}
                                        //           >
                                        //             {isFullTextVisible2[index] ? " Read Less" : " Read More"}
                                        //           </span>
                                        //         )}
                                        //       </>) : ((`-`))}
                                        //   </TableCell>
                                        //   <TableCell align="center">
                                        //     {list.remarks ? (
                                        //       <>
                                        //         {isFullTextVisible3[index]
                                        //           ? list.remarks
                                        //           : truncateTexts(list.remarks || '', 20)}

                                        //         {list.remarks && list.remarks.length > 20 && (
                                        //           <span
                                        //             onClick={() => toggleText3(index)}
                                        //             style={{ cursor: "pointer", color: "#7F9AD2" }}
                                        //           >
                                        //             {isFullTextVisible3[index] ? " Read Less" : " Read More"}
                                        //           </span>
                                        //         )}
                                        //       </>) : ((`-`))}
                                        //     {list.reasonAll && (
                                        //       <ClarificationAll
                                        //         remarks={list.reasonAll}
                                        //         lblName="Reason"
                                        //         btnName="Show All Reason"
                                        //       />
                                        //     )}
                                        //   </TableCell>
                                        //   <TableCell align="center">
                                        //     {list.time}
                                        //   </TableCell>
                                        //   <TableCell align="center">
                                        //     <p className="nav-link mb-0">
                                        //       <StartupAdditionalinfo
                                        //         data={list}
                                        //         statusValue={statusValue2}
                                        //         partnerId={partnerId}
                                        //         progressList={progressList}
                                        //         handleCpFddApiCall={handleCpFddApiCall}
                                        //         cpFdd={'cpFdd'}
                                        //       />
                                        //     </p>
                                        //   </TableCell>
                                        // </TableRow>




                                        <TableRow key={index}>
                                          <TableCell align="center">
                                            {pageHistory3 * rowsPerPageHistory3 + index + 1}
                                          </TableCell>

                                          {/* <TableCell align="center">
                                            {list.reason ? (
                                              <>
                                                {isFullTextVisible[index]
                                                  ? list.reason
                                                  : truncateTexts(list.reason || '', 20)}

                                                {list.reason && list.reason.length > 20 && (
                                                  <span
                                                    onClick={() => toggleText(index)}
                                                    style={{ cursor: "pointer", color: "#7F9AD2" }}
                                                  >
                                                    {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                                  </span>
                                                )}
                                              </>) : ((`-`))}
                                          </TableCell> */}

                                          {((statusValue2 === 0 || statusValue2 === 1 || statusValue2 === 2)) && (
                                            <TableCell align="center">
                                              {list.clarificationList && list.clarificationList[0] ? (
                                                <>
                                                  {isFullTextVisible3[index]
                                                    ? list.clarificationList[0]
                                                    : truncateTexts(list.clarificationList[0] || '', 20)}

                                                  {list.clarificationList[0] && list.clarificationList[0].length > 20 && (
                                                    <span
                                                      onClick={() => toggleText3(index)}
                                                      style={{ cursor: "pointer", color: "#7F9AD2" }}
                                                    >
                                                      {isFullTextVisible3[index] ? " Read Less" : " Read More"}
                                                    </span>
                                                  )}
                                                </>) : ((`-`))}
                                              {list.reasonAll && (
                                                <ClarificationAll
                                                  remarks={list.reasonAll}
                                                  lblName="Reason"
                                                  btnName="Show All Reason"
                                                />
                                              )}
                                            </TableCell>
                                          )}


                                          {list.time ? (<>
                                            <TableCell align="center">
                                              {list.time}
                                            </TableCell>
                                          </>) : ((`-`))}
                                          {/* <TableCell align="center">
                                            {list.by}
                                          </TableCell> */}

                                          <TableCell align="center">
                                            {/* <span className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</span> */}
                                            <p className="nav-link mb-0">
                                              <StartupAdditionalinfo
                                                data={list}
                                                statusValue={statusValue2}
                                                partnerId={partnerId}
                                                progressList={progressList}
                                                handleCpFddApiCall={handleCpFddApiCall}
                                                cpFdd={'cpFdd'}
                                                historyList3={historyList3}
                                              />
                                            </p>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                    <caption>
                                      <TablePagination
                                        component="div"
                                        count={countHistory3}
                                        page={pageHistory3}
                                        onPageChange={handleHistoryChangePage3}
                                        rowsPerPage={rowsPerPageHistory3}
                                        onRowsPerPageChange={
                                          handleChangeHistoryRowsPerPage3
                                        }
                                        style={{ float: "left" }}
                                      />
                                    </caption>
                                  </Table>
                                </TableContainer>
                              ) : (
                                <div className="nodata_tag">
                                  <img
                                    src="/images/Tanfundlogo-black-1.png"
                                    className="no_data_logo"
                                    alt=""
                                  />
                                  <p>No Records Found</p>
                                </div>
                              )}
                            </div>
                          </TabContext>
                        </div>
                      </div>
                      <Dialog
                        maxWidth={"lg"}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <Startuphistroy
                          partnerorInvestorId={partnerorInvestorId}
                          handleClose={handleClose}
                        />
                      </Dialog>
                    </section>
                  </Box>
                </TabPanel>
              </Box>


            </Box>
          </div>
        </section>
      ) : (
        <section className="container-fluid">
          <div className="form_div">
            <div className="table_tag">
              {progressData.length ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        {name === "scst" ? (
                          <>
                            <TableCell>HUB Name</TableCell>
                            <TableCell align="center">Comment</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>Investor</TableCell>
                          </>
                        )}
                        <TableCell align="center">Application Status</TableCell>
                        <TableCell align="center">Additional Info</TableCell>
                        <TableCell align="center">
                          Additional Info History
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {progressData.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          {name === "scst" ? (
                            <>
                              <TableCell>{list.partnerName}</TableCell>
                              <TableCell align="center">
                                {list.partnerComments
                                  ? list.partnerComments
                                  : `-`}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{list.partnerName}</TableCell>
                            </>
                          )}
                          <TableCell align="center">{list.status}</TableCell>
                          <TableCell align="center">
                            <p className="nav-link mb-0">
                              <Additionalinfo
                                status={list.additionalInfo}
                                id={list.partnerorInvestorId}
                                listApi={progressList}
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            <span
                              className="reqSpan"
                              onClick={() =>
                                handleClickOpen(list.partnerorInvestorId)
                              }
                            >
                              View
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <caption>
                      <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{ float: "left" }}
                      />
                    </caption>
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />

                  {name === "tanseed" ? (
                    <>
                      {readyOnlyValue ? (
                        <div>
                          {/* <p>Your TANSEED 7.0 Application is under process !</p> */}
                          <Button variant="contained" onClick={handleTanseed7}>
                            Go to Application
                          </Button>
                        </div>
                      ) : (
                        // <h3>
                        //   Your TANSEED Application (
                        //   <b>{tanseedApplicationNo}</b>) {status}
                        // </h3>
                        <h3>
                          {status}
                        </h3>
                      )}
                    </>
                  ) : (
                    <p>
                      A partner will be assigned as soon as the criteria matches
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <Dialog
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <Startuphistroy
              partnerorInvestorId={partnerorInvestorId}
              handleClose={handleClose}
            />
          </Dialog>
        </section>
      )}
    </>
  );
};

export default Progresslist;
