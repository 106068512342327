import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Modal, TextField } from '@mui/material';
import notifyService from '../../../api/notifySerivce';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';

import SampleTemplates from '../../common-page/SampleTemplates';
const SendEmailTemplate = ({ startupId,userListItemTanseed }) => {
    const { modalstyle, setPageLoading, fullScreen } = useContext(DataContext)
    const [formData, setFormData] = useState({})
    const { handleSubmit, reset, control, clearErrors, setValue, formState: { errors } } = useForm({ values: formData });
    const [openEmailModal, setopenEmailModal] = useState(false)
    const handleOpenModal = () => {
        setFormData({})
        reset()
        setopenEmailModal(true)
    }
    const handleOpenModalClose = () => {
        setFormData({})
        reset()
        setopenEmailModal(false)
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        var val = typeof startupId
        if (val === 'string') {
            data.startupId = [startupId]
        } else {
            data.startupId = startupId
        }
        apiService('admin/email/send', data, 'post').then((result) => {
            setPageLoading(false)
            if (result) {
                if (result.data.responseStatus === "Success") {
                    notifyService('success', 'Success', '')
                    userListItemTanseed()
                    handleOpenModalClose()
                }
            }
        }).catch((err) => {

        });
        // setEmailValues(emails)
        // exportExcelStartup('All',emails)
    };
    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };


    return (
        <div>
            <Button
                variant="outlined"
                onClick={handleOpenModal}
                className='cohorts-Btn'
            >
               Send Mail
            </Button>
            <Dialog
                open={openEmailModal}
                fullScreen={fullScreen}
                maxWidth={'lg'}
            >
                <DialogTitle >
                    <div className='d-flex align-item-center'>
                        Email Template
                        <div className='ml-auto' >
                            <SampleTemplates setFormData={setFormData} template={'TANSEED'} mode={'EMAIL'} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <form
                        className="signin-form"
                        onSubmit={handleSubmit(onSubmit, onError)}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="form-group mt-2">
                                    <Controller
                                        name="subject"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Subject is required" }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Email Subject *"
                                                placeholder="Enter Email Subject"
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.subject && errors.subject.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <div className="col-lg-12 col-12">
                                <div className="form-group mt-2">
                                    <Controller
                                        name="message"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Subject is required" }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Email Message *"
                                                placeholder="Enter Email Message"
                                                fullWidth
                                                multiline
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.message && errors.message.message}
                                    </FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center mt-4 ">
                            <Button
                                variant="contained"
                                onClick={handleOpenModalClose}
                                className="btn btn-cancel submit px-3"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="btn btn-primary submit px-3 ml-3"
                            >
                                <span>Send</span>
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SendEmailTemplate