import React, { useContext, useState } from 'react'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import apiService from '../../../api/apiService';
import CheckIcon from '@mui/icons-material/Check';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Slide from '@mui/material/Slide';
import notifyService from '../../../api/notifySerivce';
import PaymentsIcon from '@mui/icons-material/Payments';
// import './dashboard.css';
// import Tanseed6 from './form-page/Tanseed6';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#28a745' : '#308fe8',
    },
}));
const style = {
    p: 4,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dashboardscst = () => {
    const { navigator, setPageLoading } = useContext(DataContext)

    useDidMountEffect(() => {
        // eslint-disable-next-line
        if (!localStorage.getItem('jwttoken')) {
            navigator('/sign-in');
        } else {
            (async () => await getApi())()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [formData, setFormData] = useState({})
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/alldetails/scst/get`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                let response = res.data
                // console.log(response.enableSubmitButton);

                setFormData(response)
                localStorage.setItem('tanseed', response.enableTanseedButton)
                localStorage.setItem('showInfo', response.showAdditionalInfo)
                // localStorage.setItem('showAddInfo',response.showAdditionalInfo)
                // if (response.id !== 0) {
                //     setFormData({})
                //     setFormData(response)
                // }
            }
        })
    }
    const handleValidation2 = () => {
        apiService(`startup/validate`, '', 'post').then((res) => {
            if (res) {
                if (res && res.data && res.data.responseStatus === 'Success') {
                    navigator('/startup/self-declaration/scst')
                }

            }
        })

    }
    const handleValidation = () => {
        // if (formData.optedForTanseed === true) {
        //     debugger
        //     handleClickOpenApply()
        // } else {
        //     navigator('/startup/self-declaration')
        // }

        if (formData.enableSubmitButton === 1) {
            if (formData.enableSubmitButton === 1) {
                apiService(`startup/startupdeclaration/get`, '', 'get').then((res) => {
                    if (res?.data?.response?.responseStatus === 'Success') {
                        handleValidation2()
                    }
                })
            }
            else {
                handleValidation2()
            }


        } else {
            apiService(`startup/startupdeclaration/get`, '', 'get').then((res) => {
                if (res) {
                    let status
                    if (formData.enableSubmitButton === 4) {
                        status = 1
                    } else if (formData.enableSubmitButton === 6) {
                        status = 2
                    } else if (formData.enableSubmitButton === 8) {
                        status = 3
                    } else if (formData.enableSubmitButton === 10) {
                        status = 4
                    } else if (formData.enableSubmitButton === 11) {
                        status = 29
                    } else if (formData.enableSubmitButton === 12) {
                        status = 30
                    }
                    else if (formData.enableSubmitButton === 13) {
                        status = 31
                    }
                    else if (formData.enableSubmitButton === 14) {
                        status = 32
                    }
                    var response = res.data
                    response.acceptscstTerms = true
                    response.scstStatus = status
                    apiService('startup/startupdeclaration/save', response, 'post').then((res) => {
                        navigator('/startup/all-dashboard/scst')
                    })
                }
            })
        }

    }
    const handleApplicaion = () => {
        navigator('/startup/application/scst')
    }
    const handleFundingFinancials = () => {
        navigator('/startup/funding-financials/scst')
    }
    const handleFounder = () => {
        navigator('/startup/founder/scst')
    }
    const handleDocumnet = () => {
        navigator('/startup/documents-upload/scst')
    }
    const handlePreliminaryDocumentPart1 = () => {
        navigator('/startup/ldd-document/scst')
    }
    const handlePreliminaryDocumentPart2 = () => {
        navigator('/startup/ldd-document1/scst')
    }

    const handleFDDAdditional1 = () => {
        navigator('/startup/scst/fdd-document/additionaldoc-1')
    }
    const handleFDDAdditional2 = () => {
        navigator('/startup/scst/fdd-document/additionaldoc-2')
    }
    const handleTermsCondition = () => {
        navigator('/startup/termandcondition/scst')
    }
    const handleIncubationAccelaration = () => {
        navigator('/startup/incubation-accelaration/scst')
    }
    const handleProductMarket = () => {
        navigator('/startup/product-market/scst')
    }
    const handleBusinessStrategy = () => {
        navigator('/startup/business-strategy/scst')
    }
    const handlePostTrancheDocumentPart1 = () => {
        navigator('/startup/cp-document/scst')
    }
    const handlePostTrancheDocumentPart2 = () => {
        navigator('/startup/cs-document/scst')
    }
    // const handleTeam = () => {
    //     navigator('/startup/team/scst')
    // }
    const handleMarketCompetitor = () => {
        navigator('/startup/market&competitors/scst')
    }
    // const handleTractionAchievements = () => {
    //     navigator('/startup/traction-achievements')
    // }
    // const handleFundingUtilisation = () => {
    //     navigator('/startup/funding-utilisation')
    // }
    // const handleDocumentsUpload = () => {
    //     navigator('/startup/documents-upload')
    // }
    // const handletanseed = () => {
    //     navigator('/startup/tanseed')
    // }
    // /startup/documents-upload
    const [openApply, setApplyOpen] = useState(false);

    const handleClickOpenApply = () => {
        setApplyOpen(true);
    };

    const handleApplyClose = () => {
        setApplyOpen(false);
    };

    const [openReview, setReviewOpen] = useState(false);

    const handleClickOpenReview = () => {
        setReviewOpen(true);
    };

    const handleReviewClose = () => {
        setReviewOpen(false);
    };
    const handleBackapp = () => {
        navigator('/startup/info')
        // localStorage.removeItem("routePath");
        localStorage.setItem("routePath", "/startup/info");
    }
    const handleRoute = (path) => {
        navigator(`/startup/scst/fdd-document/${path}`)
    }
    return (
        <>
            <section>
                <div className='container-fluid'>
                    <div className='form_div chart'>
                        {/* <div className="d-flex align-items-center">
                            <span className="back_span" onClick={handleBackapp}>
                                <i className="fas fa-arrow-alt-circle-left"></i>
                            </span>{" "}
                            <h6 className="ml-2 mb-0">Startup Details</h6>
                        </div>
                        <hr /> */}
                        <div className='chart-head'>
                            <div className='d-flex'>
                                <span className="c-pointer" onClick={handleBackapp}>
                                    {/* <i className="fas fa-arrow-alt-circle-left"></i> */}
                                    <img src='/images/Fig-back.png' className='img-fluid' alt='' />
                                </span>
                                <div className='ml-3'>
                                    <h5 className='font-weight-bold mb-3'> Begin Your Journey: Select a Section to Start</h5>
                                    <p className='p-size'>Start with any section and return to complete others as you wish. Your progress is saved automatically</p>
                                    <div className='d-flex align-item-center'>
                                        {/* <h5 className='font-weight-bold mb-0 c-yellow'>TANSEED 6.0 is Live </h5> */}
                                        {/* <button className='apply_span ml-3' onClick={handleClickOpenReview}>View More</button> */}
                                    </div>
                                </div>
                                <div className='top-btn-scst'>
                                    {/* <button className='button-scst'><UploadFileRoundedIcon />  Download Checklist</button>
                                    <button className='button-scst'><QuestionAnswerIcon />  Need Help,Chat with us</button> */}
                                    {/* {
                                        formData.enableTanseedButton === 0 && (<button className='apply_span ml-3' onClick={handleClickOpenApply}> Apply for TANSEED 6.0 <img src='/images/sigup-btn.png' className='arrow-img-sml' alt='' /></button>)
                                    }
                                    {
                                        formData.enableTanseedButton === 1 && (<button className='apply_span ml-3' onClick={handleClickOpenReview}> Apply for TANSEED 6.0 <img src='/images/sigup-btn.png' className='arrow-img-sml' alt='' /></button>)
                                    } */}
                                    {/* {
                                        formData.enableTanseedButton === 2 && (<div><p className='ml-2 font-weight-bold'>TANSEED 6.0 Applied !</p><button className='apply_span ml-2' onClick={handletanseed}><PaymentsIcon /> Check your Application Status</button></div>)
                                    } */}
                                </div>
                            </div>
                        </div>
                        <hr className='hr-wid' />
                        <div className='main-rec-box'>
                            <div className='row'>
                                <div className='col-lg-6 px-4'>
                                    <div className={formData.detailsPercent === 100 ? "rectangle-com   mx-4" : "rectangle  mx-4"} onClick={handleApplicaion}>
                                        {
                                            (formData.detailsPercent === 100) ? (
                                                <div className="circle1 font-weight-bold bg-green">
                                                    <CheckIcon />
                                                </div>
                                            ) : (<div className="circle font-weight-bold">1</div>)
                                        }

                                        <div className='rec-box' >
                                            <div>
                                                <h6 className='font-weight-bold'>Startup Details  <ErrorRoundedIcon /></h6>
                                                <p>Brand logo, Incorporation Certificate</p>
                                            </div>
                                            <div className='rec-box1'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <BorderLinearProgress variant="determinate" value={formData.detailsPercent} />
                                                </Box>
                                                <p className='mt-3 right' >{formData.detailsPercent}% Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 px-4'>
                                    <div className={formData.fundingFinancialsPercent === 100 ? "rectangle-com   mx-4" : "rectangle  mx-4"} onClick={handleFundingFinancials}>
                                        {
                                            (formData.fundingFinancialsPercent === 100) ? (
                                                <div className="circle1 font-weight-bold bg-green">
                                                    <CheckIcon />
                                                </div>
                                            ) : (<div className="circle font-weight-bold">2</div>)
                                        }
                                        <div className='rec-box'>
                                            <div>
                                                <h6 className='font-weight-bold'>Funding & Financials  <ErrorRoundedIcon /></h6>
                                                <p>Your funding and financial infomation</p>
                                            </div>
                                            <div className='rec-box1'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <BorderLinearProgress variant="determinate" value={formData.fundingFinancialsPercent} />
                                                </Box>
                                                <p className='mt-3 right' >{formData.fundingFinancialsPercent}% Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 px-4'>
                                    <div className={formData.teamInfoPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={handleFounder}>
                                        {
                                            (formData.teamInfoPercent === 100) ? (
                                                <div className="circle1 font-weight-bold bg-green">
                                                    <CheckIcon />
                                                </div>
                                            ) : (<div className="circle font-weight-bold">3</div>)
                                        }
                                        <div className='rec-box'>
                                            <div>
                                                <h6 className='font-weight-bold'>Founders Info  <ErrorRoundedIcon /></h6>
                                                <p>Your team information</p>
                                            </div>
                                            <div className='rec-box1'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <BorderLinearProgress variant="determinate" value={formData.teamInfoPercent} />
                                                </Box>
                                                <p className='mt-3 right' >{formData.teamInfoPercent}% Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 px-4'>
                                    <div className={formData.incAccDetailsPercent === 100 ? "rectangle-com   mx-4" : "rectangle  mx-4"} onClick={handleIncubationAccelaration}>
                                        {
                                            (formData.incAccDetailsPercent === 100) ? (
                                                <div className="circle1 font-weight-bold bg-green">
                                                    <CheckIcon />
                                                </div>
                                            ) : (<div className="circle font-weight-bold">4</div>)
                                        }
                                        <div className='rec-box'>
                                            <div>
                                                <h6 className='font-weight-bold'>Incubation/Acceleration details<ErrorRoundedIcon /></h6>
                                                <p>Incubator or accelerator with some organization</p>
                                            </div>
                                            <div className='rec-box1'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <BorderLinearProgress variant="determinate" value={formData.incAccDetailsPercent} />
                                                </Box>
                                                <p className='mt-3 right' >{formData.incAccDetailsPercent}% Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    formData.showAdditionalInfo === true && (
                                        <>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.productMarketPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={handleProductMarket}>
                                                    {
                                                        (formData.productMarketPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">5</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Product & Market Fit  <ErrorRoundedIcon /></h6>
                                                            <p>Your product and its market fit</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.productMarketPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.productMarketPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.businessStrategyPercent === 100 ? "rectangle-com   mx-4" : "rectangle  mx-4"} onClick={handleBusinessStrategy}>
                                                    {/* <div className="circle font-weight-bold">6</div> */}
                                                    {
                                                        (formData.businessStrategyPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">6</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Business Strategy  <ErrorRoundedIcon /></h6>
                                                            <p>How you Monitise your Product</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.businessStrategyPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.businessStrategyPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.marketAndCompetitorsPercent === 100 ? "rectangle-com   mx-4" : "rectangle  mx-4"} onClick={handleMarketCompetitor}>
                                                    {
                                                        (formData.marketAndCompetitorsPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">7</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Market & Competitors  <ErrorRoundedIcon /></h6>
                                                            <p>Market & Competitors information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.marketAndCompetitorsPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.marketAndCompetitorsPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.showDeepDiveInfo === true && (
                                        <>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.documentsPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={handleDocumnet}>
                                                    {
                                                        (formData.documentsPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">8</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Deep Dive Documents Upload  <ErrorRoundedIcon /></h6>
                                                            <p>Your Deep Dive information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.documentsPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.documentsPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.showLddInfo === true && (
                                        <>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionOnePercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={handlePreliminaryDocumentPart1}>
                                                    {
                                                        (formData.lddSectionOnePercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">9</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Due Diligence Part 1<ErrorRoundedIcon /></h6>
                                                            <p>Information from the Company</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionOnePercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionOnePercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionTwoPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={handlePreliminaryDocumentPart2}>
                                                    {
                                                        (formData.lddSectionTwoPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">10</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Due Diligence Part 2<ErrorRoundedIcon /></h6>
                                                            <p>Certificate from a Practising Company Secretary</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionTwoPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionTwoPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.showLddInfo === true && (
                                        <>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.fddPriorityDocumentsPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('priority')}>
                                                    {
                                                        (formData.fddPriorityDocumentsPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">11</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Due Diligence Part 3<ErrorRoundedIcon /></h6>
                                                            <p>Priority Documents</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.fddPriorityDocumentsPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.fddPriorityDocumentsPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.fddSectionOnepercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleFDDAdditional1()}>
                                                    {
                                                        (formData.fddSectionOnepercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">12</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Due Diligence Part 4<ErrorRoundedIcon /></h6>
                                                            <p>Additional Document - 1</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.fddSectionOnepercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.fddSectionOnepercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* FDD 2 */}
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.fddSectionTwoPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleFDDAdditional2()}>
                                                    {
                                                        (formData.fddSectionTwoPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">13</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>Due Diligence Part 5<ErrorRoundedIcon /></h6>
                                                            <p>Additional Document - 2</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.fddSectionTwoPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.fddSectionTwoPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            {/* <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionApercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-a')}>
                                                    {
                                                        (formData.lddSectionApercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">12</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - A   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionApercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionApercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionBpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-b')}>
                                                    {
                                                        (formData.lddSectionBpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">13</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - B   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionBpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionBpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionCpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-c')}>
                                                    {
                                                        (formData.lddSectionCpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">14</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - C   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionCpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionCpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionDpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-d')}>
                                                    {
                                                        (formData.lddSectionDpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">15</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - D   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionDpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionDpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionEpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-e')}>
                                                    {
                                                        (formData.lddSectionEpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">16</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - E   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionEpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionEpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionFpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-f')}>
                                                    {
                                                        (formData.lddSectionFpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">17</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - F   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionFpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionFpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionGpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-g')}>
                                                    {
                                                        (formData.lddSectionGpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">18</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - G   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionGpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionGpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionHpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-h')}>
                                                    {
                                                        (formData.lddSectionHpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">19</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - H   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionHpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionHpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionIpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-i')}>
                                                    {
                                                        (formData.lddSectionIpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">20</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - I   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionIpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionIpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionJpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-j')}>
                                                    {
                                                        (formData.lddSectionJpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">21</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - J   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionJpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionJpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionKpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-k')}>
                                                    {
                                                        (formData.lddSectionKpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">22</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - K   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionKpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionKpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionLpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-l')}>
                                                    {
                                                        (formData.lddSectionLpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">23</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - L   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionLpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionLpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionMpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-m')}>
                                                    {
                                                        (formData.lddSectionMpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">24</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - M   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionMpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionMpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionNpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-n')}>
                                                    {
                                                        (formData.lddSectionNpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">25</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - N   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionNpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionNpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionOpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-o')}>
                                                    {
                                                        (formData.lddSectionOpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">26</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - O   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionOpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionOpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionPpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-p')}>
                                                    {
                                                        (formData.lddSectionPpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">27</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - P   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionPpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionPpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionQpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-q')}>
                                                    {
                                                        (formData.lddSectionQpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">28</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - Q   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionQpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionQpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionRpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-r')}>
                                                    {
                                                        (formData.lddSectionRpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">29</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - R   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionRpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionRpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionSpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-s')}>
                                                    {
                                                        (formData.lddSectionSpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">30</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - S   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionSpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionSpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionTpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-t')}>
                                                    {
                                                        (formData.lddSectionTpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">31</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - T   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionTpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionTpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionUpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-u')}>
                                                    {
                                                        (formData.lddSectionUpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">32</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - U   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionUpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionUpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionVpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-v')}>
                                                    {
                                                        (formData.lddSectionVpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">33</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - V   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionVpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionVpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionWpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-w')}>
                                                    {
                                                        (formData.lddSectionWpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">34</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - W   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionWpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionWpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionXpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-x')}>
                                                    {
                                                        (formData.lddSectionXpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">35</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - X   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionXpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionXpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionYpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-y')}>
                                                    {
                                                        (formData.lddSectionYpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">36</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - Y   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionYpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionYpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionZpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-z')}>
                                                    {
                                                        (formData.lddSectionZpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">37</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - Z   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionZpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionZpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionAApercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-aa')}>
                                                    {
                                                        (formData.lddSectionAApercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">38</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - AA   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionAApercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionAApercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.lddSectionABpercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={() => handleRoute('section-ab')}>
                                                    {
                                                        (formData.lddSectionABpercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">39</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>FDD Documents Upload Section - AB   <ErrorRoundedIcon /></h6>
                                                            <p>Your FDD information</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.lddSectionABpercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.lddSectionABpercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </>
                                    )
                                }
                            </div>
                            {/* {(formData.showCpInfo === true && formData.showCsInfo === false ) && (<div className='mt-5 ml-4'>
                                <h6 style={{ color: "blue" }}>CP Documents:</h6>
                            </div>) }
                            {(formData.showCpInfo === true && formData.showCsInfo === true) && (<div className='mt-5 ml-4'>
                                <h6 style={{ color: "blue" }}>CP/CS Documents:</h6>
                            </div>) } */}
                            {formData.showCpInfo && (
                                <div className='mt-5 ml-4'>
                                    <h6 style={{ color: "blue" }}>
                                        {formData.showCsInfo ? "CP/CS Documents:" : "CP Documents:"}
                                    </h6>
                                </div>
                            )}
                            <div className='row'>

                                {
                                    formData.showCpInfo === true && (
                                        <>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.investmentCPDocumentsPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={handlePostTrancheDocumentPart1}>
                                                    {
                                                        (formData.investmentCPDocumentsPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">1</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>CP Investment Documents<ErrorRoundedIcon /></h6>
                                                            <p>Information from the Company</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.investmentCPDocumentsPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.investmentCPDocumentsPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.showCsInfo === true && (
                                        <>
                                            <div className='col-lg-6 px-4'>
                                                <div className={formData.investmentCSDocumentsPercent === 100 ? "rectangle-com  mx-4" : "rectangle  mx-4"} onClick={handlePostTrancheDocumentPart2}>
                                                    {
                                                        (formData.investmentCSDocumentsPercent === 100) ? (
                                                            <div className="circle1 font-weight-bold bg-green">
                                                                <CheckIcon />
                                                            </div>
                                                        ) : (<div className="circle font-weight-bold">2</div>)
                                                    }
                                                    <div className='rec-box'>
                                                        <div>
                                                            <h6 className='font-weight-bold'>CS Investment Documents<ErrorRoundedIcon /></h6>
                                                            <p>Certificate from a Practising Company Secretary</p>
                                                        </div>
                                                        <div className='rec-box1'>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={formData.investmentCSDocumentsPercent} />
                                                            </Box>
                                                            <p className='mt-3 right' >{formData.investmentCSDocumentsPercent}% Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                            <div className='row'>
                                <div className='col-lg-12 px-3'>
                                    <div className='scst-btn text-right'>
                                        {
                                            formData.enableSubmitButton === 0 && (<button type='button' className='btn-scst' disabled> Submit for validation </button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 1 && (<button type='button' className='btn-scst' onClick={handleValidation}> Submit for validation </button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 2 && (<button type='button' className='btn-scst' onClick={handleValidation}>Resubmit for validation</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 3 && (<button type='button' className='btn-scst' disabled>Submit for First Connect</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 4 && (<button type='button' className='btn-scst' onClick={handleValidation}>Submit for First Connect</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 5 && (<button type='button' className='btn-scst' disabled>Submit for Deep Dive</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 6 && (<button type='button' className='btn-scst' onClick={handleValidation}>Submit for Deep Dive</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 7 && (<button type='button' className='btn-scst' disabled>Submit for Due Diligence</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 8 && (<button type='button' className='btn-scst' onClick={handleValidation}>Submit for Due Diligence</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 11 && (<button type='button' className='btn-scst' onClick={handleValidation}>Submit</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 12 && (<button type='button' className='btn-scst' onClick={handleValidation}>Update & Notify Mentor</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 13 && (<button type='button' className='btn-scst' onClick={handleValidation}>Submit for CP</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 14 && (<button type='button' className='btn-scst' onClick={handleValidation}>Submit for CS</button>)
                                        }
                                        {/* {
                                            formData.enableSubmitButton === 9 && (<button type='button' className='btn-scst' disabled>Submit for FDD</button>)
                                        }
                                        {
                                            formData.enableSubmitButton === 10 && (<button type='button' className='btn-scst' onClick={handleValidation}>Submit for FDD</button>)
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Dialog
                open={openApply}
                TransitionComponent={Transition}
                keepMounted
                className='text-center p-5'
                fullWidth
                maxWidth="md"
            >
                {/* <Tanseed6 handleApplyClose={handleApplyClose} optedForTanseed={formData.optedForTanseed} page={'dashboard'} /> */}
            </Dialog>
            <Dialog
                open={openReview}
                TransitionComponent={Transition}
                keepMounted
                className='text-center p-5'
                fullWidth
                maxWidth="md"
            >
                <Box sx={style} className='modal1 ' >
                    {/* <h5>Apply for TANSEED 6.0</h5>
                    <h5>Please complete the application details to apply</h5> */}
                    <img src='/images/tanseed-logo.png' alt='img-fluid' />
                    <h3 className='head font-weight-bold'>TANFUND 6.0</h3>
                    <h5>The Next Biggest Opportunity to Kickstart Your Startup</h5>
                    <span className='date'>Last date to Apply : <span className='yellow-clr font-weight-bold'>FEBRUARY 7, 2024</span></span>
                    <DialogContent>
                        {/* <DialogContentText id="alert-dialog-slide-description">
                            <span className='white-clr'>It is available under Funding & Financials</span>
                        </DialogContentText> */}
                        {/* <div className='mb-2'>
                            <span className='white-clr'>It is available under Funding & Financials</span>
                        </div> */}
                        <div className='my-2'>
                            <h5>Please complete the application and apply for TANSEED</h5>
                        </div>
                    </DialogContent>
                    <div className='text-center'>
                        {/* <Button variant="contained" className="btn btn-primary submit px-3" onClick={handleReviewClose}>Ok</Button> */}
                        <button className="yellow" onClick={handleReviewClose}>OK</button>
                    </div>
                </Box>
            </Dialog>
        </>
    )
}

export default Dashboardscst