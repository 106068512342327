import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import './scst-page/scst.css'
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import { Badge } from '@mui/material';

const Scsthubavpnavbar = ({ role }) => {
  const { scstRemoveFiters } = useContext(DataContext)
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
    scstRemoveFiters()
  };
  const notificationCount = localStorage.getItem('notificationCount')
  return (
    <nav>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/${role}/dashboard`} onClick={(e) => {
                handleClick(e);
                scstRemoveFiters();
              }}>Dashboard</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/${role}/startups`} onClick={handleClick}>Startups</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/${role}/evaluator`} onClick={(e) => {
                handleClick(e);
                scstRemoveFiters();
              }}>Evaluators</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/${role}/assign-evaluator`} onClick={(e) => {
                handleClick(e);
                scstRemoveFiters();
              }}>Assign Evaluators</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink
                className="nav-link"
                to={`/${role}/approval-pending`}
                onClick={(e) => {
                  handleClick(e);
                  scstRemoveFiters();
                }}
              >
                Approval Pending
                <span className='pos-top1'>
                  {/* <Badge badgeContent={notificationCount} color="primary" sx={{width:'10px'}}>
                                </Badge> */}
                  {notificationCount > 0 && (
                    <Badge color="primary" sx={{ width: '10px' }}>
                      {/* <NotificationsIcon sx={{ color: "#253b80" }} /> */}
                      <img src="/images/notification.gif" alt="" style={{ width: '28px' }} />
                    </Badge>
                  )}

                </span>
              </NavLink>
            </Typography>

            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/scsthubavp/mentortab`} onClick={handleClick}>Mentorship Startup</NavLink>
            </Typography>
            {/* <Typography sx={{ minWidth: 100 }}>

              <NavLink className="nav-link" to={`/${role}/report`} onClick={handleClick}>Report</NavLink>

            </Typography> */}
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Scsthubavpnavbar