import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import CpDocuments from "./CpDocuments";
import CsDocuments from "./CsDocuments";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const CpcsLayout = ({startupValue,idNumber}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="overallTab">
      <Tabs
        className="verticalTabCSS"
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        {/* <Tab
          className="verticalLable"
          label="CP Documents"
          {...a11yProps(0)}
        /> */}
       {/* {startupValue.status >= 26 &&( */}
        <Tab
          className="verticalLable"
          label="CS Documents"
          {...a11yProps(0)}
        /> 
        {/* )}       */}
      </Tabs>
      <Box sx={{ p: 3 }}>
        {/* <TabPanel value={value} index={0}>
            <CpDocuments idNumber={idNumber}/>
        </TabPanel> */}
        <TabPanel value={value} index={0}>
            <CsDocuments idNumber={idNumber}/>
        </TabPanel>
      </Box>
    </div>
  );
};

export default CpcsLayout;

