import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import apiService from '../../../api/apiService'
import notifyService from '../../../api/notifySerivce'
import Viewimage from '../../../api/Viewimage'

const DocumentRoomAdminList = ({ tab, page, setPage, status, searchInputValue, count, setCount, startupId, listApi, listData, setListData, rowsPerPage, setRowsPerPage }) => {
    const [openModal, setOpenModal] = useState(false)
    const [listId, setListId] = useState(0)

    const { tableIndexValue, setPageLoading, } = useContext(DataContext)

    useDidMountEffect(() => {
        listApi(status, startupId);
    }, [page, rowsPerPage, searchInputValue]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const OpensureModal = (id) => {
        setOpenModal(true)
        setListId(id)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }



    const submit = () => {
        setPageLoading(true)
        var req = {
            id: listId,
            status: 3,
            startupId: startupId,
        };
        debugger
        apiService('document/room/request/reponse/save', req, 'post')
            .then((res) => {
                setPageLoading(false)
                if (res && res.data && res.data.responseStatus === "Success") {
                    notifyService("success", "Success", "Saved successfully !!");
                    handleCloseModal()
                    listApi()
                }
            }).catch((err) => {
                console.log(err);
            });
    }
    const [isFullTextVisible1, setIsFullTextVisible1] = useState({})
    const toggleText1 = (index) => {
        setIsFullTextVisible1(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const [isFullTextVisible2, setIsFullTextVisible2] = useState({})
    const toggleText2 = (index) => {
        setIsFullTextVisible2(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const truncateTexts = (text, charLimit) => {
        if (typeof text !== 'string') return '';
        return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
    };
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value, formet) => {
        debugger
        setViewImage(value);
        if (formet === "xlsx" || formet === "docx" || formet === "zip" || formet === "txt") {
            const link = document.createElement('a');
            link.href = value;
            // link.download = 'excelfile.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            setOpen(true);
        }
    };
    // const handleClickOpen = (value, formet) => {
    //     debugger
    //     setViewImage(value);
    //     if (formet === "xlsx") {
    //         const link = document.createElement('a');
    //         link.href = value;
    //         // link.download = 'excelfile.xlsx';
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     }
    //     else if (formet === "docx") {
    //         const link = document.createElement('a');
    //         link.href = value;
    //         // link.download = 'excelfile.docs';
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     }
    //     else {
    //         setOpen(true);
    //     }
    // };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <div className='mt-4'>

                {listData.length > 0 ? (<TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Subject</TableCell>
                                <TableCell align="center">Body</TableCell>
                                <TableCell align="center">Requested By</TableCell>
                                <TableCell align="center">Requested Time</TableCell>
                                {status === 4 && (<TableCell align="center">Submitted Time</TableCell>)}

                                {status === 4 && (<TableCell align="center">Status</TableCell>)}
                                {status === 0 && (<TableCell align="center">Action</TableCell>)}
                                {status === 4 && (<TableCell align="center">Document</TableCell>)}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listData && (
                                <>
                                    {listData.map((list, index) => (
                                        <TableRow key={index}>
                                            <>
                                                <TableCell component="th" scope="row" align="center">
                                                    {tableIndexValue(rowsPerPage, page, index)}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {list.subject ? (
                                                        <>
                                                            {isFullTextVisible1[index]
                                                                ? list.subject
                                                                : truncateTexts(list.subject || '', 20)}
                                                            {list.subject && list.subject.length > 20 && (
                                                                <h6
                                                                    onClick={() => toggleText1(index)}
                                                                    style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                >
                                                                    {isFullTextVisible1[index] ? " Read Less" : " Read More"}
                                                                </h6>
                                                            )}
                                                        </>) : (("-"))}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {list.body ? (
                                                        <>
                                                            {isFullTextVisible2[index]
                                                                ? list.body
                                                                : truncateTexts(list.body || '', 20)}
                                                            {list.body && list.body.length > 20 && (
                                                                <h6
                                                                    onClick={() => toggleText2(index)}
                                                                    style={{ cursor: "pointer", color: "#7F9AD2", fontSize: '14px' }}
                                                                >
                                                                    {isFullTextVisible2[index] ? " Read Less" : " Read More"}
                                                                </h6>
                                                            )}
                                                        </>) : (("-"))}
                                                </TableCell>
                                                <TableCell align="center">{list.requestedBy}</TableCell>
                                                <TableCell align="center">{list.requestedTime}</TableCell>
                                                {status === 0 && (

                                                    // <TableCell align="center">
                                                    //     <Button variant='contained' size="small" disabled={list.enableRequestClosed === false} onClick={() => OpensureModal(list.id)}
                                                    //         sx={{
                                                    //             textTransform: 'none',
                                                    //             '&:hover': { background: 'transparent', color: 'blue', border: '1px solid blue' }, "&.Mui-disabled": { cursor: "not-allowed" } 
                                                    //         }}>Close Request</Button>
                                                    // </TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            disabled={!list.enableRequestClosed}
                                                            onClick={() => OpensureModal(list.id)}
                                                            className="close-req-btn"
                                                            sx={{
                                                                textTransform: "none",
                                                                '&:hover': {
                                                                    background: "transparent",
                                                                    color: "blue",
                                                                    border: "1px solid blue"
                                                                },
                                                                "&.Mui-disabled": {
                                                                    pointerEvents: "auto",
                                                                    cursor: "not-allowed"
                                                                },
                                                                "&.Mui-disabled:hover": {
                                                                    background: "#0000001f",
                                                                    color: "#0000001f",
                                                                    border: "none"
                                                                }
                                                            }}
                                                        >
                                                            Close Request
                                                        </Button>
                                                    </TableCell>

                                                )}

                                                {status === 4 && (<TableCell align="center">

                                                    <>
                                                        {list.submittedTime ?? "-"}
                                                    </>


                                                </TableCell>)
                                                }


                                                {status === 4 && (<TableCell align="center">
                                                    {list.status === 1 && (<p>Submitted</p>)}
                                                    {list.status === 2 && (<p>Responded</p>)}
                                                    {list.status === 3 && (<p>Request Closed</p>)}

                                                </TableCell>)}
                                                {/* {tab !== "pending" ? (<TableCell align="center"><Tooltip title="Click to Download"><img src="/images/img/google-docs.png" alt="Document" style={{ width: '100%', maxWidth: '30px', cursor: 'pointer' }} /></Tooltip></TableCell>) : (<></>)} */}
                                                {status === 4 && (<TableCell align="center">
                                                    {list.docUrl ? (<>
                                                        <Tooltip title="View Document" arrow>
                                                            <img src="/images/img/file (1).png" alt="Document" style={{ width: '100%', maxWidth: '30px', cursor: 'pointer' }}
                                                                onClick={() => handleClickOpen(list.docUrl, list.formet)}
                                                            />
                                                        </Tooltip></>) : (<>
                                                            {list.status === 2 && ("NA")}
                                                            {list.status === 3 && ("-")}
                                                        </>)}

                                                </TableCell>)}
                                            </>
                                        </TableRow>
                                    ))}
                                </>
                            )}

                        </TableBody>
                        <caption>
                            <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                style={{ float: 'right' }}
                            /></caption>
                    </Table>
                </TableContainer>) : (<>
                    <div className="nodata_tag">
                        <img
                            src="/images/Tanfundlogo-black-1.png"
                            className="no_data_logo"
                            alt="Tanfund_logo"
                        />
                        <h4>No Records Found</h4>
                    </div>
                </>)}
            </div>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={() => submit()}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Viewimage
                viewImage={viewImage}
                open={open}
                handleClose={handleClose}
            />
        </div>
    )
}

export default DocumentRoomAdminList
